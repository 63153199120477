import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { SocketType } from 'src/app/core/enums/socket-type.enum';
import { ShellService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  readonly activeUserId = localStorage.getItem('userId');
  socket = webSocket(`${environment.distServiceWsPath}/?userId=${this.activeUserId}`);

  socketObservables = {
    lrjPercentageUpdate: new Subject(),
    notification: new Subject(),
  };

  constructor(private shellService: ShellService) {
    this.listenSocket();
  }

  listenSocket() {
    this.socket.subscribe((socketMessage: any) => {
      switch (socketMessage.socketMsgType) {
        case SocketType.LrjPercentageUpdate:
          this.socketObservables.lrjPercentageUpdate.next(socketMessage.data);
          break;
        case SocketType.Notification:
          this.socketObservables.notification.next(socketMessage.data);
          break;

        case SocketType.AuthChanged:
          this.getUserAccess();
          break;
      }
    });
  }

  private getUserAccess() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.shellService.getUserAccess(userId).subscribe((userAccess: any) => {
        localStorage.setItem('policies', JSON.stringify(userAccess.policies));
        localStorage.setItem('accessibleData', JSON.stringify(userAccess.accessibleData));
      });
    }
  }
}
