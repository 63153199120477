<mat-drawer-container>
  <mat-drawer
    class="menu-wrapper"
    mode="over"
    #menuDrawer
    position="end"
    [disableClose]="false"
    (closed)="closeDrawer()">
    <app-extra-time-menu
      *ngIf="selectedMenuType === menuType.ExtraTime"
      [selectedRoomId]="selectedNotification.data.resourceParentId"
      [extraTimeReqId]="selectedNotification.data.planExtensionRequestId"
      [testName]="selectedNotification.data.testName"
      [requestedTime]="selectedNotification.data.extendedDayNumber"
      [description]="selectedNotification.data.desc"
      [newDate]="selectedNotification.data.newDate"
      [testId]="selectedNotification.data.testId"
      [isInDrawer]="true"
      (closeMenu)="closeDrawer()"></app-extra-time-menu>
  </mat-drawer>
  <mat-drawer-content>
    <div
      class="notification-icon-wrapper clickable"
      [ngClass]="{ 'selected-color': activePageName === 'notifications' }"
      (click)="openNotificationDialog()"
      [ngSwitch]="currentNotificationView">
      <mat-icon
        class="notifications"
        *ngSwitchCase="notificationView.clicked"
        [ngClass]="{ 'selected-icon-color': activePageName === 'notifications' }">
        notifications
      </mat-icon>
      <mat-icon
        class="notifications"
        *ngSwitchCase="notificationView.notHasNotification"
        [ngClass]="{ 'selected-icon-color': activePageName === 'notifications' }">
        notifications_none
      </mat-icon>
      <mat-icon
        class="notifications"
        [ngClass]="{ 'selected-icon-color': activePageName === 'notifications' }"
        *ngSwitchCase="notificationView.hasNotification"
        matBadge="{{ unreadNotificationCount > 99 ? 99 + '+' : unreadNotificationCount }}"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matBadgeHidden]="!unreadNotificationCount">
        notifications
      </mat-icon>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
