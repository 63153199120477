<div class="lab-selector-wrapper">
  <div fxFlex fxLayout="column">
    <ng-container *ngTemplateOutlet="stationSelectionDialog"></ng-container>
  </div>
</div>

<ng-template #stationSelectionDialog>
  <button mat-icon-button [matMenuTriggerFor]="menu" fxLayout="row" class="menu-btn clickable selected-lab-name">
    {{ selectedLab?.name }}
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="lab-selection-dialog-mat-menu">
    <mat-radio-group>
      <mat-radio-button
        *ngFor="let lab of laboratories"
        (click)="changeLab(lab)"
        [value]="lab.id"
        matTooltip="{{ lab.name }}"
        [checked]="isSelectedLab(lab)">
        {{ lab.name }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-menu>
</ng-template>
