<div
  class="rt-dialog-wrapper save-as-template-dialog"
  fxLayout="column"
  fxLayoutGap="14px"
  fxFlexFill
  fxLayoutAlign="space-between stretch">
  <div class="rt-dialog-header-container">
    <div fxFlex class="rt-dialog-header-text">{{ '484' | translate: 'Save as Template' }}</div>
    <div class="rt-dialog-close-icon-wrapper">
      <mat-icon class="clickable" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
  <div class="rt-dialog-content" fxFlex fxLayout="column" fxLayoutGap="24px">
    <div fxLayout="column" class="options" fxLayoutGap="7px">
      <div class="form-header">{{ '263' | translate: 'Header' }}</div>
      <input type="text" #templateName (input)="onChange($event.target)" />
    </div>
  </div>
  <div fxLayoutAlign="end end" class="rt-dialog-footer save-template-footer" fxLayout="row" fxLayoutGap="16px">
    <button class="rt-btn-lg rt-btn-secondary" (click)="closeDialog()">{{ '109' | translate: 'Cancel' }}</button>
    <button
      class="rt-btn-lg"
      [disabled]="saveButtonDisable"
      [ngClass]="saveButtonDisable ? 'rt-btn-disable' : 'rt-btn-primary'"
      (click)="saveAsTemplate(templateName.value); closeDialog()">
      {{ '483' | translate: 'Save' }}
    </button>
  </div>
</div>
