import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  private readonly baseURI = 'api/organization';

  constructor(private http: HttpClient) {}

  getAllEstablishments(userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/getAllEstablishments`, {});
  }

  getAllEstablishmentsForAuth(userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/getAllEstablishmentsForAuth`, {});
  }

  getUserEstablishments(userId: string): Observable<any> {
    return this.http.get(`${this.baseURI}/getUserEstablishments/${userId}`);
  }

  addEstablishment(request: any, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/addEstablishment`, {});
  }

  updateEstablishment(request: any, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/updateEstablishment`, {});
  }

  deleteEstablishment(id: string, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/deleteEstablishment`, {});
  }

  getOrgLevels(): Observable<any> {
    return this.http.get(`${this.baseURI}/getOrgLevels`);
  }

  addEstablishmentMember(request: any, userId: string, organizationId: string) {
    return this.http.post(`${this.baseURI}/addEstablishmentMember`, {});
  }

  deleteEstablishmentMember(deleteUserId: string, activeUserId: string, organizationId: string) {
    return this.http.post(`${this.baseURI}/deleteEstablishmentMember`, {});
  }
}
