import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectButtonDataShareService {
  selectedRow = new Subject<any>();

  constructor() {}
}
