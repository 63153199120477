import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-delete-sensor-confirmation-dialog',
  templateUrl: './delete-sensor-confirmation-dialog.component.html',
  styleUrls: ['./delete-sensor-confirmation-dialog.component.scss'],
})
export class DeleteSensorConfirmationDialogComponent {
  @Output() closeDialogEvent = new EventEmitter();

  onApprove() {
    this.closeDialogEvent.emit(true);
  }

  onCancel() {
    this.closeDialogEvent.emit(false);
  }
}
