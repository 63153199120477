<div class="more-action-dialog-wrapper">
  <button mat-menu-item (click)="cancel()" *ngIf="longRunningJob.state !== lrjStatus.Completed">
    <mat-icon class="menu-action-icon menu-icon">close</mat-icon>
    <span class="more-actions-text">{{ '109' | translate: 'Cancel' }}</span>
  </button>
  <button mat-menu-item (click)="download()" *ngIf="longRunningJob.state === lrjStatus.Completed">
    <mat-icon class="menu-action-icon menu-icon">
      {{ longRunningJob?.type === lrjType.Report ? 'picture_as_pdf' : 'grid_on' }}
    </mat-icon>
    <span class="more-actions-text">{{ '191' | translate: 'Download Report' }}</span>
  </button>
  <div *appAccessControl="getLrjDeleteDefAccessParams()">
    <mat-divider *ngIf="longRunningJob.state === lrjStatus.Completed"></mat-divider>
  </div>
  <div *appAccessControl="getLrjDeleteDefAccessParams()">
    <button
      mat-menu-item
      (click)="delete()"
      class="menu-selection-item-with-warning"
      *ngIf="longRunningJob.state === lrjStatus.Completed">
      <mat-icon>delete_outline</mat-icon>
      <span>{{ '167' | translate: 'Delete Report' }}</span>
    </button>
  </div>
</div>
