import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  header = '';
  confirmationQuestion = '';
  confirmText = '';
  rejectText = '';
  approved = false;
  isDanger = false;
  closeAllDialogs = true;
  valueNumber = 0;
  isValueNumberInput = false;

  constructor(
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  ngOnInit() {}

  reject() {
    this.approved = false;
    if (this.closeAllDialogs) {
      this.matDialog.closeAll();
    } else {
      this.dialogRef.close();
    }
  }

  approve() {
    this.approved = true;
    if (this.closeAllDialogs) {
      this.matDialog.closeAll();
    } else {
      this.dialogRef.close();
    }
  }

  isValid() {
    return this.isValueNumberInput ? this.valueNumber !== null : true;
  }
}
