import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StationChangedService {
  private newStation: Subject<any> = new Subject();

  setDataToSelectedStation = this.newStation.asObservable();

  selectedStationChanged(data: any) {
    this.newStation.next(data);
  }
}
