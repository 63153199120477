export enum NotificationType {
  ExtraTimeRequest = 1,
  LrjCompleted,
  OnboardingRequest,
  TestStart,
  TestEnd,
  UpcomingTestEndReminder,
  NoResponseFromDevice,
  NoResponseFromChannel,
  NoResponseFromAllChannels,
  GenericNotification,
  PlcRoomIncident,
}
