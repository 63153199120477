import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequestParams } from '@rotateknik/rt-std-wc';
import { Observable } from 'rxjs';
import { ActiveTestWithRelatedResourceId, CreateTest, UpdateTest } from 'src/app/shared/interfaces';
import { environment } from '../../../environments/environment';
import { ComponentViewEnum } from '../enums/component-view.enum';
import {
  ActiveTestList,
  AnalogSensors,
  AuthPolicy,
  AuthRoleDefinition,
  AuthSource,
  ComponentViewDetail,
  Establishment,
  EstablishmentLaboratory,
  ExtraTimeRequest,
  FiltersByTableAndUser,
  GateRelation,
  GetLabResourceHierarchyResponse,
  LabInfoWithActiveTests,
  Language,
  LongRunningJob,
  OrganizationSchema,
  PlcIdsByResourceId,
  RmsTmsRelationDefinition,
  SaveFilter,
  ShellRelationDefinition,
  Test,
  TmsRelationDefinition,
  Trace,
  Translation,
  TranslationsKeys,
  Version,
} from '../models';
import { EstablishmentMember } from '../models/establishment-member.model';
import { IsModuleActivePayload } from '../models/is-module-active-payload.model';
import { IsModuleActiveResponse } from '../models/is-module-active-response.model';
import { NotificationData } from '../models/notification-data.model';
import { Plan } from '../models/plan.model';

const baseUrl = environment.shellServicePath;

@Injectable({
  providedIn: 'root',
})
export class ShellService {
  token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getLabs(): Observable<any> {
    return this.http.get(`${baseUrl}/lab/getLabs`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getLabsHierarchicallyWithRmsResources(): Observable<any> {
    return this.http.post(
      `${baseUrl}/lab/getLabsHierarchicallyWithRmsResources`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getLabResourceHierarchy(): Observable<GetLabResourceHierarchyResponse> {
    return this.http.post<GetLabResourceHierarchyResponse>(
      `${baseUrl}/lab/getLabResourceHierarchy`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAllLanguages(): Observable<any> {
    return this.http.post(
      `${baseUrl}/language/getAllLanguages`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUserLang(userId: number, languageId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userSetting/updateUserLanguageByUserId`,
      {
        userSetting: {
          user_id: userId,
          language_id: languageId,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getUserSettingByUserId(userId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userSetting/getUserSettingByUserId`,
      {
        id: userId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUserSettingPdfSetting(userId: number, pdfWidth: number, pdfHeight: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userSetting/updatePdfSetting`,
      {
        user_id: userId,
        pdf_width: pdfWidth,
        pdf_height: pdfHeight,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUserTraceHoverModeSetting(userId: number, traceHoverMode: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/userSetting/updateTraceHoverMode`,
      {
        user_id: userId,
        traceHoverMode,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUserTraceHoverTimeFormatSetting(userId: number, traceHoverTimeFormat: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/userSetting/updateTraceHoverTimeFormat`,
      {
        user_id: userId,
        traceHoverTimeFormat,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUserXAxisTickFormatSetting(userId: number, xAxisTickFormat: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/userSetting/updateXAxisTickFormat`,
      {
        user_id: userId,
        xAxisTickFormat,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createTranslationKeyWithTranslations(translations: TranslationsKeys) {
    return this.http.post(`${baseUrl}/translationKey/createTranslationKeyWithTranslations`, translations, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getRoles(): Observable<any> {
    return this.http.get(`${baseUrl}/authRoleDefinition/getLabRoles`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getRequestFormData(): Observable<any> {
    return this.http.get(`${baseUrl}/authRoleDefinition/getLabRoleRequestFormData`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getSensorCountWithRelatedResourceId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getSensorCountWithRelatedResourceId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getUdaqSensorWithRelatedResourceId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getUdaqSensorWithRelatedResourceId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getUdaqSensor(stationId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getUdaqSensor`,
      {
        stationId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getActiveTestIdWithStationId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getActiveTestIdWithStationId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createTest(test: CreateTest): Observable<{ test: Test; traces: Trace[] }> {
    return this.http.post(`${baseUrl}/relation/createTest`, test, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<{ test: Test; traces: Trace[] }>;
  }

  endTest(testId: number, is_deleted = false) {
    return this.http.post(
      `${baseUrl}/relation/endTest`,
      { test_id: testId, is_deleted },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createUser(userName: string, email: string, password: string): Observable<any> {
    return this.http.post(`${baseUrl}/authentication/createUser`, {
      name: userName,
      email,
      password,
    });
  }

  activateUser(code: number): Observable<any> {
    return this.http.post(`${baseUrl}/authentication/activateUser`, {
      code,
    });
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${baseUrl}/authentication/login`, {
      email,
      password,
    });
  }

  loginWithLdap(email: string, password: string): Observable<any> {
    return this.http.post(`${baseUrl}/authentication/loginWithLdap`, {
      id: email,
      pass: password,
    });
  }

  resetPassword(email: string, password: string): Observable<any> {
    return this.http.post(`${baseUrl}/authentication/resetPassword`, {
      email,
      password,
    });
  }

  activateNewPassword(code: number): Observable<any> {
    return this.http.post(`${baseUrl}/authentication/activateNewPassword`, {
      code,
    });
  }

  loginWithOkta(token: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/authentication/loginWithOkta`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          auid: this.auid,
        },
      },
    );
  }

  loginWithToken(token: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/authentication/loginWithToken`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          auid: this.auid,
        },
      },
    );
  }

  createLabRoleRequest(userId: number | null, roleId: number, labId: number, languageId: number) {
    return this.http.post(
      `${baseUrl}/labRoleRequest/createLabRoleRequest`,
      {
        user_id: userId,
        role_id: roleId,
        lab_id: labId,
        language_id: languageId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateLabRoleRequest(id: number, roleId: number, labId: number, languageId: number) {
    return this.http.post(
      `${baseUrl}/labRoleRequest/updateLabRoleRequest`,
      {
        id,
        role_id: roleId,
        lab_id: labId,
        language_id: languageId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
          domain_id: labId.toString(),
        },
      },
    );
  }

  rejectLabRoleRequest(id: number, labId: number) {
    return this.http.post(
      `${baseUrl}/labRoleRequest/rejectLabRoleRequest`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
          domain_id: labId.toString(),
        },
      },
    );
  }

  approveLabRoleRequest(id: number, labId: number) {
    return this.http.post(
      `${baseUrl}/labRoleRequest/approveLabRoleRequest`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
          domain_id: labId.toString(),
        },
      },
    );
  }

  getLabRoleRequests() {
    return this.http.post(
      `${baseUrl}/labRoleRequest/getLabRoleRequests`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAllAuthSources(): Observable<AuthSource[]> {
    return this.http.post(
      `${baseUrl}/authSource/getAllAuthSources`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthSource[]>;
  }

  getAllAuthSourcesDetailed(): Observable<any> {
    return this.http.post(
      `${baseUrl}/authSource/getAllAuthSourcesDetailed`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthSource[]>;
  }

  deleteAuthSource(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authSource/deleteAuthSource`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  updateAuthSource(source: AuthSource): Observable<any> {
    return this.http.post(
      `${baseUrl}/authSource/updateAuthSource`,
      { authSource: source },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthActionsBySourceId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authAction/getAllAuthActionsBySourceId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthPolicyPermissionsByPolicyId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicyPermission/getAllAuthPolicyPermissionsByPolicyId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthPolicyPermissionsByActionId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicyPermission/getAllAuthPolicyPermissionsByActionId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthPolicyUsersByAuthActionId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicyPermission/getAllAuthPolicyUsersByAuthActionId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  addEditAuthPolicyPermissions(policyPermission: { policyId: number; permissions: any[] }): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicyPermission/addEditAuthPolicyPermissions`,
      { policyPermission: policyPermission },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  addEditPolicesByAuthPolicy(policyId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicyPermission/addEditPolicesByAuthPolicy`,
      { policyId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  addAuthAction(authAction: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/authAction/addAuthAction`,
      { authAction: authAction },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  updateAuthAction(authAction: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/authAction/updateAuthAction`,
      { authAction: authAction },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteAuthAction(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authAction/deleteAuthAction`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthRolePoliciesByRoleId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authRolePolicy/getAllAuthRolePoliciesByRoleId `,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthRoleDefinitions(): Observable<AuthRoleDefinition[]> {
    return this.http.post(
      `${baseUrl}/authRoleDefinition/getAllAuthRoleDefinitions`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthRoleDefinition[]>;
  }

  deleteAuthRoleDefinition(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authRoleDefinition/deleteAuthRoleDefinition`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getRolesByUserId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userRoleDomain/getAllUserRoleByUserId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllUserRoleDetailsByUserId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userRoleDomain/getAllUserRoleDetailsByUserId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getAllAuthPolicies(): Observable<AuthPolicy[]> {
    return this.http.post(
      `${baseUrl}/authPolicy/getAllAuthPolicies`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthPolicy[]>;
  }

  addAuthSource(source: AuthSource): Observable<AuthSource> {
    return this.http.post(
      `${baseUrl}/authSource/addAuthSource`,
      {
        authSource: source,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthSource>;
  }

  addAuthPolicy(policy: AuthPolicy): Observable<AuthPolicy> {
    return this.http.post(
      `${baseUrl}/authPolicy/addAuthPolicy`,
      {
        policy: policy,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthPolicy>;
  }

  addAuthRolePolicy(authRolePolicy: { authRoleDefinitionId: number; authPolicyId: number }): Observable<AuthPolicy> {
    return this.http.post(
      `${baseUrl}/authRolePolicy/addAuthRolePolicy`,
      {
        authRolePolicy,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthPolicy>;
  }

  deleteAuthPolicy(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicy/deleteAuthPolicy`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteAuthRolePolicy(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/authRolePolicy/deleteAuthRolePolicy`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  updateAuthPolicy(policy: AuthPolicy): Observable<any> {
    return this.http.post(
      `${baseUrl}/authPolicy/updateAuthPolicy`,
      {
        policy: policy,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  addAuthRoleDefinition(role: AuthRoleDefinition): Observable<AuthRoleDefinition> {
    return this.http.post(
      `${baseUrl}/authRoleDefinition/addAuthRoleDefinition`,
      {
        authRoleDefinition: role,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthRoleDefinition>;
  }

  updateAuthRoleDefinition(role: AuthRoleDefinition): Observable<AuthRoleDefinition> {
    return this.http.post(
      `${baseUrl}/authRoleDefinition/updateAuthRoleDefinition`,
      {
        authRoleDefinition: role,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AuthRoleDefinition>;
  }

  addUserRole(userId: number, authRoleDefinitionId: number, type: number, value: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/userRoleDomain/addUserRoleDomain`,
      {
        userRoleDomain: { userId, authRoleDefinitionId, type, value: value.toString() },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteUserRole(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userRoleDomain/deleteUserRoleDomain`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteLanguage(id: number): Observable<Language> {
    return this.http.post(
      `${baseUrl}/language/deleteLanguage`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Language>;
  }

  addLanguage(payload: {
    name: string;
    language_settings: { component_id: number; code: string }[];
  }): Observable<Language> {
    return this.http.post(`${baseUrl}/language/addLanguage`, payload, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<Language>;
  }

  getLRJs(requestParams?: FilterRequestParams, searchValue?: string[]): Observable<LongRunningJob[]> {
    return this.http.post(
      `${baseUrl}/longRunningJob/getLRJs`,
      {
        filters: requestParams,
        searchValue,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<LongRunningJob[]>;
  }

  deleteLRJ(id: number) {
    return this.http.post(
      `${baseUrl}/longRunningJob/deleteLRJ`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAllUsers(): Observable<any> {
    return this.http.get(`${baseUrl}/user/getAllUsers`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<any>;
  }

  getUsersById(user_id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/user/getUsersById`,
      { ids: [user_id] },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getEstablishment(): Observable<OrganizationSchema[]> {
    return this.http.get(`${baseUrl}/company/getOrganizationSchema`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<OrganizationSchema[]>;
  }

  addEstablishment(establishment: Establishment): Observable<Establishment> {
    return this.http.post(
      `${baseUrl}/company/addCompany`,
      {
        company: establishment,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Establishment>;
  }

  editEstablishment(establishment: Establishment): Observable<Establishment> {
    return this.http.post(
      `${baseUrl}/company/updateCompany`,
      {
        company: establishment,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Establishment>;
  }

  deleteEstablishment(establishmentId: number): Observable<Establishment> {
    return this.http.post(
      `${baseUrl}/company/deleteCompany`,
      {
        id: establishmentId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Establishment>;
  }

  addEstablishmentMember(establishmentId: number, userId: number): Observable<EstablishmentMember> {
    return this.http.post(
      `${baseUrl}/company/addCompanyUser`,
      {
        companyId: establishmentId,
        userId: userId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EstablishmentMember>;
  }

  deleteEstablishmentMember(establishmentId: number, userId: number): Observable<EstablishmentMember> {
    return this.http.post(
      `${baseUrl}/company/deleteCompanyUser`,
      {
        companyId: establishmentId,
        userId: userId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EstablishmentMember>;
  }

  addLaboratory(laboratory: EstablishmentLaboratory): Observable<EstablishmentLaboratory> {
    return this.http.post(
      `${baseUrl}/lab/addLab`,
      {
        lab: laboratory,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EstablishmentLaboratory>;
  }

  updateLaboratory(laboratory: EstablishmentLaboratory): Observable<EstablishmentLaboratory> {
    return this.http.post(
      `${baseUrl}/lab/updateLab`,
      {
        lab: laboratory,
      },
      {
        headers: {
          domain_id: laboratory.id?.toString() as unknown as string,
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EstablishmentLaboratory>;
  }

  deleteLaboratory(labId: number): Observable<EstablishmentLaboratory> {
    return this.http.post(
      `${baseUrl}/lab/deleteLab`,
      {
        id: labId,
      },
      {
        headers: {
          domain_id: labId.toString() as unknown as string,
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EstablishmentLaboratory>;
  }

  addLabMember(user_id: number, lab_id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/labUser/addLabUser`,
      {
        user_id: user_id,
        lab_id: lab_id,
      },
      {
        headers: {
          domain_id: lab_id.toString() as unknown as string,
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteLabMember(user_id: number, lab_id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/labUser/deleteLabUser`,
      {
        user_id: user_id,
        lab_id: lab_id,
      },
      {
        headers: {
          domain_id: lab_id.toString() as unknown as string,
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  addTeam(team: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/team/addTeam`,
      {
        team: team,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  updateTeam(team: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/team/updateTeam`,
      {
        team: team,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteTeam(teamId: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/team/deleteTeam`,
      {
        id: teamId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  addTeamMember(teamId: number, userId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/team/addTeamUser`,
      {
        userId,
        teamId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteTeamMember(teamId: number, userId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/team/deleteTeamUser`,
      {
        userId,
        teamId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getTranslationsByLanguageId(id: number): Observable<{ result: Translation[] }> {
    return this.http.post(
      `${baseUrl}/translation/getTranslationsByLanguageId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<{ result: Translation[] }>;
  }

  updateTranslation(id: number, text: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/translation/updateTranslation`,
      {
        translation: {
          id,
          text,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getActiveTestsWithLabId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getActiveTestsWithLabId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getActiveTestsByLabId(id: number): Observable<LabInfoWithActiveTests> {
    return this.http.post<LabInfoWithActiveTests>(
      `${baseUrl}/relation/getActiveTestsByLabId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getPlcIdsByResourceIds(ids: number[]): Observable<PlcIdsByResourceId[]> {
    return this.http.post<PlcIdsByResourceId[]>(
      `${baseUrl}/relation/getPlcIdsByResourceIds`,
      {
        ids,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getActiveTestWithUserId(
    id: number,
    requestParams?: FilterRequestParams,
    searchValue?: string[],
  ): Observable<ActiveTestList> {
    return this.http.post(
      `${baseUrl}/relation/getActiveTestWithUserId`,
      { id, filters: requestParams, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ActiveTestList>;
  }

  getFinishedTestWithUserId(id: number, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestWithUserId`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestForExportCreate(id: number, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestForExportCreate`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestForExportInProgress(
    id: number,
    filters: FilterRequestParams,
    searchValue?: string[],
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestForExportInProgress`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestForExported(id: number, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestForExported`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestForImportCreate(id: number, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestForImportCreate`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestForImportInProgress(
    id: number,
    filters: FilterRequestParams,
    searchValue?: string[],
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestForImportInProgress`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestForImported(id: number, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getFinishedTestForImported`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getArchivedTestWithUserId(id: number, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getArchivedTestWithUserId`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createTestView(testId: number, stationId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/createTestView`,
      {
        test_id: testId,
        resource: {
          id: stationId,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestViewTestsByRelatedResourceId(stationId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getTestViewTestsByRelatedResourceId`,
      {
        id: stationId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestViewTestsForArchivedTestsByTestViewId(testViewId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getTestViewTestsForArchivedTestsByTestViewId`,
      {
        id: testViewId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestDetailsForPdfByTestId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getTestDetailsForPdfByTestId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getArchivedTestViewsCount(): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getArchivedTestViewsCount`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getArchivedTestViews(limit: number = 0, offset: number = 0): Observable<any[]> {
    return this.http.post<any[]>(
      `${baseUrl}/relation/getArchivedTestViews`,
      { limit, offset },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createShellRelationDefinition(
    rmsTmsRelationDefinition: RmsTmsRelationDefinition,
  ): Observable<ShellRelationDefinition> {
    return this.http.post(
      `${baseUrl}/relationDefinition/addRelationDefinition`,
      {
        relationDefinition: rmsTmsRelationDefinition,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ShellRelationDefinition>;
  }

  deleteRelation(id: number): Observable<ShellRelationDefinition> {
    return this.http.post(
      `${baseUrl}/relation/deleteRelation`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ShellRelationDefinition>;
  }

  addRelation(relation: GateRelation): Observable<ShellRelationDefinition> {
    return this.http.post(
      `${baseUrl}/relation/addRelation`,
      {
        relation,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ShellRelationDefinition>;
  }

  getActiveTestWithRelatedResourceId(id: number): Observable<ActiveTestWithRelatedResourceId> {
    return this.http.post<ActiveTestWithRelatedResourceId>(
      `${baseUrl}/relation/getActiveTestWithRelatedResourceId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getSensorsWithRelatedResourceId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getSensorsWithRelatedResourceId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getSensorsWithRelatedResourceIds(ids: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getSensorsWithRelatedResourceIds`,
      {
        ids,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getCalibrationSensorsWithRelatedResourceId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/getCalibrationSensorsWithRelatedResourceId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestRelationDefinitions(): Observable<TmsRelationDefinition[]> {
    return this.http.post(
      `${baseUrl}/relationDefinition/getTestRelationDefinitions`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<TmsRelationDefinition[]>;
  }

  getRoomDeviceInfoWithTestId(id: number): Observable<any[]> {
    return this.http.post(
      `${baseUrl}/relation/getRoomDeviceInfoWithTestId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any[]>;
  }

  getActiveTestIdByRelatedResourceId(id: number): Observable<{ isAvailable: boolean; testId: number }> {
    return this.http.post(
      `${baseUrl}/relation/getActiveTestIdByRelatedResourceId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<{ isAvailable: boolean; testId: number }>;
  }

  uploadCertificate(password: string, cert: any): Observable<any[]> {
    const formData: any = new FormData();
    formData.append('password', password);
    formData.append('cert', cert);

    return this.http.post(`${baseUrl}/authentication/uploadCert`, formData, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<any[]>;
  }

  updateTranslationsByLanguageId(
    languageId: number,
    translations: { translation_key_id: number; text: string }[],
  ): Observable<{ isAvailable: boolean; testId: number }> {
    return this.http.post(
      `${baseUrl}/translation/updateTranslationsByLanguageId`,
      {
        language_id: languageId,
        translations,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<{ isAvailable: boolean; testId: number }>;
  }

  getAllExtraTimeRequests(requestParams?: FilterRequestParams): Observable<ExtraTimeRequest[]> {
    return this.http.post(
      `${baseUrl}/relation/getAllPlanExtensionRequests`,
      {
        filters: requestParams,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ExtraTimeRequest[]>;
  }

  createPlanExtensionRequest(
    testId: number,
    requestedTime: number,
    userId: number,
    description: string,
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/createPlanExtensionRequest`,
      {
        test_id: testId,
        extended_day_number: requestedTime,
        user_id: userId,
        description,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  cancelPlan(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/cancelPlan`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  approveExtraTimeRequest(id: number, affectedPlans: Plan[], newDate: Date, testId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/approvePlanExtensionRequest`,
      { id, affected_plans: affectedPlans, new_date: newDate, test_id: testId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getUserAccess(uid?: string): Observable<any> {
    let auid = this.auid;
    if (uid) {
      auid = uid;
    }
    return this.http.post(`${baseUrl}/authorization/getUserAccess`, { auId: auid });
  }

  getLabRoleRequestFormData(): Observable<any> {
    return this.http.get(`${baseUrl}/authRoleDefinition/getLabRoleRequestFormData`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  getFiltersByTableAndUser(tableName: string): Observable<FiltersByTableAndUser[]> {
    return this.http.post(
      `${baseUrl}/tableFilter/getFiltersByTableAndUser`,
      { table_name: tableName, user_id: this.auid },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<FiltersByTableAndUser[]>;
  }

  getActiveFilterOfTable(tableName: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/tableFilter/getActiveFilterOfTable`,
      { table_name: tableName, user_id: this.auid },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  saveFilter(tableFilter: SaveFilter): Observable<FiltersByTableAndUser> {
    return this.http.post(
      `${baseUrl}/tableFilter/saveFilter`,
      { tableFilter },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<FiltersByTableAndUser>;
  }

  saveTableFilterData(id: number, filterModel: any): Observable<any> {
    return this.http.post(
      `${baseUrl}/tableFilter/saveTableFilterData`,
      { tableFilter: { id: id, column_filter: filterModel } },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  changeTableActiveFilter(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/tableFilter/changeTableActiveFilter`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  clearFilterDataById(activeFilterId: number) {
    return this.http.post(
      `${baseUrl}/tableFilter/clearFilterDataById`,
      { id: activeFilterId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteFilterById(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/tableFilter/deleteFilterById`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteUserRoleDomain(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/userRoleDomain/deleteUserRoleDomain`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getNotificationsForUser(requestParams?: FilterRequestParams): Observable<NotificationData[]> {
    return this.http.post(
      `${baseUrl}/notification/getNotificationsForUser`,
      {
        filters: requestParams,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<NotificationData[]>;
  }

  markNotificationAsRead(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/notification/markNotificationAsRead`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  markNotificationsAsRead(ids: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/notification/markNotificationsAsRead`,
      { ids },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteNotificationForUser(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/notification/deleteNotificationForUser`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteNotificationsForUser(ids: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/notification/deleteNotificationsForUser`,
      { ids },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getNotificationCount(): Observable<{ unread: number; total: number }> {
    return this.http.get(`${baseUrl}/notification/getNotificationCount`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<{ unread: number; total: number }>;
  }

  deleteAllNotificationsForUser(): Observable<any> {
    return this.http.get(`${baseUrl}/notification/deleteAllNotificationsForUser`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  markAllNotificationsAsRead(): Observable<any> {
    return this.http.get(`${baseUrl}/notification/markAllNotificationsAsRead`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  getComponentViewDetailsBySourceIds(component: ComponentViewEnum, ids: number[]): Observable<ComponentViewDetail[]> {
    return this.http.post(
      `${baseUrl}/componentView/getComponentViewDetailsBySourceIds`,
      { component: component, source_ids: ids },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ComponentViewDetail[]>;
  }

  getComponentViewDetailsBySourceId(component: ComponentViewEnum, id: number): Observable<ComponentViewDetail> {
    return this.http.post(
      `${baseUrl}/componentView/getComponentViewDetailsBySourceId`,
      { component: component, source_id: id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<ComponentViewDetail>;
  }

  getNotificationConditions(): Observable<any> {
    return this.http.get(`${baseUrl}/notificationCondition/getNotificationConditions`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  createNotificationCondition(
    notificationTemplateId: number,
    name: string,
    value: string,
    compareType: string,
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/notificationCondition/createNotificationCondition`,
      {
        notification_template_id: notificationTemplateId,
        name,
        value,
        compare_type: compareType,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  deleteNotificationCondition(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/notificationCondition/deleteNotificationCondition`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  updateNotificationCondition(
    id: number,
    notificationTemplateId: number,
    name: string,
    value: string,
    compareType: string,
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/notificationCondition/updateNotificationCondition`,
      {
        id,
        notification_template_id: notificationTemplateId,
        name,
        value,
        compare_type: compareType,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  isAuthenticated(): Observable<any> {
    return this.http.get(`${baseUrl}/authentication/isAuthenticated`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  getAllTranslationKeys(): Observable<any> {
    return this.http.post(
      `${baseUrl}/translationKey/getAllTranslationKeys`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateResourceAttributes(
    resourceId: number,
    labId: number,
    attributes: { name: string; value: string }[],
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/relation/updateResourceAttributes`,
      {
        resource_id: resourceId,
        attributes,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
          domain_id: labId.toString(),
        },
      },
    );
  }

  updateTest(test: UpdateTest): Observable<any> {
    return this.http.post(`${baseUrl}/relation/updateTest`, test, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getPlcDoorResourceRelation(): Observable<any> {
    return this.http.post(
      `${baseUrl}/relationDefinition/getPlcDoorResourceRelation`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  isModuleActive(modules: IsModuleActivePayload['modules']): Observable<IsModuleActiveResponse[]> {
    return this.http.post<IsModuleActiveResponse[]>(
      `${baseUrl}/util/isModuleActive`,
      {
        modules,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAllServicesVersion(): Observable<Version[]> {
    return this.http.post<Version[]>(
      `${baseUrl}/util/getAllServicesVersion`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAnalogSensors(): Observable<AnalogSensors[]> {
    return this.http.get(`${baseUrl}/relation/getAnalogSensors`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    }) as Observable<AnalogSensors[]>;
  }
}
