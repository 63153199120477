import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnBoardingCompleteService } from './auth/onboarding-complete.service';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { RMenuComponent } from './core/r-menu/r-menu.component';
import config from './okta.config';
import { SharedModule } from './shared/shared.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent, RMenuComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatButtonToggleModule,
    SharedModule,
    OktaAuthModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatNativeDateModule,
  ],
  providers: [{ provide: OKTA_CONFIG, useValue: config.oidc }, OnBoardingCompleteService, AuthGuardService],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
