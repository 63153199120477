<div class="rt-dialog-wrapper confirm-dialog-wrapper" style="display: flex; flex-direction: column; gap: 12px">
  <div class="rt-dialog-header-container">
    <div fxFlex class="rt-dialog-header-text" [style]="isDanger ? 'color: red;' : ''">{{ header }}</div>
    <div class="rt-dialog-close-icon-wrapper">
      <mat-icon class="clickable" (click)="reject()">close</mat-icon>
    </div>
  </div>
  <div
    class="rt-dialog-content"
    fxFlex
    [style]="isDanger ? 'color: red; white-space: pre-line;' : 'white-space: pre-line'">
    {{ confirmationQuestion }}
  </div>
  <div *ngIf="isValueNumberInput" style="display: flex; align-items: center; gap: 16px; padding: 0 24px">
    <span style="color: var(--color-text)">{{ '628' | translate: 'Value' }}</span>
    <input [(ngModel)]="valueNumber" type="number" />
  </div>
  <div fxLayoutAlign="end end" class="rt-dialog-footer" fxLayout="row" fxLayoutGap="16px">
    <button class="rt-btn-lg rt-btn-secondary" (click)="reject()">
      {{ rejectText }}
    </button>
    <button
      class="rt-btn-lg rt-btn-primary"
      [ngClass]="isValid() ? '' : 'rt-btn-disable'"
      (click)="approve()"
      [disabled]="!isValid()"
      [style]="isDanger ? 'background-color: red;' : ''">
      {{ confirmText }}
    </button>
  </div>
</div>
