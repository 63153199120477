import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-r-menu',
  templateUrl: './r-menu.component.html',
  styleUrls: ['./r-menu.component.scss'],
})
export class RMenuComponent implements OnInit {
  @Input() menuState = false;
  @Output() extended = new EventEmitter();
  constructor() {}
  ngOnInit(): void {}

  public toggleMenu(): void {
    this.menuState = this.menuState ? false : true;
    this.extended.emit(this.menuState);
  }
}
