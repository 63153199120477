import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnBoardingCompleteService as OnBoardingComplete } from './auth/onboarding-complete.service';
import { LoginComponent } from './shared/components/login/login.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { OnboardingComponent } from './shared/components/onboarding/onboarding.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
  },
  {
    path: 'settings/:subComp',
    loadChildren: () => import('src/app/modules/settings/settings.module').then((mod) => mod.SettingsModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'rooms',
    loadChildren: () => import('src/app/modules/rooms/rooms.module').then((mod) => mod.RoomsModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'resources',
    loadChildren: () => import('src/app/modules/resources/resources.module').then((mod) => mod.ResourcesModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'long-running-jobs',
    loadChildren: () =>
      import('src/app/modules/long-running-job/long-running-job.module').then((mod) => mod.LongRunningJobModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('src/app/modules/notifications/notifications.module').then((mod) => mod.NotificationsModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'tests',
    loadChildren: () => import('src/app/modules/test/test.module').then((mod) => mod.TestModule),
    canActivate: [OnBoardingComplete],
    // TODO: will be open after run backend
    // canActivate: [ OktaAuthGuard ]
  },
  {
    path: 'gate-control',
    loadChildren: () => import('src/app/modules/gate-control/gate-control.module').then((mod) => mod.GateControlModule),
    canActivate: [OnBoardingComplete],
  },

  {
    path: 'certificate',
    loadChildren: () => import('src/app/modules/certificate/certificate.module').then((mod) => mod.CertificateModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
