<div fxLayout="column" class="book-a-test-after-dialog-wrapper">
  <div class="rt-dialog-close-icon-wrapper close-icon clickable">
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="dialog-title">
    {{ labName }}- {{ roomName }} - {{ stationName }} {{ '1892' | translate: 'test planned.' }}
  </div>
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
    <button class="rt-btn-primary" (click)="navigateToRoomStationDetail()" fxFlex="50">
      {{ '20157' | translate: 'Go To The Station' }}
    </button>
    <button
      class="rt-btn-primary"
      [ngClass]="{
        'rt-btn-disable': !isAvailable
      }"
      [disabled]="!isAvailable"
      (click)="openTestStartMenu.emit()"
      fxFlex="50">
      {{ '529' | translate: 'Start Test' }}
    </button>
  </div>
  <div *ngIf="!isAvailable" class="station-full-warning-text">
    {{ '20332' | translate: 'The station is not available to start a test!' }}
  </div>
</div>
