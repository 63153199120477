<div class="notification-wrapper notification-history-dialog-wrapper" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <div class="notification-header">{{ '20227' | translate: 'Creation History' }}</div>
  </div>
  <div class="items-wrapper">
    <div class="notification-item active-item">
      <ul fxLayout="column" class="item-container">
        <li *ngFor="let history of histories" class="history-text">{{ getDate(history) }}</li>
      </ul>
    </div>
  </div>
</div>
