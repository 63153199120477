import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-grid-quick-search-bar',
  templateUrl: './grid-quick-search-bar.component.html',
  styleUrls: ['./grid-quick-search-bar.component.scss'],
})
export class GridQuickSearchBarComponent {
  @Output() searchKeysChanged: EventEmitter<string[]> = new EventEmitter();

  searchInput: string = '';
  searchKeys: string[] = [];

  onEnter() {
    if (this.searchInput && this.searchInput.trim() !== '' && this.searchKeys.indexOf(this.searchInput) === -1) {
      this.searchKeys = [...this.searchKeys, this.searchInput];

      this.searchInput = '';

      this.searchKeysChanged.emit(this.searchKeys);
    }
  }

  removeSearchKey(index: number) {
    this.searchKeys = this.searchKeys.filter((_, i) => i !== index);
    this.searchKeysChanged.emit(this.searchKeys);
  }

  removeAllSearchKeys() {
    this.searchKeys = [];
    this.searchKeysChanged.emit(this.searchKeys);
  }
}
