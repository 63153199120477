import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  FeedbackService,
  FeedbackSizes,
  FeedbackTypes,
  TableCsrComponent,
} from '@rotateknik/rt-std-wc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  RmsTable,
  ThirdPartyComponentTypes,
  ValidationTypes,
} from 'src/app/core/enums';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';
import { ShellRelationResourceDefinition } from 'src/app/core/models';
import {
  LanguageService,
  ResourceManagementService,
} from 'src/app/core/services';
import {
  AttributeDataType,
  PlanType,
  PlanedTestStatus,
  ResourceType,
  Theme,
} from 'src/app/shared/enums';
import {
  FormValidatorService,
  PermissionControlService,
  ThemeService,
  UtilService,
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import {
  AttributeDefinitionDetail,
  AvailableStation,
  PlanConditionEventAttributeDefinition,
  PlanDefinition,
  PlanDefinitionDetail,
} from '../../interfaces';
import { TranslatePipe } from '../../pipes';
import { DeviceGridMenuComponent } from './device-grid-menu/device-grid-menu.component';
interface DynamicForm {
  form_name: string;
  label: string;
  type: AttributeDataType;
  dataSource?: any[];
  value?: any;
  validationType: ValidationTypes;
  id?: number;
}

interface AvailableStations {
  id: number;
  labName: string;
  roomName: string;
  stationName: string;
  date: string;
  roomId: number;
  resourceDefinitionId: number;
}

export enum TempHumidity {
  Temp = 'Sıcaklık',
  Humidity = 'Nem',
}

@Component({
  selector: 'app-book-a-test-menu',
  templateUrl: './book-a-test-menu.component.html',
  styleUrls: ['./book-a-test-menu.component.scss'],
})
export class BookATestMenuComponent implements OnInit, OnDestroy {
  @Output() closeMenu: EventEmitter<any> = new EventEmitter();
  @Output() createdPlan: EventEmitter<any> = new EventEmitter();

  @ViewChild('stepper') stepper: any;
  @ViewChild('table') table: TableCsrComponent;
  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;
  @Input() selectedTemp: number | undefined;
  @Input() selectedHumidity: number | undefined;
  @Input() selectedStationId: number | undefined;
  testPlan: PlanDefinitionDetail;

  planConditions: any[] = [];

  isQueryForDevice = false;
  infoFrom: FormGroup;
  formFields: DynamicForm[] = [];
  isInfoFormReady: boolean = false;

  options: (AvailableStations & { isSelected: boolean })[] = [];
  selectedStation: AvailableStations & { isSelected: boolean };
  selectedResourceId: number;

  resourceDefinitions: ShellRelationResourceDefinition[] = [];
  minDate: Date;
  startDateFormName = 'startDate';
  selectedRow: any;
  userId: number;

  columnDefs: {
    headerName: string;
    colId: string;
    field: string;
    filter: string | boolean;
    sortable: boolean;
    suppressFilter?: boolean;
    width?: number;
    hide?: boolean;
    suppressToolPanel?: boolean;
    suppressSizeToFit?: boolean;
  }[] = [];
  tableName: string;
  quickFiltersChooses = [];
  selectedDevice:
    | {
        id: number;
        value: string;
        relationDefinitionId: number;
        lab_id: number;
      }
    | undefined;
  deviceLabel = 'device';
  hasUserPlanDefReadAccess = false;
  hasUserResourceDefReadAccess = false;

  loaderPath = '/assets/RLS_LightLoader.json';
  isDarkMode = false;
  isLoading = true;

  selectedHumidityIndex: any = -1;
  selectedTempIndex: any = -1;

  private startDateText = new TranslatePipe(this.languageService).transform(
    '528',
    'Start Date',
  );
  private resourcesText = new TranslatePipe(this.languageService).transform(
    '450',
    'Resources',
  );
  private deviceText = new TranslatePipe(this.languageService).transform(
    '173',
    'Description',
  );
  private testTypeText = new TranslatePipe(this.languageService).transform(
    '579',
    'Test Type',
  );
  private testPlannedText = new TranslatePipe(this.languageService).transform(
    '1892',
    'test planned.',
  );
  private successText = new TranslatePipe(this.languageService).transform(
    '1858',
    'Success',
  );

  private unsubscribe = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private formValidatorService: FormValidatorService,
    private feedbackService: FeedbackService,
    private utilService: UtilService,
    private resourceManagementService: ResourceManagementService,
    private matDialog: MatDialog,
    private languageService: LanguageService,
    private dateAdapter: DateAdapter<Date>,
    private permissionControlService: PermissionControlService,
    private themeService: ThemeService,
  ) {
    this.userId = parseInt(localStorage.getItem('userId') as string);
    this.minDate = new Date();
    this.dateAdapter.setLocale(
      this.utilService.getLanguageCodeByThirdPartyComponentType(
        ThirdPartyComponentTypes.MatDatePicker,
      ),
    );
    this.setLoaderPath();
  }

  ngOnInit(): void {
    this.getUserPlanDefReadAccess();
    this.getUserResourceDefReadAccess();
    if (this.hasUserPlanDefReadAccess) {
      this.resourceManagementService
        .getPlanDefinitionDetailByTypeId(PlanType.TestPlanning)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((planDefinitionDetails: PlanDefinitionDetail) => {
          this.testPlan = planDefinitionDetails;
          this.constructTheForm();
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get isGetProductByIndexAtBookATestMenu() {
    return environment.isGetProductByIndexAtBookATestMenu;
  }

  get validationTypes() {
    return ValidationTypes;
  }

  get attributeDataType() {
    return AttributeDataType;
  }

  get tempHumidity() {
    return TempHumidity;
  }

  isFormReady(): boolean {
    return this.infoFrom?.valid && !!this.selectedStation;
  }

  bookATest() {
    const { testType, device } = this.infoFrom.value;

    const relations = [
      {
        relation_definition_id: testType.relationDefinitionId,
        reference_id: testType.id,
      },
      {
        relation_definition_id: device.relationDefinitionId,
        reference_id: device.id,
      },
    ];

    let plan: PlanDefinition = {
      plan_definition_id: this.testPlan.id,
      start_date: this.selectedStation.date,
      resource_id: this.selectedStation.id,
      plan_conditions: this.planConditions,
      relations,
    };
    const reservationInfo = {
      plan: plan,
      user_id: this.userId,
      lab_id: this.infoFrom.value.device.lab_id,
    };

    this.resourceManagementService
      .createPlan(reservationInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.closeMenu.emit();
        this.createdPlan.emit({
          roomStationDetailQueryParams: {
            roomId: this.selectedStation.roomId,
            stationId: this.selectedStation.id,
          },
          isAvailable: response.state === PlanedTestStatus.Active,
          labId: this.infoFrom.value.device.lab_id,
          stationName: this.selectedStation.stationName,
          plannedId: response.id,
          stationsOfRoom: this.options
            .filter((option) => option.roomId === this.selectedStation.roomId)
            .map((option) => {
              return {
                id: option.id,
                name: option.stationName,
              };
            }),
          resourceDefinitionId: this.selectedStation.resourceDefinitionId,
          deviceId: device.id,
          deviceName: device.value,
          testTypeId: testType.id,
          testTypeName: testType.value,
          labName: this.selectedStation.labName,
          roomName: this.selectedStation.roomName,
        });
        this.feedbackService.showFeedback(
          this.successText,
          `${this.selectedStation.labName}- ${this.selectedStation.roomName} - ${this.selectedStation.stationName} ${this.testPlannedText}`,
          FeedbackTypes.Success,
          FeedbackSizes.Medium,
          5000,
        );
      });
  }

  constructTheForm() {
    this.formFields = [];
    let resourceAttributes =
      this.testPlan.plan_condition_definitions[0]
        .plan_condition_attribute_definitions;
    resourceAttributes.forEach(
      (
        planConditionAttributeDefinition: PlanConditionEventAttributeDefinition,
      ) => {
        let attributeDefinition =
          planConditionAttributeDefinition.attribute_definition;
        let formKeyName =
          planConditionAttributeDefinition.attribute_definition.description;
        switch (attributeDefinition.type) {
          case AttributeDataType.Boolean:
            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: attributeDefinition?.description
                ? attributeDefinition.description
                : '',
              type: AttributeDataType.Boolean,
              validationType: ValidationTypes.NotRequired,
            });
            break;
          case AttributeDataType.Date:
            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: planConditionAttributeDefinition.attribute_definition
                .description
                ? planConditionAttributeDefinition.attribute_definition
                    .description
                : '',
              type: AttributeDataType.Date,
              validationType: ValidationTypes.Required,
              // validationType: attributeDefinition
              //   ? attributeDefinition[0]?.is_required
              //     ? ValidationTypes.Required
              //     : ValidationTypes.NotRequired
              //   : ValidationTypes.NotRequired,
            });
            break;
          case AttributeDataType.Dropdown:
            let dataSource: {
              id: number;
              value: string;
              planConditionAttributeDefinitionId: number;
            }[] = [];
            if (
              planConditionAttributeDefinition.attribute_definition
                .attribute_definition_details
            ) {
              planConditionAttributeDefinition.attribute_definition.attribute_definition_details.forEach(
                (defDetail: AttributeDefinitionDetail) => {
                  dataSource.push({
                    id: defDetail?.id ? defDetail?.id : 0,
                    value: defDetail.value,
                    planConditionAttributeDefinitionId:
                      planConditionAttributeDefinition.id,
                  });
                },
              );
            }

            dataSource = this.utilService.sortArrayIncreasingOrder(
              dataSource,
              'value',
            );

            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: new TranslatePipe(this.languageService).transform(
                planConditionAttributeDefinition.attribute_definition
                  .translation_key_id ?? '',
                planConditionAttributeDefinition.attribute_definition
                  .description,
              ),

              // value: planConditionAttributeDefinition.value ?? null,
              type: AttributeDataType.Dropdown,
              dataSource: dataSource,
              validationType: ValidationTypes.Required,
              // validationType: attributeDefinition
              //   ? attributeDefinition[0]?.is_required
              //     ? ValidationTypes.Required
              //     : ValidationTypes.NotRequired
              //   : ValidationTypes.NotRequired,
            });
            break;
          case AttributeDataType.Number:
            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: planConditionAttributeDefinition.attribute_definition
                .description
                ? planConditionAttributeDefinition.attribute_definition
                    .description
                : '',
              type: AttributeDataType.Number,
              validationType: ValidationTypes.Required,
              // validationType: attributeDefinition
              //   ? attributeDefinition[0]?.is_required
              //     ? ValidationTypes.Required
              //     : ValidationTypes.NotRequired
              //   : ValidationTypes.NotRequired,
            });
            break;
          case AttributeDataType.String:
            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: planConditionAttributeDefinition.attribute_definition
                .description
                ? planConditionAttributeDefinition.attribute_definition
                    .description
                : '',
              type: AttributeDataType.String,
              validationType: ValidationTypes.Required,
              // validationType: attributeDefinition
              //   ? attributeDefinition[0]?.is_required
              //     ? ValidationTypes.Required
              //     : ValidationTypes.NotRequired
              //   : ValidationTypes.NotRequired,
            });
            break;
          case AttributeDataType.Text:
            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: planConditionAttributeDefinition.attribute_definition
                .description
                ? planConditionAttributeDefinition.attribute_definition
                    .description
                : '',
              type: AttributeDataType.Text,
              validationType: ValidationTypes.Required,
              // validationType: attributeDefinition
              //   ? attributeDefinition[0]?.is_required
              //     ? ValidationTypes.Required
              //     : ValidationTypes.NotRequired
              //   : ValidationTypes.NotRequired,
            });
            break;
          default:
            this.formFields.push({
              form_name: formKeyName ? formKeyName : '',
              label: planConditionAttributeDefinition.attribute_definition
                .description
                ? planConditionAttributeDefinition.attribute_definition
                    .description
                : '',
              value: null,
              type: AttributeDataType.String,
              validationType: ValidationTypes.Required,
              // validationType: attributeDefinition
              //   ? attributeDefinition[0]?.is_required
              //     ? this.validationTypes.Required
              //     : this.validationTypes.NotRequired
              //   : this.validationTypes.NotRequired,
            });
            break;
        }
      },
    );

    this.setDevices();
    this.setTestTypes();

    this.formFields.push({
      form_name: this.startDateFormName,
      label: this.startDateText,
      value: new Date(),
      type: AttributeDataType.Date,
      validationType: ValidationTypes.Required,
    });

    let form: any = [];

    this.formFields.forEach((formField) => {
      form[formField.form_name] =
        formField.validationType === this.validationTypes.Required
          ? new FormControl(formField.value ?? null, [Validators.required])
          : new FormControl(formField.value ?? null);
    });

    this.infoFrom = this.formBuilder.group(form);
    this.setTempHumidity();
    this.isInfoFormReady = true;
  }

  setTempHumidity() {
    const Humidity = this.formFields.find(
      (item) => item.form_name === TempHumidity.Humidity,
    );
    const humidityIndex: any = Humidity?.dataSource?.findIndex(
      (fieldData) => fieldData.value === this.selectedHumidity?.toString(),
    );

    if (
      Humidity?.dataSource &&
      this.selectedHumidity !== null &&
      this.selectedHumidity !== undefined &&
      humidityIndex !== -1
    ) {
      this.infoFrom.patchValue({
        Nem: Humidity.dataSource[humidityIndex],
      });
    }
    const temp = this.formFields.find(
      (item) => item.form_name === TempHumidity.Temp,
    );
    const tempIndex: any = temp?.dataSource?.findIndex(
      (fieldData) => fieldData.value === this.selectedTemp?.toString(),
    );

    if (
      temp?.dataSource &&
      this.selectedTemp !== null &&
      this.selectedTemp !== undefined &&
      tempIndex !== -1
    ) {
      this.infoFrom.patchValue({
        Sıcaklık: temp.dataSource[tempIndex],
      });
    }

    setTimeout(() => {
      if (humidityIndex !== -1) {
        this.selectedHumidityIndex = humidityIndex;
      }

      if (tempIndex !== -1) {
        this.selectedTempIndex = tempIndex;
      }
    }, 0);
  }

  getAvailableStations() {
    const { startDate, testType, device } = this.infoFrom.value;
    this.planConditions = [];
    this.selectedStation = null as any;

    this.testPlan.plan_condition_definitions.forEach(
      (planConditionDefinition: any) => {
        let planConditionAttributes: any[] = [];

        planConditionDefinition.plan_condition_attribute_definitions.forEach(
          (
            planConditionAttributeDefinition: PlanConditionEventAttributeDefinition,
          ) => {
            const formControls = Object.keys(this.infoFrom.controls).map(
              (key) => [this.infoFrom.controls[key]][0],
            );
            const index = formControls.findIndex(
              (infoFormControl: AbstractControl) => {
                return (
                  infoFormControl.value.planConditionAttributeDefinitionId ===
                  planConditionAttributeDefinition.id
                );
              },
            );

            if (index !== -1) {
              planConditionAttributes.push({
                id: planConditionAttributeDefinition.id,
                attribute_definition: {
                  id: planConditionAttributeDefinition.attribute_definition.id,
                  value: (formControls[index] as any).value.id.toString(),
                  data_type:
                    planConditionAttributeDefinition.attribute_data_type,
                },
              });
            }
          },
        );

        this.planConditions.push({
          id: planConditionDefinition.id,
          plan_condition_attributes: planConditionAttributes,
        });
      },
    );

    let plan: PlanDefinition = {
      plan_definition_id: this.testPlan.id,
      resource_definition_id: this.testPlan.resource_definition_id,
      start_date: startDate,
      plan_conditions: this.planConditions,
      test_type: testType,
      lab_id: device.lab_id,
    };

    let planDefinition = {
      plan: plan,
    };
    this.resourceManagementService
      .getAvailableResourcesByPlan(planDefinition)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((availableStations: AvailableStation) => {
        this.editAvailableStations(availableStations);
        this.stepper.next();
      });
  }

  stationSelected(e: any) {
    this.options.map((option) => (option.isSelected = false));
    e.value.isSelected = true;
    this.selectedStation = e.value;
  }

  onSelectionChange(e: any) {
    if (e?.value?.devices !== undefined) {
      this.clearSelectedDevice();
      this.selectedResourceId = e.value.id;
    }
  }

  getErrorClass(form: FormGroup, field: string): string {
    return this.formValidatorService.getErrorClass(form, field);
  }

  openDeviceMenu() {
    const confirmDialogRef = this.matDialog.open(DeviceGridMenuComponent, {
      width: '80vw',
      height: '500px',
    });
    const dialogElement: HTMLCollectionOf<Element> =
      document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');
    confirmDialogRef.componentInstance.selectedResourceId =
      this.selectedResourceId;

    let selectedResourceDefinition = this.getSelectedResourceDefinition(
      this.selectedResourceId,
    );
    selectedResourceDefinition &&
      (confirmDialogRef.componentInstance.selectedResourceDefinition =
        selectedResourceDefinition);

    confirmDialogRef.componentInstance.selectedRowSubject.subscribe(
      (selectedRowData) => {
        let mutatedRowData = {
          id: selectedRowData.id,
          value: selectedRowData.name,
          relationDefinitionId: this.testPlan.relations[0].id,
          lab_id: selectedRowData.lab_id ?? 1,
        };

        this.selectedDevice = mutatedRowData;
        this.infoFrom.patchValue({ device: mutatedRowData });
        confirmDialogRef.componentInstance.close();
      },
    );
  }

  getDeviceByIndex(indexNo: string) {
    this.isQueryForDevice = true;

    this.resourceManagementService
      .getResourceByIndexNo(indexNo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          if (response) {
            const mutatedData = {
              id: response.id,
              value: response.name,
              relationDefinitionId: this.testPlan.relations[0].id,
              lab_id: 1,
            };
            this.selectedDevice = mutatedData;
            this.infoFrom.patchValue({
              device: mutatedData,
            });
          }

          this.isQueryForDevice = false;
        },
        error: (e) => {
          this.isQueryForDevice = false;
        },
      });
  }

  hasAvailableStation(): boolean {
    return this.options.length > 0;
  }

  getUserPlanDefReadAccess() {
    this.hasUserPlanDefReadAccess =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.plandef,
        AuthorizationActionEnum.read,
      );
  }

  getUserResourceDefReadAccess() {
    this.hasUserResourceDefReadAccess =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.resourcedef,
        AuthorizationActionEnum.read,
      );
  }

  private clearSelectedDevice() {
    this.selectedDevice = undefined;
    this.infoFrom.patchValue({ device: null });
  }

  private editAvailableStations(availableStations: any) {
    this.options = [];
    availableStations.forEach((station: any) => {
      const option = {
        id: station.id,
        labName: station.parent.lab.name,
        roomName: station.parent.name,
        stationName: station.name,
        date: new Date(station.available_date) as any,
        isSelected: station.id === this.selectedStationId,
        roomId: station.parent.id,
        resourceDefinitionId: station.resource_definition,
      };

      if (station.id === this.selectedStationId) {
        this.selectedStation = option;
      }

      this.options.push(option);
    });
  }

  private setDevices() {
    this.resourceDefinitions = [];
    const deviceIndex = this.testPlan.relations.findIndex(
      (relation: any) =>
        relation.reference_id === ResourceType.TestSpecimen &&
        relation.reference_table_name === RmsTable.ResourceType,
    );

    if (deviceIndex !== -1) {
      this.resourceDefinitions =
        this.testPlan.relations[deviceIndex].resource_definitions ?? [];
    }
    const dataSource: any[] = [];

    this.resourceDefinitions.forEach((resourceDefinition) => {
      dataSource.push({
        id: resourceDefinition.id,
        value: resourceDefinition.name,
        devices: null,
        relationDefinitionId: this.testPlan.relations[deviceIndex].id,
      });
    });

    if (!this.isGetProductByIndexAtBookATestMenu) {
      this.formFields.push({
        form_name: 'ResourceDefinition',
        label: this.resourcesText,
        type: AttributeDataType.Dropdown,
        dataSource: dataSource,
        validationType: ValidationTypes.Required,
      });
    }

    this.formFields.push({
      form_name: 'device',
      label: this.deviceText,
      type: AttributeDataType.Dropdown,
      dataSource: [],
      validationType: ValidationTypes.Required,
    });
  }

  private setTestTypes() {
    let testTypes = [];
    const testTypeIndex = this.testPlan.relations.findIndex(
      (relation: any) =>
        relation.reference_id === ResourceType.TestSpace &&
        relation.source_table_name === 'test_type',
    );

    if (testTypeIndex !== -1) {
      testTypes = this.testPlan.relations[testTypeIndex].test_type ?? [];
      const dataSource: any[] = [];
      testTypes.forEach((testType: any) => {
        dataSource.push({
          id: testType.id,
          value: `${testType.name} - ${testType.duration_h}`,
          relationDefinitionId: this.testPlan.relations[testTypeIndex].id,
          duration_h: testType.duration_h,
        });
      });

      this.formFields.push({
        form_name: 'testType',
        label: this.testTypeText,
        type: AttributeDataType.Dropdown,
        dataSource: dataSource,
        validationType: ValidationTypes.Required,
      });
    }
  }

  private setLoaderPath() {
    this.themeService.selectedTheme
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedTheme) => {
        this.loaderPath =
          selectedTheme === Theme.Dark
            ? '/assets/RLS_DarkLoader.json'
            : '/assets/RLS_LightLoader.json';
        this.isDarkMode = selectedTheme === Theme.Dark;
      });
  }

  private getSelectedResourceDefinition(resourceId: number) {
    const deviceTypeIndex = this.testPlan.relations.findIndex(
      (relation) => relation.reference_id === ResourceType.TestSpecimen,
    );
    if (deviceTypeIndex !== -1) {
      return this.testPlan.relations[
        deviceTypeIndex
      ].resource_definitions?.find(
        (resourceDefinition) => resourceDefinition.id === resourceId,
      );
    } else {
      return undefined;
    }
  }
}
