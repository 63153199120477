import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class FormValidatorService {
  getErrorClass(form: FormGroup | any, field: string | Array<string>, isProductionForm: boolean = false): string {
    if (isProductionForm) {
      return form.get(field).invalid && (form.get(field).touched || form.get(field).dirty)
        ? 'form-field-not-valid-color'
        : '';
    } else {
      return form.get(field).invalid && (form.get(field).touched || form.get(field).dirty)
        ? 'form-field-not-valid'
        : '';
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (String(control.value) || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
