<div class="graphs-wrapper availability-graphs-wrapper" fxLayout="row" fxFlex>
  <div class="availability-main-content-wrapper" fxLayout="column" fxFlex fxLayoutGap="16px">
    <div fxLayout="row" fxFlex fxLayoutGap="16px">
      <div class="momentary hidden-mobile" fxLayout="column" fxLayoutGap="26px">
        <div class="sub-title-wrapper">
          <div class="sub-title-text">{{ 20156 | translate: 'Active Tests Count' }}</div>
        </div>
        <div class="momentary-spinner-wrapper" fxFlexAlign="center">
          <div class="spinner-color-change horizontal-align-center">
            <div class="spinner-values-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="6px">
              <div class="percentage-value">{{ activeTestCount }}</div>
            </div>
          </div>
        </div>
        <div class="graphic-wrapper" *ngIf="!isDarkMode" fxFlexAlign="center">
          <svg
            width="227px"
            height="53px"
            viewBox="0 0 227 53"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="transparent">
            <title>Group 40</title>
            <defs>
              <rect id="path-1" x="0" y="0" width="226" height="53"></rect>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
                <stop stop-color="#1E1D35" stop-opacity="0.44" offset="0%"></stop>
                <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="linearGradient-4">
                <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
                <stop stop-color="#FFFFFF" offset="76.4491368%"></stop>
                <stop stop-color="#FFFFFF" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g id="Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-105.000000, -473.000000)" id="Group-39">
                <g transform="translate(82.000000, 88.000000)">
                  <g id="Group-35" transform="translate(0.000000, 56.000000)">
                    <g id="Group-40" transform="translate(23.000000, 329.000000)">
                      <g id="Group-Copy-4" transform="translate(1.000000, 0.000000)">
                        <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"></use>
                        </mask>
                        <g id="Mask"></g>
                        <g id="Group" mask="url(#mask-2)">
                          <g transform="translate(-6.000000, 7.310345)" id="Group-15">
                            <path
                              d="M219.417476,45.6896552 L0,45.6896552 L0,8.91505467 L14.6165012,28.1892749 C17.2862318,31.7097463 22.304382,32.399408 25.8248535,29.7296775 C26.1608996,29.4748387 26.4762677,29.1938253 26.7680088,28.889265 L34.8378809,20.4647985 C37.8942053,17.2741777 42.9583551,17.1653089 46.1489759,20.2216333 C46.2147304,20.2846201 46.2794047,20.3487249 46.3429706,20.4139197 L53.2612684,27.5095042 C56.3456924,30.6729689 61.410607,30.737044 64.5740717,27.65262 C64.9644694,27.2719766 65.3149296,26.8524184 65.6199865,26.4004891 L76.870388,9.7334756 C78.5879905,7.18891728 81.6102363,5.85170923 84.6485042,6.29200856 L100.442091,8.58078168 C101.843275,8.78383825 103.273249,8.61140192 104.585996,8.08108028 L118.884917,2.30462638 C122.248291,0.945895982 126.104935,2.02022571 128.28143,4.9221749 L139.824775,20.3130734 C142.475767,23.8476768 147.490183,24.5639854 151.024787,21.9129934 C151.661715,21.4352899 152.223766,20.8651841 152.692358,20.221523 L162.344673,6.96304673 C164.762306,3.64217186 169.302116,2.69194138 172.848642,4.76445443 L186.000078,12.4498687 C186.214581,12.5752192 186.423127,12.7104938 186.62504,12.8552528 L201.847759,23.7689886 C204.686888,25.8044667 208.518459,25.7623538 211.312164,23.6649652 L226,12.6379965 L226,12.6379965 L226,45.6896552 L219.417476,45.6896552 Z"
                              id="Path-2-Copy-2"
                              fill="url(#linearGradient-3)"></path>
                            <path
                              d="M0,8.91505467 L14.6165012,28.1892749 C17.2862318,31.7097463 22.304382,32.399408 25.8248535,29.7296775 C26.1608996,29.4748387 26.4762677,29.1938253 26.7680088,28.889265 L34.6810206,20.6285513 C37.7373451,17.4379305 42.8014948,17.3290617 45.9921157,20.3853861 C46.1098591,20.4981736 46.2241269,20.6145343 46.334759,20.7343052 L53.1430291,28.1049722 C56.1409473,31.3505333 61.2022873,31.5512876 64.4478484,28.5533694 C64.9355686,28.1028633 65.365309,27.5933939 65.7271566,27.0367136 L76.8900274,9.86332189 C78.5905391,7.24718902 81.6569226,5.8584749 84.7449043,6.30597867 L100.442091,8.58078168 C101.843275,8.78383825 103.273249,8.61140192 104.585996,8.08108028 L118.77783,2.34788696 C122.190979,0.96904904 126.104486,2.09730425 128.259778,5.08151096 L139.733238,20.9676027 C142.320123,24.5493906 147.320821,25.3559185 150.902609,22.7690331 C151.627576,22.2454376 152.259623,21.6039933 152.772462,20.871377 L162.361542,7.17289431 C164.755751,3.7526469 169.377536,2.74860658 172.975011,4.8672159 L186.003577,12.5399437 C186.21575,12.6648957 186.422041,12.7995768 186.621791,12.9435568 L201.830833,23.906244 C204.67706,25.9578065 208.527915,25.9154139 211.328288,23.8016903 L226,12.7274716 L226,12.7274716"
                              id="Path-2-Copy-2"
                              stroke="#1E1D35"
                              stroke-linecap="round"></path>
                            <path
                              id="Path-2-Copy-2"
                              d="M227.596314,11.5225727 C226.930867,10.6409528 225.676721,10.4657103 224.795101,11.1311576 C223.913481,11.7966049 223.738239,13.0507507 224.403686,13.9323706 C225.069133,14.8139905 226.323279,14.989233 227.204899,14.3237857 C228.086519,13.6583383 228.261761,12.4041926 227.596314,11.5225727 Z"
                              fill="#1E1D35"
                              fill-rule="nonzero"></path>
                          </g>
                        </g>
                      </g>
                      <rect id="Rectangle" fill="url(#linearGradient-4)" x="0" y="0" width="24" height="53"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div class="graphic-wrapper" *ngIf="isDarkMode" fxFlexAlign="center">
          <svg
            width="227px"
            height="53px"
            viewBox="0 0 227 53"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="transparent">
            <title>Group 40</title>
            <defs>
              <rect id="path-1" x="0" y="0" width="226" height="53"></rect>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
                <stop stop-color="#1E1D35" offset="0%"></stop>
                <stop stop-color="#2F2E44" stop-opacity="0" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="99.8288455%" y1="50%" x2="0%" y2="50%" id="linearGradient-4">
                <stop stop-color="#2F2E44" stop-opacity="0" offset="0%"></stop>
                <stop stop-color="#2F2E44" offset="76.4491368%"></stop>
                <stop stop-color="#2F2E44" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g id="Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-105.000000, -473.000000)" id="Group-35">
                <g transform="translate(82.000000, 144.000000)">
                  <g id="Group-40" transform="translate(23.000000, 329.000000)">
                    <g id="Group-Copy-4" transform="translate(1.000000, 0.000000)">
                      <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                      </mask>
                      <g id="Mask"></g>
                      <g id="Group" mask="url(#mask-2)">
                        <g transform="translate(-6.000000, 7.310345)">
                          <path
                            d="M219.417476,45.6896552 L0,45.6896552 L0,8.91505467 L14.6165012,28.1892749 C17.2862318,31.7097463 22.304382,32.399408 25.8248535,29.7296775 C26.1608996,29.4748387 26.4762677,29.1938253 26.7680088,28.889265 L34.8378809,20.4647985 C37.8942053,17.2741777 42.9583551,17.1653089 46.1489759,20.2216333 C46.2147304,20.2846201 46.2794047,20.3487249 46.3429706,20.4139197 L53.2612684,27.5095042 C56.3456924,30.6729689 61.410607,30.737044 64.5740717,27.65262 C64.9644694,27.2719766 65.3149296,26.8524184 65.6199865,26.4004891 L76.870388,9.7334756 C78.5879905,7.18891728 81.6102363,5.85170923 84.6485042,6.29200856 L100.442091,8.58078168 C101.843275,8.78383825 103.273249,8.61140192 104.585996,8.08108028 L118.884917,2.30462638 C122.248291,0.945895982 126.104935,2.02022571 128.28143,4.9221749 L139.824775,20.3130734 C142.475767,23.8476768 147.490183,24.5639854 151.024787,21.9129934 C151.661715,21.4352899 152.223766,20.8651841 152.692358,20.221523 L162.344673,6.96304673 C164.762306,3.64217186 169.302116,2.69194138 172.848642,4.76445443 L186.000078,12.4498687 C186.214581,12.5752192 186.423127,12.7104938 186.62504,12.8552528 L201.847759,23.7689886 C204.686888,25.8044667 208.518459,25.7623538 211.312164,23.6649652 L226,12.6379965 L226,12.6379965 L226,45.6896552 L219.417476,45.6896552 Z"
                            id="Path-2-Copy-2"
                            fill="url(#linearGradient-3)"></path>
                          <g id="Group-15" stroke-width="1" fill="none">
                            <path
                              d="M0,8.91505467 L14.6165012,28.1892749 C17.2862318,31.7097463 22.304382,32.399408 25.8248535,29.7296775 C26.1608996,29.4748387 26.4762677,29.1938253 26.7680088,28.889265 L34.6810206,20.6285513 C37.7373451,17.4379305 42.8014948,17.3290617 45.9921157,20.3853861 C46.1098591,20.4981736 46.2241269,20.6145343 46.334759,20.7343052 L53.1430291,28.1049722 C56.1409473,31.3505333 61.2022873,31.5512876 64.4478484,28.5533694 C64.9355686,28.1028633 65.365309,27.5933939 65.7271566,27.0367136 L76.8900274,9.86332189 C78.5905391,7.24718902 81.6569226,5.8584749 84.7449043,6.30597867 L100.442091,8.58078168 C101.843275,8.78383825 103.273249,8.61140192 104.585996,8.08108028 L118.77783,2.34788696 C122.190979,0.96904904 126.104486,2.09730425 128.259778,5.08151096 L139.733238,20.9676027 C142.320123,24.5493906 147.320821,25.3559185 150.902609,22.7690331 C151.627576,22.2454376 152.259623,21.6039933 152.772462,20.871377 L162.361542,7.17289431 C164.755751,3.7526469 169.377536,2.74860658 172.975011,4.8672159 L186.003577,12.5399437 C186.21575,12.6648957 186.422041,12.7995768 186.621791,12.9435568 L201.830833,23.906244 C204.67706,25.9578065 208.527915,25.9154139 211.328288,23.8016903 L226,12.7274716 L226,12.7274716"
                              id="Path-2-Copy-2"
                              stroke="#FFFFFF"
                              stroke-linecap="round"></path>
                            <path
                              id="Path-2-Copy-2"
                              d="M227.596314,11.5225727 C226.930867,10.6409528 225.676721,10.4657103 224.795101,11.1311576 C223.913481,11.7966049 223.738239,13.0507507 224.403686,13.9323706 C225.069133,14.8139905 226.323279,14.989233 227.204899,14.3237857 C228.086519,13.6583383 228.261761,12.4041926 227.596314,11.5225727 Z"
                              fill="#FFFFFF"
                              fill-rule="nonzero"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                    <rect id="Rectangle" fill="url(#linearGradient-4)" x="0" y="0" width="24" height="53"></rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <div class="weekly-wrapper" fxLayout="column" fxFlex>
        <div class="sub-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="sub-title-text">{{ 1840 | translate: 'Weekly Occupancy' }}</div>
          <div class="date-icon-wrapper">
            <button class="rt-btn-lg rt-btn-secondary hidden-mobile" (click)="goThisMonth()">
              {{ '1888' | translate: 'This Month' }}
            </button>
            <div class="month-select">
              <mat-select [(value)]="selectedMonth" (selectionChange)="weeklyMonthChange()">
                <mat-option *ngFor="let month of months" [value]="month.value">{{ month.text }}</mat-option>
              </mat-select>
            </div>
            <div style="display: flex; align-items: center; gap: 8px">
              <div class="clickable year-select">
                <div>{{ selectedWeeklyYear }}</div>
              </div>
              <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
                <mat-icon
                  class="left-right-icon vertical-align-center horizontal-align-center clickable"
                  (click)="decreaseWeeklyYear()">
                  keyboard_arrow_left
                </mat-icon>
                <mat-icon
                  class="left-right-icon vertical-align-center horizontal-align-center clickable"
                  (click)="increaseWeeklyYear()">
                  keyboard_arrow_right
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="plot-wrapper" (window:resize)="resizeWeeklyPlot()" #weeklyWrapper fxFlex>
          <ng-container *ngTemplateOutlet="weeklyAvailabilityTemp"></ng-container>
          <rt-loading-indicator
            [showLoadingIndicator]="isWeeklyOccupancyLoading"
            [path]="loaderPath"
            [isDarkMode]="isDarkMode"></rt-loading-indicator>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout="row">
      <div class="monthly" fxLayout="column" fxFlex>
        <div class="sub-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="sub-title-text">{{ '1841' | translate: 'Monthly Occupancy' }}</div>
          <div class="date-icon-wrapper" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
            <button class="rt-btn-lg rt-btn-secondary hidden-mobile" (click)="goThisYear()">
              {{ '1924' | translate: 'This Year' }}
            </button>
            <div class="year-select">
              <div>{{ selectedMonthlyYear }}</div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
              <mat-icon
                class="left-right-icon vertical-align-center horizontal-align-center clickable"
                (click)="decreaseMonthlyYear()">
                keyboard_arrow_left
              </mat-icon>
              <mat-icon
                class="left-right-icon vertical-align-center horizontal-align-center clickable"
                (click)="increaseMonthlyYear()">
                keyboard_arrow_right
              </mat-icon>
            </div>
          </div>
        </div>
        <div class="plot-wrapper" (window:resize)="resizeMonthlyPlot()" #monthlyWrapper fxFlex>
          <ng-container *ngTemplateOutlet="monthlyAvailabilityTemp"></ng-container>
          <rt-loading-indicator
            [showLoadingIndicator]="isMonthlyOccupancyLoading"
            [path]="loaderPath"
            [isDarkMode]="isDarkMode"></rt-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #weeklyAvailabilityTemp>
  <rt-plot
    #weeklyPlot
    [isDarkMode]="isDarkMode"
    [plotBackground]="'#FFFFFF'"
    [plotBackgroundDark]="'#2F2E44'"
    [plotSize]="{ width: weeklyPlotSize.width, height: weeklyPlotSize.height }"></rt-plot>
</ng-template>

<ng-template #monthlyAvailabilityTemp>
  <rt-plot
    #monthlyPlot
    [isDarkMode]="isDarkMode"
    [plotBackground]="'#FFFFFF'"
    [plotBackgroundDark]="'#2F2E44'"
    [plotSize]="{ width: monthlyPlotSize.width, height: monthlyPlotSize.height }"></rt-plot>
</ng-template>
