import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlotModule } from '@rotateknik/plot';
import { RTSTDWCModule } from '@rotateknik/rt-std-wc';
import { PdfService } from '../core/services/pdf.service';
import { AvailabilityGraphsComponent } from './components/availability-graphs/availability-graphs.component';
import { BookATestAfterDialogComponent } from './components/book-a-test-menu/book-a-test-after-dialog/book-a-test-after-dialog.component';
import { BookATestMenuComponent } from './components/book-a-test-menu/book-a-test-menu.component';
import { DeviceGridMenuComponent } from './components/book-a-test-menu/device-grid-menu/device-grid-menu.component';
import { SelectButtonRendererComponent } from './components/book-a-test-menu/device-grid-menu/select-button-renderer/select-button-renderer.component';
import { ExtraTimeMenuComponent } from './components/extra-time-menu/extra-time-menu.component';
import { GridQuickSearchBarComponent } from './components/grid-quick-search-bar/grid-quick-search-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationHistoryDialogComponent } from './components/header/notifications-dialog/notification-history-dialog/notification-history-dialog.component';
import { NotificationsDialogComponent } from './components/header/notifications-dialog/notifications-dialog.component';
import { LabRoomSelectorComponent } from './components/lab-room-selector/lab-room-selector.component';
import { LabRoomStationFilterDialogComponent } from './components/lab-room-station-filter-dialog/lab-room-station-filter-dialog.component';
import { LabRoomStationSelectorComponent } from './components/lab-room-station-selector/lab-room-station-selector.component';
import { MenuItemComponent } from './components/lab-room-station-selector/menu-item/menu-item.component';
import { LabSelectorComponent } from './components/lab-selector/lab-selector.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { LongRunningJobComponent } from './components/left-menu/long-running-job/long-running-job.component';
import { MoreActionsDialogComponent } from './components/left-menu/long-running-job/more-actions-dialog/more-actions-dialog.component';
import { SubMenuComponent } from './components/left-menu/sub-menu/sub-menu.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { StationSelectorComponent } from './components/station-selector/station-selector.component';
import { DeleteSensorConfirmationDialogComponent } from './components/test-create-edit/delete-sensor-confirmation-dialog/delete-sensor-confirmation-dialog.component';
import { SaveAsTemplateDialogComponent } from './components/test-create-edit/save-as-template-dialog/save-as-template-dialog.component';
import { TestCreateEditMenuComponent } from './components/test-create-edit/test-create-edit-menu.component';
import { TestStartedDialogComponent } from './components/test-create-edit/test-started-dialog/test-started-dialog.component';
import { TestRoomListFilterDialogComponent } from './components/test-room-list-filter-dialog/test-room-list-filter-dialog.component';
import { ConfirmDialogComponent } from './dialogs';
import { LauncherDialogComponent } from './dialogs/launcher-dialog/launcher-dialog.component';
import { AccessControlDirective } from './directives';
import { TranslatePipe } from './pipes/translation.pipe';
import {
  FormValidatorService,
  PermissionControlService,
  ResourceManipulationService,
  StationChangedService,
  UtilService,
} from './services';
import { TableFilterService } from './services/table-filter.service';

@NgModule({
  declarations: [
    LeftMenuComponent,
    LoginComponent,
    OnboardingComponent,
    ConfirmDialogComponent,
    AccessControlDirective,
    SubMenuComponent,
    HeaderComponent,
    NotificationsDialogComponent,
    LongRunningJobComponent,
    MoreActionsDialogComponent,
    TestRoomListFilterDialogComponent,
    TestCreateEditMenuComponent,
    SaveAsTemplateDialogComponent,
    TestStartedDialogComponent,
    StationSelectorComponent,
    ExtraTimeMenuComponent,
    TranslatePipe,
    LabRoomStationSelectorComponent,
    MenuItemComponent,
    LabRoomSelectorComponent,
    LabSelectorComponent,
    AvailabilityGraphsComponent,
    BookATestMenuComponent,
    DeviceGridMenuComponent,
    NotFoundComponent,
    SelectButtonRendererComponent,
    NotificationHistoryDialogComponent,
    LabRoomStationFilterDialogComponent,
    BookATestAfterDialogComponent,
    DeleteSensorConfirmationDialogComponent,
    LauncherDialogComponent,
    GridQuickSearchBarComponent,
  ],
  imports: [
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    PlotModule,
    MatDividerModule,
    MatRippleModule,
    FlexLayoutModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonToggleModule,
    RTSTDWCModule,
    MatBadgeModule,
    MatMenuModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    FormsModule,
    MatSidenavModule,
  ],
  providers: [
    FormValidatorService,
    PermissionControlService,
    StationChangedService,
    UtilService,
    TableFilterService,
    ResourceManipulationService,
    PdfService,
    DatePipe,
  ],
  exports: [
    LeftMenuComponent,
    AvailabilityGraphsComponent,
    ConfirmDialogComponent,
    AccessControlDirective,
    FlexLayoutModule,
    HeaderComponent,
    TestRoomListFilterDialogComponent,
    TestCreateEditMenuComponent,
    SaveAsTemplateDialogComponent,
    ExtraTimeMenuComponent,
    TestStartedDialogComponent,
    StationSelectorComponent,
    LabRoomStationSelectorComponent,
    LabRoomSelectorComponent,
    LabSelectorComponent,
    TranslatePipe,
    BookATestMenuComponent,
    LabRoomStationFilterDialogComponent,
    GridQuickSearchBarComponent,
  ],
})
export class SharedModule {}
