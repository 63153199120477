import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequestParams } from '@rotateknik/rt-std-wc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.reportingServicePath;

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  public token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  getReportingListById(id: string, filters: FilterRequestParams, searchValue?: string[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/reporting-list/getReportingListById`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      }
    ) as Observable<any>;
  }
}
