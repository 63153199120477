import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  DetailHoverItem,
  InfoHoverData,
  PlanningEventInput,
  PlanningOptions,
  PlanningResourceInput,
} from '@rotateknik/rt-std-wc';
import { ThirdPartyComponentTypes } from 'src/app/core/enums';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';
import { Plan } from 'src/app/core/models/plan.model';
import { LanguageService } from 'src/app/core/services';
import { Resource } from 'src/app/shared/interfaces';
import { TranslatePipe } from 'src/app/shared/pipes';
import { PermissionControlService, UtilService } from 'src/app/shared/services';

@Injectable()
export class PlanningFactory {
  constructor(
    private languageService: LanguageService,
    private datePipe: DatePipe,
    private permissionControlService: PermissionControlService,
    private utilService: UtilService,
  ) {}

  getOptions(): PlanningOptions {
    const years: number[] = [];
    const currentYear = new Date().getFullYear();
    const languageCode =
      this.utilService.getLanguageCodeByThirdPartyComponentType(
        ThirdPartyComponentTypes.MatDatePicker,
      );

    for (let i = currentYear - 5; i <= currentYear + 1; i++) {
      years.push(i);
    }
    const planningOptions: PlanningOptions = {
      title: new TranslatePipe(this.languageService).transform(
        '536',
        'Stations',
      ),
      todayButtonText: new TranslatePipe(this.languageService).transform(
        '1960',
        'Today',
      ),
      resourceDetailItems: [
        {
          id: 1,
          icon: 'remove_red_eye',
          text: new TranslatePipe(this.languageService).transform(
            '1961',
            'Station Detail',
          ),
        },
      ],
      months: [
        {
          text: new TranslatePipe(this.languageService).transform(
            '1963',
            'January',
          ),
          value: 0,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1964',
            'February',
          ),
          value: 1,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1965',
            'March',
          ),
          value: 2,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1966',
            'April',
          ),
          value: 3,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1967',
            'May',
          ),
          value: 4,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1968',
            'June',
          ),
          value: 5,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1969',
            'July',
          ),
          value: 6,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1970',
            'August',
          ),
          value: 7,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1971',
            'September',
          ),
          value: 8,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1972',
            'October',
          ),
          value: 9,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1973',
            'November',
          ),
          value: 10,
        },
        {
          text: new TranslatePipe(this.languageService).transform(
            '1974',
            'December',
          ),
          value: 11,
        },
      ],
      years: years,
      languageCode: languageCode ? languageCode : null,
    };

    return planningOptions;
  }

  convertResourceData(data: Resource[]): PlanningResourceInput[] {
    const convertedData: PlanningResourceInput[] = [];
    data.forEach((item) => {
      const convertedItem: PlanningResourceInput = {
        id: item.id.toString(),
        title: item.name,
        extendedProps: {
          subTitle: item.description,
          isShowAdditionalInfo: false,
        },
      };
      convertedData.push(convertedItem);
    });
    return convertedData;
  }

  convertEventData(data: Plan[]): PlanningEventInput[] {
    const convertedData: PlanningEventInput[] = [];
    data.forEach((plan: Plan) => {
      const convertedItem: PlanningEventInput = {
        id: plan.id.toString(),
        resourceId: plan.resource_id.toString(),
        title: plan.test ? plan.test.name : '-',
        start: plan.start_date,
        end: plan.end_date,
        extendedProps: {
          subTitle:
            new TranslatePipe(this.languageService).transform(
              '401',
              'Product',
            ) +
            ': ' +
            (plan.resource?.name ? plan.resource?.name : ''),
          rightDesc: this.getRightDesc(plan),
          completedPercent: this.getCompletedPercent(plan),
          eventInfoData: this.getEventInfoData(plan),
          eventDetailData: this.getEventDetailItems(plan),
          subId: plan.test?.id,
        },
      };
      convertedData.push(convertedItem);
    });
    return convertedData;
  }

  getRightDesc(item: Plan): string {
    const oneDay = 24 * 60 * 60;
    const oneHour = 60 * 60;
    let totalDiff =
      (new Date(item.end_date).getTime() -
        new Date(item.start_date).getTime()) /
      1000;
    totalDiff = Math.abs(Math.floor(totalDiff));
    const totalDays = Math.floor(totalDiff / oneDay);
    const leftTotal = totalDiff - totalDays * oneDay;
    const totalHrs = Math.floor(leftTotal / oneHour);

    let days: number;
    let hrs: number;
    if (new Date() <= new Date(item.start_date)) {
      days = 0;
      hrs = 0;
    } else if (new Date() >= new Date(item.end_date)) {
      days = totalDays;
      hrs = totalHrs;
    } else {
      let diff =
        (new Date().getTime() - new Date(item.start_date).getTime()) / 1000;
      diff = Math.abs(Math.floor(diff));
      days = Math.floor(diff / oneDay);
      const leftSec = diff - days * oneDay;
      hrs = Math.floor(leftSec / oneHour);
    }

    const text =
      days +
      ' ' +
      new TranslatePipe(this.languageService).transform('156', 'day') +
      ' ' +
      hrs +
      new TranslatePipe(this.languageService).transform('1839', 'h') +
      ' /' +
      totalDays +
      ' ' +
      new TranslatePipe(this.languageService).transform('156', 'day') +
      ' ' +
      totalHrs +
      new TranslatePipe(this.languageService).transform('1839', 'h');

    return text;
  }

  getCompletedPercent(item: Plan): number {
    let completedPercent: number;
    if (!item.test && new Date() > new Date(item.start_date)) {
      completedPercent = 100;
    } else if (new Date() <= new Date(item.start_date)) {
      completedPercent = 0.1;
    } else if (new Date() >= new Date(item.end_date)) {
      completedPercent = 100;
    } else {
      completedPercent = item.completedPercent;
    }
    return completedPercent;
  }

  getEventInfoData(item: Plan): InfoHoverData {
    const formattedStartDate = this.datePipe.transform(
      item.start_date,
      'dd.MM.yyyy - HH:mm',
    ) as string;
    const testOwner = item.test ? (item.test?.user_name as string) : '-';
    const planOwner = item.user ? item.user.name : '-';
    return {
      items: [
        {
          key: new TranslatePipe(this.languageService).transform('562', 'Test'),
          value: item.test ? item.test.name : '-',
        },
        {
          key: new TranslatePipe(this.languageService).transform(
            '1915',
            'Product No',
          ),
          value: item.resource?.id.toString(),
        },
        {
          key: new TranslatePipe(this.languageService).transform(
            '571',
            'Test Owner',
          ),
          value: testOwner,
        },
        {
          key: new TranslatePipe(this.languageService).transform(
            '1916',
            'Plan Owner',
          ),
          value: planOwner,
        },
        {
          key: new TranslatePipe(this.languageService).transform(
            '379',
            'Planned Start Date',
          ),
          value: formattedStartDate,
        },
        {
          key: new TranslatePipe(this.languageService).transform(
            '579',
            'Test Type',
          ),
          value: item.test
            ? item.test.test_type.name +
              ' - ' +
              item.test.test_type.duration_h +
              ' ' +
              new TranslatePipe(this.languageService).transform('1917', 'Hours')
            : '-',
        },
        {
          key: new TranslatePipe(this.languageService).transform(
            '381',
            'Planning',
          ),
          value: this.getRightDesc(item),
        },
      ],
    };
  }

  getEventDetailItems(item: Plan): DetailHoverItem[] {
    const testViewReadPermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.testView,
        AuthorizationActionEnum.read,
      );
    const testViewCreatePermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.testView,
        AuthorizationActionEnum.create,
      );
    let items = [];
    if (
      new Date(item.start_date) > new Date() ||
      !item.test ||
      !testViewReadPermission ||
      !testViewCreatePermission
    ) {
      items.push({
        id: 2,
        icon: 'remove_red_eye',
        text: new TranslatePipe(this.languageService).transform(
          '1961',
          'Station Detail',
        ),
      });
    } else if (testViewReadPermission && testViewCreatePermission) {
      items.push({
        id: 1,
        icon: 'remove_red_eye',
        text: new TranslatePipe(this.languageService).transform(
          '1962',
          'Test Detail',
        ),
      });
    }
    return items;
  }
}
