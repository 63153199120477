import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'src/app/core/services';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(translationKeyId: number | string, words?: string | any): string {
    let trans = '';

    if (this.languageService.translationOfSelectedLang === null) {
      this.languageService.translationOfSelectedLang = [];
    }

    if (this.languageService.translationOfSelectedLang.length < 1) {
      trans = words;
    } else {
      const index = this.languageService.translationOfSelectedLang.findIndex(
        (translation) => translation.translation_key_id === +translationKeyId
      );

      if (index !== -1) {
        trans = this.languageService.translationOfSelectedLang[index].text;
        if (trans === '') {
          trans = words;
        }
      } else {
        trans = words;
      }
    }

    return trans;
  }
}
