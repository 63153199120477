<div class="lab-room-selector-wrapper" fxLayout="row" fxLayoutGap="16px">
  <mat-icon class="back-icon clickable" (click)="navigateToPreviousPage()">arrow_back</mat-icon>
  <div *ngIf="!isArchiveTest" class="hidden-mobile" fxFlex fxLayout="column">
    <div class="room-name">{{ labName }} - {{ roomName }}</div>
    <div class="lab-room-selector-wrapper">
      <button
        mat-button
        mat-icon-button
        [matMenuTriggerFor]="stationSelectorMatMenu"
        fxLayout="row"
        class="menu-btn clickable">
        {{ stationName }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu class="lab-room-selector-mat-menu" #stationSelectorMatMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let menuItem of menuItems">
    <button mat-menu-item [matMenuTriggerFor]="roomsMatMenu">
      <span>{{ menuItem.name }}</span>
    </button>
    <mat-menu class="lab-room-selector-mat-menu" #roomsMatMenu="matMenu">
      <ng-container *ngFor="let resource of menuItem.resources">
        <button mat-menu-item [mat-menu-trigger-for]="stationsMatMenu">
          <span>{{ resource.name }}</span>
        </button>
        <mat-menu class="lab-room-selector-mat-menu" #stationsMatMenu="matMenu">
          <ng-container *ngFor="let station of resource.childs">
            <button mat-menu-item>
              <mat-radio-button
                fxLayout="row"
                fxLayoutAlign="start center"
                class="station-selector-radio-button"
                (click)="onSelectStation(menuItem, resource, station)"
                [value]="station.id"
                matTooltip="{{ menuItem.name }}"
                [checked]="station.isSelected">
                {{ station.name }}
              </mat-radio-button>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-menu>
  </ng-container>
</mat-menu>
