import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Fuse from 'fuse.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActiveTest, ActiveTestList } from 'src/app/core/models';
import { ShellService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { Theme } from '../../enums';
import { ThemeService } from '../../services';

@Component({
  selector: 'app-launcher-dialog',
  templateUrl: './launcher-dialog.component.html',
  styleUrls: ['./launcher-dialog.component.scss']
})
export class LauncherDialogComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.goToTest();

        break;

      case 'Escape':
        this.closeDialog();

        break;

      case 'ArrowDown':
        if(!this.selectedTest){
          this.selectedTest = this.filteredTests[0];
        }else{
          const index = this.filteredTests.findIndex(test => test.id === this.selectedTest.id);
          if(index !== this.filteredTests.length - 1){
            this.selectedTest = this.filteredTests[index + 1];
          }
        }

        break;

      case 'ArrowUp':
        if(!this.selectedTest){
          this.selectedTest = this.filteredTests[0];
        }else{
          const index = this.filteredTests.findIndex(test => test.id === this.selectedTest.id);
          if(index !== 0){
            this.selectedTest = this.filteredTests[index - 1];
          }
        }

        break;
    }



    
 
  }

  userId: number;
  activeTests : ActiveTestList['data']['list']= [];
  filteredTests : ActiveTest[]= [];
  selectedTest: ActiveTest;
  isExtraColumnsWillShowByEnvironment = environment.isGetProductByIndexAtBookATestMenu;
  isLoading = false;
  loaderPath = '/assets/RLS_LightLoader.json';
  isDarkMode = false;
  filterText = '';
  fuse: Fuse<ActiveTestList['data']['list'][number]>;
  fuseOptions = {
	  // isCaseSensitive: false,
	  // includeScore: false,
	  // shouldSort: true,
	  // includeMatches: false,
	  // findAllMatches: false,
	  // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: [
      "name",
      "test_space_parent_name",
      "test_space_name",
    ]
  };
  
  private unsubscribe = new Subject<void>();

  constructor(private matDialog: MatDialog, private shellService: ShellService, private themeService: ThemeService, private router: Router) {
    this.setTheme();

    if(this.isExtraColumnsWillShowByEnvironment) {
      this.fuseOptions.keys.push("inventory_engineer", "inventory_technician", "inventory_index_no");
    }
  }

  ngOnInit(): void {
    this.userId = parseInt(localStorage.getItem('userId') as string);
    this.getActiveTests();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

  filterTests(){
    if(this.filterText !== ''){
      this.filteredTests = this.fuse.search(this.filterText).map(result => result.item);
    }else{
      this.filteredTests = JSON.parse(JSON.stringify(this.activeTests));
    }
    
  }

  private setTheme() {
    this.themeService.selectedTheme.pipe(takeUntil(this.unsubscribe)).subscribe((selectedTheme) => {
      this.loaderPath = selectedTheme === Theme.Dark ? '/assets/RLS_DarkLoader.json' : '/assets/RLS_LightLoader.json';
      this.isDarkMode = selectedTheme === Theme.Dark;
    
    });
  }

  changeSelectedTest(selectedTest: ActiveTestList['data']['list'][number]) {
    this.selectedTest = selectedTest;
  }

  goToTest() {
    if (this.selectedTest) {
      this.router.navigate(['tests/testDetail'], {
        queryParams: {
          roomId: this.selectedTest.test_space_parent_id,
          stationId: this.selectedTest.test_space_id,
          testId: this.selectedTest.id,
        },
      });
      this.closeDialog();
    }
  }


  private getActiveTests() {
    this.isLoading = true;

    this.shellService
    .getActiveTestWithUserId(
      this.userId,
      {
        "startRow": 0,
        "endRow": 0,
        "rowGroupCols": [],
        "valueCols": [],
        "pivotCols": [],
        "pivotMode": false,
        "groupKeys": [],
        "filterModel": {},
        "sortModel": [
            {
                "colId": "start_date",
                "sort": "desc"
            }
        ]
    },
      undefined
    )
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (activeTestList: ActiveTestList) => {
        const { list } = activeTestList.data;
        
        this.activeTests = list;
    
        this.fuse = new Fuse(this.activeTests, this.fuseOptions);

        this.filteredTests = JSON.parse(JSON.stringify(this.activeTests));
        
        this.isLoading = false;
     
      },
      () => {
        this.isLoading = false;
      }
    );
  }

}
