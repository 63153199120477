<div fxLayout="row" class="search-filter-input hidden-mobile" style="flex: 1 1 0%">
  <div class="filter-text-inbox-wrapper">
    <input
      [(ngModel)]="searchInput"
      (keydown.enter)="onEnter()"
      type="text"
      placeholder="{{ '492' | translate: 'Search' }}..." />
    <mat-icon>search</mat-icon>
  </div>

  <mat-chip-list>
    <mat-chip
      *ngFor="let searchKey of searchKeys; let index = index"
      fxLayout="row"
      class="quick-search-chip"
      fxLayoutAlign="space-between center"
      [selectable]="false"
      [removable]="true"
      [disableRipple]="true"
      (removed)="removeSearchKey(index)">
      {{ searchKey }}

      <mat-icon class="clickable" matChipRemove>close</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <button *ngIf="searchKeys.length > 0" class="rt-btn-secondary remove-all-chips-btn" (click)="removeAllSearchKeys()">
    <mat-icon class="clickable">close</mat-icon>
    Filtreleri Temizle
  </button>
</div>
