import { environment } from '../environments/environment';

const oktaRedirectUri = (environment as any).oktaRedirectUri;

export default {
  oidc: {
    clientId: `0oa41ac2rsfGazKWj4x7`,
    issuer: `https://login.rota.net/`,
    redirectUri: oktaRedirectUri,
    scopes: ['openid', 'profile', 'email'],
  },
};
