export enum SettingsPageOptions {
  StaffManagement = 1,
  OrgSchema,
  Language,
  Hardware,
  Calibration,
  IniFiles,
  OnboardingRequests,
  PreviousCalibration,
  ResourceDefinition,
  AddTestType,
  SampleRateOperations,
  NotificationParams,
  SensorSettings,
  AnalogScale,
  Versions,
  AnalogSensor,
  Units,
  GraphSettings,
}
