import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Room, Station } from 'src/app/core/models';
import { LabRoomStation } from '../../interfaces';

@Component({
  selector: 'app-lab-room-station-filter-dialog',
  templateUrl: './lab-room-station-filter-dialog.component.html',
  styleUrls: ['./lab-room-station-filter-dialog.component.scss'],
})
export class LabRoomStationFilterDialogComponent implements OnInit {
  labRoomsStations: LabRoomStation[];
  selectedLabId: number;
  selectedRoomId: number;
  selectedStationId: number;
  selectTheStation: any;
  isSelectedStationChanged: boolean;

  filteredStationList: Station[] = [];
  filteredRoomList: Room[] = [];

  constructor(private matDialogRef: MatDialogRef<LabRoomStationFilterDialogComponent>) {}

  ngOnInit(): void {
    this.filterTheRooms({ value: this.selectedLabId });
    this.filterTheStations({ value: this.selectedRoomId });
  }

  filterTheRooms(event: any) {
    this.selectedLabId = event.value;
    const index = this.labRoomsStations.findIndex((lab) => lab.id === event.value);
    if (index !== -1) {
      this.filteredRoomList = this.labRoomsStations[index].rooms as any;
    }
  }

  filterTheStations(event: any) {
    this.selectedRoomId = event.value;
    const index = this.filteredRoomList.findIndex((room) => room.id === event.value);
    if (index !== -1) {
      this.filteredStationList = this.filteredRoomList[index].stations as any;
    }
  }

  setSelectTheStation(event: any) {
    this.isSelectedStationChanged = true;
    this.selectTheStation = event.value;
    this.matDialogRef.close();
  }
}
