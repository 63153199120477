import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeedbackService, FeedbackSizes, FeedbackTypes } from '@rotateknik/rt-std-wc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationType } from 'src/app/core/enums';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';
import { NotificationLevel } from 'src/app/core/enums/notification-level.enum';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { LanguageService, ShellService, TestManagementService } from 'src/app/core/services';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs';
import { NotificationDialogRequestEventType, TestListsPageTabOptions, Theme } from 'src/app/shared/enums';
import { TranslatePipe } from 'src/app/shared/pipes';
import { PermissionControlService, ThemeService, UtilService } from 'src/app/shared/services';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationHistoryDialogComponent } from './notification-history-dialog/notification-history-dialog.component';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent implements OnInit, OnDestroy {
  notifications: NotificationData[] = [];
  isQueryRunning = false;
  isAnyNotificationDeleted = false;

  loaderPath = '/assets/RLS_LightLoader.json';
  isDarkMode = false;
  focusedNotify: NotificationData;
  hasUserExtraTimeListReadAccess = false;
  hasUserOnboardingListReadAccess = false;
  allNotificationsCount: number = 0;

  selectedNotificationForExtraTimeMenu: Notification;

  private unsubscribe = new Subject<void>();

  private errorText = new TranslatePipe(this.languageService).transform('211', 'Error');
  private confirmationQuestion = new TranslatePipe(this.languageService).transform('1979', 'Are You Sure ?');
  private yesText = new TranslatePipe(this.languageService).transform(641, 'Yes');
  private cancelText = new TranslatePipe(this.languageService).transform(109, 'Cancel');
  private deleteText = new TranslatePipe(this.languageService).transform('160', 'Delete');

  private liveReportCreatedText = new TranslatePipe(this.languageService).transform('20443', 'Live report created');
  private archiveActionText = new TranslatePipe(this.languageService).transform('1882', 'Go to Archive View');
  private successText = new TranslatePipe(this.languageService).transform(1858, 'Success');

  constructor(
    private languageService: LanguageService,
    private themeService: ThemeService,
    private router: Router,
    private shellService: ShellService,
    private feedbackService: FeedbackService,
    private matDialog: MatDialog,
    private utilService: UtilService,
    private dialogRef: MatDialogRef<NotificationsDialogComponent>,
    private permissionControlService: PermissionControlService,
    private notificationService: NotificationService,
    private testManagementService: TestManagementService
  ) {
    this.setLoaderPath();
  }

  ngOnInit(): void {
    this.extraTimeReadAccess();
    this.labRoleReadAccess();

    this.notifications.forEach((item) => {
      item.dateAgo = this.utilService.getDuration(item.creationDate);
      const translatedDescription = new TranslatePipe(this.languageService).transform(item.description, '');
      const translatedContent = new TranslatePipe(this.languageService).transform(item.content, '');
      item.descriptionString = this.utilService.getNotificationFieldsFromData(translatedDescription, item.data);
      item.contentString = this.utilService.getNotificationFieldsFromData(translatedContent, item.data, item.type);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  showAllNotifications() {
    this.dialogRef.close();
    this.router.navigate(['notifications']);
  }

  onRead() {
    this.shellService
      .markNotificationAsRead(this.focusedNotify.notificationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          const item = this.notifications.find((item) => item.notificationId === this.focusedNotify.notificationId);
          if (item) {
            item.readDate = new Date();
          }
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
        }
      );
  }

  onRemove() {
    this.shellService
      .deleteNotificationForUser(this.focusedNotify.notificationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.notifications = this.notifications.filter(
            (item) => item.notificationId !== this.focusedNotify.notificationId
          );
          this.isAnyNotificationDeleted = true;
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
        }
      );
  }

  allRead() {
    this.shellService
      .markAllNotificationsAsRead()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.notifications.map((item) => (item.readDate = new Date()));
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
        }
      );
  }

  allRemove() {
    const confirmDialogRef = this.matDialog.open(ConfirmDialogComponent);

    confirmDialogRef.componentInstance.confirmationQuestion = this.confirmationQuestion;
    confirmDialogRef.componentInstance.header = this.deleteText;
    confirmDialogRef.componentInstance.confirmText = this.yesText;
    confirmDialogRef.componentInstance.rejectText = this.cancelText;
    confirmDialogRef.componentInstance.closeAllDialogs = false;

    const dialogElement: HTMLCollectionOf<Element> = document.getElementsByClassName('mat-dialog-container');
    dialogElement[1].classList.add('custom-dialog');

    confirmDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (confirmDialogRef.componentInstance.approved) {
          this.shellService
            .deleteAllNotificationsForUser()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
              () => {
                this.notifications = [];
                this.allNotificationsCount = 0;
                this.notificationService.unreadNotificationCount = 0;
                this.isAnyNotificationDeleted = true;
              },
              (resp) => {
                const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
                this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
              }
            );
        }
      });
  }

  showCreationHistory(histories: Date[]) {
    const confirmDialogRef = this.matDialog.open(NotificationHistoryDialogComponent);
    confirmDialogRef.componentInstance.histories = histories;
  }

  acceptRequest(notification: NotificationData = this.focusedNotify) {
    this.shellService
      .markNotificationAsRead(notification.notificationId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (notification.type === NotificationType.ExtraTimeRequest) {
          this.dialogRef.close({ tab: NotificationDialogRequestEventType.ExtraTimeRequestTab, notification });
        } else if (notification.type === NotificationType.OnboardingRequest) {
          this.dialogRef.close({
            tab: NotificationDialogRequestEventType.OnboardingRequestTab,
            type: notification.type,
          });
          this.router.navigate(['settings'], {
            state: { activeTab: NotificationDialogRequestEventType.OnboardingRequestTab },
          });
        }
      });
  }

  onGoToTest(notification: NotificationData) {
    this.router.navigate(['tests/testDetail'], {
      queryParams: {
        stationId: notification.data.testSpaceResourceId,
        testId: notification.data.testId,
        roomId: notification.data.testSpaceParentResourceId,
      },
    });
  }

  onGoToFinishedTestList(notification: NotificationData) {
    this.router.navigate(['/tests'], {
      queryParams: {
        selected: TestListsPageTabOptions.FinishedTestsList,
        selectedFinishedTestId: notification.data.testId,
      },
    });

    this.dialogRef.close();
  }

  onCreateLiveReport(notification: NotificationData) {
    this.testManagementService
      .createArchiveTestView([notification.data.testId])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (testView) => {
          this.feedbackService
            .showFeedback(
              this.successText,
              this.liveReportCreatedText,
              FeedbackTypes.Success,
              FeedbackSizes.Medium,
              2000
            )
            .onAction()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.dialogRef.close();
              this.router.navigate(['tests/testDetail'], {
                queryParams: { testViewId: testView.id, testId: notification.data.testId },
              });
            });
        },
        (resp) => {
          this.dialogRef.close();

          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
        }
      );
  }

  extraTimeReadAccess() {
    this.hasUserExtraTimeListReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.planextension,
      AuthorizationActionEnum.read
    );
  }

  labRoleReadAccess() {
    this.hasUserOnboardingListReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.labRoleRequest,
      AuthorizationActionEnum.read
    );
  }

  hasNotifications(): boolean {
    return this.allNotificationsCount > 0;
  }

  private setLoaderPath() {
    this.themeService.selectedTheme.pipe(takeUntil(this.unsubscribe)).subscribe((selectedTheme) => {
      this.loaderPath = selectedTheme === Theme.Dark ? '/assets/RLS_DarkLoader.json' : '/assets/RLS_LightLoader.json';
      this.isDarkMode = selectedTheme === Theme.Dark;
    });
  }

  get notificationLevel() {
    return NotificationLevel;
  }

  get notificationType() {
    return NotificationType;
  }
}
