<form [formGroup]="roomsSortFilterForm" class="test-filter-dialog-wrapper" fxLayout="column" fxLayoutGap="11px">
  <div class="sort" fxLayout="row" fxLayoutGap="16px">
    <div fxLayout="column" class="sort-options" fxLayoutGap="7px">
      <div class="header">{{ 519 | translate: 'Sort' }}</div>
      <mat-select disableOptionCentering formControlName="sortType">
        <mat-option *ngFor="let sortType of sortTypes" [value]="sortType.id">
          {{ sortType.name }}
        </mat-option>
      </mat-select>
    </div>
    <button class="rt-btn-fourth sort-direction-btn" fxLayout="row" (click)="changeSortDirectionType()">
      <mat-icon>
        {{
          roomsSortFilterForm.get('sortDirection')!.value === sortDirectionType.Ascending
            ? 'arrow_upward'
            : 'arrow_downward'
        }}
      </mat-icon>
    </button>
  </div>
  <div class="rt-form">
    <div *ngFor="let roomDynamicFieldWithOptions of roomDynamicFieldsWithOptions" fxLayout="column" fxLayoutGap="7px">
      <div class="header">{{ roomDynamicFieldWithOptions.title }}</div>
      <mat-select disableOptionCentering [formControlName]="roomDynamicFieldWithOptions.title">
        <mat-option *ngFor="let option of roomDynamicFieldWithOptions.values" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <!-- <mat-checkbox [checked]="false">Kapı Kontrollü Odalar</mat-checkbox> -->

  <div class="action-buttons" fxLayout="row">
    <button class="rt-btn-secondary reset-btn" (click)="resetFilter.next()">{{ 437 | translate: 'Reset' }}</button>
    <div fxLayoutAlign="end center" fxLayout="row" fxFlex>
      <button class="rt-btn-primary rt-btn-lg" (click)="applyFilter.next()">{{ 64 | translate: 'Apply' }}</button>
    </div>
  </div>
</form>
