import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequestParams } from '@rotateknik/rt-std-wc';
import { Observable } from 'rxjs';
import { PlanType } from 'src/app/shared/enums';
import {
  AttributeDefinition,
  PlansByTypeId,
  PlansByTypeIdAndResourceId,
  ResourceDefinition,
  ResourceDefinitionListItem,
  ResourceEditModel,
  ResourceListItem,
  ResourceMaintenanceStatus,
} from 'src/app/shared/interfaces';
import { AvailableStation } from 'src/app/shared/interfaces/available-station.model';
import { PlanDefinitionDetail } from 'src/app/shared/interfaces/plan-definition-detail.model';
import { PlanDefinition } from 'src/app/shared/interfaces/plan-definition.model';
import { environment } from 'src/environments/environment';
import { ExtensionApproveStatus } from '../enums';
import {
  EditData,
  EntityAvailability,
  GetAllEntityAvailabilityByLabId,
  RmsRelationDefinition,
  ShellRmsRelationDefinition,
  UpdateResource,
} from '../models';
import { Plan } from '../models/plan.model';
import { PlansByResourceDefinition } from '../models/plans-by-resource-definition';
import { Resources } from '../models/resources.model';

const baseUrl = environment.resourceManagementServicePath;

@Injectable({
  providedIn: 'root',
})
export class ResourceManagementService {
  private token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getResourcesWithParentByDefinitionId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/getResourcesWithParentByDefinitionId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getStationsWithRoomId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/getStationsWithRoomId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getRoomStationHierarchically(): Observable<any> {
    return this.http.post(
      `${baseUrl}/entity/getRoomStationHierarchically`,

      {},

      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTemperatureHumidity(): Observable<any> {
    return this.http.get(`${baseUrl}/entity/getTemperatureHumidity`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  createTemperatureHumidity(temperature: number, humidity: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/entity/createTemperatureHumidity`,
      {
        temperature,
        humidity,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteTemperatureHumidity(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/entity/deleteTemperatureHumidity`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  checkDevice(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/entity/checkDevice`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getIniFiles(): Observable<any> {
    return this.http.get(`${baseUrl}/entity/getIniFiles`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getIniSensorsWithIniFileId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/entity/getIniSensorsWithIniFileId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getRoomTempAndHumidity(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/room/getRoomTempAndHumidity`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getResourceDefinitions(): Observable<ResourceDefinition[]> {
    return this.http.get(`${baseUrl}/resourceDefinition/getResourceDefinitions`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<ResourceDefinition[]>;
  }

  createResourceDefinition(resourceDefinition: ResourceDefinition): Observable<any> {
    return this.http.post(
      `${baseUrl}/resourceDefinition/createResourceDefinition`,
      {
        resourceDefinition,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteResourceDefinition(id: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/resourceDefinition/deleteResourceDefinition`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getResourceDefinitionList(): Observable<ResourceDefinitionListItem[]> {
    return this.http.get<ResourceDefinitionListItem[]>(`${baseUrl}/resourceDefinition/getResourceDefinitionList`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getResourcesByResourceDefinitionId(parentId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/getResourcesByResourceDefinitionId`,
      {
        id: parentId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getPlanDefinitionDetailByTypeId(id: PlanType): Observable<PlanDefinitionDetail> {
    return this.http.post(
      `${baseUrl}/planDefinition/getPlanDefinitionDetailByTypeId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<PlanDefinitionDetail>;
  }

  getResourcesByDefinitionId(definitionId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/getResourcesByDefinitionId`,
      {
        id: definitionId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAvailableResourcesByPlan(planDefinition: { plan: PlanDefinition }): Observable<AvailableStation> {
    return this.http.post(`${baseUrl}/plan/getAvailableResourcesByPlan`, planDefinition, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<AvailableStation>;
  }

  createPlan(reservationInfo: { plan: PlanDefinition; user_id: number; lab_id: number }): Observable<PlanDefinition> {
    return this.http.post(`${baseUrl}/plan/createPlan`, reservationInfo, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<PlanDefinition>;
  }

  createResource(resource: {
    resource_definition_id: number;
    parent_id: number;
    name: string;
    description: string;
    attributes: { attribute_definition_id: number; data_type: number; value: any }[];
    relations: { relation_definition_id: number; destination_record_id: number }[];
  }): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/createResource`,
      {
        resource,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateResource(resource: ResourceEditModel): Observable<UpdateResource[]> {
    return this.http.post(
      `${baseUrl}/resource/updateResource`,
      {
        resource,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<UpdateResource[]>;
  }

  createRmsRelationDefinition(
    shellRmsRelationDefinition: ShellRmsRelationDefinition,
  ): Observable<RmsRelationDefinition> {
    return this.http.post(
      `${baseUrl}/rmsRelationDefinition/createRmsRelationDefinition`,
      {
        rmsRelationDefinition: shellRmsRelationDefinition,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<RmsRelationDefinition>;
  }

  getAttributeDefinitions(): Observable<AttributeDefinition[]> {
    return this.http.get<AttributeDefinition[]>(`${baseUrl}/attributeDefinition/getAttributeDefinitions`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  createAttributeDefinitions(attributeDefinitions: AttributeDefinition[]): Observable<AttributeDefinition[]> {
    return this.http.post(
      `${baseUrl}/attributeDefinition/createAttributeDefinitions`,
      {
        attributeDefinitions: attributeDefinitions,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AttributeDefinition[]>;
  }

  updateAttributeDefinition(attributeDefinition: AttributeDefinition): Observable<AttributeDefinition> {
    return this.http.post(
      `${baseUrl}/attributeDefinition/updateAttributeDefinition`,
      {
        attributeDefinition: attributeDefinition,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AttributeDefinition>;
  }

  getPlansByTypeId(type: number, filters?: FilterRequestParams, searchValue?: string[]): Observable<PlansByTypeId> {
    return this.http.post<PlansByTypeId>(
      `${baseUrl}/plan/getPlansByTypeId`,
      {
        type_id: type,
        filters,
        searchValue,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getPlansByTypeIdAndResourceId(
    type: number,
    stationId: number,
    filters?: FilterRequestParams,
    searchValue?: string[],
  ): Observable<PlansByTypeIdAndResourceId[]> {
    return this.http.post<PlansByTypeIdAndResourceId[]>(
      `${baseUrl}/plan/getPlansByTypeIdAndResourceId`,
      {
        type_id: type,
        resource_id: stationId,
        filters,
        searchValue,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteResource(resourceId: number): Observable<{ result: number }> {
    return this.http.post(
      `${baseUrl}/resource/deleteResource`,
      {
        id: resourceId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<{ result: number }>;
  }

  getResourcesByParentId(roomId: number): Observable<Resources[]> {
    return this.http.post(
      `${baseUrl}/resource/getResourcesByParentId`,
      {
        id: roomId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Resources[]>;
  }

  getPlansByResourceIds(
    plansByResourceDefinition: PlansByResourceDefinition & { excludeCancelled: boolean },
  ): Observable<Plan[]> {
    return this.http.post(
      `${baseUrl}/plan/getPlansByResourceIds`,
      {
        resource_ids: plansByResourceDefinition.resource_ids,
        start_date: plansByResourceDefinition.start_date,
        end_date: plansByResourceDefinition.end_date,
        plan_type_id: plansByResourceDefinition.plan_type_id,
        excludeCancelled: plansByResourceDefinition.excludeCancelled,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Plan[]>;
  }

  getResourceRelationDefinitions(id: number): Observable<any[]> {
    return this.http.post(
      `${baseUrl}/resource/getResourceRelationDefinitionsByResourceDefinitionId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any[]>;
  }

  getEditDataOfResourceById(resourceId: number): Observable<EditData> {
    return this.http.post<EditData>(
      `${baseUrl}/resource/getEditDataOfResourceById`,
      {
        id: resourceId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getPlannedTestsCount(): Observable<any> {
    return this.http.post(
      `${baseUrl}/plan/getPlansCountByTypeId`,
      {
        type_id: 1,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getEntityAvailabilityInstant(entityType: number, entityId: number): Observable<EntityAvailability> {
    return this.http.post(
      `${baseUrl}/resource/getEntityAvailabilityInstant`,
      {
        entity_type: entityType,
        entity_id: entityId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EntityAvailability>;
  }

  getEntityAvailabilityWeekly(
    entityType: number,
    entityId: number,
    startDate: string,
    endDate: string,
  ): Observable<EntityAvailability> {
    return this.http.post(
      `${baseUrl}/resource/getEntityAvailabilityWeekly`,
      {
        entity_type: entityType,
        entity_id: entityId,
        start_date: startDate,
        end_date: endDate,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EntityAvailability>;
  }

  getEntityAvailabilityMonthly(
    entityType: number,
    entityId: number,
    startDate: string,
    endDate: string,
  ): Observable<EntityAvailability> {
    return this.http.post(
      `${baseUrl}/resource/getEntityAvailabilityMonthly`,
      {
        entity_type: entityType,
        entity_id: entityId,
        start_date: startDate,
        end_date: endDate,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<EntityAvailability>;
  }

  getAffectedPlansByPlanExtensionId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/PlanExtension/getAffectedPlansByPlanExtensionId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  rejectExtraTimeRequest(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/PlanExtension/reject`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  toMaintenanceMode(id: number, labId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/setResourceMaintenanceStatusTrue`,
      { id },
      {
        responseType: 'text',
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
          domain_id: labId.toString(),
        },
      },
    ) as Observable<any>;
  }

  exitMaintenanceMode(id: number, labId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/setResourceMaintenanceStatusFalse`,
      { id },
      {
        responseType: 'text',
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
          domain_id: labId.toString(),
        },
      },
    ) as Observable<any>;
  }

  cancelPlan(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/plan/cancelPlan`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getCountOfPendingPlanExtensionRequests(): Observable<any> {
    return this.http.get(`${baseUrl}/PlanExtension/getCountOfPendingPlanExtensionRequests`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<any>;
  }

  getAllEntityAvailabilityByLabId(
    id: number,
    start_date: string,
    end_date: string,
  ): Observable<GetAllEntityAvailabilityByLabId[]> {
    return this.http.post<GetAllEntityAvailabilityByLabId[]>(
      `${baseUrl}/resource/getAllEntityAvailabilityByLabId`,
      { id, start_date, end_date },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getResourcesAndDetailsByIds(ids: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/resource/getResourcesAndDetailsByIds`,
      { ids },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<any>;
  }

  getResourcesMaintenanceStatus(ids: number[]) {
    return this.http.post<ResourceMaintenanceStatus[]>(
      `${baseUrl}/resource/getResourcesMaintenanceStatus`,
      { ids },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getResourceListByDefinitionId(
    id: number,
    filters: FilterRequestParams,
    searchValue?: string[],
  ): Observable<ResourceListItem[]> {
    return this.http.post<ResourceListItem[]>(
      `${baseUrl}/resource/getResourceListByDefinitionId`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getResourceListWithLabIdByDefinitionId(
    id: number,
    filters: FilterRequestParams,
    searchValue?: string[],
  ): Observable<ResourceListItem[]> {
    return this.http.post<ResourceListItem[]>(
      `${baseUrl}/resource/getResourceListWithLabIdByDefinitionId`,
      { id, filters, searchValue },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getChildDetailsByParentId(id: number): Observable<{ id: number; name: string }[]> {
    return this.http.post<{ id: number; name: string }[]>(
      `${baseUrl}/resource/getChildDetailsByParentId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateResourceAttributesByIds(attributes: { id: number; value: any }[]) {
    return this.http.post<{
      isSucceeded: boolean;
    }>(
      `${baseUrl}/resource-attribute/updateResourceAttributesByIds`,
      { attributes },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getPlanExtensionStatusById(id: number): Observable<{ is_approved: ExtensionApproveStatus }> {
    return this.http.post<{ is_approved: ExtensionApproveStatus }>(
      `${baseUrl}/planExtension/getPlanExtensionStatusById`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getResourceByIndexNo(indexNo: string) {
    return this.http.post<{
      id: number;
      resource_definition_id: number;
      name: string;
      description: string;
      parent_id: null | number;
      is_deleted: number;
      createdAt: string;
      updatedAt: string;
    } | null>(
      `${baseUrl}/resource/getResourceByIndexNo`,
      { indexNo },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }
}
