import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { FeedbackService, FeedbackSizes, FeedbackTypes } from '@rotateknik/rt-std-wc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Translation } from 'src/app/core/models';
import {
  CsvService,
  DataService,
  LanguageService,
  ProductManagementService,
  ResourceManagementService,
  ResourcesTabManagementService,
  ShellService,
  StatisticService,
  TestManagementService,
} from 'src/app/core/services';
import { ComplianceService } from 'src/app/core/services/compliance.service';
import { OnboardingStatus, Theme } from 'src/app/shared/enums';
import { LoginType } from 'src/app/shared/enums/login-type.enum';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { TranslatePipe } from '../../pipes';
import { FormValidatorService } from '../../services';

enum FormTypeEnum {
  SignIn = 1,
  SignUp,
  ForgetPass,
  ActivateUser,
  ActivatePass,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  isSubmitted = false;
  isSignUpSubmitted = false;
  isForgetPassSubmitted = false;
  isLoginFailed: boolean;

  loginForm = this.formBuilder.group({
    email: [
      null,
      environment.loginType === LoginType.Custom ? [Validators.email, Validators.required] : [Validators.required],
    ],
    password: [null, [Validators.required, this.formValidatorService.noWhitespaceValidator]],
  });

  SignUpForm = this.formBuilder.group({
    email: [null, [Validators.email, Validators.required]],
    userName: [null, Validators.required],
    password: [null, [Validators.required, this.formValidatorService.noWhitespaceValidator]],
  });

  forgetPassForm = this.formBuilder.group({
    email: [null, [Validators.email, Validators.required]],
    password: [null, [Validators.required, this.formValidatorService.noWhitespaceValidator]],
  });

  activateUserForm = this.formBuilder.group({
    code: [null, [Validators.required, this.formValidatorService.noWhitespaceValidator]],
  });

  activatePassForm = this.formBuilder.group({
    code: [null, [Validators.required, this.formValidatorService.noWhitespaceValidator]],
  });

  selectedTheme: any;
  selectedFormType = FormTypeEnum.SignIn;

  private unsubscribe = new Subject<void>();

  private successText = new TranslatePipe(this.languageService).transform('1858', 'Success');
  private errorText = new TranslatePipe(this.languageService).transform('211', 'Error');

  constructor(
    private formBuilder: FormBuilder,
    public oktaAuth: OktaAuthService,
    public shellService: ShellService,
    private router: Router,
    private themeService: ThemeService,
    private feedbackService: FeedbackService,
    public languageService: LanguageService,
    private formValidatorService: FormValidatorService,
    private csvService: CsvService,
    private dataService: DataService,
    private productManagementService: ProductManagementService,
    private resourceManagementService: ResourceManagementService,
    private resourcesTabManagementService: ResourcesTabManagementService,
    private statisticService: StatisticService,
    private testManagementService: TestManagementService,
    private complianceService: ComplianceService
  ) {
    this.themeService.selectedTheme
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedTheme) => (this.selectedTheme = selectedTheme));
  }

  async ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get formTypeEnum(): any {
    return FormTypeEnum;
  }

  get theme(): any {
    return Theme;
  }

  get Environment() {
    return environment;
  }

  get loginType() {
    return LoginType;
  }

  onLogin(): void {
    this.isSubmitted = true;

    const { email, password }: any = this.loginForm.value;

    switch (environment.loginType) {
      case LoginType.Custom:
        this.shellService
          .login(email, password)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (resp: any) => {
              this.afterLoggedIn(resp);
            },
            () => {
              this.isLoginFailed = true;
              this.isSubmitted = false;
            }
          );
        break;

      case LoginType.Ldap:
        this.shellService
          .loginWithLdap(email, password)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (resp: any) => {
              this.afterLoggedIn(resp);
            },
            () => {
              this.isLoginFailed = true;
              this.isSubmitted = false;
            }
          );
        break;

      default:
        break;
    }
  }

  afterLoggedIn(resp: any): void {
    const { token, type, user } = resp;
    this.shellService
      .getUserAccess(user.id.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userAccess: any) => {
        const isOnboardingCompleted = user.user_setting ? user.user_setting.is_onboarding_complete.toString() : 'false';
        localStorage.setItem('token', token);
        localStorage.setItem('userName', user.name);
        localStorage.setItem('email', user.email);
        localStorage.setItem('userId', user.id);
        localStorage.setItem('isOnboardingCompleted', isOnboardingCompleted);

        this.updateServicesAuidToken(token, user.id.toString());

        localStorage.setItem('policies', JSON.stringify(userAccess.policies));
        localStorage.setItem('accessibleData', JSON.stringify(userAccess.accessibleData));
        localStorage.setItem('languageCodes', JSON.stringify(user.user_setting?.language.language_settings));

        if (type === OnboardingStatus.Completed) {
          this.router.navigate(['/dashboard']);
          this.handleTranslation(resp.user);
        } else if (type === OnboardingStatus.Pending) {
          this.router.navigate(['/onboarding'], {
            queryParams: { type, req: JSON.stringify(resp.requests), user: JSON.stringify(user) },
          });
        } else if (type === OnboardingStatus.FirstRequest) {
          this.router.navigate(['/onboarding'], {
            queryParams: { type, user: JSON.stringify(user) },
          });
        }

        this.isLoginFailed = false;
        this.isSubmitted = false;
      });
  }

  getUserAccess(): void {
    this.shellService
      .getUserAccess()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: any) => {
        localStorage.setItem('policies', JSON.stringify(resp.policies));
        localStorage.setItem('accessibleData', JSON.stringify(resp.accessibleData));
      });
  }

  signUpCustom(): void {
    const { userName, email, password }: any = this.SignUpForm.value;
    this.shellService
      .createUser(userName, email, password)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (resp: any) => {
          const msg = new TranslatePipe(this.languageService).transform(resp.code, resp.msg);
          this.feedbackService.showFeedback(this.successText, msg, FeedbackTypes.Success, FeedbackSizes.Medium, 3000);
          this.selectedFormType = FormTypeEnum.ActivateUser;
        },
        (resp) => {
          const msg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, msg, FeedbackTypes.Error, FeedbackSizes.Medium, 3000);
        }
      );
  }

  forgetPassCustom(): void {
    const { email, password }: any = this.forgetPassForm.value;
    this.shellService
      .resetPassword(email, password)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (resp: any) => {
          const msg = new TranslatePipe(this.languageService).transform(resp.code, resp.msg);
          this.feedbackService.showFeedback(this.successText, msg, FeedbackTypes.Success, FeedbackSizes.Medium, 3000);
          this.selectedFormType = FormTypeEnum.ActivatePass;
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Medium, 3000);
        }
      );
  }

  activatePassCustom() {
    const { code }: any = this.activatePassForm.value;
    this.shellService
      .activateNewPassword(parseInt(code))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (resp: any) => {
          const msg = new TranslatePipe(this.languageService).transform(resp.code, resp.msg);
          this.feedbackService.showFeedback(this.successText, msg, FeedbackTypes.Success, FeedbackSizes.Medium, 3000);
          this.selectedFormType = FormTypeEnum.SignIn;
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Medium, 3000);
        }
      );
  }

  activateUserCustom() {
    const { code }: any = this.activateUserForm.value;
    this.shellService
      .activateUser(parseInt(code))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (resp: any) => {
          const msg = new TranslatePipe(this.languageService).transform(resp.code, resp.msg);
          this.feedbackService.showFeedback(this.successText, msg, FeedbackTypes.Success, FeedbackSizes.Medium, 3000);
          this.selectedFormType = FormTypeEnum.SignIn;
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Medium, 3000);
        }
      );
  }

  selectSignIn() {
    this.selectedFormType = FormTypeEnum.SignIn;
  }

  selectSignUp() {
    this.selectedFormType = FormTypeEnum.SignUp;
  }

  selectForgetPass() {
    this.selectedFormType = FormTypeEnum.ForgetPass;
  }

  isLoginButtonEnable(): boolean {
    return this.loginForm.valid && !this.isSubmitted;
  }

  isSignUpButtonEnable(): boolean {
    return this.SignUpForm.valid && !this.isSignUpSubmitted;
  }

  isForgetPassButtonEnable(): boolean {
    return this.forgetPassForm.valid && !this.isForgetPassSubmitted;
  }

  isActivateFormValid(): boolean {
    const { code }: any = this.activatePassForm.value;
    return !!code;
  }

  changeLoginModeToCustomLogin() {
    environment.loginType = this.loginType.Custom;
    this.loginForm.get('email')?.setValue(null);
    this.loginForm.get('email')?.setValidators([Validators.email, Validators.required]);
    this.loginForm.get('password')?.setValue(null);
  }

  private handleTranslation(user: any) {
    const languageId = user.user_setting.language.id;

    if (this.isLanguageDifferentFromStorage(languageId)) {
      localStorage.setItem('languageId', languageId);
      this.shellService.getTranslationsByLanguageId(languageId).subscribe((resp: { result: Translation[] }) => {
        localStorage.setItem('translations', JSON.stringify(resp.result));
        this.languageService.translationOfSelectedLang = resp.result;
      });
    } else {
      let translation = JSON.parse(localStorage.getItem('translations') as any);

      if (!this.isTranslationSaved(translation)) {
        this.shellService.getTranslationsByLanguageId(languageId).subscribe((resp: { result: Translation[] }) => {
          localStorage.setItem('translations', JSON.stringify(resp.result));
          this.languageService.translationOfSelectedLang = resp.result;
        });
      }
    }
  }

  private isTranslationSaved(translation: any[]): boolean {
    return translation && translation.length > 0;
  }

  private isLanguageDifferentFromStorage(languageId: number): boolean {
    const languageIdOnStorage = parseInt(localStorage.getItem('languageId') as string);
    return languageIdOnStorage !== languageId;
  }

  private updateServicesAuidToken(token: string, userId: string) {
    this.shellService.setToken(token);
    this.complianceService.setToken(token);
    this.shellService.setAuid(userId);
    this.csvService.setToken(token);
    this.csvService.setAuid(userId);
    this.dataService.setToken(token);
    this.dataService.setAuid(userId);
    this.productManagementService.setToken(token);
    this.productManagementService.setAuid(userId);
    this.resourceManagementService.setToken(token);
    this.resourceManagementService.setAuid(userId);
    this.resourcesTabManagementService.setToken(token);
    this.resourcesTabManagementService.setAuid(userId);
    this.statisticService.setToken(token);
    this.statisticService.setAuid(userId);
    this.testManagementService.setToken(token);
    this.testManagementService.setAuid(userId);
  }
}
