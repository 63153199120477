<div class="station-selector-wrapper" fxLayout="row" fxLayoutGap="16px">
  <mat-icon class="back-icon clickable" (click)="navigateToPreviousPage()">arrow_back</mat-icon>
  <div fxFlex fxLayout="column">
    <div class="room-name">{{ roomName }}</div>
    <ng-container *ngTemplateOutlet="stationSelectionDialog"></ng-container>
  </div>
</div>

<ng-template #stationSelectionDialog>
  <button mat-icon-button [matMenuTriggerFor]="menu" fxLayout="row" class="menu-btn clickable">
    {{ selectedStationName }}
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="station-selection-dialog-mat-menu">
    <mat-radio-group fxLayoutGap="15px">
      <mat-radio-button
        *ngFor="let station of stations"
        (click)="changeStation(station)"
        [value]="station.id"
        matTooltip="{{ station.name }}">
        {{ station.name }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-menu>
</ng-template>
