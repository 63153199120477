import { Injectable } from '@angular/core';
import { AttributeDataType } from 'src/app/core/enums/attribute-data-type.enum';
import {
  LabInfoChild,
  LabInfoResource,
  LabInfoResourceAttribute,
  UtilLab,
} from 'src/app/core/models';
import { Resources } from 'src/app/core/models/resources.model';
import { LanguageService } from 'src/app/core/services';
import { AttributeDefinitionName, ResourceType } from '../enums';
import {
  LabRoomStation,
  Resource,
  ResourceAttribute,
  ResourceMaintenanceStatus,
  RoomView,
  StationView,
  TestResource,
} from '../interfaces';
import { RmsRelation } from '../interfaces/rms-relation.model';

interface Station {
  id: number;
  name: string;
  room_id: number;
  isAvailable: boolean;
}

interface Room {
  id: number;
  name: string;
  lab_id: number;
  stations: Station[];
  plcIds?: number[];
}

@Injectable()
export class ResourceManipulationService {
  constructor(private languageService: LanguageService) {}

  editLabRoomStationHierarchy(labs: UtilLab[]): LabRoomStation[] {
    //TODO: any data tipi düzeltilecek.
    const labRoomStation: LabRoomStation[] = [];
    let rooms: Room[] = [];
    let stations: Station[] = [];

    labs.forEach((lab) => {
      rooms = [];
      lab.resources.forEach((room: Resource) => {
        stations = [];
        room.childs!.forEach((station: Resource) => {
          stations.push({
            id: station.id,
            name: station.name,
            room_id: room.id,
            isAvailable: true,
          });
        });

        rooms.push({
          id: room.id,
          name: room.name,
          lab_id: lab.id,
          stations,
          plcIds: room.plcIds,
        });
      });

      labRoomStation.push({
        id: lab.id,
        name: lab.name,
        rooms,
      });
    });

    return labRoomStation;
  }

  editRoomStationTestHierarchy(
    rooms: LabInfoResource[],
    labId: number,
    maintenanceStatuses: ResourceMaintenanceStatus[] = [],
  ): RoomView[] {
    const roomView: RoomView[] = [];
    let stations: StationView[] = [];
    let isMaintenance = false;

    rooms.forEach((room) => {
      stations = [];
      room.childs.forEach((station: LabInfoChild) => {
        const { test } = station;
        let testInfo = {};
        if (test) {
          testInfo = this.editAndGetTest(test, labId, station.id, true);
        }

        stations.push({
          id: station.id,
          resourceDefinitionId: station.resource_definition_id,
          name: station.name,
          room_id: room.id,
          sensors: [],
          test: testInfo as any,
          isAvailable: station.isAvailable as boolean,
          sensorCount: 1, // TODO: düzeltilmeli
        });
      });

      isMaintenance = this.isMaintenance(room, maintenanceStatuses);

      roomView.push({
        id: room.id,
        name: room.name,
        lab_id: labId,
        stations,
        is_maintenance: isMaintenance,
      });
    });

    return roomView;
  }

  editAndGetTests(tests: TestResource[]) {
    const testList: TestResource[] = [];
    let editedTest;
    tests.forEach((test: TestResource) => {
      editedTest = this.editAndGetTest(test);
      testList.push(editedTest);
    });

    return testList;
  }

  editAndGetTest(
    test: any,
    labId?: number,
    stationId?: number,
    fromResource: boolean = false,
  ): any {
    const testInfo = {
      id: test.id,
      name: test.name,
      station_id: stationId,
      device_set: test.device_set,
      test_type_id: test.test_type_id,
      test_status_id: test.test_status_id,
      test_status: test.test_status,
      sample_time_id: test.sample_time_id,
      test_sample_time: test.test_sample_time,
      start_date: test.start_date,
      end_date: test.end_date,
      description: test.description,
      testType: test.test_type,
      user_id: test.user_id,
      user_name: test.user_name,
      completedPercent: test.completedPercent,
      isTimeout: test.isTimeout,
      traces: test.traces,
      room_name: test.room_name,
      station_name: test.station_name,
      udaq_test: test.udaq_test,
      udaq_traces: test.udaq_traces,
      power_threshold: test?.power_threshold,
      resources: test.resources ? test.resources : undefined,
    };

    if (test.resources) {
      const deviceIndex = test.resources.findIndex(
        (resource: any) =>
          resource.resource_definition.resource_type_id ===
          ResourceType.TestSpecimen,
      );

      if (deviceIndex !== -1) {
        const deviceResource = test.resources[deviceIndex];

        const engineer = this.getEngineerInfo(deviceResource.rms_relations);
        const technician = this.getTechnicianInfo(deviceResource.rms_relations);
        const creatorUserId = this.getCreatorUserId(
          deviceResource.rms_relations,
        );
        const energyClass = fromResource
          ? this.getEnergyClassForResource(deviceResource.resource_attributes)
          : this.getEnergyClass(deviceResource.resource_attributes);

        const device = {
          id: deviceResource.id,
          name: deviceResource.name,
          lab_id: labId,
          device_id: deviceResource.id,
          engineer,
          engineer_id: engineer?.id,
          technician,
          technician_id: technician?.id,
          creator_user_id: creatorUserId,
          energyClass,
        };

        Object.assign(testInfo, { device, device_id: device.id });
      }

      const stationIndex = test.resources.findIndex(
        (resource: any) =>
          resource.resource_definition.resource_type_id ===
          ResourceType.TestSpace,
      );

      if (stationIndex !== -1) {
        const stationResource = test.resources[stationIndex];
        const station = {
          id: stationResource.id,
          name: stationResource.name,
          room_id: stationResource.parent_id,
        };

        if (stationResource.parent) {
          const room = this.getRoomFromStation(stationResource);
          Object.assign(station, { room });
        }

        Object.assign(testInfo, { station });
      }
    }

    return testInfo;
  }

  getTestsDynamic(tests: TestResource[]) {
    const testList: TestResource[] = [];
    let dynamicTest: { testInfo: any; deviceColumns: any };
    let deviceColumns: any[] = [];
    let deviceColumnDefs: any[] = [];
    tests.forEach((test: TestResource) => {
      dynamicTest = this.getTestDynamic(deviceColumnDefs, test);
      testList.push(dynamicTest.testInfo);
      deviceColumns = dynamicTest.deviceColumns;
    });

    return { testList, deviceColumns };
  }

  getTestDynamic(deviceColumnDefs: any[], test: any): any {
    let deviceColumns = deviceColumnDefs;
    let device: any;
    let testInfo = JSON.parse(JSON.stringify(test));
    if (test.test_specimen_id) {
      device = { id: test.test_specimen_id, name: test.test_specimen_name };
    }
    try {
      const testSpecimenAttributes = JSON.parse(test.test_specimen_attributes);
      if (testSpecimenAttributes.length > 0) {
        testSpecimenAttributes.forEach((resourceAttribute: any) => {
          if (resourceAttribute?.attribute_definition?.name) {
            let attributeDefinition = resourceAttribute?.attribute_definition;
            let fieldId: string =
              resourceAttribute?.attribute_definition?.id.toString();
            let fieldName: string =
              resourceAttribute?.attribute_definition?.name;
            let isFieldDefined = deviceColumns.find(
              (deviceColumnDef: any) => deviceColumnDef.colId === fieldId,
            );

            if (!isFieldDefined) {
              let fieldTranslatedName =
                attributeDefinition.translation_key_id &&
                attributeDefinition.translation_key_id !== null
                  ? this.languageService.getLanguageText(
                      attributeDefinition.translation_key_id,
                    )
                  : fieldName;
              deviceColumns.push({ name: fieldTranslatedName, colId: fieldId });
            }

            let fieldVal =
              attributeDefinition.type === this.attributeDataType.Dropdown
                ? resourceAttribute?.attribute_definition_detail.value
                : resourceAttribute?.value;
            if (attributeDefinition.type === this.attributeDataType.Date) {
              fieldVal =
                new Date(fieldVal).getDate() +
                '.' +
                (new Date(fieldVal).getMonth() + 1).toString() +
                '.' +
                new Date(fieldVal).getFullYear();
            }
            device[fieldId] = fieldVal;
            testInfo[fieldId] = fieldVal;
          }
        });
      }
    } catch (error) {}

    if (test.test_specimen_id) {
      Object.assign(testInfo, { device, device_id: device.id });
    }

    return { testInfo, deviceColumns };
  }

  get attributeDataType() {
    return AttributeDataType;
  }

  private isMaintenance(
    room: LabInfoResource,
    maintenanceStatuses: ResourceMaintenanceStatus[],
  ): boolean {
    let isMaintenance = true;
    const index = room?.resource_attributes?.findIndex(
      (attribute: LabInfoResourceAttribute) => {
        return (
          attribute.attribute_definition.name ===
          AttributeDefinitionName.IsMaintenance
        );
      },
    );

    if (index && index !== -1) {
      isMaintenance = room.resource_attributes[index].value;
    } else if (maintenanceStatuses.length > 0) {
      const mIndex = maintenanceStatuses.findIndex((m) => m.id === room.id);
      if (mIndex >= 0) {
        const maintenanceStatus: ResourceMaintenanceStatus =
          maintenanceStatuses[mIndex];
        if (maintenanceStatus.is_maintenance != null) {
          isMaintenance = maintenanceStatus.is_maintenance;
        }
      }
    }

    return isMaintenance;
  }

  private getEngineerInfo(rms_relations: RmsRelation[]) {
    let engineer;

    const index = rms_relations.findIndex(
      (rms_relation: RmsRelation) =>
        rms_relation.rms_relation_definition.relation_name ===
        'fridge_engineer',
    );

    if (index !== -1) {
      engineer =
        rms_relations[index].rms_relation_definition?.user_role_domain?.user;
    }
    return engineer;
  }

  private getTechnicianInfo(rms_relations: RmsRelation[]) {
    let technician;

    const index = rms_relations.findIndex(
      (rms_relation: RmsRelation) =>
        rms_relation.rms_relation_definition.relation_name ===
        'fridge_technician',
    );

    if (index !== -1) {
      technician =
        rms_relations[index].rms_relation_definition?.user_role_domain?.user;
    }
    return technician;
  }

  private getCreatorUserId(rms_relations: RmsRelation[]) {
    let id;

    const index = rms_relations.findIndex(
      (rms_relation: RmsRelation) =>
        rms_relation.rms_relation_definition.relation_name === 'fridge_owner',
    );

    if (index !== -1) {
      id = rms_relations[index].rms_relation_definition?.user?.id;
    }
    return id;
  }

  private getEnergyClass(resourceAttributes: any[]): string {
    let energyClass: string = '';
    const index = resourceAttributes?.findIndex(
      (resourceAttribute) =>
        resourceAttribute.attribute_definition.name === 'energy_class',
    );
    if (index && index !== -1) {
      energyClass = resourceAttributes[index].attribute_definition_detail[0]
        ? resourceAttributes[index].attribute_definition_detail[0].value
        : (resourceAttributes[index].attribute_definition_detail as any).value;
    }
    return energyClass;
  }

  private getEnergyClassForResource(
    resourceAttributes: ResourceAttribute[],
  ): string {
    let energyClass: string = '';
    const index = resourceAttributes?.findIndex(
      (resourceAttribute) =>
        resourceAttribute.attribute_definition.name === 'energy_class',
    );

    if (index && index !== -1) {
      energyClass = resourceAttributes[index].attribute_definition_detail
        ? resourceAttributes[index].attribute_definition_detail.value
        : (resourceAttributes[index].attribute_definition_detail as any).value;
    }
    return energyClass;
  }

  private getRoomFromStation(stationResource: Resources) {
    const { parent } = stationResource;
    const room = {
      id: parent?.id,
      name: parent?.name,
    };

    return room;
  }
}
