import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Theme } from 'src/app/shared/enums';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit {
  selectedTheme: Theme;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private dialogRef: MatDialogRef<SubMenuComponent>
  ) {}

  ngOnInit(): void {
    this.selectedTheme = this.themeService.theme;
  }

  get theme() {
    return Theme;
  }

  logout() {
    this.dialogRef.close({ logout: true });
    localStorage.removeItem('userId');
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('email');
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
    localStorage.removeItem('isOnboardingCompleted');
    localStorage.removeItem('policies');
    localStorage.removeItem('accessibleData');
    this.router.navigate(['/login']);
  }

  switchTheme(): void {
    document.body.classList.toggle('dark');
    this.selectedTheme = document.body.classList.contains('dark') ? Theme.Dark : Theme.Light;
    this.themeService.theme = this.selectedTheme;
  }
}
