import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeedbackService, FeedbackSizes, FeedbackTypes } from '@rotateknik/rt-std-wc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LongRunningJob } from 'src/app/core/models';
import { LanguageService, ShellService } from 'src/app/core/services';
import { LrjStatus, LrjType } from 'src/app/shared/enums';
import { TranslatePipe } from 'src/app/shared/pipes';
import { SocketService } from 'src/app/shared/services';
import { LeftMenuService } from 'src/app/shared/services/left-menu.service';
import { environment } from 'src/environments/environment';
import { LrjAction } from './more-actions-dialog/more-actions-dialog.component';

@Component({
  selector: 'app-long-running-job',
  templateUrl: './long-running-job.component.html',
  styleUrls: ['./long-running-job.component.scss'],
})
export class LongRunningJobComponent {
  @Input() fromMobileMenu = false;

  navigateToLRJPage = new Subject<void>();

  longRunningJobs: LongRunningJob[] = [];

  private unsubscribe = new Subject<void>();

  private errorText = new TranslatePipe(this.languageService).transform('211', 'Error');
  private informationText = new TranslatePipe(this.languageService).transform('279', 'Information');
  private jobDeletedText = new TranslatePipe(this.languageService).transform('289', 'Job deleted.');
  private thoseInTheProcessText = new TranslatePipe(this.languageService).transform('600', 'Those in the process');
  private reportsText = new TranslatePipe(this.languageService).transform('429', 'Reports');

  constructor(
    private feedbackService: FeedbackService,
    private shellService: ShellService,
    private matDialogRef: MatDialogRef<LongRunningJobComponent>,
    private router: Router,
    private socketService: SocketService,
    private languageService: LanguageService,
    private leftMenuService: LeftMenuService,
  ) {
    this.getLastTenLRJs();
    this.listenSocket();
  }

  get lrjStatus(): any {
    return LrjStatus;
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  getHeader(): string {
    const hasRunningJob = this.longRunningJobs.some((longRunningJob) => longRunningJob.state === LrjStatus.Running);
    return hasRunningJob ? this.thoseInTheProcessText : this.reportsText;
  }

  navigateToLongRunningJobs() {
    this.router.navigate(['long-running-jobs']);
    this.navigateToLRJPage.next();
    this.leftMenuService.updateLeftMenuState(false);
  }

  executeLRJAction(event: { action: LrjAction; lrj: LongRunningJob }) {
    const { action, lrj } = event;
    switch (action) {
      case LrjAction.cancel:
        this.deleteLRJ(lrj);
        break;
      case LrjAction.delete:
        this.deleteLRJ(lrj);
        break;
      case LrjAction.download:
        this.downloadLRJ(lrj);
        break;
    }
  }

  downloadLRJ(lrj: LongRunningJob) {
    switch (lrj.type) {
      case LrjType.Excel:
        window.open(environment.csvServicePath + '/getRangeSelectionExcel?fileName=' + encodeURIComponent(lrj.name));
        break;
      case LrjType.Report:
        window.open(environment.csvServicePath + '/getTestReport?fileName=' + encodeURIComponent(lrj.name));
        break;
      case LrjType.Csv:
        window.open(environment.csvServicePath + '/getTestViewCSV?fileName=' + encodeURIComponent(lrj.name));
        break;
      case LrjType.Plc:
        window.open(
          environment.csvServicePath +
            '/getPLCReport?fileName=' +
            encodeURIComponent(lrj.name) +
            '&lrjId=' +
            encodeURIComponent(lrj.id),
        );
        break;
    }
  }

  deleteLRJ(lrj: LongRunningJob) {
    this.shellService
      .deleteLRJ(lrj.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.feedbackService.showFeedback(
            this.informationText,
            this.jobDeletedText,
            FeedbackTypes.Success,
            FeedbackSizes.Large,
          );
          this.getLastTenLRJs();
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
        },
      );
  }

  private getLastTenLRJs() {
    this.shellService
      .getLRJs({
        startRow: 0,
        endRow: 10,
        groupKeys: [],
        pivotCols: [],
        pivotMode: false,
        rowGroupCols: [],
        valueCols: [],
        sortModel: [
          {
            colId: 'createdAt',
            sort: 'desc',
          },
        ],
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((results) => {
        this.longRunningJobs = results;
      });
  }

  private listenSocket() {
    this.socketService.socketObservables.lrjPercentageUpdate
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((socketMessage: any) => {
        const lrjValue = socketMessage;
        const index = this.longRunningJobs.findIndex((lrj: any) => lrj.id === lrjValue.id);
        if (index >= 0) {
          this.longRunningJobs[index].load_value = lrjValue.loadValue;
          if (this.longRunningJobs[index].load_value >= 100) {
            this.longRunningJobs[index].state = LrjStatus.Completed;
          }
        }
      });
  }
}
