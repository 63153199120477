import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';
import { LongRunningJob } from 'src/app/core/models';
import { LrjStatus, LrjType } from 'src/app/shared/enums';

export enum LrjAction {
  cancel,
  delete,
  download,
}

@Component({
  selector: 'app-more-actions-dialog',
  templateUrl: './more-actions-dialog.component.html',
  styleUrls: ['./more-actions-dialog.component.scss'],
})
export class MoreActionsDialogComponent implements OnInit {
  @Input() set setLongRunningJob(longRunningJob: any) {
    this.longRunningJob = longRunningJob;
  }
  @Output() lrjAction = new EventEmitter<{ action: LrjAction; lrj: LongRunningJob }>();
  longRunningJob: LongRunningJob;

  constructor() {}

  ngOnInit(): void {}

  get lrjType() {
    return LrjType;
  }

  get lrjStatus() {
    return LrjStatus;
  }

  cancel() {
    this.lrjAction.emit({ action: LrjAction.cancel, lrj: this.longRunningJob });
  }

  delete() {
    this.lrjAction.emit({ action: LrjAction.delete, lrj: this.longRunningJob });
  }

  download() {
    this.lrjAction.emit({ action: LrjAction.download, lrj: this.longRunningJob });
  }

  getLrjDeleteDefAccessParams() {
    const params = {
      action: AuthorizationActionEnum.delete,
      source: AuthorizationSourceEnum.lrj,
      domainType: AuthorizationDomainTypes.Global,
    };
    return JSON.stringify(params);
  }
}
