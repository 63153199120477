<div class="sub-menu-wrapper">
  <div class="menu-item clickable sub-menu-item" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="vertical-align-center">
      <mat-icon>dark_mode_outline</mat-icon>
      <div class="menu-item-text">{{ '153' | translate: 'Dark Mode' }}</div>
    </div>
    <mat-slide-toggle
      class="slide-dark-mode"
      [checked]="selectedTheme === theme.Dark"
      (change)="switchTheme()"></mat-slide-toggle>
  </div>
  <div class="menu-item clickable sub-menu-item" fxLayout="row" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <div class="menu-item-text">{{ 316 | translate: 'Logout' }}</div>
  </div>
</div>
