import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterRequestParams } from '@rotateknik/rt-std-wc';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AverageTest,
  CalibrationCreateTemplate,
  CalibrationTemplate,
  GraphAxis,
  IniFile,
  IniSensor,
  LongestTest,
  MostPreferredEnergyClass,
  MostPreferredTestType,
  Note,
  Sensor,
  ShellTmsRelationDefinition,
  StepCalculateSave,
  Test,
  TestEvent,
  TestSampleTime,
  TestStatistics,
  TestType,
  TestView,
  TmsRelationDefinition,
  TopTestOwner,
  TotalTest,
  Unit,
} from 'src/app/core/models';
import { TestHistoryLevel } from 'src/app/shared/enums';
import {
  CreateAxis,
  CreateGraph,
  UpdateAxis,
  UpdateGraph,
  UpdateTest,
  UpsertSensorTemplatePayload,
  UpsertSensorTemplateResponse,
} from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { TraceType } from '../enums';
import { AnalogScale } from '../models/analog-scale.model';
import { IniFiles } from '../models/ini-files.model';
import { SensorTemplateInterface } from '../models/sensor-template.model';

const baseUrl = environment.testManagementServicePath;

@Injectable({
  providedIn: 'root',
})
export class TestManagementService {
  token: string | null = localStorage.getItem('token');
  public testList = new BehaviorSubject<Array<Test>>([]);
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getTestInfo(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/getTestInfo`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getArchivedTestViewsCount(): Observable<any> {
    return this.http.get(`${baseUrl}/test-view/getArchivedTestViewsCount`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getTestTypes(): Observable<TestType[]> {
    return this.http.post(
      `${baseUrl}/test-type/getTestTypes`,
      {},
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<TestType[]>;
  }

  getTestSampleTimes(): Observable<TestSampleTime[]> {
    return this.http.get(`${baseUrl}/testSampleTime/getTestSampleTimes`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<TestSampleTime[]>;
  }

  getEnergyClasses(): Observable<any> {
    return this.http.get(`${baseUrl}/test/getEnergyClasses`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getAnalogScales(params?: any): Observable<AnalogScale[]> {
    return this.http.post<AnalogScale[]>(
      `${baseUrl}/analog-scale/getAnalogScales`,
      { filters: params },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  addAnalogScale(analogScale: { name: string; a: number; b: number }): Observable<AnalogScale> {
    return this.http.post<AnalogScale>(
      `${baseUrl}/analog-scale/addAnalogScale`,
      { analogScale },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteAnalogScale(id: number): Observable<{ isSuccess: boolean }> {
    return this.http.post<{ isSuccess: boolean }>(
      `${baseUrl}/analog-scale/deleteAnalogScale`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createTestView(testId: number, stationId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-view/createTestView`,
      {
        test_id: testId,
        station_id: stationId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createArchiveTestView(testIds: number[]): Observable<TestView> {
    return this.http.post<TestView>(
      `${baseUrl}/test-view/createArchiveTestView`,
      {
        testIds,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestViewTestsByTestViewId(testViewId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-view/getTestViewTestsByTestViewId`,
      {
        id: testViewId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  addTestToTestView(testId: number, testViewId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-view/addTestToTestView`,
      {
        test_id: testId,
        test_view_id: testViewId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  removeTestFromTestView(testId: number, testViewId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-view/removeTestFromTestView`,
      {
        test_id: testId,
        test_view_id: testViewId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  changeVirtualTraceName(virtualTraceId: number, name: string) {
    return this.http.post(
      `${baseUrl}/virtual-trace/changeVirtualTraceName`,
      {
        virtualTraceId,
        name,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateTestViewTraces(testViewId: number, traces: { id: number; sortIndex: number; type: TraceType }[]) {
    return this.http.post(
      `${baseUrl}/test-view-trace/updateTestViewTraces`,
      {
        testViewTraces: {
          test_view_id: testViewId,
          traces,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateTestViewSortsByTestViewId(testViewId: number, sensorGroups: { sensor_group_id: number; sort_index: number }[]) {
    return this.http.post(
      `${baseUrl}/test-view-sort/updateTestViewSortsByTestViewId`,
      {
        test_view_id: testViewId,
        sensor_groups: sensorGroups,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createAxisLine(payload: {
    description: string;
    y0: number;
    y1: number;
    color: string;
    visibility: boolean;
    graph_axis_id: number;
  }) {
    return this.http.post<{
      id: number;
      description: string;
      y0: number;
      y1: number;
      color: string;
      visibility: boolean;
      graph_axis_id: number;
      updatedAt: string;
      createdAt: string;
    }>(`${baseUrl}/graph/createAxisLine`, payload, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  updateAxisLine(payload: {
    id: number;
    description: string;
    y0: number;
    y1: number;
    color: string;
    visibility: boolean;
    graph_axis_id: number;
  }) {
    return this.http.post<{
      id: number;
      description: string;
      y0: number;
      y1: number;
      color: string;
      visibility: boolean;
      graph_axis_id: number;
      updatedAt: string;
      createdAt: string;
    }>(`${baseUrl}/graph/updateAxisLine`, payload, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteAxisLine(id: number) {
    return this.http.post(
      `${baseUrl}/graph/deleteAxisLine`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createGraph(graph: CreateGraph): Observable<any> {
    return this.http.post(`${baseUrl}/graph/createGraph`, graph, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  updateGraph(graph: UpdateGraph): Observable<any> {
    return this.http.post(`${baseUrl}/graph/updateGraph`, graph, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteGraph(graphId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/graph/deleteGraph`,
      { graphId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createAxis(axis: CreateAxis): Observable<GraphAxis> {
    return this.http.post<GraphAxis>(`${baseUrl}/graph/createAxis`, axis, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  updateAxis(axis: UpdateAxis): Observable<{ isSucceed: boolean }> {
    return this.http.post<{ isSucceed: boolean }>(`${baseUrl}/graph/updateAxis`, axis, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteAxis(axisId: number): Observable<{ isSucceed: boolean }> {
    return this.http.post<{ isSucceed: boolean }>(
      `${baseUrl}/graph/deleteAxis`,
      {
        id: axisId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createSelection(
    testId: number,
    selectionMode: number,
    traceIds: number[],
    vTraceIds: number[],
    deviceId: number,
    range: { min: number; max: number },
    name: string,
    powerThreshold: number,
    powTrace?: number,
    engTrace?: number,
  ) {
    return this.http.post(
      `${baseUrl}/range-selection/createRangeSelection`,
      {
        test_id: testId,
        selection_mode: selectionMode,
        trace_ids: traceIds,
        vTraceIds,
        powTrace,
        engTrace,
        device_id: deviceId,
        range_start: range.min,
        range_end: range.max,
        name,
        powerThreshold,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getSelections(testId: number) {
    return this.http.post(
      `${baseUrl}/range-selection/getTestRangeSelections`,
      {
        test_id: testId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateSelection(
    id: number,
    selectionMode: number,
    traceIds: number[],
    vTraceIds: number[],
    deviceId: number,
    range: { min: number; max: number },
    name: string,
    powerThreshold: number,
    powTrace?: number,
    engTrace?: number,
  ) {
    return this.http.post(
      `${baseUrl}/range-selection/updateRangeSelection`,
      {
        id,
        selection_mode: selectionMode,
        trace_ids: traceIds,
        vTraceIds,
        powTrace,
        engTrace,
        device_id: deviceId,
        range_start: range.min,
        range_end: range.max,
        name,
        powerThreshold,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateSelections(
    payload: {
      id: number;
      selection_mode: number;
      trace_ids: number[];
      vTraceIds: number[];
      powTrace?: number;
      engTrace?: number;
      device_id: number;
      range_start: number;
      range_end: number;
      name: string;
      powerThreshold: number;
    }[],
  ) {
    return this.http.post(`${baseUrl}/range-selection/updateRangeSelections`, payload, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteSelection(id: number) {
    return this.http.post(
      `${baseUrl}/range-selection/deleteRangeSelection`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateTest(test: UpdateTest): Observable<any> {
    return this.http.post(`${baseUrl}/test/updateTest`, test, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  stopTest(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/stopTest`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  startTest(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/startTest`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  endTest(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/endTest`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createNote(note: any): Observable<any> {
    return this.http.post(`${baseUrl}/note/createNote`, note, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  updateNote(note: Note): Observable<any> {
    return this.http.post(`${baseUrl}/note/updateNote`, note, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteNote(noteId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/note/deleteNote`,
      { id: noteId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestNotes(test_id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/note/getTestNotes`,
      {
        test_id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getIniSensors(iniFileIds: (number | undefined)[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/getIniSensors`,
      { iniFileIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  adapterIniCheck(udaqSensorId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/adapterIniCheck`,
      { udaqSensorId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getIniFilesWithTestId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/getIniFilesWithTestId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getActiveTestCountWithUserId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/getActiveTestCountWithUserId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getActiveTestCountByLabId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/getActiveTestCountByLabId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getFinishedTestCountWithUserId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test/getFinishedTestCountWithUserId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUdaqSettings(selectedUdaqTraces: any): Observable<any> {
    return this.http.post(`${baseUrl}/ini/updateUdaqSettings`, selectedUdaqTraces, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getUdaqSettings(testId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/getUdaqSettings`,
      { testId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  iniCheck(udaqSensorId: number): Observable<IniFiles> {
    return this.http.post(
      `${baseUrl}/ini/iniCheck`,
      { udaqSensorId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<IniFiles>;
  }

  getAvailableIniFilesWithTestId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/getAvailableIniFilesWithTestId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAvailableIniFilesByIniCheck(udaqSensorId: number | undefined): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/getAvailableIniFilesByIniCheck`,
      { udaqSensorId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getTestStatistics(startDate: string, endDate: string): Observable<TestStatistics> {
    return this.http.post(
      `${baseUrl}/test/getTestUsageStatistics`,
      { startDate, endDate },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<TestStatistics>;
  }

  getMostPreferredTestTypes(startDate: string, endDate: string): Observable<MostPreferredTestType[]> {
    return this.http.post(
      `${baseUrl}/test/getMostPreferredTestTypes`,
      { startDate, endDate },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<MostPreferredTestType[]>;
  }

  getMostPreferredEnergyClasses(startDate: string, endDate: string): Observable<MostPreferredEnergyClass[]> {
    return this.http.post(
      `${baseUrl}/test/getMostPreferredEnergyClasses`,
      { startDate, endDate },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<MostPreferredEnergyClass[]>;
  }

  getTopTestOwners(startDate: string, endDate: string, limit: number, roleId: number): Observable<TopTestOwner[]> {
    return this.http.post(
      `${baseUrl}/test/getTopTestOwners`,
      { startDate, endDate, limit, roleId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<TopTestOwner[]>;
  }

  getLongestTests(startDate: string, endDate: string, limit: number, roleId: number): Observable<LongestTest[]> {
    return this.http.post(
      `${baseUrl}/test/getLongestTests`,
      { startDate, endDate, limit, roleId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<LongestTest[]>;
  }

  getTotalTestDurations(startDate: string, endDate: string, limit: number, roleId: number): Observable<TotalTest[]> {
    return this.http.post(
      `${baseUrl}/test/getTotalTestDurations`,
      { startDate, endDate, limit, roleId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<TotalTest[]>;
  }

  getAverageTestDurations(
    startDate: string,
    endDate: string,
    limit: number,
    roleId: number,
  ): Observable<AverageTest[]> {
    return this.http.post(
      `${baseUrl}/test/getAverageTestDurations`,
      { startDate, endDate, limit, roleId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<AverageTest[]>;
  }

  deleteIniFile(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/deleteIniFile`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getSensorsWithSensorId(ids: number[]): Observable<Sensor[]> {
    return this.http.post(
      `${baseUrl}/sensor/getSensorsWithSensorId`,
      {
        ids,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<Sensor[]>;
  }

  getIniFiles(): Observable<any> {
    return this.http.get(`${baseUrl}/ini/getIniFiles`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  createIniFiles(iniFile: IniFile, iniSensors: IniSensor[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/createIniFiles`,
      {
        iniFile,
        iniSensors,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getIniSensorsWithIniFileId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/ini/getIniSensorsWithIniFileId`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createTmsRelationDefinition(
    shellTmsRelationDefinition: ShellTmsRelationDefinition,
  ): Observable<TmsRelationDefinition> {
    return this.http.post(
      `${baseUrl}/tmsRelationDefinition/addTmsRelationDefinition`,
      {
        tmsRelationDefinition: shellTmsRelationDefinition,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<TmsRelationDefinition>;
  }

  calibrationRead(
    sampleRate: number,
    sampleCount: number,
    sensors: { id: number; expectedValue: number }[],
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/calibration/calibrationRead`,
      {
        sampleRate,
        sampleCount,
        sensors,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  calibrationReadWithoutRecursive(sensors: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/calibration/calibrationReadWithoutRecursive`,
      {
        sensors,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  saveCalibration(
    calibrationValues: { id: number; calA: string; calB: string }[],
    calibration_view_id: number,
    isDelete: boolean,
  ): Observable<any> {
    return this.http.post(
      `${baseUrl}/calibration/saveCalibration`,
      {
        calibrationValues,
        calibration_view_id,
        isDelete,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getCalibrationTemplates(): Observable<any> {
    return this.http.get(`${baseUrl}/calibration/getViews`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getFinishedCalibrationTemplates(requestParams?: FilterRequestParams): Observable<CalibrationTemplate[]> {
    return this.http.post(
      `${baseUrl}/calibration/getFinishedViews`,
      {
        filters: requestParams,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<CalibrationTemplate[]>;
  }

  getViewWithId(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/calibration/getViewWithId`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createCalibrationTemplate(calibrationTemplate: CalibrationCreateTemplate): Observable<any> {
    return this.http.post(`${baseUrl}/calibration/createView`, calibrationTemplate, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteCalibrationTemplate(calibration_view_id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/calibration/deleteView`,
      {
        calibration_view_id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  saveStep(calculates: StepCalculateSave[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/calibration/saveStep`,
      {
        calculates,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getSensorTemplates(requestParams?: FilterRequestParams): Observable<SensorTemplateInterface[]> {
    return this.http.post(
      `${baseUrl}/sensor/getSensorTemplates`,
      {
        filters: requestParams,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    ) as Observable<SensorTemplateInterface[]>;
  }

  upsertSensorTemplate(data: UpsertSensorTemplatePayload): Observable<UpsertSensorTemplateResponse> {
    return this.http.post<UpsertSensorTemplateResponse>(`${baseUrl}/sensor/upsertSensorTemplate`, data, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  deleteSensorTemplate(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/sensor/deleteSensorTemplate`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  addTestType(name: string, duration: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-type/addTestType `,
      {
        testType: {
          name,
          duration_h: duration,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteTestType(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-type/deleteTestType `,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateTestType(id: number, name: string, duration_h: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-type/updateTestType`,
      {
        testType: {
          id,
          name,
          duration_h,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteTestSampleTime(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/testSampleTime/deleteTestSampleTime`,
      {
        id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  addTestSampleTime(time: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/testSampleTime/addTestSampleTime`,
      {
        testSampleTime: {
          time,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getChannelDescriptions(): Observable<any> {
    return this.http.get(`${baseUrl}/channel-description/getChannelDescriptions`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  updateTraceName(id: number, name: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/trace/updateTraceName`,
      {
        id,
        name,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createTestHistory(newTestEvent: {
    test_id: number;
    header: string;
    message: string;
    is_added_from_plot: false;
    data: any;
    level: TestHistoryLevel;
    timestamp: number;
    is_global: boolean;
  }): Observable<any> {
    return this.http.post(`${baseUrl}/test-history/createTestHistory`, newTestEvent, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getTestHistoriesByTestId(id: number, startRow?: number, endRow?: number): Observable<TestEvent[]> {
    return this.http.post<TestEvent[]>(
      `${baseUrl}/test-history/getTestHistoriesByTestId`,
      {
        id,
        filters: {
          startRow,
          endRow,
        },
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateTestHistory(id: number, message: string, header: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-history/updateTestHistory`,
      { id, message, header },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteTestHistoriesByIds(ids: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/test-history/deleteTestHistoriesByIds`,
      { ids },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  addVirtualTraceToGroup(virtualTraceId: number, groupId: number) {
    return this.http.post(
      `${baseUrl}/virtual-trace/addVirtualTraceToGroup`,
      {
        virtualTraceId,
        groupId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  removeVirtualTraceFromGroup(virtualTraceId: number) {
    return this.http.post(
      `${baseUrl}/virtual-trace/removeVirtualTraceFromGroup`,
      {
        virtualTraceId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getUnitList(): Observable<Unit[]> {
    return this.http.get(`${baseUrl}/unit/list`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<Unit[]>;
  }

  updateAnalogSensorUnit(id: number, unitId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/sensor/updateAnalogSensorUnit`,
      {
        id,
        unit_id: unitId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  unitListNonDefaults(): Observable<Unit[]> {
    return this.http.get(`${baseUrl}/unit/listNonDefaults`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<Unit[]>;
  }

  createUnit(name: string, unit: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/unit/create`,
      {
        name,
        unit,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateUnit(id: number, name: string, unit: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/unit/update`,
      {
        id,
        name,
        unit,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateTestPowerThreshold({ testId, powerThreshold }: { testId: number; powerThreshold: number }) {
    return this.http.post(
      `${baseUrl}/test/updateTestPowerThreshold`,
      {
        id: testId,
        power_threshold: powerThreshold,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteTestData(testId: number) {
    return this.http.post(
      `${baseUrl}/test/deleteTestData`,
      { id: testId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }
}
