import { Injectable } from '@angular/core';
import { TranslationKey } from 'src/app/shared/interfaces/translation-key.model';
import { ShellService } from '.';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  translationOfSelectedLang: any[] = [];

  constructor(private shellService: ShellService) {
    this.translationOfSelectedLang = JSON.parse(localStorage.getItem('translations') as any);
  }

  getLanguageText(translationId: number) {
    let translatedText = this.translationOfSelectedLang.find(
      (translation: any) => translation.translation_key_id === translationId
    )?.translation_key?.key;
    return translatedText ? translatedText : '';
  }
}
