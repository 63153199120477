import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackService, FilterRequestParams, TableComponent } from '@rotateknik/rt-std-wc';
import { TableFilter } from '@rotateknik/rt-std-wc/lib/table/models/table-filter.model';
import { GridOptions } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ShellRelationResourceDefinition } from 'src/app/core/models';
import { LanguageService, ResourceManagementService, ShellService } from 'src/app/core/services';
import { FilterTableName, Theme } from 'src/app/shared/enums';
import { ResourceListItem } from 'src/app/shared/interfaces';
import { TranslatePipe } from 'src/app/shared/pipes';
import { ThemeService, UtilService } from 'src/app/shared/services';
import { TableFilterService } from 'src/app/shared/services/table-filter.service';
import { SelectButtonDataShareService } from './select-button-renderer/select-button-data-share.service';
import { SelectButtonRendererComponent } from './select-button-renderer/select-button-renderer.component';

@Component({
  selector: 'app-device-grid-menu',
  templateUrl: './device-grid-menu.component.html',
  styleUrls: ['./device-grid-menu.component.scss'],
})
export class DeviceGridMenuComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: TableComponent;

  frameworkComponents = {
    selectButtonRenderer: SelectButtonRendererComponent,
  };
  selectedRowSubject = new Subject<ResourceListItem>();
  selectedResourceId: number;
  selectedResourceDefinition: ShellRelationResourceDefinition;
  gridOptions = {
    enableFilter: true,
    suppressPropertyNamesCheck: true,
    blockLoadDebounceMillis: 100,

    onDragStopped: () => this.onDragStopped(),
    onFilterChanged: () => this.onFilterChanged(),
    onSortChanged: () => this.onSortChanged(),
    onGridColumnsChanged: () => this.onGridColumnsChanged(),
    onDisplayedColumnsChanged: () => this.onDisplayedColumnsChanged(),
  } as GridOptions;
  tableName: string;
  defaultColDef = {
    sortable: true,
    menuTabs: ['filterMenuTab'],
    resizable: true,
  };
  selectedDevice: {
    id: number;
    value: string;
    relationDefinitionId: number;
  };
  savedFilters: TableFilter[] = [];
  columnDefs: {
    headerName: string;
    colId: string;
    field: string;
    filter: string | boolean;
    sortable?: boolean;
    suppressFilter?: boolean;
    width?: number;
    hide?: boolean;
    suppressToolPanel?: boolean;
    cellRenderer?: any;
    cellRendererParams?: any;
    minWidth?: number;
    maxWidth?: number;
    pinned?: 'left' | 'right';
    suppressMenu?: boolean;
    editable?: boolean;
    resizable?: boolean;
  }[] = [];
  deviceDataSource: any[] = [];
  dataSource: any[] = [];
  quickFiltersChooses = [];
  isLoadingDeviceData = false;

  loaderPath = '/assets/RLS_LightLoader.json';
  isDarkMode = false;

  columnNameToSort = 'id';
  defaultSortColumnInfo: {
    colId: string;
    sort: string;
  } = {
    colId: 'id',
    sort: 'asc',
  };

  cacheBlockSize = 40;
  maxBlocksInCache = 10;
  serverSideDatasource: any;
  isLoading = false;

  lastStatusOfFilter: { requestParams: FilterRequestParams; params: any };

  filterRequestReceiver = new Subject();

  isFirstRender = true;

  searchValueControl = new FormControl();

  private userId: number;

  activeFilterId: number;
  activeFilterName: string;
  automaticFilterId: number;
  automaticFilter: any;

  private unsubscribe = new Subject<void>();

  constructor(
    private resourceManagementService: ResourceManagementService,
    private matDialog: MatDialog,
    private themeService: ThemeService,
    private shellService: ShellService,
    private utilService: UtilService,
    private feedbackService: FeedbackService,
    private tableFilterService: TableFilterService,
    private languageService: LanguageService,
    private deviceSelectionDataService: SelectButtonDataShareService
  ) {
    this.setLoaderPath();
  }

  ngOnInit(): void {
    this.deviceSelectionDataService.selectedRow.subscribe((data) => {
      if (data && data !== null) {
        this.selectedRowSubject.next(data as ResourceListItem);
      }
    });

    this.userId = parseInt(localStorage.getItem('userId') as string);

    // this.getFiltersByTableAndUser();
    this.listenSearchValueChanges();
  }

  ngOnDestroy(): void {
    this.selectedRowSubject.complete();
    this.selectedRowSubject.unsubscribe();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  requestParamsChanged(e: { requestParams: FilterRequestParams; params: any }) {
    this.isLoadingDeviceData = true;
    this.resourceManagementService
      .getResourceListWithLabIdByDefinitionId(this.selectedResourceId, e.requestParams, [
        this.searchValueControl.value ?? '',
      ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if (this.isFirstRender) {
          this.defineColumnDefs();
          this.getFiltersByTableAndUser();
          this.isFirstRender = false;
        }

        this.serverSideDatasource = {
          response: {
            rows: response,
            lastRow: this.utilService.getLastRowIndex(this.lastStatusOfFilter.requestParams, response),
          },
          params: this.lastStatusOfFilter.params,
        };

        this.isLoadingDeviceData = false;
      });

    this.lastStatusOfFilter = e;
  }

  close() {
    this.matDialog.closeAll();
  }

  //FILTERS PUBLIC METHODS START
  onSavedFilterDeleted(event: number) {
    this.tableFilterService.deleteSavedFilter(event);
    this.filterRequestReceiver.next();
  }

  onActiveSavedFilterSwitched() {
    this.applyActiveFilter();
  }

  onUserSavedFilterCreate(filterName: string) {
    this.tableFilterService.saveCurrentFilter(
      this.table.gridInstance,
      FilterTableName.DeviceGrid,
      this.userId,
      this.savedFilters,
      undefined,
      filterName
    );
  }

  //FILTERS PUBLIC METHODS END

  private setLoaderPath() {
    this.themeService.selectedTheme.pipe(takeUntil(this.unsubscribe)).subscribe((selectedTheme) => {
      this.loaderPath = selectedTheme === Theme.Dark ? '/assets/RLS_DarkLoader.json' : '/assets/RLS_LightLoader.json';
      this.isDarkMode = selectedTheme === Theme.Dark;
    });
  }

  private defineColumnDefs() {
    this.addDeviceIdColumnDef();
    this.addNameColumnDef();
    this.selectedResourceDefinition.parent_id && this.addParentNameColumnDef();
    this.addResourceDescriptionColumnDef();
    this.addAttributeDefinitionsColumnDefs();
    this.addSelectionButtonColumnDef();

    this.columnDefs.forEach((columnDef: any, index: number) => {
      if (this.isEmptyColumn(columnDef)) {
        this.columnDefs.splice(index, 1);
      }
    });

    this.columnDefs = [...this.columnDefs];
    this.table.gridInstance.api.setColumnDefs(this.columnDefs);
  }

  private isEmptyColumn(columnDef: any) {
    return columnDef.headerName === '' && columnDef.colId === '' && columnDef.field === '';
  }

  private addDeviceIdColumnDef() {
    this.columnDefs.push({
      headerName: 'Id',
      colId: 'id',
      field: 'id',
      sortable: true,
      filter: 'agTextColumnFilter',
    });
  }

  private addNameColumnDef() {
    this.columnDefs.push({
      headerName: new TranslatePipe(this.languageService).transform(446),
      colId: 'name',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
    });
  }

  private addParentNameColumnDef() {
    this.columnDefs.push({
      headerName: new TranslatePipe(this.languageService).transform(374),
      colId: 'parent_name',
      field: 'parent_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    });
  }

  private addResourceDescriptionColumnDef() {
    this.columnDefs.push({
      headerName: new TranslatePipe(this.languageService).transform(444),
      colId: 'resource_description',
      field: 'resource_description',
      sortable: true,
      filter: 'agTextColumnFilter',
    });
  }

  private addAttributeDefinitionsColumnDefs() {
    this.selectedResourceDefinition.resource_attribute_definitions.forEach((resourceAttributeDefinition) => {
      this.columnDefs.push({
        headerName: resourceAttributeDefinition.attribute_definition.translation_key_id
          ? new TranslatePipe(this.languageService).transform(
              resourceAttributeDefinition.attribute_definition.translation_key_id
            )
          : resourceAttributeDefinition.attribute_definition.description,
        colId: `${resourceAttributeDefinition.attribute_definition.id}.value`,
        field: `${resourceAttributeDefinition.attribute_definition.id}.value`,
        sortable: true,
        filter: 'agTextColumnFilter',
      });
    });
  }

  private addSelectionButtonColumnDef() {
    this.columnDefs.push({
      headerName: '',
      colId: 'selectionButton',
      field: 'selectionButton',
      filter: '',
      cellRenderer: 'selectButtonRenderer',
      minWidth: 170,
      maxWidth: 170,
      pinned: 'right',
      suppressMenu: true,
      editable: false,
      resizable: false,
    });
  }

  private listenSearchValueChanges() {
    this.searchValueControl.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe)).subscribe(() => {
      this.table.gridInstanceApi.purgeServerSideCache();
    });
  }

  //FILTERS PRIVATE METHODS START

  private getFiltersByTableAndUser() {
    this.isLoading = true;

    this.shellService
      .getFiltersByTableAndUser(FilterTableName.DeviceGrid)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (filters) => {
          filters.forEach((filter) => {
            this.savedFilters.push({
              id: filter.id,
              user_id: filter.user_id,
              filter_name: filter.filter_name,
              table_name: filter.table_name,
              filter_data: JSON.parse(filter.filter_data),
              column_filter: JSON.parse(filter.column_filter),
              sort_data: JSON.parse(filter.sort_data),
              width_data: JSON.parse(filter.width_data),
              is_active: filter.is_active,
            });
          });

          this.applyActiveFilter();
          setTimeout(() => {
            this.listenFilterRequests();
          }, 100);

          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  private applyActiveFilter() {
    const activeFilter = this.getActiveFilter();

    if (activeFilter) {
      this.tableFilterService.applyFilter(activeFilter, this.table.gridInstance);
    }
  }

  private getActiveFilter() {
    const activeFilter = this.savedFilters.find((item) => item.is_active);

    return activeFilter ? activeFilter : null;
  }

  private onDragStopped() {
    this.filterRequestReceiver.next();
  }

  private onFilterChanged() {
    this.filterRequestReceiver.next();
  }

  private onSortChanged() {
    this.filterRequestReceiver.next();
  }

  private onGridColumnsChanged() {
    this.filterRequestReceiver.next();
  }

  private onDisplayedColumnsChanged() {
    this.filterRequestReceiver.next();
  }

  private listenFilterRequests() {
    this.filterRequestReceiver.pipe(takeUntil(this.unsubscribe), debounceTime(100)).subscribe(() => {
      this.tableFilterService.saveCurrentFilter(
        this.table.gridInstance,
        FilterTableName.DeviceGrid,
        this.userId,
        this.savedFilters,
        this.getActiveFilter()?.id,
        this.getActiveFilter()?.filter_name
      );
    });
  }

  //FILTERS PRIVATE METHODS END
}
