export enum Unit {
  Temperature = 1,
  Current,
  Frekans,
  Voltage,
  Power,
  Pressure,
  Udaq,
  Energy,
  KilowattHour,
  MegawattHour,
  PowerFactor,
  Watt,
  Analog
}
