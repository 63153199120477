import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PermissionControlService } from 'src/app/shared/services';
import { AuthorizationActionEnum } from '../enums/authorization-action.enum';
import { AuthorizationSourceEnum } from '../enums/authorization-source.enum';
import { AuthorizationDomainTypes } from '../enums/domain-type.enum';
import { ShellService } from '../services';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private shellService: ShellService,
    private router: Router,
    private permissionControlService: PermissionControlService,
    private activatedRoute: ActivatedRoute
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.shellService.isAuthenticated().pipe(
      map(() => {
        const accessConditions = route.data['accessList'];
        let allowedRequest = true;
        if (accessConditions) {
          for (let condition of accessConditions) {
            if (
              AuthorizationSourceEnum[condition.source] &&
              AuthorizationActionEnum[condition.action] &&
              AuthorizationDomainTypes[condition.domainType]
            ) {
              if (condition.domainType === AuthorizationDomainTypes.Lab && !condition.domain) {
                if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.labId) {
                  condition.domain = this.activatedRoute.snapshot.queryParams.labId;
                } else {
                  condition.domainType = AuthorizationDomainTypes.Global;
                }
              }
              if (
                this.permissionControlService.isActiveUserHasAuthorization(
                  condition.domainType,
                  condition.domain,
                  condition.source,
                  condition.action
                )
              ) {
                allowedRequest = true;
              } else {
                this.redirectTo404();
                allowedRequest = false;
                break;
              }
            }
          }
        }

        return allowedRequest;
      }),
      catchError(() => {
        this.redirectToLogin();
        return of(false);
      })
    );
  }

  private redirectToLogin() {
    this.router.navigate(['/login']);
    return false;
  }

  private redirectTo404() {
    this.router.navigate(['/not-found']);
    return false;
  }
}
