export enum AuthorizationSourceEnum {
  /**
   * Authorization Source for AuthPolicy entity
   */
  authPolicy = 1,

  /**
   * Authorization Source for AuthPolicyPermission entity
   */
  authPolicyPermission,

  /**
   * Authorization Source for AuthRoleDefinition entity
   */
  authRoleDefinition,

  /**
   * Authorization Source for AuthRolePolicy entity
   */
  authRolePolicy,

  /**
   * Authorization Source for AuthSource entity
   */
  authSource,

  /**
   * Authorization Source for AuthAction entity
   */
  authAction,

  /**
   * Authorization Source for Organization entity
   */
  organization,
  /**
   * Authorization Source for Department entity
   */
  department,
  /**
   * Authorization Source for Division entity
   */
  division,
  /**
   * Authorization Source for Team entity
   */
  team,

  /** Authorization Source for Language entity
   */
  language,

  /** Authorization Source for Translation entity
   */
  translation,

  /** Authorization Source for TranslationKey entity
   */
  translationKey,

  /** Authorization Source for Company entity
   */
  company,

  /** Authorization Source for Company User entity
   */
  companyUser,

  /** Authorization Source for Lab entity
   */
  lab,

  /** Authorization Source for Lab Role Request entity
   */
  labRoleRequest,

  /** Authorization Source for User Role Domain entity
   */
  userRoleDomain,

  /** Authorization Source for Lab User Domain entity
   */
  labUser,

  /** Authorization Source for Team User Domain entity
   */
  teamUser,

  /** Authorization Source for Relation Definition entity
   */
  relationDefinition,

  /** Authorization Source for Relation entity
   */
  relation,

  /** Authorization Source for Long Running Job
   */
  lrj,

  /** Authorization Source for User
   */
  user,

  /** Authorization Source for User Setting
   */
  userSetting,

  /** Authorization Source for Plan Extension
   */
  planextension,

  /** Authorization Source for Calibration
   */
  calibration,

  /** Authorization Source for Calibration View
   */
  calibrationView,

  /** Authorization Source for Graph
   */
  graph,

  /** Authorization Source for Ini File
   */
  inifile,

  /** Authorization Source for Ini Check
   */
  inicheck,

  /** Authorization Source for Udaq Test
   */
  udaqTest,

  /** Authorization Source for Ini Sensor
   */
  inisensor,

  /** Authorization Source for Note
   */
  note,

  /** Authorization Source for Range Selection
   */
  rangeSelection,

  /** Authorization Source for Sensor
   */
  sensor,

  /** Authorization Source for Test Graph
   */
  testGraph,

  /** Authorization Source for Test Type
   */
  testType,

  /** Authorization Source for Test View
   */
  testView,

  /** Authorization Source for Test
   */
  test,

  /** Authorization Source for Trace
   */
  trace,

  /** Authorization Source for Tms Relation Definition
   */
  tmsreldef,

  /** Authorization Source for Tms Relation
   */
  tmsrel,

  /** Authorization Source for Analog Scale
   */
  analogScale,

  /** Authorization Source for Attribute Definition Detail
   */
  attdefdet,

  /** Authorization Source for Attribute Definition
   */
  attdef,

  /** Authorization Source for Plan Condition Attribute Definition
   */
  plancondattrdef,

  /** Authorization Source for Plan Condition Attribute
   */
  plancondattr,

  /** Authorization Source for Plan Condition Definition
   */
  planconddef,

  /** Authorization Source for Plan Condition Resource Type
   */
  plancondresourcetype,

  /** Authorization Source for Plan Condition Type
   */
  plancondtype,

  /** Authorization Source for Plan Condition
   */
  plancond,

  /** Authorization Source for Plan Definition
   */
  plandef,

  /** Authorization Source for Plan
   */
  plan,

  /** Authorization Source for Resource Attribute Definition
   */
  resourceattrdef,

  /** Authorization Source for Resource Definition
   */
  resourcedef,

  /** Authorization Source for Resource
   */
  resource,

  /** Authorization Source for Rms Relation Definition
   */
  rmsreldef,

  /** Authorization Source for Rms Relation
   */
  rmsrel,

  /** Authorization Source for Test View Trace
   */
  testViewTrace,

  /** Authorization Source for Sensor Template
   */
  sensorTemplate,

  /** Authorization Source for Test Sample Time
   */
  testSampleTime,

  /** Authorization Source for Notification Condition
   */
  notificationCondition,

  /** Authorization Source for Test Event History
   */
  testHistory,

  /** Authorization Source for Finished Calibration
   */
  finishedCalibration,

  /** Authorization Source for Module
   */
  module,
}
