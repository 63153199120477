import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CsvService,
  DataService,
  LoginService,
  ProductManagementService,
  ResourceManagementService,
  ResourcesTabManagementService,
  ShellService,
  StatisticService,
  TestManagementService,
} from 'src/app/core/services';
import { OnboardingStatus, Theme } from 'src/app/shared/enums';
import { ComplianceService } from './core/services/compliance.service';
import { LauncherDialogComponent } from './shared/dialogs/launcher-dialog/launcher-dialog.component';
import { LoginResp } from './shared/interfaces';
import { LeftMenuService } from './shared/services/left-menu.service';
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  isLeftMenuOpen: boolean;
  oktaToken: any;
  token: string;

  showLoggingIndicator = false;
  isDisplayNavigateToLoginButton = false;
  isDisplayNavigateToLoginButtonTimer: any;

  options: AnimationOptions = {
    path: '/assets/RLS_LightLoader.json',
  };

  isLauncherDialogOpened: boolean = false;

  private isCheckedFromRefresh: boolean;
  private isPageRefreshed: boolean;
  private unsubscribe = new Subject<void>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(
      event.altKey && event.code === 'KeyL' &&
      localStorage.getItem('userId') && !this.isLauncherDialogOpened
    ){
      this.openLauncher();
    }
  }

  constructor(
    public router: Router,
    private loginService: LoginService,
    private shellService: ShellService,
    private themeService: ThemeService,
    private csvService: CsvService,
    private dataService: DataService,
    private productManagementService: ProductManagementService,
    private resourceManagementService: ResourceManagementService,
    private resourcesTabManagementService: ResourcesTabManagementService,
    private statisticService: StatisticService,
    private testManagementService: TestManagementService,
    private leftMenuService: LeftMenuService,
    private complianceService: ComplianceService,
    private snackBar: MatSnackBar,
    private matDialog:MatDialog
  ) {
    this.listenLeftMenuStateChanges();
    this.checkFromRefresh();
    this.themeService.selectedTheme.pipe(takeUntil(this.unsubscribe)).subscribe((selectedTheme) => {
      const path = selectedTheme === Theme.Dark ? '/assets/RLS_DarkLoader.json' : '/assets/RLS_LightLoader.json';
      this.options = {
        path,
      };
    });
  }

  ngOnInit() {
    this.setTheme();
    if (location.pathname !== '/certificate') {
      this.handleLoginProcess();
    }
    this.favIconThemeChange();
    this.subscribeToRouter();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get routerLocation() {
    return location;
  }

  isDisplayLeftMenu(): boolean {
    return !(
      location.pathname === '/login' ||
      location.pathname === '/onboarding' ||
      location.pathname === '/certificate' ||
      location.pathname === '/' ||
      location.pathname.includes('/login')
    );
  }

  favIconThemeChange() {
    this.favIcon!.href =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'assets/rls_dark_icon.png'
        : 'assets/rls_light_icon.png';

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      this.favIcon!.href =
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'assets/rls_dark_icon.png'
          : 'assets/rls_light_icon.png';
    });
  }

  goToLoginPage() {
    this.showLoggingIndicator = false;
    this.router.navigate(['/login']);
  }

  private setTheme() {
    const selectedTheme = this.themeService.theme;
    if (selectedTheme === Theme.Dark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }

  private handleLoginProcess() {
    const token = localStorage.getItem('token');

    if (token) {
      this.shellService
        .loginWithToken(token)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (resp: LoginResp) => {
            this.isDisplayNavigateToLoginButtonTimer = setTimeout(() => {
              this.isDisplayNavigateToLoginButton = true;
            }, 6000);
            const interval = setInterval(() => {
              if (this.isCheckedFromRefresh) {
                if (!this.isPageRefreshed) {
                  this.showLoggingIndicator = true;
                }
                this.handleLoginSuccessCase(resp);
                clearInterval(interval);
                this.isCheckedFromRefresh = false;
              }
            }, 50);
          },
          () => {
            this.router.navigate(['/login']);
            localStorage.removeItem('token');
          }
        );
    } else {
      this.router.navigate(['/login']);
      localStorage.removeItem('token');
    }
  }

  private handleLoginSuccessCase(resp: LoginResp) {
    const { token, type, user } = resp;
    this.shellService
      .getUserAccess(user.id.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userAccess: any) => {
        this.shellService.setToken(token);
        this.complianceService.setToken(token);
        const isOnboardingCompleted = user.user_setting ? user.user_setting.is_onboarding_complete.toString() : 'false';
        localStorage.setItem('token', token);
        localStorage.setItem('userName', user.name);
        localStorage.setItem('email', user.email);
        localStorage.setItem('userId', user.id.toString());
        localStorage.setItem('isOnboardingCompleted', isOnboardingCompleted);
        localStorage.setItem('policies', JSON.stringify(userAccess.policies));
        localStorage.setItem('accessibleData', JSON.stringify(userAccess.accessibleData));
        localStorage.setItem('languageCodes', JSON.stringify(user.user_setting?.language?.language_settings));

        this.updateServicesAuidToken(token, user.id.toString());

        this.loginService.hasLoggedIn.next(true);

        if (type === OnboardingStatus.Completed && !this.isPageRefreshed) {
          this.router.navigate(['/dashboard']);
          this.checkRouteEnd();
        } else if (type === OnboardingStatus.Pending) {
          this.router.navigate(['/onboarding'], {
            queryParams: {
              type,
              req: JSON.stringify(resp.requests),
              user: JSON.stringify(user),
            },
          });
          this.checkRouteEnd();
        } else if (type === OnboardingStatus.FirstRequest) {
          this.router.navigate(['/onboarding'], {
            queryParams: { type, user: JSON.stringify(user) },
          });
          this.checkRouteEnd();
        }
      });
  }

  private checkFromRefresh() {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe((event) => {
      const isTestsRoute = event.urlAfterRedirects === '/tests' && event.url.startsWith('/tests?selected=');
      this.isPageRefreshed = event.id === 1 && (event.url === event.urlAfterRedirects || isTestsRoute);
      this.isCheckedFromRefresh = true;
      this.showLoggingIndicator = false;
    });
  }

  private checkRouteEnd() {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe((event) => {
      const isNavigateEnd = event.id === 1 && event.url !== event.urlAfterRedirects;
      if (isNavigateEnd) {
        this.showLoggingIndicator = false;
        clearTimeout(this.isDisplayNavigateToLoginButtonTimer);
      }
    });
  }

  private updateServicesAuidToken(token: string, userId: string) {
    this.shellService.setToken(token);
    this.complianceService.setToken(token);
    this.shellService.setAuid(userId);
    this.csvService.setToken(token);
    this.csvService.setAuid(userId);
    this.dataService.setToken(token);
    this.dataService.setAuid(userId);
    this.productManagementService.setToken(token);
    this.productManagementService.setAuid(userId);
    this.resourceManagementService.setToken(token);
    this.resourceManagementService.setAuid(userId);
    this.resourcesTabManagementService.setToken(token);
    this.resourcesTabManagementService.setAuid(userId);
    this.statisticService.setToken(token);
    this.statisticService.setAuid(userId);
    this.testManagementService.setToken(token);
    this.testManagementService.setAuid(userId);
  }

  private listenLeftMenuStateChanges() {
    this.leftMenuService.leftMenuState.pipe(takeUntil(this.unsubscribe)).subscribe((isOpen) => {
      this.isLeftMenuOpen = isOpen;
    });
  }

  private subscribeToRouter() {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe((event) => {
      const isNavigatedToLoginPage = event.urlAfterRedirects === '/login';
      if (isNavigatedToLoginPage) {
        this.snackBar.dismiss();
      }
    });
  }

  private openLauncher(){
    this.isLauncherDialogOpened = true;
    const launcherDialogRef = this.matDialog.open(LauncherDialogComponent, {
      width:'900px',
      height:'470px',
    });

    const dialogElement: HTMLCollectionOf<Element> = document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');

    launcherDialogRef
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(() => {
      this.isLauncherDialogOpened = false;
    });
  }
}
