<div class="lab-room-selector-wrapper" fxLayout="row" fxLayoutGap="16px">
  <mat-icon class="back-icon clickable" (click)="navigateToPreviousPage()">arrow_back</mat-icon>
  <div class="hidden-mobile" fxFlex fxLayout="column">
    <div class="lab-room-name">{{ labName }}</div>
    <div class="lab-room-selector-wrapper">
      <button mat-button mat-icon-button [matMenuTriggerFor]="menu.childMenu" fxLayout="row" class="menu-btn clickable">
        {{ roomName }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <app-menu-item #menu [itemType]="menuItemType" [menuItems]="menuItems?.children"></app-menu-item>
    </div>
  </div>
</div>
