<div class="notification-wrapper notifications-dialog-wrapper" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <div class="notification-header">{{ '353' | translate: 'Notifications' }}</div>
    <button class="rt-btn-fourth general-popup-button detail-button" [matMenuTriggerFor]="generalPopupMenu">
      <mat-icon class="vertical-align-center general-detail-icon">more_horiz</mat-icon>
    </button>
  </div>
  <div class="items-wrapper">
    <rt-loading-indicator
      [showLoadingIndicator]="isQueryRunning"
      [path]="loaderPath"
      [isDarkMode]="isDarkMode"></rt-loading-indicator>
    <div
      (mouseenter)="focusedNotify = notification"
      class="notification-item"
      [ngClass]="{ 'active-item': focusedNotify === notification }"
      *ngFor="let notification of notifications">
      <div fxLayout="column" class="item-container">
        <div class="notification-header-wrapper" fxLayout="row">
          <mat-icon *ngIf="notification.level === notificationLevel.Important" class="important-icon">
            warning_amber
          </mat-icon>
          <div
            class="notification-header-item"
            [ngClass]="{
              'title-error': notification.level === notificationLevel.Important,
              'title-warning': notification.level === notificationLevel.Warning,
              'title-info': notification.level === notificationLevel.Info
            }">
            {{ notification.title | translate }}
          </div>
          <button
            [style.visibility]="focusedNotify === notification ? 'visible' : 'hidden'"
            class="rt-btn-fourth detail-button"
            [matMenuTriggerFor]="itemPopupMenu">
            <mat-icon class="vertical-align-center detail-button-margin">more_horiz</mat-icon>
          </button>
        </div>
        <div class="notification-description">{{ notification.descriptionString }}</div>
        <div class="notification-content">{{ notification.contentString }}</div>
        <div class="duration" fxLayout="row" *ngIf="notification.dateAgo">
          <div class="duration-sign" *ngIf="!notification.readDate">•</div>
          <div class="duration-text">{{ notification.dateAgo }} {{ '53' | translate: 'ago' }}</div>
        </div>
        <div class="duration" fxLayout="row" *ngIf="notification.count">
          <div class="duration-sign" *ngIf="!notification.readDate">•</div>
          <div class="duration-text">{{ '20228' | translate: 'Count' }} : {{ notification.count }}</div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="4px">
          <button
            class="rt-btn-fourth approve-button rt-btn-sm"
            (click)="showCreationHistory(notification.creationHistory)">
            {{ 20395 | translate: 'Show History' }}
          </button>
          <button
            *ngIf="
              (notification.type === notificationType.ExtraTimeRequest && hasUserExtraTimeListReadAccess) ||
              (notification.type === notificationType.OnboardingRequest && hasUserOnboardingListReadAccess)
            "
            class="rt-btn-fourth approve-button rt-btn-sm"
            (click)="acceptRequest(notification)">
            {{ '1885' | translate: 'Go to Detail' }}
          </button>
          <button
            *ngIf="notification.type === notificationType.TestStart"
            class="rt-btn-fourth approve-button rt-btn-sm"
            (click)="onGoToTest(notification)">
            {{ '257' | translate: 'Go to Test' }}
          </button>
          <button
            *ngIf="notification.type === notificationType.TestEnd"
            class="rt-btn-fourth approve-button rt-btn-sm"
            (click)="onGoToFinishedTestList(notification)">
            {{ '20460' | translate: 'Go To Finished Tests' }}
          </button>
          <button
            *ngIf="notification.type === notificationType.TestEnd"
            class="rt-btn-fourth approve-button rt-btn-sm"
            (click)="onCreateLiveReport(notification)">
            {{ '20442' | translate: 'Create Live Report Screen' }}
          </button>
        </div>
      </div>
    </div>
    <span class="no-notification-text vertical-align-center horizontal-align-center" *ngIf="!hasNotifications()">
      {{ 20229 | translate: 'You have no notifications' }}
    </span>
  </div>
  <div class="button-container">
    <button class="rt-btn rt-btn-secondary show-all-button" (click)="showAllNotifications()">
      {{ ('516' | translate: 'Show All') + (' (' + allNotificationsCount + ')') }}
    </button>
  </div>
</div>
<mat-menu #itemPopupMenu="matMenu" class="item-pop-up-mat-menu" xPosition="before">
  <button class="mat-menu-button" (click)="onRead()" mat-menu-item>
    <mat-icon class="menu-icon">check</mat-icon>
    <span class="menu-text">
      {{ '20130' | translate: 'Mark As Read' }}
    </span>
  </button>
  <button class="mat-menu-button" (click)="onRemove()" mat-menu-item>
    <mat-icon class="menu-icon">close</mat-icon>
    <span class="menu-text">
      {{ '20132' | translate: 'Remove Notification' }}
    </span>
  </button>
  <div
    *ngIf="
      (focusedNotify?.type === notificationType.ExtraTimeRequest && hasUserExtraTimeListReadAccess) ||
      (focusedNotify?.type === notificationType.OnboardingRequest && hasUserOnboardingListReadAccess)
    ">
    <hr />
    <button class="mat-menu-button" (click)="acceptRequest()" mat-menu-item>
      <mat-icon class="menu-icon">check_circle_outline</mat-icon>
      <span class="menu-text">
        {{ '1885' | translate: 'Go to Detail' }}
      </span>
    </button>
  </div>
</mat-menu>
<mat-menu #generalPopupMenu="matMenu" class="general-pop-up-mat-menu" xPosition="before">
  <button
    class="mat-menu-button"
    [disabled]="!hasNotifications()"
    (click)="hasNotifications() && allRead()"
    mat-menu-item
    [ngClass]="{ 'disabled-options-wrapper': !hasNotifications() }">
    <mat-icon class="menu-icon" [ngClass]="{ 'disabled-options': !hasNotifications() }">check</mat-icon>
    <span class="menu-text" [ngClass]="{ 'disabled-options': !hasNotifications() }">
      {{ '20131' | translate: 'Mark All As Read' }}
    </span>
  </button>
  <button
    class="mat-menu-button"
    [disabled]="!hasNotifications()"
    (click)="hasNotifications() && allRemove()"
    mat-menu-item
    [ngClass]="{ 'disabled-options-wrapper': !hasNotifications() }">
    <mat-icon class="menu-icon" [ngClass]="{ 'disabled-options': !hasNotifications() }">close</mat-icon>
    <span class="menu-text" [ngClass]="{ 'disabled-options': !hasNotifications() }">
      {{ '20133' | translate: 'Remove All Notifications' }}
    </span>
  </button>
</mat-menu>
