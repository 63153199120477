import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { GetLabResourceHierarchyResponse } from 'src/app/core/models';
import { ShellService } from 'src/app/core/services';
import { LabRoomStationSelectorMenuItem } from '../../interfaces';

@Component({
  selector: 'app-lab-room-station-selector',
  templateUrl: './lab-room-station-selector.component.html',
  styleUrls: ['./lab-room-station-selector.component.scss'],
})
export class LabRoomStationSelectorComponent implements OnInit, OnDestroy {
  @Input() stationId: number;
  @Input() isArchiveTest = false;
  @Input() roomId: number;

  @Output() selectedStationChanged: EventEmitter<{
    labName: string;
    labId: number;
    roomName: string;
    roomId: number;
    stationName: string;
    stationId: number;
  }> = new EventEmitter();

  menuItems: LabRoomStationSelectorMenuItem[];

  stationName: string;
  labName: string;
  roomName: string;

  private unsubscribe = new Subject<void>();

  constructor(private shellService: ShellService, private location: Location) {}

  ngOnInit() {
    this.getLabResourceHierarchy();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigateToPreviousPage() {
    this.location.back();
  }

  onSelectStation(
    menuItem: LabRoomStationSelectorMenuItem,
    resource: LabRoomStationSelectorMenuItem['resources'][0],
    station: LabRoomStationSelectorMenuItem['resources'][0]['childs'][0]
  ) {
    this.setDisplayedTexts(menuItem.name, resource.name, station.name);

    this.selectedStationChanged.emit({
      labName: menuItem.name,
      labId: menuItem.id,
      roomName: resource.name,
      roomId: resource.id,
      stationName: station.name,
      stationId: station.id,
    });
  }

  private getLabResourceHierarchy() {
    this.shellService.getLabResourceHierarchy().subscribe((response: GetLabResourceHierarchyResponse) => {
      this.menuItems = response.labs.map((lab) => {
        return {
          ...lab,
          resources: lab.resources.map((resource) => {
            return {
              ...resource,
              childs: resource.childs.map((child) => {
                if (child.id === this.stationId) {
                  this.setDisplayedTexts(lab.name, resource.name, child.name);
                }

                return {
                  ...child,
                  isSelected: child.id === this.stationId,
                };
              }),
            };
          }),
        };
      });
    });
  }

  private setDisplayedTexts(labName: string, roomName: string, stationName: string) {
    this.labName = labName;
    this.roomName = roomName;
    this.stationName = stationName;
  }
}
