import { Injectable } from '@angular/core';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';

@Injectable()
export class PermissionControlService {
  isActiveUserHasAuthorization(domainType: any, domain: any, source: any, action: any): boolean {
    const policies = JSON.parse(localStorage.getItem('policies') as string);
    const accessibleData = JSON.parse(localStorage.getItem('accessibleData') as string);
    let domainWithPrefix = domain + '';
    if (domainType == AuthorizationDomainTypes.Global || domainType == AuthorizationDomainTypes.Lab) {
      if (!domain) {
        domain = 0;
      }
      domainWithPrefix = AuthorizationDomainTypes[domainType] + '-' + domain;
    }
    let filteredAccessibleData = accessibleData;
    filteredAccessibleData = accessibleData?.filter((data: any) => data[2] === domainWithPrefix);
    if (filteredAccessibleData && filteredAccessibleData.length > 0) {
      let role: any;
      let index;
      const filteredDataItem = filteredAccessibleData.find((filteredAccessibleDataItem: any) => {
        role = filteredAccessibleDataItem[1];
        index = policies.findIndex(
          (policy: any) =>
            policy[0] === role &&
            +AuthorizationSourceEnum[policy[1]] === +source &&
            +AuthorizationActionEnum[policy[2]] === +action
        );
        if (index !== -1) {
          return filteredAccessibleDataItem;
        }
      });
      return !!filteredDataItem;
    } else {
      return false;
    }
  }
}
