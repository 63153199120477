import { Injectable } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColumnApi } from 'ag-grid-community';
import { FilterData, FiltersByTableAndUser, TableFilter } from 'src/app/core/models';
import { ShellService } from 'src/app/core/services';
import { FilterTableName } from '../enums';
import { AutomaticFilter } from '../enums/automatic-filter.enum';

@Injectable()
export class TableFilterService {
  constructor(private shellService: ShellService) {}

  deleteSavedFilter(id: number) {
    this.shellService.deleteFilterById(id).subscribe();
  }

  saveCurrentFilter(
    gridInstance: AgGridAngular,
    table_name: FilterTableName | string,
    user_id: number,
    savedFilters: TableFilter[],
    filter_id?: number,
    filter_name?: string
  ) {
    let filter_data: FilterData[] = [];
    let width_data: { colId: string; width: number }[] = [];
    let sort_data: { colId: string; sort: string }[] = [];

    gridInstance.api.getColumnDefs().forEach((columnDef: ColDef, index) => {
      filter_data.push({
        colId: columnDef.colId ?? '0',
        hide: !!columnDef.hide,
        index,
      });

      width_data.push({
        colId: columnDef.colId ?? '0',
        width: columnDef.width ?? 0,
      });

      if (columnDef.sort) {
        sort_data.push({
          colId: columnDef.colId ?? '0',
          sort: columnDef.sort,
        });
      }
    });

    let tableFilter = {
      id: filter_id ? filter_id : undefined,
      user_id: user_id,
      table_name: table_name,
      filter_name: filter_name ? filter_name : AutomaticFilter.Name,
      filter_data,
      sort_data,
      column_filter: gridInstance.api.getFilterModel(),
      width_data,
    };

    this.shellService.saveFilter(tableFilter).subscribe((data) => {
      this.updateSavedFilters(savedFilters, data);
    });
  }

  applyFilter(activeFilter: TableFilter, gridInstanceApi: AgGridAngular) {
    this.setColumnsVisibilityAndOrder(activeFilter.filter_data, gridInstanceApi.columnApi);

    this.setColumnsWidth(activeFilter.width_data, gridInstanceApi.columnApi);

    this.setColumnsSortData(activeFilter.sort_data, gridInstanceApi.columnApi);

    gridInstanceApi.api.setFilterModel(activeFilter.column_filter);
  }

  private setColumnsVisibilityAndOrder(filterData: FilterData[], columnApi: ColumnApi) {
    filterData.forEach((column) => {
      columnApi.setColumnVisible(column.colId, !column.hide);
      columnApi.moveColumn(column.colId, column.index);
    });
  }

  private setColumnsWidth(widthData: { colId: string; width: number }[], columnApi: ColumnApi) {
    widthData.forEach((column) => {
      columnApi.setColumnWidth(column.colId, column.width);
    });
  }

  private setColumnsSortData(sortData: { colId: string; sort: string }[], columnApi: ColumnApi) {
    if (sortData.length > 0) {
      sortData.forEach((column) => {
        columnApi.applyColumnState({
          state: [{ colId: column.colId, sort: column.sort }],
          defaultState: {
            // important to say 'null' as undefined means 'do nothing'
            sort: null,
          },
          applyOrder: false,
        });
      });
    } else {
      columnApi.applyColumnState({
        state: [],
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null,
        },
        applyOrder: false,
      });
    }
  }

  private updateSavedFilters(savedFilters: TableFilter[], filterData: FiltersByTableAndUser) {
    let filter = savedFilters.find((filter) => filter.id === filterData.id);

    if (filter) {
      filter.id = filterData.id;
      filter.user_id = filterData.user_id;
      filter.filter_name = filterData.filter_name;
      filter.table_name = filterData.table_name;
      filter.filter_data = JSON.parse(filterData.filter_data);
      filter.column_filter = JSON.parse(filterData.column_filter);
      filter.sort_data = JSON.parse(filterData.sort_data);
      filter.width_data = JSON.parse(filterData.width_data);
      filter.is_active = true;
    } else {
      savedFilters.push({
        id: filterData.id,
        user_id: filterData.user_id,
        filter_name: filterData.filter_name,
        table_name: filterData.table_name,
        filter_data: JSON.parse(filterData.filter_data),
        column_filter: JSON.parse(filterData.column_filter),
        sort_data: JSON.parse(filterData.sort_data),
        width_data: JSON.parse(filterData.width_data),
        is_active: true,
      });
    }

    savedFilters.forEach((filter) => {
      if (filter.id === filterData.id) {
        filter.is_active = true;
      } else {
        filter.is_active = false;
      }
    });
  }
}
