import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LabInfoWithActiveTests, Laboratory } from 'src/app/core/models';
import { ShellService } from 'src/app/core/services';
import { ResourceManipulationService } from 'src/app/shared/services/resource-manipulation.service';

@Component({
  selector: 'app-lab-selector',
  templateUrl: './lab-selector.component.html',
  styleUrls: ['./lab-selector.component.scss'],
})
export class LabSelectorComponent implements OnInit, OnDestroy {
  @Input() set setLaboratories(laboratories: Laboratory[]) {
    this.laboratories = laboratories;
    this.selectedLab = laboratories[0];
  }
  @Output() selectedLabChanged: EventEmitter<any> = new EventEmitter();

  laboratories: Laboratory[] = [];

  selectedLab: Laboratory;
  testNameInSelectedStation: string = 'G945645';

  private unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private shellService: ShellService,
    private resourceManipulationService: ResourceManipulationService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigateToTestListPage() {
    this.router.navigate(['tests']);
  }

  changeLab(lab: Laboratory): void {
    this.selectedLab = lab;

    this.shellService
      .getActiveTestsByLabId(this.selectedLab.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: LabInfoWithActiveTests) => {
        const { lab } = result;
        const rooms = this.resourceManipulationService.editRoomStationTestHierarchy(lab.resources, lab.id);

        this.selectedLabChanged.emit({ labId: lab.id, rooms });
      });
  }

  isSelectedLab(lab: Laboratory): boolean {
    return lab.id === this.selectedLab.id;
  }
}
