import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductManagementService {
  token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getAllProductTabs(): Observable<any> {
    return this.http.get(`${environment.resourceManagementServicePath}/resourceDefinition/getResourceDefinitions`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    });
  }

  getResourcesByResourceDefinitionId(id: number): Observable<any> {
    return this.http.post(
      `${environment.resourceManagementServicePath}/resource/getResourcesByResourceDefinitionId`,
      {
        id: id,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      }
    );
  }
}
