export enum TestHistoryTemplate {
  OnOffCycle = 1,
  DefrostStart,
  TestStart,
  TestStop,
  TestEnd,
  DataLoss,
  DataOutOfRange,
  NoResponseFromVTS,
}
