<div
  [ngClass]="fromMobileMenu ? '' : 'rt-dialog-wrapper long-running-job-wrapper'"
  fxLayout="column"
  fxFlexFill
  fxLayoutAlign="space-between stretch"
  [style]="fromMobileMenu ? 'max-height: auto !important;' : ''">
  <div
    class="rt-dialog-content"
    fxLayout="column"
    [style]="fromMobileMenu ? 'max-height: auto !important; height:auto !important;flex: 1 1 0%;' : ''">
    <div *ngIf="!fromMobileMenu" class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="rt-dialog-header-text">{{ '1893' | translate: 'Background Tasks' }}</div>
      <mat-icon class="customIcon" (click)="closeDialog()">close</mat-icon>
    </div>
    <div
      class="scrollable"
      [style]="fromMobileMenu ? 'max-height: unset !important; height:auto !important; flex: 1 1 0%;' : ''">
      <div *ngFor="let longRunningJob of longRunningJobs" fxLayout="row" fxLayoutGap="10px" class="lrj-item clickable">
        <div class="indicator">
          <mat-progress-spinner
            class="lrj-spinner"
            [ngClass]="longRunningJob.state === lrjStatus.Error ? 'error-spinner' : 'success-spinner'"
            [mode]="longRunningJob.state === lrjStatus.RunningProcess ? 'indeterminate' : 'determinate'"
            [value]="longRunningJob.load_value"
            [diameter]="35"></mat-progress-spinner>
          <div [ngSwitch]="longRunningJob.state" class="loading-indicator-text">
            <mat-icon *ngSwitchCase="lrjStatus.RunningProcess" class="running-icon">file_upload</mat-icon>
            <mat-icon *ngSwitchCase="lrjStatus.Error" class="error-icon">close</mat-icon>
            <mat-icon *ngSwitchCase="lrjStatus.Success" class="success-icon">check</mat-icon>
            <mat-icon *ngSwitchCase="lrjStatus.StopProcess" class="stop-icon">pause</mat-icon>
          </div>
        </div>
        <div fxLayout="column" class="lrj-item-text" fxFlex>
          <span class="lrj-item-header">
            {{ longRunningJob.name }}
          </span>
          <!-- <span class="lrj-item-state">{{ longRunningJob.fileStatus }}</span> -->
        </div>
        <mat-icon class="lrj-more-action-icon" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ longRunningJob }">
          more_horiz
        </mat-icon>
      </div>
    </div>
    <button
      [ngClass]="fromMobileMenu ? '' : 'custom-button'"
      class="rt-btn rt-btn-secondary"
      (click)="navigateToLongRunningJobs()"
      [style]="fromMobileMenu ? 'margin-top: auto' : ''">
      {{ '516' | translate: 'Show All' }}
    </button>
  </div>
</div>

<mat-menu class="long-running-job-mat-menu-wrapper" #menu="matMenu">
  <ng-template matMenuContent let-longRunningJob="longRunningJob">
    <app-more-actions-dialog
      [setLongRunningJob]="longRunningJob"
      (lrjAction)="executeLRJAction($event)"></app-more-actions-dialog>
  </ng-template>
</mat-menu>
