import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LanguageService } from 'src/app/core/services';
import { TranslatePipe } from 'src/app/shared/pipes';
import { SelectButtonDataShareService } from './select-button-data-share.service';

@Component({
  selector: 'select-button',
  template: `
    <button class="select-device-button rt-btn-primary" (click)="selectRow($event)">
      {{ selectDeviceText }}
    </button>
  `,
})
export class SelectButtonRendererComponent implements ICellRendererAngularComp {
  selectDeviceText = new TranslatePipe(this.languageService).transform(20075, 'Select');

  private params: any;

  constructor(private languageService: LanguageService, private selectButtonService: SelectButtonDataShareService) {}

  refresh(params: any): any {
    // throw new Error('Method not implemented.');
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    // throw new Error('Method not implemented.');
  }

  selectRow(event: any) {
    this.selectButtonService.selectedRow.next(this.params.data);
  }
}
