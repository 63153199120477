import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RedisResponse, WriteVariablesModel } from 'src/app/shared/interfaces';
import { HardwarePlcInterface, ReadVariableResponseInterface } from 'src/app/shared/interfaces/plc-interfaces.model';
import { environment } from '../../../environments/environment';
import {
  ClearGateErrorResponse,
  DoorCreateTemplatePayload,
  GetAllDoorsWithActiveTestResponse,
  GetAllTemplatesWithoutRelationsResponse,
  plcActionGroup,
  PlcDoorTestTemplate,
  plcIndicatorGroup,
  plcTabGroup,
  plcTargetSetGroup,
  SummarySet,
} from '../models';
import { plcVariacSet } from '../models/plc-variac-set.model';

const baseUrl = environment.plcControl;

@Injectable({
  providedIn: 'root',
})
export class PlcService {
  token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getPlcInfoWithPlcId(plcId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcInfoWithPlcId`,
      { id: plcId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getPlcInfoWithPlcIdWithPromise(plcId: number): Promise<HardwarePlcInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${baseUrl}/plc/plc-control/getPlcInfoWithPlcId`,
          { id: plcId },
          {
            headers: {
              authorization: `Bearer ${this.token}`,
            },
          }
        )
        .subscribe(
          (plcData: any) => {
            resolve(plcData);
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  getPlcDataWithPlcId(plcId: number): Observable<HardwarePlcInterface> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcInfoWithPlcId`,
      { id: plcId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<HardwarePlcInterface>;
  }

  readVariablesWithVariableIds(variableIds: number[]): Observable<ReadVariableResponseInterface[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/readVariables`,
      { ids: variableIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<ReadVariableResponseInterface[]>;
  }

  getPlcTargetSetGroupsWithPlcIds(plcIds: number[]): Observable<plcTargetSetGroup[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcTargetSetGroupsWithPlcIds`,
      { ids: plcIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<plcTargetSetGroup[]>;
  }

  getPlcIndicatorGroupsWithPlcIds(plcIds: number[]): Observable<plcIndicatorGroup[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcIndicatorGroupsWithPlcIds`,
      { ids: plcIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<plcIndicatorGroup[]>;
  }

  getPlcActionGroupsWithPlcIds(plcIds: number[]): Observable<plcActionGroup[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcActionGroupsWithPlcIds`,
      { ids: plcIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<plcActionGroup[]>;
  }

  getPlcVariacSetWithPlcIds(plcIds: number[]): Observable<plcVariacSet[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcVariacSetWithPlcIds`,
      { ids: plcIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<plcVariacSet[]>;
  }

  getPlcTabGroupsWithPlcIds(plcIds: number[]): Observable<plcTabGroup[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getPlcTabGroupsWithPlcIds`,
      { ids: plcIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<plcTabGroup[]>;
  }

  writeVariables(id: number, value: any) {
    return this.http.post(
      `${baseUrl}/plc/plc-control/writeVariables`,
      {
        writeItems: [
          {
            id: id,
            value: value,
          },
        ],
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<WriteVariablesModel[]>;
  }

  writeVariablesWithMultipleItem(writeItems: { id: number; value: any }[]) {
    return this.http.post(
      `${baseUrl}/plc/plc-control/writeVariables`,
      {
        writeItems,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<WriteVariablesModel[]>;
  }

  getPlcDataWithVariableIds(
    variableIds: number[],
    plotSize: number,
    sampleTime: number,
    range: { minRange: number | '-'; maxRange: number | '+' }
  ) {
    return this.http.post<RedisResponse[]>(
      `${baseUrl}/plc/plc-data/getPlcDataWithVariableIds`,
      {
        variableIds,
        plotSize,
        sampleTime,
        range,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  variableSetDraw(id: number, value: boolean) {
    return this.http.post<any>(
      `${baseUrl}/plc/plc-control/variableSetDraw`,
      {
        id,
        value,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getReadRateWithPlcIds(plcIds: number[]): Observable<plcTabGroup[]> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getReadRateWithPlcIds`,
      { ids: plcIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<plcTabGroup[]>;
  }

  getVoltageSetTemplateWithDeviceIds(deviceIds: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getVoltageSetTemplateWithDeviceIds`,
      { ids: deviceIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any[]>;
  }

  getVoltageVariacSetTemplateWithDeviceIds(deviceIds: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/plc-control/getVoltageVariacSetTemplateWithDeviceIds`,
      { ids: deviceIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  getMdasVoltageSetTemplateWithDeviceIds(deviceIds: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/mdas/control/getVoltageSetTemplateWithDeviceIds`,
      { ids: deviceIds },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  getVoltageValueWithDevices(devices: { device_id: number; card_id: number }[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/mdas/control/getVoltageValueWithDevices`,
      { devices },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  setVoltageValueWithDevice(device_id: number, card_id: number, value: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/mdas/control/setVoltageValueWithDevice`,
      { device_id, card_id, value },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  getDoorTestTemplateWithTempId(plcDoorTestTempId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/door/getDoorTestTemplateWithTempId`,
      { id: plcDoorTestTempId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  addPlannedTest(tmpId: number, date: string, openTime: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/door/addPlannedTest`,
      { tmpId, date, openTime },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  deletePlannedTest(tmpId: number, index: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/door/deletePlannedTest`,
      { tmpId, index },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  deleteAllPlannedTest(tmpId: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/plc/door/deleteAllPlannedTest`,
      { tmpId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    ) as Observable<any>;
  }

  getAllTemplates(): Observable<PlcDoorTestTemplate[]> {
    return this.http.get<PlcDoorTestTemplate[]>(`${baseUrl}/plc/door/getAllTemplates`, {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    });
  }

  createTemplate(payload: DoorCreateTemplatePayload): Observable<PlcDoorTestTemplate> {
    return this.http.post<PlcDoorTestTemplate>(
      `${baseUrl}/plc/door/createTemplate`,
      {
        name: payload.name,
        plc_door_test_template_rows: payload.plc_door_test_template_rows,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  deleteTemplate(id: number) {
    return this.http.post<{ msg: string }>(
      `${baseUrl}/plc/door/deleteTemplate`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getAllDoorsWithActiveTest(): Observable<GetAllDoorsWithActiveTestResponse> {
    return this.http.get<GetAllDoorsWithActiveTestResponse>(`${baseUrl}/plc/door/getAllDoorsWithActiveTest`, {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    });
  }

  clearGateError(doorId: number): Observable<ClearGateErrorResponse> {
    return this.http.post<ClearGateErrorResponse>(
      `${baseUrl}/plc/door/clearError`,
      {
        plc_door_id: doorId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getAllTemplatesWithoutRelations(): Observable<GetAllTemplatesWithoutRelationsResponse[]> {
    return this.http.get<GetAllTemplatesWithoutRelationsResponse[]>(
      `${baseUrl}/plc/door/getAllTemplatesWithoutRelations`,
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getAllDoorWithActiveTestById(id: number): Observable<GetAllDoorsWithActiveTestResponse> {
    return this.http.post<GetAllDoorsWithActiveTestResponse>(
      `${baseUrl}/plc/door/getAllDoorWithActiveTestById`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  startDoorTest(plc_door_id: number, tmp_id: number): Observable<GetAllDoorsWithActiveTestResponse['tests']> {
    return this.http.post<GetAllDoorsWithActiveTestResponse['tests']>(
      `${baseUrl}/plc/door/startDoorTest`,
      { plc_door_id, tmp_id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  endDoorTest(plc_door_id: number): Observable<GetAllDoorsWithActiveTestResponse['tests']> {
    return this.http.post<GetAllDoorsWithActiveTestResponse['tests']>(
      `${baseUrl}/plc/door/endDoorTest`,
      { plc_door_id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getSummarySetsWithPlcId(ids: number[], has_value: boolean): Observable<SummarySet[]> {
    return this.http.post<SummarySet[]>(
      `${baseUrl}/plc/maintenance/getSummarySetsWithPlcId`,
      {
        ids,
        has_value,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }
}
