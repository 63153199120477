import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Room, UtilLab } from 'src/app/core/models';
import { ShellService } from 'src/app/core/services';
import { MenuItemType } from 'src/app/shared/enums/menu-item-type.enum';
import { LabRoomStation } from '../../interfaces';
import { ResourceManipulationService } from '../../services';
import { RoomChangedService } from '../../services/room-changed.service';

@Component({
  selector: 'app-lab-room-selector',
  templateUrl: './lab-room-selector.component.html',
  styleUrls: ['./lab-room-selector.component.scss'],
})
export class LabRoomSelectorComponent implements OnInit, OnDestroy {
  @Input() previousUrl: string;
  @Input() set setRoomId(roomId: number | undefined) {
    this.roomId = roomId;
    this.shellService
      .getLabsHierarchicallyWithRmsResources()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: { labs: UtilLab[]; maxDepth: number }): void => {
        const labRoomsStations = this.resourceManipulationService.editLabRoomStationHierarchy(result.labs);
        this.setMenuItems(labRoomsStations);
      });
  }

  @Output() selectedRoomChanged: EventEmitter<{
    roomId: number;
    roomName: string;
    room?: Room;
    labId: number;
  }> = new EventEmitter();

  menuItems: any;
  roomId: number | undefined;
  labName: string;
  roomName: string;
  menuItemType = MenuItemType.room;

  private unsubscribe = new Subject<void>();

  constructor(
    private shellService: ShellService,
    private roomChangedService: RoomChangedService,
    private resourceManipulationService: ResourceManipulationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.roomChangedService.setDataToSelectedRoom.pipe(takeUntil(this.unsubscribe)).subscribe((room: any) => {
      this.selectedRoomChanged.emit({
        roomId: room.roomId,
        roomName: room.roomName,
        room: room,
        labId: room.labId,
      });
      this.setNewRoomData(room);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigateToPreviousPage() {
    this.location.back();
  }

  setNewRoomData(room: any) {
    this.roomName = room.roomName;
    this.labName = room.labName;
    this.roomId = room.roomId;
  }

  private setMenuItems(labRoomsStations: LabRoomStation[]) {
    const labs = labRoomsStations.map((lab: any) => {
      lab.children = lab.rooms.map((room: any) => {
        if (room.id === this.roomId) {
          this.roomName = room.name;
          this.labName = lab.name;
        }
        return {
          id: room.id,
          name: room.name,
          isSelected: room.id === this.roomId,
          roomName: room.name,
          labName: lab.name,
          roomId: room.id,
          plcIds: room.plcIds,
          stations: room.stations,
          labId: lab.id,
        };
      });
      return { children: lab.children, name: lab.name };
    });

    this.menuItems = { name: this.roomName, children: labs };
  }
}
