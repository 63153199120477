/**
 * Authorization Action Enums
 */
export enum AuthorizationActionEnum {
  /**
   * Authorization Action - Create
   */
  create,

  /**
   * Authorization Action - Update
   */
  update,

  /**
   * Authorization Action - Delete
   */
  delete,

  /**
   * Authorization Action - Read
   */
  read,

  /**
   * Authorization Action - Approve
   */
  approve,

  /**
   * Authorization Action - Reject
   */
  reject,

  /**
   * Authorization Action - Maintenance Update
   */
  maintenanceUpdate,

  /**
   * Authorization Action - Module CRC
   */
  moduleCRC,
}
