<div
  class="menu menu-container book-a-test-wrapper"
  fxLayout="column"
  fxLayoutGap="33px"
>
  <div class="header" fxLayout="row" *ngIf="!isLoading">
    <div fxFlex class="header-text">
      {{ "96" | translate: "Book A Test" }}
    </div>
    <div class="icons-wrapper" fxLayout="row" fxLayoutAlign="end start">
      <div
        class="close-icon-wrapper"
        (click)="closeMenu.emit()"
        matTooltip="{{ 20154 | translate: 'Close' }}"
      >
        <mat-icon>keyboard_tab</mat-icon>
      </div>
    </div>
  </div>
  <div
    fxFlex
    *ngIf="!isLoading"
    class="menu-content book-a-test-content"
    fxLayout="column"
    fxLayoutGap="24px"
  >
    <mat-vertical-stepper class="stepper-wrapper" #stepper [linear]="true">
      <mat-step
        label="{{ '209' | translate: 'Enter Form Info' }}"
        [completed]="infoFrom?.valid"
      >
        <ng-container *ngTemplateOutlet="formInfo"></ng-container>
      </mat-step>
      <mat-step
        label="{{ '91' | translate: 'Available Stations' }}"
        id="grid"
        fxLayoutGap="25px"
      >
        <ng-container *ngTemplateOutlet="availableStations"></ng-container>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <div
    class="menu-footer"
    *ngIf="!isLoading"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="16px"
  >
    <button class="rt-btn-secondary rt-btn-lg" (click)="closeMenu.emit()">
      {{ "109" | translate: "Cancel" }}
    </button>
    <button
      class="rt-btn-lg"
      [ngClass]="isFormReady() ? 'rt-btn-primary' : 'rt-btn-disable'"
      [disabled]="!isFormReady()"
      (click)="bookATest()"
    >
      {{ "508" | translate: "Send" }}
    </button>
  </div>
</div>
<rt-loading-indicator
  [showLoadingIndicator]="isLoading"
  [path]="loaderPath"
  [isDarkMode]="isDarkMode"
></rt-loading-indicator>

<ng-template #formInfo>
  <div fxLayout="column" fxLayoutGap="24px" *ngIf="isInfoFormReady">
    <form
      [formGroup]="infoFrom"
      class="rt-form station-selection-form"
      fxLayout="column"
      fxFlex
      fxLayoutGap="16px"
    >
      <div *ngFor="let field of formFields">
        <div
          *ngIf="field.type === attributeDataType.String"
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label class="disabled-label">{{
            "" | translate: field.label
          }}</label>
          <input
            type="text"
            class="form-control"
            formControlName="{{ field.form_name }}"
          />
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="field.type === attributeDataType.Number"
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label class="disabled-label">{{
            "" | translate: field.label
          }}</label>
          <input
            type="number"
            class="form-control"
            formControlName="{{ field.form_name }}"
          />
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="
            field.type === attributeDataType.Dropdown &&
            field.form_name !== deviceLabel
          "
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label>{{ "" | translate: field.label }}</label>
          <mat-select
            placeholder=""
            formControlName="{{ field.form_name }}"
            disableOptionCentering
            [value]="
              (field.form_name === tempHumidity.Temp &&
                field.dataSource &&
                field.dataSource[selectedTempIndex]) ||
              (field.form_name === tempHumidity.Humidity &&
                field.dataSource &&
                field.dataSource[selectedHumidityIndex])
            "
            (selectionChange)="onSelectionChange($event)"
          >
            <mat-option
              *ngFor="let fieldData of field.dataSource"
              [value]="fieldData"
            >
              {{ fieldData.value }}
            </mat-option>
          </mat-select>
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="
            field.type === attributeDataType.Dropdown &&
            field.form_name === deviceLabel
          "
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label>{{ "" | translate: field.label }}</label>

          <label class="disabled-label selected-device-label">
            {{ "1889" | translate: "Selected Device" }} :
            {{ selectedDevice?.id }} - {{ selectedDevice?.value }}
          </label>

          <ng-container *ngIf="!isGetProductByIndexAtBookATestMenu">
            <button
              class="rt-btn-lg rt-btn-secondary"
              (click)="openDeviceMenu()"
              [ngClass]="
                !!selectedResourceId ? 'rt-btn-secondary' : 'rt-btn-disable'
              "
              [disabled]="!selectedResourceId"
            >
              {{ "1890" | translate: "Select Device" }}
            </button>
          </ng-container>

          <ng-container *ngIf="isGetProductByIndexAtBookATestMenu">
            <label class="disabled-label">{{
              "20512" | translate: "Device Index Number"
            }}</label>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <input type="number" #input />
              <div style="position: relative">
                <button
                  [ngClass]="
                    input.value === '' ? 'rt-btn-disable' : 'rt-btn-secondary'
                  "
                  class="rt-btn-lg"
                  style="height: 46px"
                  (click)="getDeviceByIndex(input.value)"
                >
                  {{ "20511" | translate: "Get Device By Index Number" }}
                </button>
                <rt-loading-indicator
                  [showLoadingIndicator]="isQueryForDevice"
                  [path]="loaderPath"
                  [isDarkMode]="isDarkMode"
                ></rt-loading-indicator>
              </div>
            </div>
          </ng-container>

          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="
            field.type === attributeDataType.Date &&
            field.form_name !== startDateFormName
          "
          class="form-row book-a-test-date-picker"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label class="disabled-label">{{
            "" | translate: field.label
          }}</label>
          <div fxLayout="row">
            <input
              matInput
              class="form-control"
              [matDatepicker]="picker1"
              formControlName="{{ field.form_name }}"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>

          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="
            field.type === attributeDataType.Date &&
            field.form_name === startDateFormName
          "
          class="form-row book-a-test-date-picker"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label class="disabled-label">{{
            "" | translate: field.label
          }}</label>
          <div fxLayout="row">
            <input
              matInput
              [min]="minDate"
              class="form-control"
              [matDatepicker]="picker1"
              formControlName="{{ field.form_name }}"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </div>

          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="field.type === attributeDataType.Text"
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label class="disabled-label">{{
            "" | translate: field.label
          }}</label>
          <textarea
            matInput
            rows="5"
            formControlName="{{ field.form_name }}"
          ></textarea>
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>

        <div
          *ngIf="field.type === attributeDataType.Boolean"
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(infoFrom, field.form_name)"
        >
          <label class="disabled-label">{{
            "" | translate: field.label
          }}</label>
          <mat-checkbox
            [checked]="field.value"
            class="custom-frame"
            formControlName="{{ field.form_name }}"
          ></mat-checkbox>
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>
      </div>
    </form>
    <div fxLayoutAlign="end end">
      <button
        class="rt-btn-primary"
        [disabled]="!infoFrom?.valid && selectedDevice !== undefined"
        [ngClass]="infoFrom?.valid ? 'rt-btn-primary' : 'rt-btn-disable'"
        (click)="getAvailableStations()"
      >
        {{ "342" | translate: "Next Step" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #availableStations>
  <div *ngIf="hasAvailableStation()">
    <mat-radio-button
      class="available-station-mat-btn"
      fxLayout="column"
      (change)="stationSelected($event)"
      *ngFor="let option of options; let i = index"
      [value]="option"
      [checked]="option.isSelected"
      fxLayoutGap="8px"
      [ngClass]="option.isSelected ? 'selected-station-item' : ''"
    >
      <div
        fxLayout="row"
        fxFlex
        fxLayoutAlign="space-between center"
        class="available-station-item"
        fxLayoutGap="8px"
      >
        <div fxLayout="column">
          <span class="available-lab-name">{{ option.labName }}</span>
          <span class="available-room-station-name"
            >{{ option.roomName }} - {{ option.stationName }}</span
          >
        </div>
        <span class="available-lab-date">{{
          option.date | date: "dd.MM.yyyy HH:mm"
        }}</span>
      </div>
    </mat-radio-button>
  </div>
  <div
    *ngIf="!hasAvailableStation()"
    fxLayout="row"
    fxLayoutGap="5px"
    class="no-available-station"
  >
    <mat-icon>info</mat-icon>
    <span>{{
      "1891"
        | translate
          : "There are no available stations that meet these conditions"
    }}</span>
  </div>
</ng-template>
