import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-history-dialog',
  templateUrl: './notification-history-dialog.component.html',
  styleUrls: ['./notification-history-dialog.component.scss'],
})
export class NotificationHistoryDialogComponent implements OnInit {
  header = '';
  confirmationQuestion = '';
  confirmText = '';
  rejectText = '';
  histories: Date[] = [];
  approved = false;
  closeAllDialogs = true;

  constructor(private matDialog: MatDialog, private dialogRef: MatDialogRef<NotificationHistoryDialogComponent>) {}

  ngOnInit() {}

  reject() {
    this.approved = false;
    if (this.closeAllDialogs) {
      this.matDialog.closeAll();
    } else {
      this.dialogRef.close();
    }
  }

  approve() {
    this.approved = true;
    if (this.closeAllDialogs) {
      this.matDialog.closeAll();
    } else {
      this.dialogRef.close();
    }
  }

  getDate(date: Date) {
    return new Date(date).toLocaleString();
  }
}
