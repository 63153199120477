import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceManagementService } from 'src/app/core/services';
import { StationView } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-station-selector',
  templateUrl: './station-selector.component.html',
  styleUrls: ['./station-selector.component.scss'],
})
export class StationSelectorComponent implements OnInit, OnDestroy {
  @Input() previousUrl: string;
  @Input() stationId: number | undefined;
  @Input() set setRoomId(roomId: number | undefined) {
    if (roomId) {
      this.roomId = roomId;
      this.resourceManagementService
        .getStationsWithRoomId(this.roomId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((result) => {
          this.roomName = result.name;
          this.stations = result.stations;
          this.selectedStationName = this.stations.find((station) => station.id === this.stationId).name;
          this.stationNameSetted.emit(this.selectedStationName);
          this.roomNameSetted.emit(this.roomName);
        });
    }
  }

  @Output() selectedStationChanged: EventEmitter<{
    stationId: number;
    roomId: number;
  }> = new EventEmitter();

  @Output() stationNameSetted: EventEmitter<string> = new EventEmitter();
  @Output() roomNameSetted: EventEmitter<string> = new EventEmitter();

  roomId: number | undefined;
  roomName: string;
  selectedStationName: string;
  stations: any[] = [];

  private unsubscribe = new Subject<void>();

  constructor(private resourceManagementService: ResourceManagementService, private location: Location) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigateToPreviousPage() {
    this.location.back();
  }

  changeStation(station: StationView): void {
    this.selectedStationName = station.name;
    this.stationNameSetted.emit(this.selectedStationName);
    this.selectedStationChanged.emit({
      stationId: station.id,
      roomId: this.roomId as number,
    });
  }
}
