import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-book-a-test-after-dialog',
  templateUrl: './book-a-test-after-dialog.component.html',
  styleUrls: ['./book-a-test-after-dialog.component.scss'],
})
export class BookATestAfterDialogComponent implements OnInit, OnDestroy {
  @Output() openTestStartMenu = new EventEmitter();

  roomStationDetailQueryParams: {
    roomId: number;
    stationId: number;
  };

  labName: string;
  roomName: string;
  stationName: string;
  isAvailable: boolean;

  private unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<BookATestAfterDialogComponent>,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigateToRoomStationDetail() {
    // If user already in station detail route don't navigate just change params.
    if (this.router.url.includes('stationDetail')) {
      this.replaceUrlWithNewParams(
        this.roomStationDetailQueryParams.roomId,
        this.roomStationDetailQueryParams.stationId
      );
    } else {
      const queryParams = this.roomStationDetailQueryParams;

      this.router.navigate(['rooms/stationDetail'], {
        queryParams,
      });
    }

    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  private replaceUrlWithNewParams(roomId: number, stationId: number) {
    const urlTree = this.router.createUrlTree(['/rooms/stationDetail/'], {
      relativeTo: this.activatedRoute,
      queryParams: { roomId: roomId, stationId: stationId },
      queryParamsHandling: 'merge',
    });

    this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }
}
