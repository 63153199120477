import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Test } from 'src/app/core/models';

@Component({
  selector: 'app-test-started-dialog',
  templateUrl: './test-started-dialog.component.html',
  styleUrls: ['./test-started-dialog.component.scss'],
})
export class TestStartedDialogComponent implements OnInit {
  isEditMode: boolean;
  selectedTest: Test;
  stationId: number;
  roomId: number;
  labId: number | undefined;

  constructor(private matDialog: MatDialog, private router: Router) {}

  ngOnInit(): void {}

  closeDialog() {
    this.matDialog.closeAll();
  }

  setSelectedTest(selectedTestData: Test, stationId: number, roomId: number, labId?: number) {
    this.selectedTest = selectedTestData;
    this.stationId = stationId;
    this.roomId = roomId;
    this.labId = labId;
  }

  goToTest() {
    if (this.selectedTest) {
      this.router.navigate(['tests/testDetail'], {
        queryParams: {
          roomId: this.roomId,
          stationId: this.stationId,
          testId: this.selectedTest.id,
        },
      });
      this.closeDialog();
    }
  }
}
