import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModuleEnum } from 'src/app/core/enums';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';
import { LanguageService, LoginService, ShellService } from 'src/app/core/services';
import { TestListsPageOptions, Theme } from 'src/app/shared/enums';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { SettingsPageOptions } from '../../enums/settings-page-options.enum';
import { TranslatePipe } from '../../pipes';
import { PermissionControlService } from '../../services';
import { LeftMenuService } from '../../services/left-menu.service';
import { LongRunningJobComponent } from './long-running-job/long-running-job.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';

enum LrjStatus {
  HasError,
  AllIsSuccess,
  HasRunningProcess,
}

enum MOBILE_SUB_MENU_TYPE {
  LRJ = 'LRJ',
  NONE = 'NONE',
  SETTINGS = 'SETTINGS',
  TESTS = 'TESTS',
}

interface Options {
  text: string;
  icon: string;
  navigationUrl: string;
  isSelected: boolean;
  action?: () => void;
}

interface SubCompSettings<T> {
  title: string;
  subComponents: { subComp: T; title: string; isSelected: boolean }[];
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  @HostBinding('style.z-index')
  zIndex = '2';

  userName: string;
  userEmail: string;
  initials: string;

  isOpen: boolean;
  options: Options[] = [];
  tutorial: { text: string; icon: string; navigationUrl: string; isSelected: boolean } | any;

  lrjState: LrjStatus = LrjStatus.HasError;

  selectedTheme: any;

  isSelectedLRJPage: boolean;

  panelOpenState = true;

  isSettingsPageSelected = false;
  isTestPageSelected = false;

  hasNewTestListsView = environment.hasNewTestListsView;

  private onboardingRequestsText = new TranslatePipe(this.languageService).transform(361, 'Onboarding Requests');
  private languageSettingsText = new TranslatePipe(this.languageService).transform(310, 'Language Settings');
  private sensorSettingsText = new TranslatePipe(this.languageService).transform('20141', 'Sensor Settings');
  private organizationSchemaText = new TranslatePipe(this.languageService).transform(366, 'Organization Schema');
  private notificationParamsText = new TranslatePipe(this.languageService).transform(20152, 'Notification Conditions');
  private userSettingsText = new TranslatePipe(this.languageService).transform(20323, 'User Settings');
  private systemSettingsText = new TranslatePipe(this.languageService).transform(20324, 'System Settings');
  private calibrationText = new TranslatePipe(this.languageService).transform(106, 'Calibration');
  private authorityManagementText = new TranslatePipe(this.languageService).transform(88, 'Authorization Management');
  private resourceDefinitionText = new TranslatePipe(this.languageService).transform(441, 'Resource Definition');
  private addTestType = new TranslatePipe(this.languageService).transform(1922, 'Add Test Type');
  private activeTestsText = new TranslatePipe(this.languageService).transform(8, 'Active Tests');
  private plannedTestsText = new TranslatePipe(this.languageService).transform(380, 'Planned Tests');
  private finishedTestsText = new TranslatePipe(this.languageService).transform(249, 'Finished Tests');
  private liveReportsText = new TranslatePipe(this.languageService).transform(20322, 'Live Reports');
  private pastTestsText = new TranslatePipe(this.languageService).transform(999909, 'Past Tests');
  private archivedTestsText = new TranslatePipe(this.languageService).transform(999910, 'Archived Tests');
  private exportText = new TranslatePipe(this.languageService).transform(999900, 'Export');
  private importText = new TranslatePipe(this.languageService).transform(999901, 'Import');

  subComponentsOfSettings: SubCompSettings<SettingsPageOptions>[] = [
    {
      title: this.userSettingsText,
      subComponents: [],
    },
    {
      title: this.systemSettingsText,
      subComponents: [],
    },
    {
      title: this.calibrationText,
      subComponents: [],
    },
  ];

  subComponentsOfTests: (SubCompSettings<TestListsPageOptions> & { pagePath: string })[] = [
    {
      title: this.activeTestsText,
      pagePath: '',
      subComponents: [],
    },
    {
      title: this.pastTestsText,
      pagePath: 'pastTests',
      subComponents: [],
    },
    {
      title: this.archivedTestsText,
      pagePath: 'archivedTests',
      subComponents: [],
    },
  ];

  selectedMobileSubMenuType = MOBILE_SUB_MENU_TYPE.NONE;

  private unsubscribe = new Subject<void>();

  private dialogWidth = 200;
  private dialogHeight: number = 0;
  private bufferSpace: number = 10;

  private dashboardText = new TranslatePipe(this.languageService).transform('154', 'Dashboard');
  private roomsText = new TranslatePipe(this.languageService).transform('474', 'Rooms');
  private gateControlText = new TranslatePipe(this.languageService).transform('20381', 'Gate Control');
  private settingsText = new TranslatePipe(this.languageService).transform('514', 'Settings');
  private resourcesText = new TranslatePipe(this.languageService).transform('450', 'Resources');
  private sampleRateText = new TranslatePipe(this.languageService).transform('1941', 'Sample Rate');
  private analogScaleText = new TranslatePipe(this.languageService).transform('1907', 'Analog Scale');
  private analogSensorsText = new TranslatePipe(this.languageService).transform('20500', 'Analog Sensors');
  private unitsText = new TranslatePipe(this.languageService).transform('20499', 'Units');
  private iniFilesText = new TranslatePipe(this.languageService).transform('283', 'Ini Files');
  private runningCalibrationsText = new TranslatePipe(this.languageService).transform('20230', 'Running Calibrations');
  private previousCalibrationsText = new TranslatePipe(this.languageService).transform(
    '20231',
    'Previous Calibrations',
  );
  private versionsText = new TranslatePipe(this.languageService).transform('20432', 'Versions');
  private graphSettingsText = new TranslatePipe(this.languageService).transform('20518', 'Graph Settings');
  private inventoryText = new TranslatePipe(this.languageService).transform('20513', 'Inventory');
  private testsText = new TranslatePipe(this.languageService).transform(582, 'Tests');

  constructor(
    private router: Router,
    public oktaAuth: OktaAuthService,
    private loginService: LoginService,
    private themeService: ThemeService,
    private matDialog: MatDialog,
    private languageService: LanguageService,
    private permissionControlService: PermissionControlService,
    private leftMenuService: LeftMenuService,
    private shellService: ShellService,
  ) {
    this.listenLeftMenuStateChanges();
    this.userName = localStorage.getItem('userName') as string;
    this.userEmail = localStorage.getItem('email') as string;
    this.setInitials();
    this.setTheme();
    this.setOptions();
    this.specifySelectedUrlOnLeftMenu();
    if (this.hasNewTestListsView) {
      this.setActiveTestsSubComps();
      this.setPastTestsSubComps();
      this.setArchivedTestsSubComps();
    }
    this.setUserSettingSubComps();
    this.setSystemSettingsSubComps();
    this.setCalibrationSettingsSubComps();
    this.checkSubComponentsOfSettings();
  }

  ngOnInit(): void {
    this.leftMenuService.updateLeftMenuState(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get lrjStatus(): any {
    return LrjStatus;
  }

  get theme(): any {
    return Theme;
  }

  get mobileSubMenuType() {
    return MOBILE_SUB_MENU_TYPE;
  }

  navigateTo(navigation: Options): void {
    this.options.map((choice) => (choice.isSelected = false));
    navigation.isSelected = true;
    this.isSelectedLRJPage = false;
    this.router.navigate([navigation.navigationUrl]);
    if (this.isSelectedSettings(navigation.navigationUrl)) {
      this.leftMenuService.updateLeftMenuState(true);
      this.setSelectedSubComp('settings', SettingsPageOptions.Language);
      this.matDialog.closeAll();
    } else if (this.isSelectedTests(navigation.navigationUrl)) {
      this.leftMenuService.updateLeftMenuState(true);
      this.setSelectedSubComp('tests', TestListsPageOptions.ActiveTests);
      this.matDialog.closeAll();
    } else {
      this.clearAllSelectedSubComp();
    }
  }

  mobileNavigateTo(navigation: Options): void {
    this.isSelectedLRJPage = false;

    if (this.isSelectedSettings(navigation.navigationUrl)) {
      this.selectedMobileSubMenuType = MOBILE_SUB_MENU_TYPE.SETTINGS;
    } else if (this.isSelectedTests(navigation.navigationUrl) && this.hasNewTestListsView) {
      this.selectedMobileSubMenuType = MOBILE_SUB_MENU_TYPE.TESTS;
    } else {
      this.options.map((choice) => (choice.isSelected = false));
      this.subComponentsOfSettings.map((subComp) => {
        subComp.subComponents.map((subComponent) => {
          subComponent.isSelected = false;
        });
      });
      navigation.isSelected = true;
      this.router.navigate([navigation.navigationUrl]);
      this.leftMenuService.updateLeftMenuState(false);
    }
  }

  onSelectMobileSubMenuType(type: MOBILE_SUB_MENU_TYPE) {
    this.selectedMobileSubMenuType = type;
  }

  navigateToDashboard() {
    this.router.navigate(['dashboard']);
  }

  mobileNavigateToDashboard() {
    this.router.navigate(['dashboard']);
    this.leftMenuService.updateLeftMenuState(false);
    this.subComponentsOfSettings.map((subComp) => {
      subComp.subComponents.map((subComponent) => {
        subComponent.isSelected = false;
      });
    });
  }

  switchMenu(): void {
    this.leftMenuService.updateLeftMenuState(!this.isOpen);
    this.matDialog.closeAll();
  }

  openSubMenu(element: any) {
    this.matDialog.closeAll();
    element.disabled = true;
    const dialogDef = this.matDialog.open(SubMenuComponent, {
      width: '260px',
      hasBackdrop: true,
    });

    dialogDef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        dialogDef.close();
      });

    dialogDef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result && result.logout) {
          localStorage.removeItem('token');
          localStorage.removeItem('userName');
          localStorage.removeItem('email');
          localStorage.removeItem('userId');
          localStorage.removeItem('policies');
          localStorage.removeItem('accessibleData');
          this.loginService.hasLoggedIn.next(false);
          this.oktaAuth.signOut();
        }

        element.disabled = false;
      });

    const dialogElement: HTMLCollectionOf<any> = document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].style.visibility = 'hidden';
    setTimeout(() => {
      this.dialogHeight = dialogElement[0].offsetHeight;
      const coordinates = this.getCoordinates(element);

      dialogElement[0].classList.add('custom-dialog');
      dialogElement[0].classList.add('sub-menu-dialog');
      dialogElement[0].style.position = 'absolute';
      dialogElement[0].style.padding = '0px';
      dialogElement[0].style.top = coordinates.top;
      dialogElement[0].style.left = coordinates.left;
      dialogElement[0].style.width = '260px';
      dialogElement[0].style.height = 'auto';
      dialogElement[0].style.maxHeight = '240px';
      dialogElement[0].style.visibility = 'visible';
    }, 100);
  }

  openLrj(element: any) {
    this.matDialog.closeAll();
    element.disabled = true;
    const dialogDef = this.matDialog.open(LongRunningJobComponent, {
      hasBackdrop: true,
      width: '305px',
      height: '250px',
    });

    dialogDef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        dialogDef.close();
      });

    dialogDef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => (element.disabled = false));

    dialogDef.componentInstance.navigateToLRJPage.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.options.map((option) => (option.isSelected = false));
      this.isSelectedLRJPage = true;
      dialogDef.close();
    });

    const dialogElement: HTMLCollectionOf<any> = document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].style.visibility = 'hidden';
    setTimeout(() => {
      this.dialogHeight = dialogElement[0].offsetHeight;
      const coordinates = this.getCoordinatesForLrj(element);

      dialogElement[0].classList.add('custom-dialog');
      dialogElement[0].classList.add('sub-menu-dialog');
      dialogElement[0].style.position = 'absolute';
      dialogElement[0].style.padding = '0px';
      dialogElement[0].style.top = coordinates.top;
      dialogElement[0].style.left = coordinates.left;
      dialogElement[0].style.width = this.dialogWidth + 'px';
      dialogElement[0].style.height = 'auto';
      dialogElement[0].style.maxHeight = '250px';
      dialogElement[0].style.width = '305px';
      dialogElement[0].style.visibility = 'visible';
    }, 100);
  }

  getUserLrjReadAccessParams() {
    const params = {
      action: AuthorizationActionEnum.read,
      source: AuthorizationSourceEnum.lrj,
      domainType: AuthorizationDomainTypes.Global,
    };
    return JSON.stringify(params);
  }

  navigateToSettingsPage(selectedSubComp: number) {
    this.options.map((choice) => (choice.isSelected = false));
    const settingsOptionIndex = this.options.findIndex((option) => option.navigationUrl.includes('settings'));
    if (settingsOptionIndex !== -1) {
      this.options[settingsOptionIndex].isSelected = true;
    }
    this.isSelectedLRJPage = false;
    this.setSelectedSubComp('settings', selectedSubComp);
    this.router.navigate(['settings', selectedSubComp]);
  }

  navigateToTestPage(pagePath: string, selectedSubComp: TestListsPageOptions) {
    this.options.map((choice) => (choice.isSelected = false));
    const testsOptionIndex = this.options.findIndex((option) => option.navigationUrl.includes('tests'));
    if (testsOptionIndex !== -1) {
      this.options[testsOptionIndex].isSelected = true;
    }
    this.isSelectedLRJPage = false;
    this.setSelectedSubComp('tests', selectedSubComp, pagePath);
    this.router.navigate([pagePath !== '' ? `tests/${pagePath}` : 'tests'], {
      queryParams: { selected: selectedSubComp },
    });
  }

  mobileNavigateToSettingsPage(selectedSubComp: number) {
    this.options.map((choice) => (choice.isSelected = false));
    const settingsOptionIndex = this.options.findIndex((option) => option.navigationUrl.includes('settings'));
    if (settingsOptionIndex !== -1) {
      this.options[settingsOptionIndex].isSelected = true;
    }
    this.isSelectedLRJPage = false;
    this.setSelectedSubComp('settings', selectedSubComp);
    this.router.navigate(['settings', selectedSubComp]);
    this.leftMenuService.updateLeftMenuState(false);
  }

  mobileNavigateToTestPage(pagePath: string, selectedSubComp: TestListsPageOptions) {
    this.options.map((choice) => (choice.isSelected = false));
    const testsOptionIndex = this.options.findIndex((option) => option.navigationUrl.includes('tests'));
    if (testsOptionIndex !== -1) {
      this.options[testsOptionIndex].isSelected = true;
    }
    this.isSelectedLRJPage = false;
    this.setSelectedSubComp('tests', selectedSubComp, pagePath);
    this.router.navigate([pagePath !== '' ? `tests/${pagePath}` : 'tests'], {
      queryParams: { selected: selectedSubComp },
    });
    this.leftMenuService.updateLeftMenuState(false);
  }

  switchTheme(): void {
    document.body.classList.toggle('dark');
    this.selectedTheme = document.body.classList.contains('dark') ? Theme.Dark : Theme.Light;
    this.themeService.theme = this.selectedTheme;
  }

  logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('email');
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
    localStorage.removeItem('isOnboardingCompleted');
    localStorage.removeItem('policies');
    localStorage.removeItem('accessibleData');
    this.router.navigate(['/login']);
  }

  private setSelectedSubComp(parent: 'tests' | 'settings', selectedSubComp: number, pagePath?: string) {
    if (parent === 'settings') {
      this.subComponentsOfSettings.forEach((subComponentsOfSetting) => {
        subComponentsOfSetting.subComponents.forEach((subComponent) => {
          subComponent.isSelected = subComponent.subComp === selectedSubComp;
        });
      });
    }

    if (parent === 'tests') {
      this.subComponentsOfTests.forEach((subComponentsOfTest) => {
        subComponentsOfTest.subComponents.forEach((subComponent) => {
          subComponent.isSelected =
            (subComponentsOfTest.pagePath === pagePath || (!pagePath && subComponentsOfTest.pagePath === '')) &&
            subComponent.subComp === selectedSubComp;
        });
      });
    }
  }

  private clearAllSelectedSubComp() {
    this.subComponentsOfSettings.forEach((subComponentsOfSetting) => {
      subComponentsOfSetting.subComponents.forEach((subComponent) => {
        subComponent.isSelected = false;
      });
    });
  }

  private isSelectedSettings(url: string): boolean {
    return url.includes('settings');
  }

  private isSelectedTests(url: string): boolean {
    return url.includes('tests');
  }

  private setTheme() {
    this.themeService.selectedTheme
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedTheme) => (this.selectedTheme = selectedTheme));
  }

  private setOptions() {
    const hasUserPlanDefReadAccessParams = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.plandef,
      AuthorizationActionEnum.read,
    );
    const hasUserResourceDefinitionReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.resourcedef,
      AuthorizationActionEnum.read,
    );
    const hasUserResourceReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.resource,
      AuthorizationActionEnum.read,
    );

    const labsPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.lab,
      AuthorizationActionEnum.read,
    );

    const hasUserTestReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.test,
      AuthorizationActionEnum.read,
    );

    const hasUserPlannedTestReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.plan,
      AuthorizationActionEnum.read,
    );

    const hasUserPlanExtensionReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.planextension,
      AuthorizationActionEnum.read,
    );

    const hasUserArchivedTestReadAccess = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.testView,
      AuthorizationActionEnum.read,
    );

    if (
      hasUserPlanDefReadAccessParams ||
      hasUserResourceDefinitionReadAccess ||
      labsPermission ||
      hasUserTestReadAccess ||
      hasUserPlannedTestReadAccess ||
      hasUserPlanExtensionReadAccess
    ) {
      const dashboardMenu = this.getDashboardMenu();
      if (dashboardMenu) {
        this.options.push(dashboardMenu);
      }
    }

    const roomMenu = this.getRoomMenu();
    if (roomMenu && labsPermission) {
      this.options.push(roomMenu);
    }

    if (hasUserTestReadAccess) {
      const testMenu = this.getTestMenu();
      if (testMenu) {
        this.options.push(testMenu);
      }
      // TO DO: auth check
      // const activeTestsMenu = this.getActiveTestsMenu();
      // if (activeTestsMenu) {
      //   this.options.push(activeTestsMenu);
      // }
      // const pastTestsMenu = this.getPastTestsMenu();
      // if (pastTestsMenu) {
      //   this.options.push(pastTestsMenu);
      // }
      // const archivedTestsMenu = this.getArchivedTestsMenu();
      // if (archivedTestsMenu) {
      //   this.options.push(archivedTestsMenu);
      // }
    }

    if (hasUserResourceDefinitionReadAccess && hasUserResourceReadAccess) {
      const resourceMenu = this.getResourceMenu();
      if (resourceMenu) {
        this.options.push(resourceMenu);
      }
    }

    this.getIsGateControlModuleActive();
    if (environment.externalInventoryUrl) {
      this.options.push(this.getInventoryMenu());
    }

    const settingsMenu = this.getSettingsMenu();
    if (settingsMenu) {
      this.options.push(settingsMenu);
    }
  }

  private setActiveTestsSubComps() {
    this.subComponentsOfTests[0].subComponents.push({
      subComp: TestListsPageOptions.ActiveTests,
      title: this.activeTestsText,
      isSelected: false,
    });

    this.subComponentsOfTests[0].subComponents.push({
      subComp: TestListsPageOptions.PlannedTests,
      title: this.plannedTestsText,
      isSelected: false,
    });
  }

  private setPastTestsSubComps() {
    this.subComponentsOfTests[1].subComponents.push({
      subComp: TestListsPageOptions.Export,
      title: this.exportText,
      isSelected: false,
    });

    this.subComponentsOfTests[1].subComponents.push({
      subComp: TestListsPageOptions.Import,
      title: this.importText,
      isSelected: false,
    });
  }

  private setArchivedTestsSubComps() {
    this.subComponentsOfTests[2].subComponents.push({
      subComp: TestListsPageOptions.LiveReports,
      title: this.liveReportsText,
      isSelected: false,
    });

    this.subComponentsOfTests[2].subComponents.push({
      subComp: TestListsPageOptions.FinishedTests,
      title: this.finishedTestsText,
      isSelected: false,
    });
  }

  private setUserSettingSubComps() {
    const getLabRoleRequestPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.labRoleRequest,
      AuthorizationActionEnum.read,
    );

    this.subComponentsOfSettings[0].subComponents.push({
      subComp: SettingsPageOptions.Language,
      title: this.languageSettingsText,
      isSelected: false,
    });

    if (getLabRoleRequestPermission) {
      this.subComponentsOfSettings[0].subComponents.push({
        subComp: SettingsPageOptions.OnboardingRequests,
        title: this.onboardingRequestsText,
        isSelected: false,
      });
    }

    const sensorPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.sensor,
      AuthorizationActionEnum.read,
    );

    if (sensorPermission) {
      this.subComponentsOfSettings[0].subComponents.push({
        subComp: SettingsPageOptions.SensorSettings,
        title: this.sensorSettingsText,
        isSelected: false,
      });
    }

    const organizationPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.organization,
      AuthorizationActionEnum.read,
    );
    if (organizationPermission) {
      this.subComponentsOfSettings[0].subComponents.push({
        subComp: SettingsPageOptions.OrgSchema,
        title: this.organizationSchemaText,
        isSelected: false,
      });
    }

    const authRoleDefinitionPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.authRoleDefinition,
      AuthorizationActionEnum.read,
    );
    const authPolicyPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.authPolicy,
      AuthorizationActionEnum.read,
    );
    const authSourcePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.authSource,
      AuthorizationActionEnum.read,
    );
    if (authRoleDefinitionPermission || authPolicyPermission || authSourcePermission) {
      this.subComponentsOfSettings[0].subComponents.push({
        subComp: SettingsPageOptions.StaffManagement,
        title: this.authorityManagementText,
        isSelected: false,
      });
    }

    const notificationConditionPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.notificationCondition,
      AuthorizationActionEnum.read,
    );
    if (notificationConditionPermission) {
      this.subComponentsOfSettings[0].subComponents.push({
        subComp: SettingsPageOptions.NotificationParams,
        title: this.notificationParamsText,
        isSelected: false,
      });
    }
  }

  private setSystemSettingsSubComps() {
    if (this.resourceDefPermission()) {
      this.subComponentsOfSettings[1].subComponents.push({
        subComp: SettingsPageOptions.ResourceDefinition,
        title: this.resourceDefinitionText,
        isSelected: false,
      });
    }

    const testTypeReadPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.testType,
      AuthorizationActionEnum.read,
    );
    const testTypeAddPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.testType,
      AuthorizationActionEnum.create,
    );
    if (testTypeReadPermission || testTypeAddPermission) {
      this.subComponentsOfSettings[1].subComponents.push({
        subComp: SettingsPageOptions.AddTestType,
        title: this.addTestType,
        isSelected: false,
      });
    }

    const getSampleRatePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.testSampleTime,
      AuthorizationActionEnum.read,
    );
    const addSampleRatePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.testSampleTime,
      AuthorizationActionEnum.create,
    );
    if (getSampleRatePermission || addSampleRatePermission) {
      this.subComponentsOfSettings[1].subComponents.push({
        subComp: SettingsPageOptions.SampleRateOperations,
        title: this.sampleRateText,
        isSelected: false,
      });
    }

    const getAnalogScalePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.analogScale,
      AuthorizationActionEnum.read,
    );
    const addAnalogScalePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.analogScale,
      AuthorizationActionEnum.create,
    );

    if (getAnalogScalePermission || addAnalogScalePermission) {
      this.subComponentsOfSettings[1].subComponents.push({
        subComp: SettingsPageOptions.AnalogScale,
        title: this.analogScaleText,
        isSelected: false,
      });
    }

    // TODO: will be add if check for analog scale permissions
    this.subComponentsOfSettings[1].subComponents.push({
      subComp: SettingsPageOptions.AnalogSensor,
      title: this.analogSensorsText,
      isSelected: false,
    });

    // TODO: will be add if check for units permissions
    this.subComponentsOfSettings[1].subComponents.push({
      subComp: SettingsPageOptions.Units,
      title: this.unitsText,
      isSelected: false,
    });

    const getIniFilePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.inifile,
      AuthorizationActionEnum.read,
    );
    if (getIniFilePermission) {
      this.subComponentsOfSettings[1].subComponents.push({
        subComp: SettingsPageOptions.IniFiles,
        title: this.iniFilesText,
        isSelected: false,
      });
    }

    this.subComponentsOfSettings[1].subComponents.push({
      subComp: SettingsPageOptions.GraphSettings,
      title: this.graphSettingsText,
      isSelected: false,
    });

    this.subComponentsOfSettings[1].subComponents.push({
      subComp: SettingsPageOptions.Versions,
      title: this.versionsText,
      isSelected: false,
    });
  }

  private setCalibrationSettingsSubComps() {
    const getCalibrationViewPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.calibrationView,
      AuthorizationActionEnum.read,
    );

    const getFinishedCalibrationPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.finishedCalibration,
      AuthorizationActionEnum.read,
    );

    if (getCalibrationViewPermission) {
      this.subComponentsOfSettings[2].subComponents.push({
        subComp: SettingsPageOptions.Calibration,
        title: this.runningCalibrationsText,
        isSelected: false,
      });
    }

    if (getFinishedCalibrationPermission) {
      this.subComponentsOfSettings[2].subComponents.push({
        subComp: SettingsPageOptions.PreviousCalibration,
        title: this.previousCalibrationsText,
        isSelected: false,
      });
    }
  }

  private checkSubComponentsOfSettings() {
    if (!this.isCalibrationSettingsHasSubComp()) {
      this.subComponentsOfSettings.splice(2, 1);
    }

    if (!this.isSystemSettingsHasSubComp()) {
      this.subComponentsOfSettings.splice(1, 1);
    }
  }

  private isSystemSettingsHasSubComp(): boolean {
    return this.subComponentsOfSettings[1].subComponents.length > 0;
  }

  private isCalibrationSettingsHasSubComp(): boolean {
    return this.subComponentsOfSettings[2].subComponents.length > 0;
  }

  private getCoordinatesForLrj(element: any): { top: string; left: string } {
    let top;
    let left;
    const rect = element.getBoundingClientRect();

    top = `${rect.top - this.dialogHeight / 2 + rect.height / 2 - this.dialogHeight / 4 - 30}px`;
    left = `${rect.right + this.bufferSpace}px`;

    return { top, left };
  }

  private getCoordinates(element: any): { top: string; left: string } {
    let top;
    let left;
    const rect = element.getBoundingClientRect();

    top = `${rect.top - this.dialogHeight / 2 + rect.height / 2 + 50}px`;
    left = `${rect.right + this.bufferSpace}px`;

    return { top, left };
  }

  private setInitials(): void {
    this.initials = this.userName
      ?.split(' ')
      .map((name) => name[0].toUpperCase())
      .join('');
  }

  private getDashboardMenu(): Options {
    return {
      text: this.dashboardText,
      icon: 'dashboard',
      navigationUrl: 'dashboard',
      isSelected: true,
    };
  }

  private getTestMenu(): Options {
    return {
      text: this.testsText,
      icon: 'playlist_add_check',
      navigationUrl: 'tests',
      isSelected: false,
    };
  }

  private getRoomMenu(): Options {
    return {
      text: this.roomsText,
      icon: 'meeting_room',
      navigationUrl: 'rooms',
      isSelected: false,
    };
  }

  private getResourceMenu(): Options {
    return {
      text: this.resourcesText,
      icon: 'kitchen',
      navigationUrl: 'resources',
      isSelected: false,
    };
  }

  private getGateControlMenu(): Options {
    return {
      text: this.gateControlText,
      icon: 'tune',
      navigationUrl: 'gate-control',
      isSelected: false,
    };
  }

  private getSettingsMenu(): Options {
    return {
      text: this.settingsText,
      icon: 'settings',
      navigationUrl: 'settings/' + SettingsPageOptions.Language,
      isSelected: false,
    };
  }

  private getInventoryMenu(): Options {
    return {
      text: this.inventoryText,
      icon: 'inventory',
      navigationUrl: '',
      isSelected: false,
      action: () => {
        const url = new URL(environment.externalInventoryUrl);
        url.searchParams.append('token', localStorage.getItem('token')!);
        url.searchParams.append('id', localStorage.getItem('userId')!);
        url.searchParams.append('email', localStorage.getItem('email')!);
        url.searchParams.append('name', localStorage.getItem('userName')!);
        window.open(url.href, '_blank');
      },
    };
  }

  private specifySelectedUrlOnLeftMenu() {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params) => {
        const navigatedUrl = params.url.split('/')[1];
        if (navigatedUrl === 'long-running-jobs') {
          this.options.map((option) => (option.isSelected = false));
          this.isSelectedLRJPage = true;
          this.isSettingsPageSelected = false;
          this.isTestPageSelected = false;
        } else if (navigatedUrl === 'notifications') {
          this.options.map((option) => (option.isSelected = false));
          this.isSelectedLRJPage = false;
          this.isSettingsPageSelected = false;
          this.isTestPageSelected = false;
        } else if (this.isOnTestPage(navigatedUrl)) {
          const index = this.options.findIndex((option) => option.navigationUrl === 'tests');
          this.options.map((option) => (option.isSelected = false));
          this.options[index].isSelected = true;
          this.isTestPageSelected = true;
          this.isSelectedLRJPage = false;
          this.isSettingsPageSelected = false;
        } else {
          const index = this.options.findIndex((option) => option.navigationUrl.includes(navigatedUrl));
          if (index !== -1) {
            this.options.map((option) => (option.isSelected = false));
            this.options[index].isSelected = true;
            this.isSettingsPageSelected = this.isOnSettingsPage(this.options[index]);
          }
          this.isSelectedLRJPage = false;
          this.isTestPageSelected = false;
        }
      });
  }

  private isOnTestPage(navigatedUrl: string): boolean {
    return navigatedUrl.includes('tests'.toLocaleLowerCase());
  }

  private resourceDefPermission(): boolean {
    const resourceDefReadPermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.resourcedef,
      AuthorizationActionEnum.read,
    );
    const attdefCreatePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.attdef,
      AuthorizationActionEnum.create,
    );
    const attdefUpdatePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.attdef,
      AuthorizationActionEnum.update,
    );
    const resourceDefCreatePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.resourcedef,
      AuthorizationActionEnum.create,
    );
    const tmsreldefCreatePermission = this.permissionControlService.isActiveUserHasAuthorization(
      AuthorizationDomainTypes.Global,
      null,
      AuthorizationSourceEnum.tmsreldef,
      AuthorizationActionEnum.create,
    );
    return resourceDefReadPermission ||
      attdefCreatePermission ||
      attdefUpdatePermission ||
      resourceDefCreatePermission ||
      tmsreldefCreatePermission
      ? true
      : false;
  }

  private isOnSettingsPage(selectedOption: Options): boolean {
    return selectedOption.navigationUrl.includes('settings');
  }

  private listenLeftMenuStateChanges() {
    this.leftMenuService.leftMenuState.pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
      this.isOpen = state;
      state ? (this.zIndex = '1000') : (this.zIndex = '2');
    });
  }

  private getIsGateControlModuleActive() {
    this.shellService
      .isModuleActive([{ moduleId: ModuleEnum.DeviceDoorControl }])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if (response.find((module) => module.moduleId === ModuleEnum.DeviceDoorControl)?.isActive) {
          let settingsOptionIndex = this.options.findIndex((option) => option.icon === 'settings');
          if (settingsOptionIndex !== -1) {
            this.options.splice(settingsOptionIndex, 0, this.getGateControlMenu());
          } else {
            this.options.push(this.getGateControlMenu());
          }
        }
      });
  }
}
