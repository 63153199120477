import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const baseUrl = environment.complianceServicePath;

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  token: string | null = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  getAllCalculationsWithoutAttributes(): Observable<any> {
    return this.http.get(`${baseUrl}/calculation/getAllCalculationsWithoutAttributes`, {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    });
  }

  getCalculationWithAttributesById(id: number): Observable<any> {
    return this.http.post(
      `${baseUrl}/calculation/getCalculationWithAttributesById`,
      { id },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  getAllCalculationComponentWithAttribute(): Observable<any> {
    return this.http.get(`${baseUrl}/calculationComponent/getAllCalculationComponentWithAttribute`, {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    });
  }

  calculate(infos: any): Observable<any> {
    return this.http.post(`${baseUrl}/energyCalculation/calculate`, infos, {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    });
  }
}
