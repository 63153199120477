import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Laboratory, Language, Role } from 'src/app/core/models';
import { ShellService } from 'src/app/core/services';
import { OnboardingStatus, Theme } from 'src/app/shared/enums';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { FormValidatorService } from '../../services';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnDestroy {
  userName: string;
  roles: Role[] = [];
  languages: Language[] = [];
  allLabs: (Laboratory & { isSelected: boolean })[] = [];
  isEditMode = false;
  editRequestId: number | undefined = undefined;

  onboardingForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, this.formValidatorService.noWhitespaceValidator]],
    role: ['', [Validators.required]],
    language: ['', [Validators.required]],
    lab: ['', [Validators.required]],
  });

  selectedTheme: any;

  currentOnboardingStatus: OnboardingStatus = OnboardingStatus.FirstRequest;

  private unsubscribe = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private themeService: ThemeService,
    private router: Router,
    private shellService: ShellService,
    private activatedRoute: ActivatedRoute,
    private formValidatorService: FormValidatorService
  ) {
    this.themeService.selectedTheme
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedTheme) => (this.selectedTheme = selectedTheme));

    this.shellService
      .getLabRoleRequestFormData()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: { labs: any[]; languages: any[]; roles: any[] }) => {
        this.allLabs = resp.labs;
        this.languages = resp.languages;
        this.roles = resp.roles;
      });

    this.determineOnboardingStatus();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get theme(): any {
    return Theme;
  }

  get onboardingStatus(): any {
    return OnboardingStatus;
  }

  getErrorClass(form: FormGroup, field: string): string {
    return this.formValidatorService.getErrorClass(form, field);
  }

  isRequestReady(): boolean {
    if (this.isEditMode) {
      return this.onboardingForm.valid && this.editRequestId !== undefined;
    } else {
      return this.onboardingForm.valid;
    }
  }

  editRequest() {
    this.isEditMode = true;
    this.currentOnboardingStatus = OnboardingStatus.FirstRequest;
  }

  createLabRoleRequest() {
    const { role, language, lab } = this.onboardingForm.value;
    const userId: string = localStorage.getItem('userId') as any;
    this.shellService
      .createLabRoleRequest(+userId, role, lab, language)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.currentOnboardingStatus = OnboardingStatus.Success;
      });
  }

  updateLabRoleRequest() {
    const { role, language, lab } = this.onboardingForm.value;
    if (this.editRequestId) {
      this.shellService
        .updateLabRoleRequest(this.editRequestId, role, lab, language)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.currentOnboardingStatus = OnboardingStatus.Success;
        });
    }
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('email');
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
    localStorage.removeItem('isOnboardingCompleted');
    localStorage.removeItem('policies');
    localStorage.removeItem('accessibleData');
    this.router.navigate(['/login']);
  }

  private determineOnboardingStatus() {
    const type = JSON.parse(this.activatedRoute.snapshot.queryParams.type);
    const user = JSON.parse(this.activatedRoute.snapshot.queryParams.user);
    this.userName = user.name;

    if (+type === OnboardingStatus.FirstRequest) {
      this.currentOnboardingStatus = OnboardingStatus.FirstRequest;
    } else if (+type === OnboardingStatus.Pending) {
      const req = JSON.parse(this.activatedRoute.snapshot.queryParams.req);
      this.editRequestId = req[0]?.id;
      this.onboardingForm.setValue({
        name: user?.name,
        role: req[0]?.role_id,
        language: req[0]?.language?.id,
        lab: req[0]?.lab?.id,
      });
      this.currentOnboardingStatus = OnboardingStatus.Pending;
    }
  }
}
