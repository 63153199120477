import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class OnBoardingCompleteService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const onBoarding = JSON.parse(localStorage.getItem('isOnboardingCompleted') as string);

    // Its checking null,undefined, 0 or false
    return !!onBoarding ? true : this.redirectToLogin();
  }

  private redirectToLogin() {
    this.router.navigate(['/login']);
    return false;
  }
}
