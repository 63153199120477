import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryService {
  private readonly baseURI = 'api/laboratory';

  constructor(private http: HttpClient) {}

  getAllLaboratorysByOrganizationId(organizationId: number, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/getAllLaboratorysByOrganizationId`, {});
  }

  getAllLaboratorysByOrganizationIdForAuth(organizationId: string, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/getAllLaboratorysByOrganizationIdForAuth`, {});
  }
  getLaboratorysAndDivisionsAndUsers(organizationId: string, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/getLaboratorysAndDivisionsAndUsers`, {});
  }
  addLaboratory(request: any, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/addLaboratory`, {});
  }

  updateLaboratory(request: any, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/updateLaboratory`, {});
  }

  deleteLaboratory(laboratoryId: string, userId: string): Observable<any> {
    return this.http.post(`${this.baseURI}/deleteLaboratory`, {});
  }

  addLaboratoryMember(request: any, userId: string) {
    return this.http.post(`${this.baseURI}/addLaboratoryMember`, {});
  }

  deleteLaboratoryMember(deleteUserId: string, laboratoryId: any, userId: string) {
    return this.http.post(`${this.baseURI}/deleteLaboratoryMember`, {});
  }
}
