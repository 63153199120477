import {
  TestCreateEditMenuTestSampleUnit,
  XAxisTextFormatType,
} from 'src/app/shared/enums';
import { LoginType } from 'src/app/shared/enums/login-type.enum';

export const environment = {
  production: false,
  location: 'localmaster',
  oktaRedirectUri: 'https://rls.rota.net/login/okta/callback',
  csvServicePath: 'https://rls.rota.net/api/csv',
  dataServicePath: 'https://rls.rota.net/api/data',
  testManagementServicePath: 'https://rls.rota.net/api/tms',
  resourceManagementServicePath: 'https://rls.rota.net/api/rms',
  shellServicePath: 'https://rls.rota.net/api/shell',
  shellServiceWsPath: 'wss://rls.rota.net/api',
  statisticServicePath: 'https://rls.rota.net/api/statisticService',
  reportingServicePath: 'https://rls.rota.net/api/reporting',
  distServiceWsPath: 'wss://rls.rota.net:443',
  plcControl: 'https://rls.rota.net:5007/api',
  cacheFillerServicePath: 'https://rls.rota.net/api/cache-filler',
  complianceServicePath: 'https://rls.rota.net/api/compliance',
  inventoryServicePath: 'https://rlsebi.arcelik.com:5013',
  crmPortNumber: '3333',
  loginType: LoginType.Custom,
  gateControlIterationCountLimit: 1000,
  selectedRelativeDateIndex: 1,
  isShowActualVariacVoltage: true,
  hasVariac: false,
  isGetProductByIndexAtBookATestMenu: false,
  variacOffset: 0,
  externalInventoryUrl: 'https://inventory.rota.net/',
  deviceTagAttributeId: 17,
  canDeleteTestData: true,
  isShowTestInfoOnTestViewChannelList: true,
  xAxisTextFormatType: XAxisTextFormatType.DATE,
  isVirtualChannelCalculationTypeOnlyAverage: false,
  hasNewTestListsView: false,
  showStationPLCVoltageSetInputPlaceHolder: false,
  testCreateEditMenuTestSampleUnit: TestCreateEditMenuTestSampleUnit.S,
};
