export enum ServicesEnum {
  Shell = 'shell',
  Tms = 'tms',
  Rms = 'rms',
  Plc = 'plc',
  Reporting = 'reporting',
  Ccms = 'ccms',
  Data = 'data',
  Dist = 'dist',
}
