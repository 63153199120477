import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gInit',
})
export class GetInitialsPipe implements PipeTransform {
  transform(value: string) {
    return value.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
  }
}
