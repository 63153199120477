import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  FeedbackService,
  FeedbackSizes,
  FeedbackTypes,
} from '@rotateknik/rt-std-wc';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { RmsTable, ThirdPartyComponentTypes } from 'src/app/core/enums';
import { AuthorizationActionEnum } from 'src/app/core/enums/authorization-action.enum';
import { AuthorizationSourceEnum } from 'src/app/core/enums/authorization-source.enum';
import { AuthorizationDomainTypes } from 'src/app/core/enums/domain-type.enum';
import {
  EnergyClass,
  Sensor,
  Test,
  TestSampleTime,
  TestType,
  TmsRelationDefinition,
  Trace,
  UdaqSensor,
} from 'src/app/core/models';
import { AnalogScale } from 'src/app/core/models/analog-scale.model';
import { IniFiles } from 'src/app/core/models/ini-files.model';
import { Resources } from 'src/app/core/models/resources.model';
import { SensorGroup } from 'src/app/core/models/sensor-group.model';
import { SensorTemplateInterface } from 'src/app/core/models/sensor-template.model';
import {
  DataService,
  LanguageService,
  ResourceManagementService,
  ShellService,
  TestManagementService,
} from 'src/app/core/services';
import {
  AttributeDataType,
  ResourceType,
  TestCreateEditMenuMode,
  TestCreateEditMenuTestSampleUnit,
  Theme,
} from 'src/app/shared/enums';
import {
  CreateTest,
  PlannedTestEvent,
  ResourceDefinition,
  TestCreateTrace,
  UdaqTest,
  UpdateTest,
  UpsertSensorTemplatePayload,
} from 'src/app/shared/interfaces';
import {
  FormValidatorService,
  PermissionControlService,
  ThemeService,
  UtilService,
} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../../dialogs';
import { TranslatePipe } from '../../pipes';
import { DeleteSensorConfirmationDialogComponent } from './delete-sensor-confirmation-dialog/delete-sensor-confirmation-dialog.component';
import { SaveAsTemplateDialogComponent } from './save-as-template-dialog/save-as-template-dialog.component';
import { TestStartedDialogComponent } from './test-started-dialog/test-started-dialog.component';

enum ChannelSelectionOptions {
  OpenChannels,
  Templates,
  AnalogScale,
  OtherSources,
}

interface PeriodicElement {
  id?: number;
  code: string;
  name: string;
  liveValue: number;
  unit: string;
  channelDescId: number;
  station_id?: number;
  sortIndex: number;
}

interface AnalogScaleTableElement {
  id: number;
  name: string;
  code: string;
  analogScaleId: number;
}

enum TabOptions {
  Default = 0,
  Group = 1,
}

@Component({
  selector: 'app-test-create-edit-menu',
  templateUrl: './test-create-edit-menu.component.html',
  styleUrls: ['./test-create-edit-menu.component.scss'],
})
export class TestCreateEditMenuComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() mode: TestCreateEditMenuMode;
  @Input() set setTestData(testData: Test) {
    this.testData = testData;
    const sensorIds =
      this.testData?.traces.map((trace) => trace.sensor_id) ?? [];
    const userSensorReadPermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.sensor,
        AuthorizationActionEnum.read,
      );
    if (userSensorReadPermission) {
      this.testManagementService
        .getSensorsWithSensorId(sensorIds)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((sensors: Sensor[]) => {
          this.resourceManagementService
            .getChildDetailsByParentId(this.roomId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((childDetails) => {
              const stationIds = childDetails?.map(
                (childDetail) => childDetail.id,
              );

              this.shellService
                .getSensorsWithRelatedResourceIds(stationIds)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                  (
                    sensorsWithResourceId: {
                      resourceId: number;
                      sensors: Sensor[];
                    }[],
                  ) => {
                    this.sensorsOfSavedTest = sensors.map((sensor: Sensor) => {
                      const stationId =
                        sensorsWithResourceId.find((item) =>
                          item.sensors.find((s) => s.id === sensor.id),
                        )?.resourceId ?? 0;

                      const stationName =
                        childDetails.find((item) => item.id === stationId)
                          ?.name ?? '';

                      return {
                        ...sensor,
                        station_id: stationId,
                        stationName,
                        unChangeableChannelCodeText:
                          sensor.channelDescription?.description,
                      };
                    });
                    this.getOnlySelectedStationSensors();
                  },
                );
            });
        });
    }
  }
  @Input() set setStationId(stationId: number) {
    this.stationId = stationId;
    const userSensorReadPermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.sensor,
        AuthorizationActionEnum.read,
      );
    if (stationId) {
      if (userSensorReadPermission) {
        this.shellService
          .getUdaqSensorWithRelatedResourceId(this.stationId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((udaqSensor: UdaqSensor) => {
            if (udaqSensor.hasUdaq) {
              this.udaqSensorId = udaqSensor.id;
            }
          });
      }
    }
  }
  @Input() set setRoomId(roomId: number) {
    this.roomId = roomId;
  }

  @Input() set setTestInfoForm(e: PlannedTestEvent) {
    if (e) {
      this.testData = {
        station_id: e.stationId,
        station_name: e.stationName,
        testType: { id: e.testTypeId, name: e.testTypeName },
        device_id: e.deviceId,
        test_type_id: e.testTypeId,
        name: '',
        device: { id: e.deviceId, name: e.deviceName },
        description: '',
        sample_time_id: 1,
        lab_id: e.labId,
        room_name: e.roomName,
        lab_name: e.labName,
      } as any;
    }
  }
  @Input() plannedId: number;
  @Input() stationName: string | undefined;
  @Input() udaqTests: UdaqTest[];

  @Output() closeMenu: EventEmitter<any> = new EventEmitter();
  @Output() testCreated: EventEmitter<{
    test: Test;
    testTypeName: string;
    selectedDevice?: { id: number; name: string };
  }> = new EventEmitter();
  @Output() powerThresholdChange = new EventEmitter<void>();

  @ViewChild('deviceInput') deviceInput: ElementRef;
  @ViewChild('resourceDefinitionElement') resourceDefinition: ElementRef;

  testData: Test;
  stationId: number;
  stationsOfRoom: { id: number; name: string }[];
  roomId: number;
  testParamsForm: FormGroup = this.formBuilder.group({
    testName: [
      '',
      [Validators.required, this.formValidatorService.noWhitespaceValidator],
    ],
    testType: ['', [Validators.required]],
    sampleTime: ['', [Validators.required]],
    description: [
      '',
      [Validators.required, this.formValidatorService.noWhitespaceValidator],
    ],
  });

  specimenParametersForm: FormGroup = this.formBuilder.group({});

  specimenParameters: TmsRelationDefinition['resource_definitions'][0]['resource_attribute_definitions'] =
    [];

  sensorFilterRequestReceiver = new Subject();
  sensorFilterInput = new FormControl();

  templateFilterInput = new FormControl();
  templateFilterText = '';

  testTypes: TestType[] = [];

  analogScales: AnalogScale[] = [];

  energyClasses: EnergyClass[] = [];

  sampleTimes: TestSampleTime[] = [];

  secondsText = new TranslatePipe(this.languageService).transform(
    20554,
    'Seconds',
  );

  millisecondsText = new TranslatePipe(this.languageService).transform(
    20555,
    'Milliseconds',
  );

  private cancelText = new TranslatePipe(this.languageService).transform(
    109,
    'Cancel',
  );
  private openChannelsText = new TranslatePipe(this.languageService).transform(
    '90',
    'Available Channels',
  );
  private analogueSelectionText = new TranslatePipe(
    this.languageService,
  ).transform('20072', 'Analog Scaling');
  private otherSourcesText = new TranslatePipe(this.languageService).transform(
    20305,
    'Other Sources',
  );
  private templatesText = new TranslatePipe(this.languageService).transform(
    '561',
    'Templates',
  );
  private groupedText = new TranslatePipe(this.languageService).transform(
    '20247',
    'Grouped',
  );
  private ungroupedText = new TranslatePipe(this.languageService).transform(
    '20248',
    'Ungrouped',
  );
  private addIniFileText = new TranslatePipe(this.languageService).transform(
    20330,
    'Add the ini file(s) to the system',
  );
  private canNotReadFridgeText = new TranslatePipe(
    this.languageService,
  ).transform(
    20329,
    'Udaq communication failed please check the cable and make sure the test specimen is powered on.',
  );
  private iniFilesFoundText = new TranslatePipe(this.languageService).transform(
    284,
    'Ini files found udaq can be recorded.',
  );

  channelSelectionOptions = [
    {
      id: ChannelSelectionOptions.OpenChannels,
      text: this.openChannelsText,
    },
    {
      id: ChannelSelectionOptions.AnalogScale,
      text: this.analogueSelectionText,
    },
  ];
  selectedChannelOption = ChannelSelectionOptions.OpenChannels;

  model: string | any = '-';
  responsiblePerson: string = '-';
  acquisitionReason: string = '-';
  hasDevice: boolean;

  sensorsOfSavedTest: Sensor[] = [];

  ungroupedSensorsForSelectedStation: Sensor[] = [];
  filteredUngroupedSensorsForSelectedStation: Sensor[] = [];

  groupedSensorsForSelectedStation: SensorGroup[] = [];
  filteredGroupedSensorsForSelectedStation: SensorGroup[] = [];

  ungroupedSensorsForAllStationsExceptSelected: Sensor[] = [];
  filteredUngroupedSensorsForAllStationsExceptSelected: Sensor[] = [];

  groupedSensorsForAllStationsExceptSelected: SensorGroup[] = [];
  filteredGroupedSensorsForAllStationsExceptSelected: SensorGroup[] = [];

  selectedAvailableChannel: any;

  displayedColumns: string[] = ['code', 'name', 'liveValue', 'unit', 'actions'];
  tableDataSource = new MatTableDataSource<PeriodicElement>([]);
  availableStationCount = 0;
  selection = new SelectionModel<PeriodicElement>(false, []);

  displayedAnalogColumns: string[] = ['code', 'name', 'analogScaleId'];
  analogScaleTableDataSource: any =
    new MatTableDataSource<AnalogScaleTableElement>([]);
  analogScaleSelection = new SelectionModel<AnalogScaleTableElement>(false, []);

  channelsDetails: any[] = [];

  selectedTemplate: any;

  isPanelOpened: boolean;

  iniFileName1 = '-';
  iniFileName2 = '-';
  iniFileId1: number = -1;
  iniFileId2: number = -1;

  hasIniFiles: boolean;
  isMakingIniCheckQuery: boolean;

  selectedSensors: Sensor[] = [];
  noLiveValueSensors: Sensor[] = [];

  userId: number;

  devices: any[] = [];
  deviceId: number;
  selectedDevice: any;
  filteredDevices: any[] = [];

  isUdaqRecord: boolean = false;
  udaqSensorId: number = -1;

  resourceDefinitions: ResourceDefinition[] = [];
  relationDefinitionId: number;
  deviceRelationDefinitionId: number;

  loaderPath = '/assets/RLS_LightLoader.json';
  isDarkMode = false;

  isCreateTestQuery = false;

  sensorTabOptions = [
    { id: TabOptions.Default, text: this.ungroupedText },
    { id: TabOptions.Group, text: this.groupedText },
  ];
  selectedSensorTab = TabOptions.Default;

  private successText = new TranslatePipe(this.languageService).transform(
    '1858',
    'Success',
  );
  private errorText = new TranslatePipe(this.languageService).transform(
    '211',
    'Error',
  );
  private noValueText = new TranslatePipe(this.languageService).transform(
    '346',
    'No value',
  );
  private unsubscribe = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private formValidatorService: FormValidatorService,
    private shellService: ShellService,
    private testManagementService: TestManagementService,
    private feedbackService: FeedbackService,
    private matDialog: MatDialog,
    private utilService: UtilService,
    private themeService: ThemeService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private permissionControlService: PermissionControlService,
    private dateAdapter: DateAdapter<Date>,
    private resourceManagementService: ResourceManagementService,
  ) {
    this.dateAdapter.setLocale(
      this.utilService.getLanguageCodeByThirdPartyComponentType(
        ThirdPartyComponentTypes.MatDatePicker,
      ),
    );

    this.userId = parseInt(localStorage.getItem('userId') as string);

    const userAnalogScaleReadPermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.analogScale,
        AuthorizationActionEnum.read,
      );

    const userRelationDefinitionReadPermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.relationDefinition,
        AuthorizationActionEnum.read,
      );

    this.testManagementService
      .getTestTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((testTypes: TestType[]) => {
        this.testTypes = testTypes;
      });

    this.testManagementService
      .getTestSampleTimes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((sampleTimes: TestSampleTime[]) => {
        this.sampleTimes = sampleTimes;
      });

    if (userAnalogScaleReadPermission) {
      this.testManagementService
        .getAnalogScales()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((analogScales: AnalogScale[]) => {
          analogScales.forEach((scale) => {
            scale.calibration.a = parseFloat(scale.calibration.a).toFixed(1);
            scale.calibration.b = parseFloat(scale.calibration.b).toFixed(1);
          });
          this.analogScales = analogScales;
        });
    }

    if (userRelationDefinitionReadPermission) {
      this.shellService
        .getTestRelationDefinitions()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((testRelationDefinitions: TmsRelationDefinition[]) => {
          this.determineRelationsAndDefinitions(testRelationDefinitions);
          this.constructTheSpecimenParametersForm(testRelationDefinitions);
        });
    }

    this.setLoaderPath();
  }

  ngOnInit(): void {
    if (this.isEditMode() || this.mode === TestCreateEditMenuMode.PlanToTest) {
      this.setForms();
      this.setDeviceInfo();
      this.setInitialUdaqValues();
    }

    if (!this.isEditMode()) {
      this.channelSelectionOptions.push({
        id: ChannelSelectionOptions.Templates,
        text: this.templatesText,
      });
    }

    this.channelSelectionOptions.push({
      id: ChannelSelectionOptions.OtherSources,
      text: this.otherSourcesText,
    });

    this.getChildDetailsByParentId();

    this.listenSensorFilterInput();
    this.listenSensorFilterRequests();

    this.listenTemplateFilterInput();
  }

  ngAfterViewInit() {
    if (
      this.mode === TestCreateEditMenuMode.Edit ||
      this.mode === TestCreateEditMenuMode.PlanToTest
    ) {
      this.setDeviceInfo();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get channelSelectOptions(): any {
    return ChannelSelectionOptions;
  }

  get testCreateEditMenuMode() {
    return TestCreateEditMenuMode;
  }

  get attributeDataType() {
    return AttributeDataType;
  }

  getErrorClass(form: FormGroup, field: string): string {
    return this.formValidatorService.getErrorClass(form, field);
  }

  changeSelectedPage(selectedOption: number) {
    this.selectedChannelOption = selectedOption;
    if (this.selectedChannelOption === ChannelSelectionOptions.Templates) {
      this.channelsDetails = [];
      this.getSensorTemplates();
    }

    this.sensorFilterInput.setValue('');
    this.templateFilterInput.setValue('');
  }

  channelSelected(availableChannel: any) {
    this.selectedAvailableChannel = availableChannel;
  }

  onDeleteSensor(sensor: any) {
    if (this.mode === TestCreateEditMenuMode.Edit) {
      this.openDeleteSensorFromListDialog(sensor);
    } else {
      this.deleteSensorFromList(sensor);
    }
  }

  deleteSensorFromList(sensor: any) {
    const datasource = this.tableDataSource.data.slice();
    const datasourceIndex = datasource.findIndex(
      (rowItem: any) =>
        rowItem.id === sensor.id && rowItem.station_id === sensor.station_id,
    );

    if (datasourceIndex !== -1) {
      datasource.splice(datasourceIndex, 1);
    }
    this.tableDataSource = new MatTableDataSource(datasource);

    const analogDatasource = this.analogScaleTableDataSource.data.slice();
    const analogDatasourceIndex = analogDatasource.findIndex(
      (rowItem: any) =>
        rowItem.id === sensor.id && rowItem.station_id === sensor.station_id,
    );

    if (analogDatasourceIndex !== -1) {
      analogDatasource.splice(analogDatasourceIndex, 1);
    }
    this.analogScaleTableDataSource = new MatTableDataSource(analogDatasource);

    const selectedSensorIndex = this.selectedSensors.findIndex(
      (sensorItem) =>
        sensorItem.id === sensor.id &&
        sensorItem.station_id === sensor.station_id,
    );

    if (selectedSensorIndex !== -1) {
      const deletedSensor = this.selectedSensors[selectedSensorIndex];

      if (
        // When the deleted sensor is in selected station and has not sensor group id.
        deletedSensor.station_id === this.stationId &&
        (deletedSensor.sensor_group_id === null ||
          deletedSensor.sensor_group_id === undefined)
      ) {
        this.ungroupedSensorsForSelectedStation.push(deletedSensor);
      } else if (
        // When the deleted sensor is in selected station and has sensor group id.
        deletedSensor.station_id === this.stationId &&
        !(
          deletedSensor.sensor_group_id === null ||
          deletedSensor.sensor_group_id === undefined
        )
      ) {
        let relatedGroup = this.groupedSensorsForSelectedStation.find(
          (sensorGroup: SensorGroup) =>
            sensorGroup.id ===
            this.selectedSensors[selectedSensorIndex].sensor_group_id,
        );
        relatedGroup && relatedGroup.sensors.push(deletedSensor);
      } else if (
        //  When the deleted sensor is not in selected station and has not sensor group id.
        deletedSensor.station_id !== this.stationId &&
        (deletedSensor.sensor_group_id === null ||
          deletedSensor.sensor_group_id === undefined)
      ) {
        this.ungroupedSensorsForAllStationsExceptSelected.push(deletedSensor);
      } else if (
        //  When the deleted sensor is not in selected station and has sensor group id.
        deletedSensor.station_id !== this.stationId &&
        !(
          deletedSensor.sensor_group_id === null ||
          deletedSensor.sensor_group_id === undefined
        )
      ) {
        let relatedGroup = this.groupedSensorsForAllStationsExceptSelected.find(
          (sensorGroup: SensorGroup) =>
            sensorGroup.id ===
            this.selectedSensors[selectedSensorIndex].sensor_group_id,
        );
        relatedGroup && relatedGroup.sensors.push(deletedSensor);
      }

      this.selectedSensors.splice(selectedSensorIndex, 1);

      this.sensorFilterRequestReceiver.next();
    }
  }

  openDeleteSensorFromListDialog(sensor: any) {
    const deleteSensorDialog = this.matDialog.open(
      DeleteSensorConfirmationDialogComponent,
      {
        width: '461px',
        height: '170px',
      },
    );

    const dialogElement: HTMLCollectionOf<Element> =
      document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');

    deleteSensorDialog.componentInstance.closeDialogEvent
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isApproved: boolean) => {
        this.matDialog.closeAll();
        if (isApproved) {
          this.deleteSensorFromList(sensor);
        }
      });
  }

  openSaveAsTemplateDialog() {
    const saveTempDialog = this.matDialog.open(SaveAsTemplateDialogComponent, {
      width: '400px',
      height: '210px',
    });

    const dialogElement: HTMLCollectionOf<Element> =
      document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');

    saveTempDialog.componentInstance.closeDialogEvent
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((name: string) => {
        this.matDialog.closeAll();
        const selectedSensor: UpsertSensorTemplatePayload['sensor_template_rows'] =
          [];
        const sensorsInSelectedStation = this.getSensorsInSelectedStation();
        sensorsInSelectedStation.forEach(
          (selectedSensorItem: any, index: number) => {
            selectedSensor.push({
              name: selectedSensorItem.name,
              channel_des_id: selectedSensorItem.channelDescId,
              sort_index: index,
            });
          },
        );

        const sensorData = {
          name,
          sensor_template_rows: selectedSensor,
        };
        this.testManagementService.upsertSensorTemplate(sensorData).subscribe(
          () => {
            this.feedbackService.showFeedback(
              this.successText,
              new TranslatePipe(this.languageService).transform(
                1858,
                'Success',
              ),
              FeedbackTypes.Success,
              FeedbackSizes.Large,
              5000,
            );
          },
          (resp) => {
            const errorMsg = new TranslatePipe(this.languageService).transform(
              resp.error.code,
              resp.error.msg,
            );
            this.feedbackService.showFeedback(
              this.errorText,
              errorMsg,
              FeedbackTypes.Error,
              FeedbackSizes.Large,
              5000,
            );
          },
        );
      });
  }

  deleteSelectedChannels() {
    const datasource = this.tableDataSource.data.slice();
    datasource.forEach((sensor: any) => {
      this.deleteSensorFromList(sensor);
    });

    this.filterSensors(this.sensorFilterInput?.value?.toString());
  }

  hasSelectedChannel(): boolean {
    return this.tableDataSource.data.length > 0;
  }

  hasAnalogSelectedChannel(): boolean {
    return this.analogScaleTableDataSource.data.length > 0;
  }

  templateSelected(template: any) {
    this.selectedTemplate = template;
  }

  changedIsUdaqRecord(e: any) {
    this.isUdaqRecord = e.checked;
  }

  openedExpansionPanel() {
    this.isPanelOpened = true;
  }

  closedExpansionPanel() {
    this.isPanelOpened = false;
  }

  iniCheck(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    if (this.isMakingIniCheckQuery) {
      return;
    }
    this.isMakingIniCheckQuery = true;
    this.testManagementService
      .iniCheck(this.udaqSensorId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (iniFiles: IniFiles) => {
          if (iniFiles.isConnected) {
            if (iniFiles.hasIniFiles) {
              this.iniFileName1 = iniFiles.iniFileName1;
              this.iniFileName2 = iniFiles.iniFileName2;
              this.iniFileId1 = iniFiles.iniFileId1;
              this.iniFileId2 = iniFiles.iniFileId2;
              this.hasIniFiles = iniFiles.hasIniFiles;

              this.feedbackService.showFeedback(
                this.successText,
                this.iniFilesFoundText,
                FeedbackTypes.Success,
                FeedbackSizes.Medium,
              );
            } else {
              const fileNames = iniFiles.mustAddFiles.join(',');
              this.feedbackService.showFeedback(
                this.errorText,
                `${fileNames} ${this.addIniFileText}`,
                FeedbackTypes.Error,
                FeedbackSizes.Medium,
              );
            }
          } else {
            this.feedbackService.showFeedback(
              this.errorText,
              this.canNotReadFridgeText,
              FeedbackTypes.Error,
              FeedbackSizes.Medium,
            );
          }
          this.isMakingIniCheckQuery = false;
        },
        () => {
          this.hasIniFiles = false;
          this.isMakingIniCheckQuery = false;
        },
      );
  }

  createTest() {
    const { testName, testType, sampleTime, description } =
      this.testParamsForm.value;
    const traces: TestCreateTrace[] = this.getTraces();

    let test: CreateTest = {
      plan_id: this.plannedId,
      name: testName,
      user_id: this.userId,
      test_type_id: testType,
      description,
      sample_time_id: sampleTime,
      isUdaq: this.isUdaqRecord,
      ini_file_id_1: this.iniFileId1,
      ini_file_id_2: this.iniFileId2,
      udaq_sensor_id: this.udaqSensorId,
      lab_id: this.testData.lab_id,
      resources: [
        {
          resource_id: this.stationId,
          relation_definition_id: this.relationDefinitionId,
          resource_attribute_definitions: [],
        },
        {
          resource_id: this.deviceId,
          relation_definition_id: this.deviceRelationDefinitionId,
          resource_attribute_definitions: [],
        },
      ],
      traces,
      device_id: this.deviceId,
      device_name: this.testData.device?.name ?? '',
      room_id: this.roomId,
      room_name: this.testData.room_name ?? '',
      lab_name: this.testData.lab_name ?? '',
      station_id: this.testData.station_id,
      station_name: this.testData.station_name ?? '',
    };

    // Add resource_attribute_definitions.
    test.resources.forEach((resource, index) => {
      this.specimenParameters?.forEach((specimenParameter) => {
        if (
          specimenParameter.tms_relation_definition_id ===
          resource.relation_definition_id
        ) {
          let formControlValue = this.specimenParametersForm.get(
            specimenParameter.attribute_definition.id.toString(),
          )?.value;

          if (
            formControlValue !== '' &&
            formControlValue !== null &&
            formControlValue !== undefined
          ) {
            if (formControlValue.toString()) {
              test.resources[index].resource_attribute_definitions.push({
                attribute_definition_id: specimenParameter.attribute_id,
                type: specimenParameter.attribute_definition.type,
                value: formControlValue.toString(),
              });
            }
          }
        }
      });
    });

    this.isCreateTestQuery = true;
    this.shellService
      .createTest(test)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (resp: { test: Test; traces: Trace[] }) => {
          this.isCreateTestQuery = false;
          this.openTestStartedDialog(this.stationId, resp.test, this.roomId);
          this.setCreatedTestAndEmit(resp.test);
        },
        (err) => {
          this.isCreateTestQuery = false;
          const errorMsg = new TranslatePipe(this.languageService).transform(
            err.error.code,
            err.error.msg,
          );
          this.feedbackService.showFeedback(
            this.errorText,
            errorMsg,
            FeedbackTypes.Error,
            FeedbackSizes.Medium,
          );
        },
      );
  }

  updateTest() {
    const { id, station_id, device_id } = this.testData;
    const { testName, testType, sampleTime, description } =
      this.testParamsForm.value;

    const traces: TestCreateTrace[] = this.getTraces();
    const test = {
      id,
      name: testName,
      station_id,
      test_type_id: testType,
      device_id,
      description,
      sample_time_id: sampleTime,
      isUdaq: this.isUdaqRecord,
      ini_file_id_1: this.iniFileId1,
      ini_file_id_2: this.iniFileId2,
      udaq_sensor_id: this.udaqSensorId,
    };

    let testUpdatePayload: UpdateTest = {
      id,
      name: testName,
      station_id,
      test_type_id: testType,
      device_id,
      description,
      sample_time_id: sampleTime,
      isUdaq: this.isUdaqRecord,
      ini_file_id_1: this.iniFileId1,
      ini_file_id_2: this.iniFileId2,
      udaq_sensor_id: this.udaqSensorId,
      traces,
      resources: [
        {
          resource_id: this.stationId,
          resource_attribute_definitions: [],
        },
        {
          resource_id: this.deviceId,
          resource_attribute_definitions: [],
        },
      ],
    };

    // Add resource_attribute_definitions.
    testUpdatePayload.resources.forEach((_, index) => {
      this.specimenParameters?.forEach((specimenParameter) => {
        if (
          specimenParameter.tms_relation_definition_id ===
          this.deviceRelationDefinitionId
        ) {
          const value = this.specimenParametersForm
            .get(specimenParameter.attribute_definition.id.toString())
            ?.value?.toString();

          if (value) {
            testUpdatePayload.resources[
              index
            ].resource_attribute_definitions.push({
              attribute_definition_id: specimenParameter.attribute_id,
              type: specimenParameter.attribute_definition.type,
              value: this.specimenParametersForm
                .get(specimenParameter.attribute_definition.id.toString())
                ?.value?.toString(),
            });
          }
        }
      });
    });

    this.shellService
      .updateTest(testUpdatePayload)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: { traces: Trace[]; udaq_test: UdaqTest | null }) => {
          const deletedTraceIds = this.testData.traces
            .filter(
              (item) =>
                data.traces.findIndex((trace) => trace.id === item.id) === -1,
            )
            .map((item) => item.id);
          const addedTraces = data.traces
            .filter(
              (item) =>
                this.testData.traces.findIndex(
                  (trace) => trace.id === item.id,
                ) === -1,
            )
            .map((item) => ({
              ...item,
              sensor: this.selectedSensors.find(
                (sensor) => sensor.id === item.sensor_id,
              ),
            }));

          // Update test data specimen resource values.
          this.testData.resources.forEach((resource) => {
            resource.resource_attributes.forEach((resource_attribute: any) => {
              resource_attribute.value = this.specimenParametersForm.get(
                resource_attribute.attribute_definition_id.toString(),
              )?.value;
            });
          });

          this.openTestStartedDialog();

          this.testData = Object.assign(this.testData, test);

          this.closeMenu.emit({
            udaqTest: data.udaq_test,
            addedTraces,
            deletedTraceIds,
            isUdaqRecord: this.isUdaqRecord,
          });
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(
            resp.error.code,
            resp.error.msg,
          );
          this.feedbackService.showFeedback(
            this.errorText,
            errorMsg,
            FeedbackTypes.Error,
            FeedbackSizes.Medium,
          );
        },
      );
  }

  selectSensor({
    sensor,
    changedName,
    isUserEvent,
  }: {
    sensor: Sensor;
    changedName?: string;
    isUserEvent?: boolean;
  }) {
    if (
      // When the added sensor is in selected station and has not sensor group id.
      sensor.station_id === this.stationId &&
      (sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const availableSensorIndex =
        this.ungroupedSensorsForSelectedStation.findIndex(
          (sensorItem) => sensorItem.id === sensor.id,
        );
      if (availableSensorIndex !== -1) {
        this.ungroupedSensorsForSelectedStation.splice(availableSensorIndex, 1);
      }
    } else if (
      // When the added sensor is in selected station and has sensor group id.
      sensor.station_id === this.stationId &&
      !(sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const sensorGroup = this.groupedSensorsForSelectedStation.find(
        (item: any) => item.id === sensor.sensor_group_id,
      );
      if (sensorGroup) {
        const sensorIndex = sensorGroup.sensors.findIndex(
          (item: any) => item.id === sensor.id,
        );
        sensorGroup.sensors.splice(sensorIndex, 1);
      }
    } else if (
      //  When the added sensor is not in selected station and has not sensor group id.
      sensor.station_id !== this.stationId &&
      (sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const availableSensorIndex =
        this.ungroupedSensorsForAllStationsExceptSelected.findIndex(
          (sensorItem) => sensorItem.id === sensor.id,
        );
      if (availableSensorIndex !== -1) {
        this.ungroupedSensorsForAllStationsExceptSelected.splice(
          availableSensorIndex,
          1,
        );
      }
    } else if (
      //  When the added sensor is not in selected station and has sensor group id.
      sensor.station_id !== this.stationId &&
      !(sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const sensorGroup = this.groupedSensorsForAllStationsExceptSelected.find(
        (item: any) => item.id === sensor.sensor_group_id,
      );
      if (sensorGroup) {
        const sensorIndex = sensorGroup.sensors.findIndex(
          (item: any) => item.id === sensor.id,
        );
        sensorGroup.sensors.splice(sensorIndex, 1);
      }
    }

    this.sensorFilterRequestReceiver.next();

    this.dataService
      .getLiveValuesWithSensorIds([sensor.id])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: { sensorValues: { id: number; value: number }[] }) => {
        const liveValue = resp.sensorValues[0].value ?? this.noValueText;

        if (this.mode === TestCreateEditMenuMode.Edit) {
          const index = this.testData?.traces.findIndex(
            (trace) => trace.sensor_id === sensor.id,
          );
          if (index !== -1) {
            changedName = this.testData?.traces[index].name;
          }
        }

        const name =
          sensor.station_id === this.stationId
            ? `${sensor?.channelDescription?.description}`
            : `${sensor.stationName} ${sensor?.channelDescription?.description}`;

        let datasource = this.tableDataSource.data.slice();

        const sensorIndex = datasource.findIndex(
          (data) =>
            data.id === sensor.id && data.station_id === sensor.station_id,
        );

        if (sensorIndex !== -1) {
          datasource.splice(sensorIndex, 1);
        }

        datasource.push({
          id: sensor.id,
          code: sensor?.unChangeableChannelCodeText ?? '',
          name: changedName ? changedName : name,
          liveValue,
          unit: sensor?.unit?.unit ?? '',
          channelDescId: sensor?.channelDescription?.id ?? NaN,
          station_id: sensor.station_id,
          sortIndex:
            isUserEvent && this.isEditMode()
              ? datasource[datasource.length - 1].sortIndex + 1
              : sensor.sortIndex,
        });
        datasource = this.utilService.sortArrayIncreasingOrder(
          datasource,
          'sortIndex',
        );
        this.selectedSensors.push({ ...sensor, liveValue });
        this.tableDataSource = new MatTableDataSource(datasource);

        if (sensor?.is_analog) {
          let datasource = this.analogScaleTableDataSource.data.slice();
          datasource.push({
            id: sensor.id,
            code: sensor?.channelDescription?.description,
            name: sensor?.unit?.name,
            analogScaleId:
              this.testData.traces?.find(
                (traceItem) => traceItem.sensor_id === sensor.id,
              )?.analog_scale_id ?? 1,
            station_id: sensor.station_id,
            sortIndex:
              isUserEvent && this.isEditMode()
                ? datasource[datasource.length - 1].sortIndex + 1
                : sensor.sortIndex,
          });
          datasource = this.utilService.sortArrayIncreasingOrder(
            datasource,
            'sortIndex',
          );
          this.analogScaleTableDataSource = new MatTableDataSource(datasource);
        }
      });
  }

  isFormReady(): boolean {
    return (
      this.testParamsForm.valid && this.hasDevice && this.hasSelectedSensor()
    );
  }

  isAllSelectedSensorHaveLiveValue() {
    this.noLiveValueSensors = this.selectedSensors.filter(
      (sensor) => sensor.liveValue === this.noValueText,
    );
    return !this.selectedSensors.find(
      (sensor) => sensor.liveValue === this.noValueText,
    );
  }

  hasSelectedSensor(): boolean {
    return this.selectedSensors.length > 0;
  }

  deviceSelected(device: any) {
    this.deviceId = device?.id;
    this.selectedDevice = device;
    this.hasDevice = true;
    this.deviceInput.nativeElement.value = `${device?.id} - ${device?.name}`;
  }

  resourceDefinitionSelected(e: any) {
    this.clearSelectedDevice();
    this.devices = e.value.resources;
    this.filteredDevices = this.devices.slice();
  }

  onSearchChange(searchValue: any): void {
    const searchText = searchValue.value.toLowerCase();
    this.filteredDevices = this.devices.filter(
      (device) =>
        device.id.toString().toLowerCase().includes(searchText) ||
        device.name.toLowerCase().includes(searchText),
    );
  }

  traceNameChange(event: Event, element: PeriodicElement) {
    const target = event.target as HTMLInputElement;
    element.name = target.value;
    this.selectedSensors.forEach((selectedSensor: Sensor) => {
      if (
        selectedSensor.id === element.id &&
        selectedSensor.station_id === element.station_id
      ) {
        if (selectedSensor?.channelDescription) {
          selectedSensor.channelDescription.description = target.value;
        }
      }
    });
  }

  getTraceValidation(element: PeriodicElement) {
    return element.name === '' ? true : false;
  }

  isTracesNotValid() {
    let isSkipForce = false;
    let returnValue = false;
    if (this.selectedSensors.length > 0) {
      this.selectedSensors.forEach((sensor: Sensor) => {
        if (isSkipForce) {
          return;
        }

        if (sensor?.unit) {
          if (sensor?.unit?.name === '' || sensor?.unit?.name === null) {
            isSkipForce = true;
            returnValue = true;
          } else {
            returnValue = false;
          }
        }
      });
    } else {
      return false;
    }

    return returnValue;
  }

  addTemplateToSelectedChannelList(channelDetail: any, event: any) {
    event.preventDefault();
    event.stopPropagation();
    let selectedSensors: {
      sensor: Sensor;
      changedName?: string;
      isUserEvent?: boolean;
    }[] = [];

    channelDetail.channels.forEach((channel: any) => {
      // Add sensor only if sensor is from the selected station.
      let index = this.ungroupedSensorsForSelectedStation.findIndex(
        (sensor: Sensor) => sensor.channelDescription?.id === channel.id,
      );
      if (index !== -1) {
        const selectedSensor = this.ungroupedSensorsForSelectedStation[index];
        selectedSensors.push({
          sensor: selectedSensor,
          changedName: channel.name,
          isUserEvent: true,
        });
      } else {
        this.groupedSensorsForSelectedStation.forEach(
          (sensorGroup: SensorGroup) => {
            let index = sensorGroup.sensors.findIndex(
              (sensor: Sensor) => sensor.channelDescription?.id === channel.id,
            );
            if (index !== -1) {
              const selectedSensor = sensorGroup.sensors[index];
              selectedSensors.push({
                sensor: selectedSensor,
                changedName: channel.name,
                isUserEvent: true,
              });
            }
          },
        );
      }
    });

    this.dataService
      .getLiveValuesWithSensorIds(
        selectedSensors.map((selected) => selected.sensor.id),
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: { sensorValues: { id: number; value: number }[] }) => {
        selectedSensors.forEach((selectedSensor) => {
          let sensorIndex = resp.sensorValues.findIndex(
            (sensorValue) => sensorValue.id === selectedSensor.sensor.id,
          );
          if (sensorIndex !== -1) {
            const liveValue =
              resp.sensorValues[sensorIndex]?.value ?? this.noValueText;
            this.selectSensorByData(selectedSensor, liveValue);
          }
        });
      });
  }

  selectSensorByData(
    {
      sensor,
      changedName,
      isUserEvent,
    }: { sensor: Sensor; changedName?: string; isUserEvent?: boolean },
    liveValue: any,
  ) {
    if (
      // When the added sensor is in selected station and has not sensor group id.
      sensor.station_id === this.stationId &&
      (sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const availableSensorIndex =
        this.ungroupedSensorsForSelectedStation.findIndex(
          (sensorItem) => sensorItem.id === sensor.id,
        );
      if (availableSensorIndex !== -1) {
        this.ungroupedSensorsForSelectedStation.splice(availableSensorIndex, 1);
      }
    } else if (
      // When the added sensor is in selected station and has sensor group id.
      sensor.station_id === this.stationId &&
      !(sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const sensorGroup = this.groupedSensorsForSelectedStation.find(
        (item: any) => item.id === sensor.sensor_group_id,
      );
      if (sensorGroup) {
        const sensorIndex = sensorGroup.sensors.findIndex(
          (item: any) => item.id === sensor.id,
        );
        sensorGroup.sensors.splice(sensorIndex, 1);
      }
    } else if (
      //  When the added sensor is not in selected station and has not sensor group id.
      sensor.station_id !== this.stationId &&
      (sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const availableSensorIndex =
        this.ungroupedSensorsForAllStationsExceptSelected.findIndex(
          (sensorItem) => sensorItem.id === sensor.id,
        );
      if (availableSensorIndex !== -1) {
        this.ungroupedSensorsForAllStationsExceptSelected.splice(
          availableSensorIndex,
          1,
        );
      }
    } else if (
      //  When the added sensor is not in selected station and has sensor group id.
      sensor.station_id !== this.stationId &&
      !(sensor.sensor_group_id === null || sensor.sensor_group_id === undefined)
    ) {
      const sensorGroup = this.groupedSensorsForAllStationsExceptSelected.find(
        (item: any) => item.id === sensor.sensor_group_id,
      );
      if (sensorGroup) {
        const sensorIndex = sensorGroup.sensors.findIndex(
          (item: any) => item.id === sensor.id,
        );
        sensorGroup.sensors.splice(sensorIndex, 1);
      }
    }

    this.sensorFilterRequestReceiver.next();

    if (this.mode === TestCreateEditMenuMode.Edit) {
      const index = this.testData?.traces.findIndex(
        (trace) => trace.sensor_id === sensor.id,
      );
      if (index !== -1) {
        changedName = this.testData?.traces[index].name;
      }
    }

    const name =
      sensor.station_id === this.stationId
        ? `${sensor?.channelDescription?.description}`
        : `${sensor.stationName} ${sensor?.channelDescription?.description}`;

    let datasource = this.tableDataSource.data.slice();

    const sensorIndex = datasource.findIndex(
      (data) => data.id === sensor.id && data.station_id === sensor.station_id,
    );

    if (sensorIndex !== -1) {
      datasource.splice(sensorIndex, 1);
    }

    datasource.push({
      id: sensor.id,
      code: sensor?.unChangeableChannelCodeText ?? '',
      name: changedName ? changedName : name,
      liveValue,
      unit: sensor?.unit?.unit ?? '',
      channelDescId: sensor?.channelDescription?.id ?? NaN,
      station_id: sensor.station_id,
      sortIndex:
        isUserEvent && this.isEditMode()
          ? datasource[datasource.length - 1].sortIndex + 1
          : sensor.sortIndex,
    });
    datasource = this.utilService.sortArrayIncreasingOrder(
      datasource,
      'sortIndex',
    );
    this.selectedSensors.push({ ...sensor, liveValue });
    this.tableDataSource = new MatTableDataSource(datasource);

    if (sensor?.is_analog) {
      let datasource = this.analogScaleTableDataSource.data.slice();
      datasource.push({
        id: sensor.id,
        code: sensor?.channelDescription?.description,
        name: sensor?.unit?.name,
        analogScaleId: 1,
        station_id: sensor.station_id,
        sortIndex:
          isUserEvent && this.isEditMode()
            ? datasource[datasource.length - 1].sortIndex + 1
            : sensor.sortIndex,
      });
      datasource = this.utilService.sortArrayIncreasingOrder(
        datasource,
        'sortIndex',
      );
      this.analogScaleTableDataSource = new MatTableDataSource(datasource);
    }
  }

  addGroupToSelectedChannelList(group: any, event: any) {
    event.preventDefault();
    event.stopPropagation();
    group.sensors.forEach((channel: any) => {
      this.selectSensor({
        sensor: channel,
        changedName: channel.name,
        isUserEvent: true,
      });
    });
  }

  deleteTemplateToSelectedChannelList(id: number, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.testManagementService
      .deleteSensorTemplate(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        const index = this.channelsDetails.findIndex(
          (channel) => channel.id === id,
        );
        if (index !== -1) {
          this.channelsDetails.splice(index, 1);
        }
      });
  }

  isEditMode(): boolean {
    return this.mode === TestCreateEditMenuMode.Edit;
  }

  getSensorsInSelectedStation() {
    return JSON.parse(
      JSON.stringify(
        this.tableDataSource.data.filter(
          (data: PeriodicElement) => data.station_id === this.stationId,
        ),
      ),
    );
  }

  get canDeleteTestData() {
    return environment.canDeleteTestData;
  }

  get tabOptions() {
    return TabOptions;
  }

  get testCreateEditMenuTestSampleUnit() {
    return environment.testCreateEditMenuTestSampleUnit;
  }

  get testCreateEditMenuTestSampleUnitEnum() {
    return TestCreateEditMenuTestSampleUnit;
  }

  selectPowerThreshold() {
    this.powerThresholdChange.emit();
  }

  deleteAllTestData() {
    const confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '410px',
      height: '160px',
    });

    confirmDialogRef.componentInstance.confirmationQuestion = new TranslatePipe(
      this.languageService,
    ).transform('20550', 'All test data will be deleted. Do you confirm?');
    confirmDialogRef.componentInstance.header = new TranslatePipe(
      this.languageService,
    ).transform('20549', 'Delete All Test Data');
    confirmDialogRef.componentInstance.isDanger = true;
    confirmDialogRef.componentInstance.confirmText = new TranslatePipe(
      this.languageService,
    ).transform('20549', 'Delete All Test Data');
    confirmDialogRef.componentInstance.rejectText = this.cancelText;

    const dialogElement: HTMLCollectionOf<Element> =
      document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');

    confirmDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (confirmDialogRef.componentInstance.approved) {
          this.testManagementService
            .deleteTestData(this.testData.id)
            .pipe(take(1))
            .subscribe(() => {
              location.reload();
            });
        }
      });
  }

  private getOnlySelectedStationSensors() {
    const userSensorReadPermission =
      this.permissionControlService.isActiveUserHasAuthorization(
        AuthorizationDomainTypes.Global,
        null,
        AuthorizationSourceEnum.sensor,
        AuthorizationActionEnum.read,
      );

    if (userSensorReadPermission) {
      this.shellService
        .getSensorsWithRelatedResourceIds([this.stationId])
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (
            sensorsWithResourceId: { resourceId: number; sensors: Sensor[] }[],
          ) => {
            const allSensors: Sensor[] = [];
            let sensorIndex = 0;
            sensorsWithResourceId.forEach((sensorWithRecourseId, index) => {
              sensorIndex = sensorIndex + 1;
              sensorWithRecourseId.sensors.forEach((sensor) => {
                Object.assign(sensor, {
                  stationName: this.stationName,
                  station_id: this.stationId,
                  unChangeableChannelCodeText:
                    sensor.channelDescription?.description,
                  sortIndex: sensorIndex,
                });
                allSensors.push(sensor);
              });
            });

            // Set ungrouped sensors for selected station.
            this.setUngroupedSensorsForSelectedStation(allSensors);

            // Set grouped sensors for selected station.
            this.setGroupedSensorsForSelectedStation(allSensors);

            if (this.sensorsOfSavedTest.length > 0) {
              this.sensorsOfSavedTest.forEach((sensor: Sensor) => {
                this.selectSensor({ sensor });
              });
            }
          },
        );
    }
  }

  private getAllSensorsOfRooms() {
    let stationRoomIds: number[] = [];

    this.stationsOfRoom.forEach((station) => {
      const userSensorReadPermission =
        this.permissionControlService.isActiveUserHasAuthorization(
          AuthorizationDomainTypes.Global,
          null,
          AuthorizationSourceEnum.sensor,
          AuthorizationActionEnum.read,
        );

      if (station && userSensorReadPermission) {
        stationRoomIds.push(station.id);
      }
    });

    this.shellService
      .getSensorsWithRelatedResourceIds(stationRoomIds)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (
          sensorsWithResourceId: { resourceId: number; sensors: Sensor[] }[],
        ) => {
          const allSensors: Sensor[] = [];
          let sensorIndex = 0;
          sensorsWithResourceId.forEach((sensorWithRecourseId, index) => {
            sensorIndex = sensorIndex + 1;
            sensorWithRecourseId.sensors.forEach((sensor) => {
              const stationIndex = this.stationsOfRoom.findIndex(
                (station) => station.id === sensorWithRecourseId.resourceId,
              );
              Object.assign(sensor, {
                stationName: this.stationsOfRoom[stationIndex].name,
                station_id: this.stationsOfRoom[stationIndex].id,
                unChangeableChannelCodeText:
                  sensor.channelDescription?.description,
                sortIndex: sensorIndex,
              });
              allSensors.push(sensor);
            });
          });

          // Set ungrouped sensors for selected station.
          this.setUngroupedSensorsForSelectedStation(allSensors);

          // Set grouped sensors for selected station.
          this.setGroupedSensorsForSelectedStation(allSensors);

          // Set ungrouped sensors for all stations expect selected station.
          this.setUngroupedSensorsForAllStationsExceptSelected(allSensors);

          // Set grouped sensors for all stations except selected station.
          this.setGroupedSensorsForAllStationsExceptSelected(allSensors);

          if (this.sensorsOfSavedTest.length > 0) {
            this.sensorsOfSavedTest.forEach((sensor: Sensor) => {
              this.selectSensor({ sensor });
            });
          }
        },
      );
  }

  private setSensorGroups(sensors: Sensor[]): SensorGroup[] {
    const sensorGroups: SensorGroup[] = [];
    sensors.forEach((sensor) => {
      let group = sensorGroups.find(
        (sensorGroup: SensorGroup) => sensor.sensor_group_id === sensorGroup.id,
      );
      if (group) {
        group.sensors.push(sensor);
      } else {
        group = JSON.parse(JSON.stringify(sensor.sensor_group));
        group && (group.sensors = []);
        group && group.sensors.push(sensor);
        group && sensorGroups.push(group);
      }
    });

    return sensorGroups;
  }

  private getSensorTemplates() {
    this.testManagementService
      .getSensorTemplates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((templates: SensorTemplateInterface[]) => {
        templates.forEach((template) => {
          const channels: any[] = [];
          template.sensor_template_rows.sort((a, b) =>
            a.sort_index > b.sort_index
              ? 1
              : b.sort_index > a.sort_index
              ? -1
              : 0,
          );
          template.sensor_template_rows.forEach((channel: any) => {
            channels.push({
              id: channel.channel_des_id,
              code: channel.ChannelDescription.description,
              name: channel.name,
            });
          });

          this.channelsDetails.push({
            id: template.id,
            name: template.name,
            channels,
          });
        });

        this.channelsDetails.sort((firstTemplate, secondTemplate) =>
          firstTemplate.id > secondTemplate.id ? -1 : 1,
        );
      });
  }

  private clearSelectedDevice() {
    this.selectedDevice = null;
    this.hasDevice = false;
    this.deviceInput.nativeElement.value = '';
  }

  private determineRelationsAndDefinitions(
    testRelationDefinitions: TmsRelationDefinition[],
  ) {
    const index = testRelationDefinitions.findIndex(
      (testRelationDefinition) =>
        testRelationDefinition.reference_id === ResourceType.TestSpecimen &&
        testRelationDefinition.reference_table_name === RmsTable.ResourceType,
    );

    if (index !== -1) {
      this.resourceDefinitions =
        testRelationDefinitions[index].resource_definitions;
      this.deviceRelationDefinitionId = testRelationDefinitions[index].id;
    }

    const testSpaceIndex = testRelationDefinitions.findIndex(
      (testRelationDefinition) =>
        testRelationDefinition.reference_table_name ===
        RmsTable.ResourceDefinition,
    );
    if (testSpaceIndex !== -1) {
      if (
        testRelationDefinitions[testSpaceIndex]?.resource_definitions.length > 0
      ) {
        const resourceIndex = testRelationDefinitions[
          testSpaceIndex
        ]?.resource_definitions[0]?.resources?.findIndex(
          (resource: Resources) => resource.id === this.stationId,
        );

        if (resourceIndex !== -1) {
          this.relationDefinitionId =
            testRelationDefinitions[testSpaceIndex].id;
        }
      }
    }
  }

  private constructTheSpecimenParametersForm(
    testRelationDefinitions: TmsRelationDefinition[],
  ) {
    testRelationDefinitions.forEach((testRelationDefinition) => {
      testRelationDefinition.resource_definitions.forEach(
        (resource_definition) => {
          if (resource_definition.resource_attribute_definitions) {
            resource_definition.resource_attribute_definitions.forEach(
              (resource_attribute_definition) => {
                this.specimenParametersForm.addControl(
                  resource_attribute_definition.attribute_definition.id.toString(),
                  new FormControl(
                    resource_attribute_definition.attribute_definition.type ===
                    AttributeDataType.Boolean
                      ? false
                      : null,
                  ),
                );
                this.specimenParameters!.push({
                  ...resource_attribute_definition,
                  tms_relation_definition_id: testRelationDefinition.id,
                });
              },
            );
          }
        },
      );
    });

    this.isEditMode() && this.setSpecimenParameters();
  }

  private openTestStartedDialog(
    stationId?: number,
    test?: Test,
    roomId?: number,
  ) {
    const dialogRef = this.matDialog.open(TestStartedDialogComponent, {
      width: '480px',
      height: '202px',
    });

    dialogRef.componentInstance.isEditMode =
      this.mode === TestCreateEditMenuMode.Edit;
    if (test && stationId && roomId) {
      dialogRef.componentInstance.setSelectedTest(
        test,
        stationId,
        roomId,
        this.testData.lab_id,
      );
    }
    const dialogElement: HTMLCollectionOf<Element> =
      document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');

    if (this.isEditMode()) {
      setTimeout(() => {
        dialogRef.close();
      }, 1000);
    }
  }

  private setCreatedTestAndEmit(test: Test) {
    let testTypeName = '';
    const index = this.testTypes.findIndex(
      (testType) => testType.id === test.test_type_id,
    );

    if (index !== -1) {
      testTypeName = this.testTypes[index].name;
    }
    test.station_id = this.stationId;
    this.testCreated.emit({
      test: test,
      testTypeName,
      selectedDevice: this.selectedDevice,
    });
    this.closeMenu.emit();
  }

  private setForms() {
    const { name, test_type_id, sample_time_id, description } = this
      .testData as any;
    this.testParamsForm.setValue({
      testName: name,
      testType: test_type_id,
      sampleTime: sample_time_id,
      description: description,
    });
  }

  private setDeviceInfo() {
    if (this.deviceInput) {
      this.deviceInput.nativeElement.value = (this.testData as any).device_id;
      this.deviceSelected(this.testData.device);
    }
  }

  private getTraces() {
    const traces: TestCreateTrace[] = [];

    this.tableDataSource.data.forEach((row: any) => {
      const sensorIndex = this.selectedSensors.findIndex(
        (sensorItem) => row.id === sensorItem.id,
      );
      if (sensorIndex !== -1) {
        const sensor = this.selectedSensors[sensorIndex];
        const updatedName = row.name;

        const tmpTrace = {
          name: updatedName,
          unit_id: sensor?.unit?.id,
          sensor_id: sensor.id,
          analog_scale_id: this.analogScales[0].id,
          analogScale: this.analogScales[0].calibration,
        };
        if (sensor?.is_analog) {
          const index = this.analogScaleTableDataSource.data.findIndex(
            (x: any) => x.id === sensor.id,
          );
          if (index !== -1) {
            const scaleIndex = this.analogScales.findIndex(
              (scale) =>
                scale.id ===
                this.analogScaleTableDataSource.data[index].analogScaleId,
            );
            if (scaleIndex !== -1) {
              tmpTrace.analog_scale_id = this.analogScales[scaleIndex].id;
              tmpTrace.analogScale = this.analogScales[scaleIndex].calibration;
            }
          }
        }
        traces.push(tmpTrace);
      }
    });

    return traces;
  }

  private setUngroupedSensorsForSelectedStation(allSensors: Sensor[]) {
    this.ungroupedSensorsForSelectedStation = allSensors.filter(
      (sensor) =>
        sensor.station_id === this.stationId &&
        (sensor.sensor_group_id === null ||
          sensor.sensor_group_id === undefined),
    );

    this.filteredUngroupedSensorsForSelectedStation = JSON.parse(
      JSON.stringify(this.ungroupedSensorsForSelectedStation),
    );
  }

  private setGroupedSensorsForSelectedStation(allSensors: Sensor[]) {
    this.groupedSensorsForSelectedStation = this.setSensorGroups(
      allSensors.filter(
        (sensor) =>
          sensor.station_id === this.stationId &&
          !(
            sensor.sensor_group_id === null ||
            sensor.sensor_group_id === undefined
          ),
      ),
    );

    this.filteredGroupedSensorsForSelectedStation = JSON.parse(
      JSON.stringify(this.groupedSensorsForSelectedStation),
    );
  }

  private setUngroupedSensorsForAllStationsExceptSelected(
    allSensors: Sensor[],
  ) {
    this.ungroupedSensorsForAllStationsExceptSelected = allSensors.filter(
      (sensor) =>
        sensor.station_id !== this.stationId &&
        (sensor.sensor_group_id === null ||
          sensor.sensor_group_id === undefined),
    );

    this.filteredUngroupedSensorsForAllStationsExceptSelected = JSON.parse(
      JSON.stringify(this.ungroupedSensorsForAllStationsExceptSelected),
    );
  }

  private setGroupedSensorsForAllStationsExceptSelected(allSensors: Sensor[]) {
    this.groupedSensorsForAllStationsExceptSelected = this.setSensorGroups(
      allSensors.filter(
        (sensor) =>
          sensor.station_id !== this.stationId &&
          !(
            sensor.sensor_group_id === null ||
            sensor.sensor_group_id === undefined
          ),
      ),
    );

    this.filteredGroupedSensorsForAllStationsExceptSelected = JSON.parse(
      JSON.stringify(this.groupedSensorsForAllStationsExceptSelected),
    );
  }

  private listenSensorFilterInput() {
    this.sensorFilterInput.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(500))
      .subscribe((value) => {
        this.filterSensors(value?.toString());
        this.tableDataSource.filter = value.toString().trim().toLowerCase();
      });
  }

  private filterSensors(value: string) {
    this.filteredUngroupedSensorsForSelectedStation =
      this.ungroupedSensorsForSelectedStation.filter((sensor) =>
        this.doesSensorCodeAndSensorStationNameContainSearchText(
          sensor?.channelDescription?.description,
          sensor?.stationName,
          value,
        ),
      );

    this.groupedSensorsForSelectedStation.forEach((sensorGroup, index) => {
      this.filteredGroupedSensorsForSelectedStation[index].sensors =
        sensorGroup.sensors.filter((sensor) =>
          this.doesSensorCodeAndSensorStationNameContainSearchText(
            sensor?.channelDescription?.description,
            sensor?.stationName,
            value,
          ),
        );
    });

    this.filteredUngroupedSensorsForAllStationsExceptSelected =
      this.ungroupedSensorsForAllStationsExceptSelected.filter((sensor) =>
        this.doesSensorCodeAndSensorStationNameContainSearchText(
          sensor?.channelDescription?.description,
          sensor?.stationName,
          value,
        ),
      );

    this.groupedSensorsForAllStationsExceptSelected.forEach(
      (sensorGroup, index) => {
        this.filteredGroupedSensorsForAllStationsExceptSelected[index].sensors =
          sensorGroup.sensors.filter((sensor) =>
            this.doesSensorCodeAndSensorStationNameContainSearchText(
              sensor?.channelDescription?.description,
              sensor?.stationName,
              value,
            ),
          );
      },
    );
  }

  private doesSensorCodeAndSensorStationNameContainSearchText(
    sensorCode?: string,
    stationName?: string,
    searchText?: string,
  ): boolean {
    return (
      (sensorCode?.toLowerCase() ?? '') +
      ' ' +
      (stationName?.toLowerCase() ?? '') +
      (sensorCode?.toLowerCase() ?? '') +
      (stationName?.toLowerCase() ?? '') +
      (sensorCode?.toLowerCase() ?? '') +
      (stationName?.toLowerCase()?.split(' ')?.join('') ?? '') +
      (sensorCode?.toLowerCase() ?? '') +
      ' ' +
      (stationName?.toLowerCase()?.split(' ')?.join('') ?? '')
    ).includes(searchText?.toLowerCase()?.trim() ?? '');
  }

  private listenSensorFilterRequests() {
    this.sensorFilterRequestReceiver
      .pipe(takeUntil(this.unsubscribe), debounceTime(100))
      .subscribe(() => {
        this.sortSensorsBySortIndex();
        this.filterSensors(this.sensorFilterInput.value?.toString());
      });
  }

  private setSpecimenParameters() {
    let resourceAttributes: any = [];

    this.testData.resources.forEach((resource) => {
      resource.resource_attributes.forEach((resource_attribute: any) => {
        resourceAttributes!.push(resource_attribute);
      });
    });

    if (resourceAttributes.length > 0) {
      resourceAttributes.forEach(
        (resourceAttribute: {
          attribute_definition_id: string | (string | number)[];
          value: any;
        }) => {
          this.specimenParametersForm
            .get(resourceAttribute.attribute_definition_id.toString())
            ?.setValue(resourceAttribute.value);
        },
      );
    }
  }

  private setLoaderPath() {
    this.themeService.selectedTheme
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedTheme) => {
        this.loaderPath =
          selectedTheme === Theme.Dark
            ? '/assets/RLS_DarkLoader.json'
            : '/assets/RLS_LightLoader.json';
        this.isDarkMode = selectedTheme === Theme.Dark;
      });
  }

  private sortSensorsBySortIndex() {
    this.ungroupedSensorsForSelectedStation =
      this.utilService.sortArrayIncreasingOrder(
        this.ungroupedSensorsForSelectedStation,
        'sortIndex',
      );
    this.groupedSensorsForSelectedStation.forEach((sensorGroup) => {
      sensorGroup.sensors = this.utilService.sortArrayIncreasingOrder(
        sensorGroup.sensors,
        'sortIndex',
      );
    });

    this.ungroupedSensorsForAllStationsExceptSelected =
      this.utilService.sortArrayIncreasingOrder(
        this.ungroupedSensorsForAllStationsExceptSelected,
        'sortIndex',
      );

    this.groupedSensorsForAllStationsExceptSelected.forEach((sensorGroup) => {
      sensorGroup.sensors = this.utilService.sortArrayIncreasingOrder(
        sensorGroup.sensors,
        'sortIndex',
      );
    });
  }

  private listenTemplateFilterInput() {
    this.templateFilterInput.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(500))
      .subscribe((value) => {
        this.templateFilterText = value;
      });
  }

  private getChildDetailsByParentId() {
    this.resourceManagementService
      .getChildDetailsByParentId(this.roomId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((childDetails) => {
        this.stationsOfRoom = childDetails;
        this.getAllSensorsOfRooms();
      });
  }

  private setInitialUdaqValues() {
    if (this.udaqTests?.[0]) {
      this.isUdaqRecord = true;
      this.iniFileId1 = this.udaqTests[0].ini_file_id_1;
      this.iniFileId2 = this.udaqTests[0].ini_file_id_2;

      this.hasIniFiles = true;
    }
  }
}
