<mat-drawer-container [hasBackdrop]="false" class="app-wrapper">
  <mat-drawer-content class="app-mat-drawer-content">
    <div class="app-wrapper">
      <app-left-menu class="left-menu" *ngIf="isDisplayLeftMenu()"></app-left-menu>
      <div style="flex: 1 1 0%; overflow-y: auto">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="loading-text-wrapper" fxLayout="column" *ngIf="showLoggingIndicator">
      <ng-lottie class="animation" [options]="options"></ng-lottie>
      <span class="loading-text">{{ '1910' | translate: 'We are logging you in' }}</span>
      <button
        class="rt-btn-primary navigate-to-login-btn"
        *ngIf="isDisplayNavigateToLoginButton"
        (click)="goToLoginPage()">
        {{ '1911' | translate: 'Try logging in manually' }}
      </button>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
