import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  FeedbackService,
  FeedbackSizes,
  FeedbackTypes,
} from '@rotateknik/rt-std-wc';
import { Duration } from 'luxon';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TestHistoryType } from 'src/app/shared/enums';
import { TranslatePipe } from 'src/app/shared/pipes';
import { TestManagementService } from '.';
import { Selection } from '../../modules/test/test-detail/info-and-notes/info/info.component';
import { ChannelAxisGraphRelationInfo, Test, TestEvent } from '../models';
import { Resources } from '../models/resources.model';
import { LanguageService } from './language.service';
import { ShellService } from './shell.service';
declare var require: any;

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdf = pdfMake;
pdf.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  selectedNotes: TestEvent[];
  currentResources: Resources[] | undefined;

  public pdfReportTrig = new Subject();

  private unsubscribe = new Subject<void>();

  private errorText = new TranslatePipe(this.languageService).transform(
    211,
    'Error',
  );
  private nameText = new TranslatePipe(this.languageService).transform(
    333,
    'Name',
  );
  private dateText = new TranslatePipe(this.languageService).transform(
    20018,
    'Date',
  );
  private minuteText = new TranslatePipe(this.languageService).transform(
    326,
    'Minute',
  );
  private noteDescText = new TranslatePipe(this.languageService).transform(
    20027,
    'Note Description',
  );
  private testNameText = new TranslatePipe(this.languageService).transform(
    570,
    'Test Name',
  );
  private testRespText = new TranslatePipe(this.languageService).transform(
    20030,
    'Responsible Person',
  );
  private fridgeText = new TranslatePipe(this.languageService).transform(
    20031,
    'Fridge',
  );
  private testTypeText = new TranslatePipe(this.languageService).transform(
    '579',
    'Test Type',
  );
  private totalTestDurationText = new TranslatePipe(
    this.languageService,
  ).transform(20504, 'Total Test Duration');
  private startDateText = new TranslatePipe(this.languageService).transform(
    528,
    'Start Date',
  );
  private endDateText = new TranslatePipe(this.languageService).transform(
    1812,
    'End Date',
  );
  private testNumberText = new TranslatePipe(this.languageService).transform(
    20505,
    'Test Number',
  );
  private roomStationText = new TranslatePipe(this.languageService).transform(
    20506,
    'Room/Station',
  );
  private allTestText = new TranslatePipe(this.languageService).transform(
    20032,
    'All Tests',
  );
  private notesText = new TranslatePipe(this.languageService).transform(
    352,
    'Notes',
  );
  private graphAxisText = new TranslatePipe(this.languageService).transform(
    20478,
    'Graph/Axis',
  );

  private pdfSuccessText = new TranslatePipe(this.languageService).transform(
    20033,
    'PDF created. Downloading.',
  );
  private successText = new TranslatePipe(this.languageService).transform(
    1858,
    'Success',
  );
  private valueText = new TranslatePipe(this.languageService).transform(
    '628',
    'Value',
  );
  private whPerDayText = new TranslatePipe(this.languageService).transform(
    20461,
    'Wh Per Day',
  );
  private kWhPerYearText = new TranslatePipe(this.languageService).transform(
    20462,
    'kWh Per Year',
  );
  private averageRunTimeText = new TranslatePipe(
    this.languageService,
  ).transform(20463, 'Average Run Time');
  private averageStopTimeText = new TranslatePipe(
    this.languageService,
  ).transform(20464, 'Average Stop Time');
  private totalDurationText = new TranslatePipe(this.languageService).transform(
    20465,
    'Total Duration',
  );
  private runPercentageText = new TranslatePipe(this.languageService).transform(
    20466,
    'Run Percentage',
  );
  private stopPercentageText = new TranslatePipe(
    this.languageService,
  ).transform(20467, 'Stop Percentage');
  private totalRunDurationText = new TranslatePipe(
    this.languageService,
  ).transform(20468, 'Total Run Duration');
  private totalStopDurationText = new TranslatePipe(
    this.languageService,
  ).transform(20469, 'Total Stop Duration');
  private hourShortText = new TranslatePipe(this.languageService).transform(
    1839,
    'h',
  );
  private minuteShortText = new TranslatePipe(this.languageService).transform(
    325,
    'min',
  );

  private avgText = 'Avg';
  private minText = 'Min';
  private maxText = 'Max';
  private startText = 'Start';
  private endText = 'End';

  constructor(
    private languageService: LanguageService,
    private shellService: ShellService,
    private testManagementService: TestManagementService,
    private feedbackService: FeedbackService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  ) {}

  createPdfReport(
    testId: number,
    selections: Selection[],
    plotImages: string[],
    channelAxisGraphRelations: ChannelAxisGraphRelationInfo[],
  ) {
    this.shellService
      .getTestDetailsForPdfByTestId(testId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((test: Test) => {
        this.testManagementService
          .getTestHistoriesByTestId(testId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (result: TestEvent[]) => {
              if (test) {
                this.selectedNotes = result;
                this.shellService
                  .getRoomDeviceInfoWithTestId(test.id)
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe(
                    (resources: Resources[]) => {
                      const uniqueIds = new Set();
                      const resourcesWithUniqueRmsRelations = resources.map(
                        (resource: Resources) => ({
                          ...resource,
                          rms_relations: [
                            ...new Set(
                              resource.rms_relations.filter((rms) => {
                                const isDuplicate = uniqueIds.has(
                                  rms.destination_record_id,
                                );
                                uniqueIds.add(rms.destination_record_id);
                                if (!isDuplicate) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }),
                            ),
                          ],
                        }),
                      );

                      this.exportPdf(
                        this.selectedNotes,
                        selections,
                        test,
                        test.id.toString(),
                        plotImages,
                        resourcesWithUniqueRmsRelations,
                        channelAxisGraphRelations,
                      );
                    },
                    (resp) => {
                      const errorMsg = new TranslatePipe(
                        this.languageService,
                      ).transform(resp.error.code, resp.error.msg);
                      this.currentResources = undefined;
                      this.feedbackService.showFeedback(
                        this.errorText,
                        errorMsg,
                        FeedbackTypes.Error,
                        FeedbackSizes.Medium,
                      );
                    },
                  );
              }
            },
            (resp) => {
              const errorMsg = new TranslatePipe(
                this.languageService,
              ).transform(resp.error.code, resp.error.msg);
              this.feedbackService.showFeedback(
                this.errorText,
                errorMsg,
                FeedbackTypes.Error,
                FeedbackSizes.Medium,
              );
            },
          );
      });
  }

  exportPdf(
    receivedNote: Array<TestEvent>,
    selections: Selection[],
    selectedTest: Test,
    sRaporId: string,
    plotImages: string[],
    resourcesWithUniqueRmsRelations: Resources[] | undefined,
    channelAxisGraphRelations: ChannelAxisGraphRelationInfo[],
  ) {
    const notes = [
      [this.nameText, this.dateText, this.minuteText, this.noteDescText],
    ];
    const calc: any = [
      [
        this.nameText,
        this.avgText,
        this.minText,
        this.maxText,
        this.startText,
        this.endText,
      ],
    ];

    if (selections) {
      selections.forEach((selection) => {
        selection.criticalValues.forEach((criticalVal) => {
          calc.push([
            criticalVal?.name ?? '',
            criticalVal?.avg?.toFixed(2) ?? '',
            criticalVal?.min?.toFixed(2) ?? '',
            criticalVal?.max?.toFixed(2) ?? '',
            criticalVal?.start?.toFixed(2) ?? '',
            criticalVal?.end?.toFixed(2) ?? '',
          ]);
        });
      });
    }
    const pdfContent: any = [];

    const notesTable = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['*', 150, 'auto', '*'],
        body: notes,
      },
    };

    const coverPage = [
      [this.testNameText + ':', selectedTest.name],
      [
        this.testRespText + ':',
        selectedTest?.user_name ?? selectedTest?.user_id,
      ],
      [this.fridgeText + ':', selectedTest.resource.id],
      [this.testTypeText + ':', selectedTest.test_type?.name],
      [
        this.totalTestDurationText + ':',
        this.getRightDesc(selectedTest.start_date, selectedTest.end_date),
      ],
      [
        this.startDateText + ':',
        this.formatDate(selectedTest.start_date, 'dd.MM.yyyy HH:MM:SS'),
      ],
      [
        this.endDateText + ':',
        this.formatDate(selectedTest.end_date, 'dd.MM.yyyy HH:MM:SS'),
      ],
      [this.testNumberText + ':', selectedTest.id],
      [
        this.roomStationText + ':',
        `${resourcesWithUniqueRmsRelations?.[0].parent?.name} / ${resourcesWithUniqueRmsRelations?.[0].name}`,
      ],
    ];

    // Add product infos to Cover Page
    if (resourcesWithUniqueRmsRelations) {
      let formattedResourcesValues = this.getFormattedResourceValuesForPdfTable(
        resourcesWithUniqueRmsRelations,
      );
      if (formattedResourcesValues && formattedResourcesValues.length > 0)
        formattedResourcesValues?.map((resourceTableTextArray: string[]) => {
          coverPage.push(resourceTableTextArray);
        });
    }

    if (this.selectedNotes) {
      receivedNote.forEach((note: TestEvent) => {
        let maxTestMinutes = 964345010000;
        if (
          note.test_minutes !== undefined &&
          note.test_minutes > maxTestMinutes
        ) {
          note.test_minutes = this.getMinDiff(
            new Date(selectedTest.start_date).getTime(),
            parseInt(note.test_minutes.toString(), 10),
          );
        }

        let testDateTime = '';
        if (note.timestamp !== undefined) {
          testDateTime =
            new Date(note.timestamp).getDate() +
            '/' +
            new Date(note.timestamp).getMonth() +
            '/' +
            new Date(note.timestamp).getFullYear() +
            ', ' +
            new Date(note.timestamp).toLocaleTimeString('en-US');
        }

        let noteTitle = '';

        if (note.type === TestHistoryType.Automatic) {
          noteTitle = new TranslatePipe(this.languageService).transform(
            note.test_history_template?.title ?? '',
          );
        } else {
          noteTitle = note.header ?? '';
        }

        notes.push([
          noteTitle,
          testDateTime,
          note.test_minutes.toString(),
          note.message ?? '',
        ]);
      });
    }

    pdfContent.push({
      columns: [
        {
          text: ' ',
          width: '*',
        },
        {
          table: {
            widths: ['auto', '*'],
            body: coverPage,
          },
          width: 'auto',
        },
        {
          text: ' ',
          width: '*',
        },
      ],
    });

    pdfContent.push(
      ...plotImages.map((plotImage) => {
        return {
          image: plotImage,
          width: 800,
          height: 520,
          pageBreak: 'before',
        };
      }),
    );

    // TODO : Backend bağlantısı gerekli

    // const rangeTableHeader: any = [['Ad', 'Değer']];
    // rangeTableHeader.push(['Wh/Gün', 'deneme Wh/d']);
    // rangeTableHeader.push(['kWh/Yıl', 'deneme kWh/y']);
    // rangeTableHeader.push(['Aralık', 'deneme Wh/d']);
    // rangeTableHeader.push(['Toplam Çalışma', 'deneme kWh/y']);
    // rangeTableHeader.push(['Toplam Durma', 'deneme kWh/y']);
    // rangeTableHeader.push(['Ortalama Çalışma', 'deneme kWh/y']);
    // rangeTableHeader.push(['Ortalama Durma', 'deneme kWh/y']);
    // rangeTableHeader.push(['Çalışma %', 'deneme kWh/y']);
    // rangeTableHeader.push(['Durma %', 'deneme kWh/y']);

    pdfContent.push({
      text: selections[0] !== undefined ? selections[0].name : '',
      style: 'header',
      pageBreak: 'before',
    });

    // pdfContent.push({
    //   layout: 'lightHorizontalLines',
    //   table: {
    //     headerRows: 1,
    //     widths: ['*', 'auto'],
    //     body: rangeTableHeader,
    //   },
    // });

    if (selections) {
      selections.forEach((selection, i) => {
        if (i === 0) {
          pdfContent.push({
            text:
              selection.name +
              ' - ' +
              selection.testName +
              ' - ' +
              Duration.fromMillis(
                selection.start - new Date(selectedTest.start_date).getTime(),
              )
                .toFormat('hh:mm')
                .split(':')
                .join(`${this.hourShortText}:`) +
              this.minuteShortText +
              ' / ' +
              Duration.fromMillis(
                selection.end - new Date(selectedTest.start_date).getTime(),
              )
                .toFormat('hh:mm')
                .split(':')
                .join(`${this.hourShortText}:`) +
              this.minuteShortText,
            style: 'subheader',
            margin: [0, 10, 0, 0],
          });
        } else {
          pdfContent.push({
            text:
              selection.name +
              ' - ' +
              selection.testName +
              ' - ' +
              Duration.fromMillis(
                selection.start - new Date(selectedTest.start_date).getTime(),
              )
                .toFormat('hh:mm')
                .split(':')
                .join(`${this.hourShortText}:`) +
              this.minuteShortText +
              ' / ' +
              Duration.fromMillis(
                selection.end - new Date(selectedTest.start_date).getTime(),
              )
                .toFormat('hh:mm')
                .split(':')
                .join(`${this.hourShortText}:`) +
              this.minuteShortText,
            style: 'subheader',
            pageBreak: 'before',
          });
        }
        if (selection.criticalValues) {
          const criticalValues = [
            [
              this.nameText,
              this.avgText,
              this.minText,
              this.maxText,
              this.startText,
              this.endText,
            ],
          ];
          selection.criticalValues.forEach((criticalVal: any) => {
            criticalValues.push([
              criticalVal?.name ?? '',
              criticalVal?.avg?.toFixed(2) ?? '',
              criticalVal?.min?.toFixed(2) ?? '',
              criticalVal?.max?.toFixed(2) ?? '',
              criticalVal?.start?.toFixed(2) ?? '',
              criticalVal?.end?.toFixed(2) ?? '',
            ]);
          });
          pdfContent.push({
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: criticalValues,
            },
          });

          const selectionDetails = [[this.nameText, this.valueText]];

          selectionDetails.push(
            ...[
              [
                this.whPerDayText,
                this.decimalPipe.transform(
                  selection?.details?.whPerDay ?? 0,
                  '1.2-2',
                ) + ' Wh',
              ],
              [
                this.kWhPerYearText,
                this.decimalPipe.transform(
                  selection?.details?.kWhPerYear ?? 0,
                  '1.2-2',
                ) + ' kWh',
              ],
              [
                this.averageRunTimeText,
                this.decimalPipe.transform(
                  this.getDurationInMinutes(
                    +(selection?.details?.avgRunTime ?? 0),
                  ),
                  '1.2-2',
                ) + ' m.',
              ],
              [
                this.averageStopTimeText,
                this.decimalPipe.transform(
                  this.getDurationInMinutes(
                    +(selection?.details?.avgStopTime ?? 0),
                  ),
                  '1.2-2',
                ) + ' m.',
              ],
              [
                this.totalDurationText,
                this.decimalPipe.transform(
                  this.getDurationInMinutes(
                    +(selection?.details?.totalDuration ?? 0),
                  ),
                  '1.2-2',
                ) + ' m.',
              ],
              [
                this.runPercentageText,
                this.decimalPipe.transform(
                  selection?.details?.runPercentage ?? 0,
                  '1.2-2',
                ) + ' %',
              ],
              [
                this.stopPercentageText,
                this.decimalPipe.transform(
                  selection?.details?.stopPercentage ?? 0,
                  '1.2-2',
                ) + ' %',
              ],
              [
                this.totalRunDurationText,
                this.decimalPipe.transform(
                  this.getDurationInMinutes(
                    +(selection?.details?.totalRunTime ?? 0),
                  ),
                  '1.2-2',
                ) + ' m.',
              ],
              [
                this.totalStopDurationText,
                this.decimalPipe.transform(
                  this.getDurationInMinutes(
                    +(selection?.details?.totalStopTime ?? 0),
                  ),
                  '1.2-2',
                ) + ' m.',
              ],
            ],
          );

          pdfContent.push({
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: ['*', 'auto'],
              body: selectionDetails,
            },
          });
        }
      });
    }

    const allTest = [
      [
        this.nameText,
        this.graphAxisText,
        this.avgText,
        this.minText,
        this.maxText,
        this.startText,
        this.endText,
      ],
    ];
    if (selectedTest.traces.length) {
      selectedTest.traces.forEach((trace) => {
        if (trace.trace_calculation) {
          allTest.push([
            trace.name ?? '',
            this.getGraphAxisRelationText(
              channelAxisGraphRelations,
              trace.id,
            ) ?? '',
            trace.trace_calculation?.avg ?? '',
            trace.trace_calculation?.min ?? '',
            trace.trace_calculation?.max ?? '',
            trace.trace_calculation?.first ?? '',
            trace.trace_calculation?.last ?? '',
          ]);
        }
      });
    }

    if (allTest.length > 1) {
      pdfContent.push({
        text: this.allTestText,
        style: 'header',
        pageBreak: 'before',
      });
      pdfContent.push({
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: allTest,
        },
      });
    }
    pdfContent.push({
      text: this.notesText,
      style: 'header',
      pageBreak: 'before',
    });
    pdfContent.push(notesTable);
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

    let exportDate =
      new Date().getDate() +
      '/' +
      new Date().getMonth() +
      '/' +
      new Date().getFullYear() +
      ', ' +
      new Date().toLocaleTimeString('en-US');

    const pdf = {
      pageOrientation: 'landscape',
      pageSize: 'A4',
      header: {
        columns: [
          {
            text: selectedTest.name,
            margin: [20, 10, 10, 10],
            style: 'subheader',
          },
          {
            text: 'ebivts.arcelik.com/rapor:' + sRaporId,
            alignment: 'center',
            margin: [0, 10, 0, 0],
            style: 'subheader',
          },
          {
            text: exportDate,
            alignment: 'right',
            margin: [10, 10, 20, 10],
            style: 'subheader',
          },
        ],
      },
      content: pdfContent,
      styles: {
        header: {
          fontSize: 18,
          bold: true,
        },
        subheader: {
          fontSize: 14,
          bold: true,
        },
      },
      info: {
        title: selectedTest.name + new Date().toLocaleDateString(),
      },
      footer: (currentPage: any, pageCount: any) => {
        if (selections) {
          let selectionDurationsText = '';
          selections.forEach((selection) => {
            selectionDurationsText =
              selectionDurationsText +
              selection.name +
              ' - ' +
              Duration.fromMillis(selection.end - selection.start).toFormat(
                'hh:mm',
              ) +
              '  ';
          });
          return (
            currentPage !== 1 &&
            plotImages.length + 1 >= currentPage && {
              text: selectionDurationsText,
              margin: [10, 10, 0, 0],
            }
          );
        } else {
          return null;
        }
      },
    };
    pdfMake
      .createPdf(pdf)
      .download(selectedTest.name + ' ' + exportDate + '.pdf');
    this.feedbackService.showFeedback(
      this.successText,
      this.pdfSuccessText,
      FeedbackTypes.Success,
      FeedbackSizes.Medium,
    );
  }

  getDurationInMinutes(duration: number) {
    return duration / 60000;
  }

  getMinDiff(startTime: any, time: any) {
    const timeDiff = time - startTime;
    const minuteDiff = Math.floor(timeDiff / 60000);
    return minuteDiff;
  }

  getEnergyRegulationReport(
    reportName: string,
    formInputs: any,
    components: any,
    response: any,
  ) {
    const headers = [
      'Compartment Type',
      'Volume',
      'Sub-Compartment(Y/N)',
      'Number of Doors',
      'Tested Annual Energy',
      'Deftrost Type',
      'Installation Type',
      'Aux',
      'NoC',
      'L',
      'V',
      'Type',
      'frzf',
      'Cc',
      'C',
      'D',
      'Ac',
      'Bc',
      'Nc',
      'rc',
      'Mc',
      '∑',
      'Annual Energy Consumption',
      'SAE',
      'EEI',
      'Energy Class',
    ];

    const table1FirstColumnStyles = [
      { color: 'black', fillColor: '#f4b084' },
      { color: 'black', fillColor: '#ffd966' },
      { color: 'black', fillColor: '#ffd966' },
      { color: 'black', fillColor: '#ffe699' },
      { color: 'black', fillColor: '#fff2cc' },
      { color: 'black', fillColor: '#ddebf7' },
      { color: 'black', fillColor: '#bdd7ee' },
      { color: 'black', fillColor: '#9bc2e6' },
      { color: 'white', fillColor: '#2f74b5' },
      { color: 'white', fillColor: '#2f74b5' },
      { color: 'black', fillColor: '#e7e6e6' },
    ];

    const table2FirstColumnStyles = [
      { color: 'white', fillColor: '#00b050' },
      { color: 'white', fillColor: '#70ac2e' },
      { color: 'white', fillColor: '#c4de42' },
      { color: 'white', fillColor: '#fffa00' },
      { color: 'white', fillColor: '#ffc000' },
      { color: 'white', fillColor: '#fb6905' },
      { color: 'white', fillColor: '#cc0000' },
    ];

    const defrostTypes = [
      { id: 1, name: 'Automatic Defrost' },
      { id: 2, name: 'Manuel Defrost' },
    ];

    const installTypes = [
      { id: 1, name: 'FreeStanding' },
      { id: 2, name: 'BuiltIn' },
    ];

    const energyRegulationPdf: any = {
      content: [
        {
          style: 'table',
          table: {
            body: [],
          },
        },
        {
          style: ['table', 'table2'],
          table: {
            heights: [15, 15, 15, 15, 15, 15, 15, 15],
            widths: [100, 300],
            body: [],
          },
        },
        {
          style: ['table', 'table3'],
          table: {
            heights: [60, 60],
            widths: [200, 70],
            body: [],
          },
        },
        {
          style: ['table', 'table4'],
          table: {
            heights: [40, 80],
            widths: [200],
            body: [],
          },
        },
      ],
      pageSize: 'A2',
      pageOrientation: 'landscape',
      styles: {
        table: { alignment: 'center' },
        table2: { margin: [0, 100, 0, 0] },
        table3: { margin: [450, -160, 0, 0] },
        table4: { margin: [850, -130, 0, 0] },
        headerCell: { fillColor: '#e2efda' },
      },
    };

    energyRegulationPdf.content[0].table.body.push(
      headers.map((header) => {
        return {
          text: header,
          style: 'headerCell',
        };
      }),
    );

    components.forEach((component: any, index: number) =>
      energyRegulationPdf.content[0].table.body.push([
        {
          text: component.name,
          color: table1FirstColumnStyles[index].color,
          fillColor: table1FirstColumnStyles[index].fillColor,
        },
        component.volume,
        component.isSub ? 'Y' : 'N',
        {
          text: formInputs.numberOfDoors,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: formInputs.annualEnergy,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: defrostTypes[formInputs.defrostTypeId - 1].name,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: installTypes[formInputs.installTypeId - 1].name,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: formInputs.aux,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.noc,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        { text: response.l, rowSpan: components.length, margin: [0, 90, 0, 0] },
        {
          text: response.volume,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.type,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.frzf,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.cc,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        { text: response.c, rowSpan: components.length, margin: [0, 90, 0, 0] },
        { text: response.d, rowSpan: components.length, margin: [0, 90, 0, 0] },
        response.componentParameter
          ? response.componentParameter[component.name].ac
          : null,
        response.componentParameter
          ? response.componentParameter[component.name].bc
          : null,
        response.componentParameter
          ? response.componentParameter[component.name].nc
          : null,
        response.componentParameter
          ? response.componentParameter[component.name].rc
          : null,
        response.componentParameter
          ? response.componentParameter[component.name].mc
          : null,
        response.componentParameter
          ? response.componentParameter[component.name].sum
          : null,
        {
          text: response.annualEnergyConsumption,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.sae,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.eei,
          rowSpan: components.length,
          margin: [0, 90, 0, 0],
        },
        {
          text: response.energyClass,
          rowSpan: components.length,
          fontSize: 36,
          bold: true,
          fillColor: 'orange',
          color: 'white',
          margin: [0, 60, 0, 0],
        },
      ]),
    );

    energyRegulationPdf.content[1].table.body.push([
      '',
      { text: 'Tested Energy Consumption(kWh/year)', style: 'headerCell' },
    ]);

    response.testedEnergyConsumptions &&
      response.testedEnergyConsumptions.forEach(
        (energyRegulationConsumption: any, index: number) =>
          energyRegulationPdf.content[1].table.body.push([
            {
              text: energyRegulationConsumption.name,
              color: table2FirstColumnStyles[index].color,
              fillColor: table2FirstColumnStyles[index].fillColor,
            },
            `${
              energyRegulationConsumption.lowerLimit !== null
                ? energyRegulationConsumption.lowerLimit + '<'
                : ''
            }TAE${
              energyRegulationConsumption.upperLimit !== null
                ? '<' + energyRegulationConsumption.upperLimit
                : ''
            }`,
          ]),
      );

    energyRegulationPdf.content[2].table.body.push([
      {
        text: 'the sum of the volumes of the frozent compartment(s), expressed in litres and rounded to the nearest integer;',
        fillColor: '#f2f2f2',
        margin: [0, 10, 0, 0],
      },
      { text: response.frozenCompartmentsVolumes, margin: [0, 25, 0, 0] },
    ]);
    energyRegulationPdf.content[2].table.body.push([
      {
        text: 'the sum of the volumes of the chill compartment(s) and the unfrozen compartment(s), expressed in litres and rounded to the nearest integer;',
        fillColor: '#f2f2f2',
        margin: [0, 10, 0, 0],
      },
      { text: response.chillCompartmentsVolumes, margin: [0, 25, 0, 0] },
    ]);

    energyRegulationPdf.content[3].table.body.push([
      {
        text: 'ENERGY EFFICIENCY LABEL DECLARATION',
        fillColor: '#f2f2f2',
        margin: [0, 5, 0, 0],
      },
    ]);

    energyRegulationPdf.content[3].table.body.push([
      {
        text: `${response.energyEfficiencyLabelDeclaration} kWh/annum`,
        margin: [0, 25, 0, 0],
      },
    ]);

    pdfMake.createPdf(energyRegulationPdf).download(`${reportName}.pdf`);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getFormattedResourceValuesForPdfTable(
    resourcesWithUniqueRmsRelations: Resources[] | undefined,
  ) {
    let formattedResourcesValues: any[] = [];

    resourcesWithUniqueRmsRelations?.map(
      (resource) =>
        resource?.resource_attributes?.map((attribute) =>
          formattedResourcesValues.push([
            `${attribute.attribute_definition.name} :`,
            attribute.value,
          ]),
        ),
    );

    return formattedResourcesValues;
  }

  private getGraphAxisRelationText(
    channelAxisGraphRelations: ChannelAxisGraphRelationInfo[],
    traceId: number,
  ) {
    return channelAxisGraphRelations
      .filter((channel) => channel.traceId === traceId)
      .map(
        (channelAxisGraphRelation) =>
          `${channelAxisGraphRelation.graphName}/${channelAxisGraphRelation.axisName}`,
      )
      .join();
  }

  private formatDate(date: Date, format: string) {
    return this.datePipe.transform(date, format);
  }

  private getRightDesc(startDate: Date, endDate: Date): string {
    const oneDay = 24 * 60 * 60;
    const oneHour = 60 * 60;
    let totalDiff =
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000;
    totalDiff = Math.abs(Math.floor(totalDiff));
    const totalDays = Math.floor(totalDiff / oneDay);
    const leftTotal = totalDiff - totalDays * oneDay;
    const totalHrs = Math.floor(leftTotal / oneHour);

    let days: number;
    let hrs: number;
    if (new Date() <= new Date(startDate)) {
      days = 0;
      hrs = 0;
    } else if (new Date() >= new Date(endDate)) {
      days = totalDays;
      hrs = totalHrs;
    } else {
      let diff = (new Date().getTime() - new Date(startDate).getTime()) / 1000;
      diff = Math.abs(Math.floor(diff));
      days = Math.floor(diff / oneDay);
      const leftSec = diff - days * oneDay;
      hrs = Math.floor(leftSec / oneHour);
    }

    const text =
      days +
      ' ' +
      new TranslatePipe(this.languageService).transform('156', 'day') +
      ' ' +
      hrs +
      new TranslatePipe(this.languageService).transform('1839', 'h');

    return text;
  }
}
