import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationData } from 'src/app/core/models/notification-data.model';
import { ShellService } from 'src/app/core/services';
import { NotificationDialogRequestEventType } from '../../enums';
import { SocketService, UtilService } from '../../services';
import { NotificationService } from '../../services/notification.service';
import { NotificationsDialogComponent } from './notifications-dialog/notifications-dialog.component';

enum MenuType {
  ExtraTime = 1,
  None,
}

enum NotificationView {
  hasNotification = 1,
  notHasNotification,
  clicked,
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('menuDrawer') menuDrawer: MatDrawer;

  @Input() activePageName: string;
  @Output() notificationActionTabNumber = new EventEmitter();

  selectedMenuType = MenuType.None;

  username: any;
  currentNotificationView: NotificationView = NotificationView.notHasNotification;

  unreadNotificationCount: number = 0;
  allNotificationCount: number = 0;

  deliveredProducts: { header: string; content: string; date?: Date }[] = [];
  notifications: NotificationData[] = [];

  activeNotificationDialog: any;

  selectedNotification: NotificationData;

  readonly activeUserId = localStorage.getItem('userId');
  private unsubscribe = new Subject<void>();

  constructor(
    private matDialog: MatDialog,
    private socketService: SocketService,
    private titleService: Title,
    private shellService: ShellService,
    private utilService: UtilService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.username = localStorage.getItem('userName');
    this.setTitle();
    this.initNotifications();
    this.listenSocket();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get notificationView() {
    return NotificationView;
  }

  get menuType() {
    return MenuType;
  }

  closeDrawer() {
    this.selectedMenuType = MenuType.None;
    this.menuDrawer.close();
  }

  setTitle() {
    this.titleService.setTitle('RLS - ' + this.username);
  }

  openNotificationDialog() {
    const notificationDialog = this.matDialog.open(NotificationsDialogComponent, {
      height: 'auto',
      panelClass: 'app-header-operation-dialog',
    });
    this.activeNotificationDialog = notificationDialog;
    notificationDialog.componentInstance.notifications = notificationDialog.componentInstance.notifications.concat(
      this.notifications
    );
    notificationDialog.componentInstance.allNotificationsCount = this.allNotificationCount;
    this.currentNotificationView = NotificationView.clicked;

    const dialogElement: HTMLCollectionOf<any> = document.getElementsByClassName('mat-dialog-container');
    dialogElement[0].classList.add('custom-dialog');
    dialogElement[0].style.position = 'absolute';
    dialogElement[0].style.top = '78px';
    dialogElement[0].style.right = '16px';
    dialogElement[0].style.width = '350px';
    dialogElement[0].style.height = 'auto';

    notificationDialog
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: { tab: NotificationDialogRequestEventType; notification: NotificationData } | undefined) => {
        this.activeNotificationDialog = null;
        this.determineNotificationIconType();

        if (result && result.tab === NotificationDialogRequestEventType.ExtraTimeRequestTab) {
          this.selectedMenuType = MenuType.ExtraTime;
          this.selectedNotification = result.notification;
          this.menuDrawer.open();
        } else {
          this.notificationActionTabNumber.emit(result);
        }

        this.unreadNotificationCount = this.notificationService.unreadNotificationCount;

        if (notificationDialog.componentInstance.isAnyNotificationDeleted) {
          this.getNotificationsForUser();
          this.getNotificationCount();
        }
      });
  }

  private initNotifications() {
    if (this.notificationService.notifications === undefined) {
      this.getNotificationsForUser();
    } else {
      this.notifications = JSON.parse(JSON.stringify(this.notificationService.notifications));
      this.determineNotificationIconType();
    }
    if (this.notificationService.allNotificationCount === undefined) {
      this.getNotificationCount();
    } else {
      this.allNotificationCount = this.notificationService.allNotificationCount;
      this.unreadNotificationCount = this.notificationService.unreadNotificationCount;
      this.determineNotificationIconType();
    }
  }

  private determineNotificationIconType() {
    this.currentNotificationView =
      this.unreadNotificationCount > 0 ? NotificationView.hasNotification : NotificationView.notHasNotification;
  }

  private listenSocket() {
    this.socketService.socketObservables.notification
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((notification: any) => {
        notification.creationDate = new Date();
        notification.dateAgo = this.utilService.getDuration(notification.creationDate);
        this.notifications.unshift(notification);
        this.notificationService.notifications = JSON.parse(JSON.stringify(this.notifications));
        this.unreadNotificationCount++;
        this.notificationService.unreadNotificationCount = this.unreadNotificationCount;
        this.allNotificationCount++;
        this.notificationService.allNotificationCount = this.allNotificationCount;
        if (this.activeNotificationDialog) {
          this.activeNotificationDialog.componentInstance.notifications.unshift(notification);
        }
        this.determineNotificationIconType();
      });
  }

  private getNotificationsForUser() {
    this.shellService
      .getNotificationsForUser({
        startRow: 0,
        endRow: 10,
        groupKeys: [],
        pivotCols: [],
        pivotMode: false,
        rowGroupCols: [],
        valueCols: [],
        sortModel: [
          {
            colId: 'createdAt',
            sort: 'desc',
          },
        ],
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((results: NotificationData[]) => {
        this.notifications = JSON.parse(JSON.stringify(results));
        this.notificationService.notifications = JSON.parse(JSON.stringify(results));
        this.determineNotificationIconType();
      });
  }

  private getNotificationCount() {
    this.shellService
      .getNotificationCount()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: { unread: number; total: number }) => {
        this.unreadNotificationCount = result.unread;
        this.notificationService.unreadNotificationCount = result.unread;
        this.allNotificationCount = result.total;
        this.notificationService.allNotificationCount = result.total;
        this.determineNotificationIconType();
      });
  }
}
