import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DatesSetArg } from '@fullcalendar/common';
import {
  EventDialogResult,
  FeedbackService,
  FeedbackSizes,
  FeedbackTypes,
  PlanningEventInput,
  PlanningOptions,
  PlanningResourceInput,
  ResourceDialogResult,
} from '@rotateknik/rt-std-wc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtensionApproveStatus } from 'src/app/core/enums';
import { Plan } from 'src/app/core/models/plan.model';
import { PlansByResourceDefinition } from 'src/app/core/models/plans-by-resource-definition';
import { Resources } from 'src/app/core/models/resources.model';
import { LanguageService, ResourceManagementService, ShellService } from 'src/app/core/services';
import { PlanType } from 'src/app/shared/enums';
import { TranslatePipe } from 'src/app/shared/pipes';
import { PlanningFactory } from '../../../modules/rooms/planning/planning-factory';

enum EventItemResult {
  Started = 1,
  Waiting = 2,
}
@Component({
  selector: 'app-extra-time-menu',
  templateUrl: './extra-time-menu.component.html',
  styleUrls: ['./extra-time-menu.component.scss'],
  providers: [PlanningFactory, DatePipe],
})
export class ExtraTimeMenuComponent implements OnInit, OnDestroy {
  @Output() closeMenu = new EventEmitter();
  @Output() approvedOrRejectedExtraTimeReq = new EventEmitter();

  @Input() selectedRoomId: number;
  @Input() extraTimeReqId: number;
  @Input() testName: string;
  @Input() requestedTime: string;
  @Input() description: string;
  @Input() newDate: Date;
  @Input() testId: number;
  @Input() labId: number;
  @Input() isInDrawer = false;

  planningOptions: PlanningOptions;
  resources: PlanningResourceInput[];
  events: PlanningEventInput[];
  resourcesIds: number[] = [];
  payload: PlansByResourceDefinition = {
    resource_ids: [],
    start_date: new Date(),
    end_date: new Date(),
    plan_type_id: PlanType.TestPlanning,
  };
  tempText = 0;
  humidityText = 0;
  testExtensionRequestStatus: ExtensionApproveStatus;

  private errorText = new TranslatePipe(this.languageService).transform('211', 'Error');
  private successText = new TranslatePipe(this.languageService).transform('1858', 'Success');
  private approveSuccessText = new TranslatePipe(this.languageService).transform(
    '20003',
    'Extra time request approved'
  );
  private rejectSuccessText = new TranslatePipe(this.languageService).transform('20004', 'Extra time request rejected');
  private noProcessText = new TranslatePipe(this.languageService).transform(
    '20445',
    'This test extension request has already been processed.'
  );

  private affectedPlans: Plan[] = [];
  private unsubscribe = new Subject<void>();

  constructor(
    private resourceManagementService: ResourceManagementService,
    private planningFactory: PlanningFactory,
    private feedbackService: FeedbackService,
    private languageService: LanguageService,
    private router: Router,
    private shellService: ShellService
  ) {}

  ngOnInit(): void {
    this.planningOptions = this.planningFactory.getOptions();
    this.getAffectedPlansByPlanExtensionId();
    this.getPlanExtensionStatusById();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get extensionApproveStatus() {
    return ExtensionApproveStatus;
  }

  downloadStations() {
    this.resourceManagementService
      .getResourcesByParentId(this.selectedRoomId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: Resources[]) => {
        this.labId = result[0].parent_id;
        this.resources = this.planningFactory.convertResourceData(result);
        this.resourcesIds = [];
        result.forEach((element: Resources) => {
          this.resourcesIds.push(element.id);
        });
        this.payload.resource_ids = this.resourcesIds;
        this.downloadPlans();
      });
  }

  downloadPlans() {
    this.resourceManagementService
      .getPlansByResourceIds({ ...this.payload, excludeCancelled: false })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: Plan[]) => {
        this.events = this.planningFactory.convertEventData(result);
      });
  }

  onDateChanged(arg: DatesSetArg) {
    this.payload = {
      resource_ids: this.resourcesIds,
      start_date: arg.start,
      end_date: arg.end,
      plan_type_id: PlanType.TestPlanning,
    };
    if (!this.resources) {
      this.downloadStations();
    } else {
      this.downloadPlans();
    }
  }

  onResourceDialogClicked(arg: ResourceDialogResult) {
    const queryParams = {
      roomId: this.selectedRoomId,
      stationId: arg.data.id,
    };
    this.router.navigate(['rooms/stationDetail'], {
      queryParams,
    });
  }

  onEventDialogClicked(arg: EventDialogResult) {
    if (arg.id === EventItemResult.Waiting) {
      const queryParams = {
        roomId: this.selectedRoomId,
        stationId: arg.data.getResources()[0].id,
      };
      this.router.navigate(['rooms/stationDetail'], {
        queryParams,
      });
    } else {
      const queryParams = {
        stationId: arg.data.getResources()[0].id,
        testId: arg.data.extendedProps.subId ? arg.data.extendedProps.subId : arg.data.id,
        roomId: this.selectedRoomId,
      };
      this.router.navigate(['tests/testDetail'], {
        queryParams,
      });
    }
  }

  selectedRoomChanged(e: { roomId: number; roomName: string }) {
    this.selectedRoomId = e.roomId;
    this.downloadStations();
  }

  approveExtraTimeRequest() {
    this.shellService
      .approveExtraTimeRequest(this.extraTimeReqId, this.affectedPlans, this.newDate, this.testId)
      .subscribe(
        () => {
          this.approvedOrRejectedExtraTimeReq.emit(this.extraTimeReqId);
          setTimeout(() => {
            this.closeMenu.emit();
          }, 100);
          this.feedbackService.showFeedback(
            this.successText,
            this.approveSuccessText,
            FeedbackTypes.Success,
            FeedbackSizes.Medium,
            5000
          );
        },
        (resp) => {
          const errorMsg = new TranslatePipe(this.languageService).transform(resp.error.code, resp.error.msg);
          this.feedbackService.showFeedback(this.errorText, errorMsg, FeedbackTypes.Error, FeedbackSizes.Large);
          this.closeMenu.emit();
        }
      );
  }

  rejectExtraTimeRequest() {
    this.resourceManagementService
      .rejectExtraTimeRequest(this.extraTimeReqId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.approvedOrRejectedExtraTimeReq.emit(this.extraTimeReqId);
        setTimeout(() => {
          this.closeMenu.emit();
        });
        this.feedbackService.showFeedback(
          this.successText,
          this.rejectSuccessText,
          FeedbackTypes.Success,
          FeedbackSizes.Medium,
          5000
        );
      });
  }

  private getAffectedPlansByPlanExtensionId() {
    this.resourceManagementService
      .getAffectedPlansByPlanExtensionId(this.extraTimeReqId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((affectedPlans: Plan[]) => {
        this.affectedPlans = affectedPlans;
      });
  }

  private getPlanExtensionStatusById() {
    this.resourceManagementService
      .getPlanExtensionStatusById(this.extraTimeReqId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: { is_approved: ExtensionApproveStatus }) => {
        this.testExtensionRequestStatus = +response.is_approved;
        if (+response.is_approved !== ExtensionApproveStatus.Pending) {
          this.feedbackService.showFeedback(
            this.errorText,
            this.noProcessText,
            FeedbackTypes.Error,
            FeedbackSizes.Medium,
            3000
          );
        }
      });
  }
}
