<div
  class="rt-dialog-wrapper test-started-dialog-wrapper"
  fxLayout="column"
  fxLayoutGap="17px"
  fxFlexFill
  fxLayoutAlign="space-between stretch">
  <div class="rt-dialog-header-container">
    <div fxFlex class="rt-dialog-header-text"></div>
    <div class="rt-dialog-close-icon-wrapper">
      <mat-icon class="clickable" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
  <div class="rt-dialog-content test-started-content" fxFlex fxLayout="row" fxLayoutGap="24px">
    <mat-icon class="success-icon">check_circle</mat-icon>
    <div fxLayout="column">
      <span class="test-started-caption">
        {{ isEditMode ? ('581' | translate: 'Test Updated !') : ('573' | translate: 'Test Started !') }}
      </span>
      <span class="test-started-text">
        {{
          isEditMode
            ? ('593'
              | translate: 'The test has been updated for the station you selected. Please check the testing process.')
            : ('592'
              | translate: 'The test has been started for the station you selected. Please check the testing process.')
        }}
      </span>
      <span>
        <a *ngIf="selectedTest && stationId" class="rt-btn-secondary" (click)="goToTest()">
          {{ '257' | translate: 'Go to Test' }}
        </a>
      </span>
    </div>
  </div>
  <div fxLayoutAlign="end end" class="rt-dialog-footer" fxLayout="row" fxLayoutGap="16px">
    <button class="rt-btn-lg rt-btn-primary" (click)="closeDialog()">{{ '357' | translate: 'OK' }}</button>
  </div>
</div>
