import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  selectedTheme = new BehaviorSubject(this.theme);

  set theme(value: any) {
    this.selectedTheme.next(value);
    localStorage.setItem('theme', value);
  }

  get theme() {
    return localStorage.getItem('theme');
  }
}
