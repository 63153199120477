import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { PlotComponent } from '@rotateknik/plot';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityAvailability } from 'src/app/core/models';
import { LanguageService, ResourceManagementService, TestManagementService } from 'src/app/core/services';
import { ThemeService } from 'src/app/shared/services';
import { Theme } from '../../enums';
import { TranslatePipe } from '../../pipes';

@Component({
  selector: 'app-availability-graphs',
  templateUrl: './availability-graphs.component.html',
  styleUrls: ['./availability-graphs.component.scss'],
  providers: [DatePipe],
})
export class AvailabilityGraphsComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() set selectedAvailabilityType(value: number) {
    if (value) {
      this.availabilityType = value;
    }
  }
  @Input() set selectedId(value: number) {
    if (value) {
      this.availabilityId = value;
      if (this.availabilityType) {
        if (this.firstRendered) {
          this.initDataFromServer();
        } else {
          this.initData();
        }
        this.firstRendered = true;
      }
    }
  }
  @Input() set refreshData(date: Date) {
    if (date) {
      this.getActiveTestCountWithUserId();
      this.getWeeklyAvailabilityData();
      this.getMonthlyAvailabilityData();
    }
  }
  @Input() activeTestCount = 0;

  @ViewChild('weeklyWrapper') weeklyWrapper: ElementRef;
  @ViewChild('monthlyWrapper') monthlyWrapper: ElementRef;
  @ViewChild('weeklyPlot') weeklyPlot: PlotComponent;
  @ViewChild('monthlyPlot') monthlyPlot: PlotComponent;

  availabilityType: number;
  availabilityId: number;
  weeklyPlotSize = { height: 0, width: 0 };
  monthlyPlotSize = { height: 0, width: 0 };

  product: number = 124;
  exchangeRatio: number = 12;

  isInstantAvailabilityIncrement: boolean = true;
  isDarkMode = false;
  firstRendered = false;

  private januaryFebruaryText = new TranslatePipe(this.languageService).transform(288, 'Jan - Feb');
  private februaryMarchText = new TranslatePipe(this.languageService).transform(243, 'Feb - Mar');
  private marchAprilText = new TranslatePipe(this.languageService).transform(320, 'Mar - Apr');
  private aprilMayText = new TranslatePipe(this.languageService).transform(69, 'Apr - May');
  private mayJuneText = new TranslatePipe(this.languageService).transform(321, 'May - June');
  private juneJulyText = new TranslatePipe(this.languageService).transform(293, 'June - July');
  private julyAugustText = new TranslatePipe(this.languageService).transform(292, 'July - Aug');
  private augustSeptemberText = new TranslatePipe(this.languageService).transform(86, 'Aug - Sep');
  private septemberOctoberText = new TranslatePipe(this.languageService).transform(511, 'Sep - Oct');
  private octoberNovemberText = new TranslatePipe(this.languageService).transform(356, 'Oct - Nov');
  private novemberDecemberText = new TranslatePipe(this.languageService).transform(354, 'Nov - Dec');
  private decemberJanuaryText = new TranslatePipe(this.languageService).transform(157, 'Dec - Jan');

  months = [
    { text: this.januaryFebruaryText, value: 0 },
    { text: this.februaryMarchText, value: 1 },
    { text: this.marchAprilText, value: 2 },
    { text: this.aprilMayText, value: 3 },
    { text: this.mayJuneText, value: 4 },
    { text: this.juneJulyText, value: 5 },
    { text: this.julyAugustText, value: 6 },
    { text: this.augustSeptemberText, value: 7 },
    { text: this.septemberOctoberText, value: 8 },
    { text: this.octoberNovemberText, value: 9 },
    { text: this.novemberDecemberText, value: 10 },
    { text: this.decemberJanuaryText, value: 11 },
  ];
  selectedMonth = new Date().getMonth();

  selectedWeeklyYear = new Date().getFullYear();
  selectedMonthlyYear = new Date().getFullYear();
  isInstantOccupancyLoading = false;
  isWeeklyOccupancyLoading = false;
  isMonthlyOccupancyLoading = false;

  loaderPath = '/assets/RLS_LightLoader.json';

  private userId: number;

  private unsubscribe = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    private resourceManagementService: ResourceManagementService,
    private datePipe: DatePipe,
    private languageService: LanguageService,
    private testManagementService: TestManagementService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.themeService.selectedTheme.pipe(takeUntil(this.unsubscribe)).subscribe((selectedTheme) => {
      switch (selectedTheme) {
        case Theme.Dark:
          this.loaderPath = '/assets/RLS_DarkLoader.json';
          this.isDarkMode = true;
          break;
        case Theme.Light:
          this.loaderPath = '/assets/RLS_LightLoader.json';
          this.isDarkMode = false;
          break;
        default:
          break;
      }
    });
    this.dateAdapter.setLocale('tr-TR');
  }

  ngOnInit() {
    this.userId = parseInt(localStorage.getItem('userId') as string);
    this.getActiveTestCountWithUserId();
  }

  ngAfterViewInit(): void {
    this.resizeWeeklyPlot();
    this.resizeMonthlyPlot();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  resizeWeeklyPlot() {
    setTimeout(() => {
      this.weeklyPlotSize.width = this.weeklyWrapper.nativeElement.clientWidth;
      this.weeklyPlotSize.height = this.weeklyWrapper.nativeElement.clientHeight;
    }, 300);
  }

  resizeMonthlyPlot() {
    setTimeout(() => {
      this.monthlyPlotSize.width = this.monthlyWrapper.nativeElement.clientWidth;
      this.monthlyPlotSize.height = this.monthlyWrapper.nativeElement.clientHeight;
    }, 300);
  }

  initDataFromServer() {
    this.getWeeklyAvailabilityData();
    this.getMonthlyAvailabilityData();
  }

  initData() {
    this.getWeeklyAvailabilityData();
    this.getMonthlyAvailabilityData();
  }

  getWeeklyAvailabilityData() {
    this.isWeeklyOccupancyLoading = true;
    const month = this.selectedMonth + 1 > 11 ? 0 : this.selectedMonth + 1;
    const year = this.selectedMonth + 1 > 11 ? this.selectedWeeklyYear + 1 : this.selectedWeeklyYear;
    const formattedStart = this.formatDate(this.selectedWeeklyYear, this.selectedMonth, 1);
    const formattedEnd = this.formatDate(year, month + 1, 0);
    if (formattedStart && formattedEnd) {
      this.resourceManagementService
        .getEntityAvailabilityWeekly(this.availabilityType, this.availabilityId, formattedStart, formattedEnd)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: EntityAvailability) => {
            this.weeklyPlot.deleteTrace(0);
            if (data.result.y) {
              data.result.y = data.result.y.map((value) => 100 - +value);
              this.weeklyPlot.addNewTrace(0, 'Test', data.result, undefined, '#f5a200', true);
            }
            this.isWeeklyOccupancyLoading = false;
          },
          () => {
            this.isWeeklyOccupancyLoading = false;
          }
        );
    }
  }

  updateWeeklyAvailabilityData() {
    this.isWeeklyOccupancyLoading = true;
    const month = this.selectedMonth + 1 > 11 ? 0 : this.selectedMonth + 1;
    const year = this.selectedMonth + 1 > 11 ? this.selectedWeeklyYear + 1 : this.selectedWeeklyYear;
    const formattedStart = this.formatDate(this.selectedWeeklyYear, this.selectedMonth, 1);
    const formattedEnd = this.formatDate(year, month + 1, 0);
    if (formattedStart && formattedEnd) {
      this.resourceManagementService
        .getEntityAvailabilityWeekly(this.availabilityType, this.availabilityId, formattedStart, formattedEnd)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: EntityAvailability) => {
            data.result.y = data.result.y.map((value) => 100 - +value);
            this.weeklyPlot.updateTraces([0], [data.result]);
            this.isWeeklyOccupancyLoading = false;
          },
          () => {
            this.isWeeklyOccupancyLoading = false;
          }
        );
    }
  }

  getMonthlyAvailabilityData() {
    this.isMonthlyOccupancyLoading = true;
    const formattedStart = this.formatDate(this.selectedMonthlyYear, 0, 1);
    const formattedEnd = this.formatDate(this.selectedMonthlyYear, 11, 31);
    if (formattedStart && formattedEnd) {
      this.resourceManagementService
        .getEntityAvailabilityMonthly(this.availabilityType, this.availabilityId, formattedStart, formattedEnd)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: EntityAvailability) => {
            this.monthlyPlot.deleteTrace(0);
            if (data.result.y) {
              data.result.y = data.result.y.map((value) => 100 - +value);

              this.monthlyPlot.addNewTrace(0, 'Test', data.result, undefined, '#f5a200', true);
            }
            this.isMonthlyOccupancyLoading = false;
          },
          () => {
            this.isMonthlyOccupancyLoading = false;
          }
        );
    }
  }

  updateMonthlyAvailabilityData() {
    this.isMonthlyOccupancyLoading = true;
    const formattedStart = this.formatDate(this.selectedMonthlyYear, 0, 1);
    const formattedEnd = this.formatDate(this.selectedMonthlyYear, 11, 31);
    if (formattedStart && formattedEnd) {
      this.resourceManagementService
        .getEntityAvailabilityMonthly(this.availabilityType, this.availabilityId, formattedStart, formattedEnd)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: EntityAvailability) => {
            data.result.y = data.result.y.map((value) => 100 - +value);
            this.monthlyPlot.updateTraces([0], [data.result]);
            this.isMonthlyOccupancyLoading = false;
          },
          () => {
            this.isMonthlyOccupancyLoading = false;
          }
        );
    }
  }

  weeklyMonthChange() {
    this.updateWeeklyAvailabilityData();
  }

  decreaseWeeklyYear() {
    this.selectedWeeklyYear--;
    this.updateWeeklyAvailabilityData();
  }

  increaseWeeklyYear() {
    this.selectedWeeklyYear++;
    this.updateWeeklyAvailabilityData();
  }

  decreaseMonthlyYear() {
    this.selectedMonthlyYear--;
    this.updateMonthlyAvailabilityData();
  }

  increaseMonthlyYear() {
    this.selectedMonthlyYear++;
    this.updateMonthlyAvailabilityData();
  }

  goThisMonth() {
    this.selectedWeeklyYear = new Date().getFullYear();
    this.selectedMonth = new Date().getMonth();
    this.updateWeeklyAvailabilityData();
  }

  goThisYear() {
    this.selectedMonthlyYear = new Date().getFullYear();
    this.updateMonthlyAvailabilityData();
  }

  getActiveTestCountWithUserId(): void {
    this.testManagementService
      .getActiveTestCountWithUserId(this.userId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: { activeTestCount: number }) => {
        this.activeTestCount = resp.activeTestCount;
      });
  }

  private formatDate(year: number, month: number, day: number): string {
    const date = new Date(year, month, day);
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd') as string;
    return formattedDate;
  }
}
