<div class="menu menu-container test-option-menu-wrapper">
  <div class="header" fxLayout="row">
    <div class="header-text">
      {{
        mode === testCreateEditMenuMode.Edit
          ? ("617" | translate: "Update Test")
          : ("529" | translate: "Start Test")
      }}
    </div>
    <div class="icons-wrapper" fxLayout="row" fxLayoutAlign="end start">
      <div
        class="close-icon-wrapper"
        (click)="closeMenu.emit()"
        matTooltip="{{ 20154 | translate: 'Close' }}"
      >
        <mat-icon>keyboard_tab</mat-icon>
      </div>
    </div>
  </div>
  <div fxFlex class="menu-content" fxLayout="column" fxLayoutGap="24px">
    <mat-vertical-stepper class="stepper-wrapper" #stepper [linear]="true">
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step
        label="{{ '572' | translate: 'Test Parameters' }}"
        [completed]="testParamsForm.valid && hasDevice"
      >
        <ng-container *ngTemplateOutlet="testParameters"></ng-container>
      </mat-step>
      <mat-step
        *ngIf="specimenParameters!.length > 0"
        label="{{ '20254' | translate: 'Specimen Parameters' }}"
        [completed]="specimenParametersForm.valid"
      >
        <ng-container *ngTemplateOutlet="specimenSection"></ng-container>
      </mat-step>
      <mat-step
        label="{{ 20308 | translate: 'Select Channels' }}"
        id="grid"
        fxLayoutGap="25px"
        [completed]="hasSelectedSensor()"
      >
        <ng-container *ngTemplateOutlet="chooseChannel"></ng-container>
      </mat-step>
      <mat-step label="{{ '20285' | translate: 'UDAQ Data Acquisition' }}">
        <ng-container *ngTemplateOutlet="udaqRegistery"></ng-container>
      </mat-step>
      <mat-step
        *ngIf="isEditMode() && canDeleteTestData"
        label="{{ '20551' | translate: 'Danger Zone' }}"
      >
        <ng-container *ngTemplateOutlet="dangerZone"></ng-container>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <div style="display: flex; width: 100%; align-self: flex-end; gap: 16px">
    <button
      *ngIf="mode === testCreateEditMenuMode.Edit"
      class="rt-btn-secondary"
      (click)="selectPowerThreshold()"
      style="margin-right: auto; padding: 0 16px"
    >
      {{ "20526" | translate: "Power Threshold Value Selection" }}
    </button>
    <button class="rt-btn-secondary rt-btn-lg" (click)="closeMenu.emit()">
      {{ "109" | translate: "Cancel" }}
    </button>
    <button
      *ngIf="mode !== testCreateEditMenuMode.Edit"
      class="rt-btn-lg"
      [ngClass]="
        isFormReady() && !isTracesNotValid()
          ? 'rt-btn-primary'
          : 'rt-btn-disable'
      "
      [disabled]="!isFormReady() || isTracesNotValid()"
      (click)="createTest()"
    >
      {{ "529" | translate: "Start Test" }}
    </button>
    <button
      *ngIf="mode === testCreateEditMenuMode.Edit"
      class="rt-btn-lg"
      [ngClass]="isFormReady() ? 'rt-btn-primary' : 'rt-btn-disable'"
      [disabled]="!isFormReady()"
      (click)="updateTest()"
    >
      {{ "617" | translate: "Update Test" }}
    </button>
  </div>
</div>
<rt-loading-indicator
  [showLoadingIndicator]="isCreateTestQuery"
  [path]="loaderPath"
  [isDarkMode]="isDarkMode"
></rt-loading-indicator>

<ng-template #testParameters>
  <div fxLayout="column" fxLayoutGap="24px">
    <form
      [formGroup]="testParamsForm"
      class="rt-form station-selection-form"
      fxLayout="column"
      fxFlex
      fxLayoutGap="16px"
    >
      <div fxLayout="row">
        <div
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(testParamsForm, 'testName')"
          fxFlex="100"
        >
          <label
            >{{ "408" | translate: "Project" }} /
            {{ "570" | translate: "Test Name" }}</label
          >
          <input matInput formControlName="testName" />
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
          <div
            class="form-row"
            fxLayout="column"
            [ngClass]="getErrorClass(testParamsForm, 'testType')"
            fxFlex="50"
          >
            <label
              [ngClass]="
                mode === testCreateEditMenuMode.Edit ||
                mode === testCreateEditMenuMode.PlanToTest
                  ? 'disabled-label'
                  : ''
              "
            >
              {{ "579" | translate: "Test Type" }}
            </label>
            <mat-select
              placeholder=""
              formControlName="testType"
              disableOptionCentering
              [disabled]="
                mode === testCreateEditMenuMode.Edit ||
                mode === testCreateEditMenuMode.PlanToTest
              "
              [ngClass]="[
                mode === testCreateEditMenuMode.PlanToTest
                  ? 'disabled-input'
                  : '',
                mode === testCreateEditMenuMode.Edit ||
                mode === testCreateEditMenuMode.PlanToTest
                  ? 'disable-dropdown'
                  : ''
              ]"
            >
              <mat-option
                *ngFor="let testType of testTypes"
                [value]="testType.id"
              >
                {{ testType.name }}
              </mat-option>
            </mat-select>
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="16px">
        <div
          class="form-row"
          fxLayout="column"
          [ngClass]="getErrorClass(testParamsForm, 'sampleTime')"
          fxFlex="100"
        >
          <label>{{ "482" | translate: "Sampling Time" }}</label>
          <mat-select
            placeholder=""
            formControlName="sampleTime"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let sampleTime of sampleTimes"
              [value]="sampleTime.id"
            >
              {{
                testCreateEditMenuTestSampleUnit ===
                testCreateEditMenuTestSampleUnitEnum.MS
                  ? sampleTime.time
                  : sampleTime.time / 1000
              }}
              {{
                testCreateEditMenuTestSampleUnit ===
                testCreateEditMenuTestSampleUnitEnum.MS
                  ? millisecondsText
                  : secondsText
              }}
            </mat-option>
          </mat-select>
          <div class="error-text">
            {{ "436" | translate: "Required Field" }}
          </div>
        </div>
      </div>

      <div
        class="form-row"
        [ngClass]="getErrorClass(testParamsForm, 'description')"
      >
        <label>{{ "563" | translate: "Test Description" }}</label>
        <textarea rows="3" formControlName="description"></textarea>
        <div class="error-text">{{ "436" | translate: "Required Field" }}</div>
      </div>
      <div class="form-row" fxLayout="column">
        <label
          [ngClass]="
            mode === testCreateEditMenuMode.Edit ? 'disabled-label' : ''
          "
        >
          {{ "180" | translate: "Device" }}
        </label>
        <div>
          <input
            type="text"
            matInput
            [readOnly]="
              mode === testCreateEditMenuMode.Edit ||
              mode === testCreateEditMenuMode.PlanToTest
            "
            [matAutocomplete]="auto"
            (input)="onSearchChange($event.target)"
            #deviceInput
            [ngClass]="
              mode === testCreateEditMenuMode.Edit ||
              mode === testCreateEditMenuMode.PlanToTest
                ? 'disabled-input'
                : ''
            "
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let device of filteredDevices"
              [value]="device"
              (click)="deviceSelected(device)"
            >
              <div class="device-id">{{ device.id }} -</div>
              <div fxFlex>{{ device.name }}</div>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </form>
    <div fxLayoutAlign="end end">
      <button
        class="rt-btn-primary"
        [ngClass]="
          testParamsForm.valid && hasDevice
            ? 'rt-btn-primary'
            : 'rt-btn-disable'
        "
        (click)="stepper.next()"
      >
        {{ "342" | translate: "Next Step" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #specimenSection>
  <div fxLayout="column" fxLayoutGap="24px">
    <form [formGroup]="specimenParametersForm" class="rt-form">
      <div *ngFor="let specimenParameter of specimenParameters">
        <div [ngSwitch]="specimenParameter.attribute_definition.type">
          <div
            *ngSwitchCase="attributeDataType.Text"
            class="form-row"
            fxLayout="column"
            [ngClass]="
              getErrorClass(
                specimenParametersForm,
                specimenParameter.attribute_definition.id.toString()
              )
            "
          >
            <label>
              {{
                specimenParameter.attribute_definition.translation_key_id ?? ""
                  | translate: specimenParameter.attribute_definition.name
              }}
            </label>
            <textarea
              rows="3"
              [formControlName]="
                specimenParameter.attribute_definition.id.toString()
              "
            ></textarea>
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
          <div
            *ngSwitchCase="attributeDataType.Number"
            class="form-row"
            fxLayout="column"
            [ngClass]="
              getErrorClass(
                specimenParametersForm,
                specimenParameter.attribute_definition.id.toString()
              )
            "
          >
            <label>
              {{
                specimenParameter.attribute_definition.translation_key_id ?? ""
                  | translate: specimenParameter.attribute_definition.name
              }}
            </label>
            <input
              matInput
              type="number"
              [formControlName]="
                specimenParameter.attribute_definition.id.toString()
              "
              [ngClass]="
                mode === testCreateEditMenuMode.Edit ? 'disabled-input' : ''
              "
            />
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
          <div
            *ngSwitchCase="attributeDataType.Date"
            class="form-row specimen-parameter-date-picker"
            fxLayout="column"
            [ngClass]="
              getErrorClass(
                specimenParametersForm,
                specimenParameter.attribute_definition.id.toString()
              )
            "
          >
            <label>
              {{
                specimenParameter.attribute_definition.translation_key_id ?? ""
                  | translate: specimenParameter.attribute_definition.name
              }}
            </label>
            <div fxLayout="row">
              <input
                matInput
                class="form-control"
                [matDatepicker]="picker1"
                [formControlName]="
                  specimenParameter.attribute_definition.id.toString()
                "
                readonly
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </div>
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
          <div
            *ngSwitchCase="attributeDataType.String"
            class="form-row"
            fxLayout="column"
            [ngClass]="
              getErrorClass(
                specimenParametersForm,
                specimenParameter.attribute_definition.id.toString()
              )
            "
          >
            <label>
              {{
                specimenParameter.attribute_definition.translation_key_id ?? ""
                  | translate: specimenParameter.attribute_definition.name
              }}
            </label>
            <input
              matInput
              type="text"
              [formControlName]="
                specimenParameter.attribute_definition.id.toString()
              "
            />
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
          <div
            *ngSwitchCase="attributeDataType.Boolean"
            class="form-row"
            fxLayout="column"
            [ngClass]="
              getErrorClass(
                specimenParametersForm,
                specimenParameter.attribute_definition.id.toString()
              )
            "
          >
            <label>
              {{
                specimenParameter.attribute_definition.translation_key_id ?? ""
                  | translate: specimenParameter.attribute_definition.name
              }}
            </label>

            <mat-checkbox
              [formControlName]="
                specimenParameter.attribute_definition.id.toString()
              "
            ></mat-checkbox>
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
          <div
            *ngSwitchCase="attributeDataType.Dropdown"
            class="form-row"
            fxLayout="column"
            [ngClass]="
              getErrorClass(
                specimenParametersForm,
                specimenParameter.attribute_definition.id.toString()
              )
            "
          >
            <label>
              {{
                specimenParameter.attribute_definition.translation_key_id ?? ""
                  | translate: specimenParameter.attribute_definition.name
              }}
            </label>
            <mat-select
              placeholder=""
              [formControlName]="
                specimenParameter.attribute_definition.id.toString()
              "
              disableOptionCentering
            >
              <mat-option
                *ngFor="
                  let attribute_definition_detail of specimenParameter
                    .attribute_definition.attribute_definition_details
                "
                [value]="attribute_definition_detail.id"
              >
                {{ attribute_definition_detail.value }}
              </mat-option>
            </mat-select>
            <div class="error-text">
              {{ "436" | translate: "Required Field" }}
            </div>
          </div>
        </div>
      </div>
    </form>

    <div fxLayoutAlign="end end">
      <button
        class="rt-btn-primary"
        [ngClass]="
          specimenParametersForm.valid ? 'rt-btn-primary' : 'rt-btn-disable'
        "
        [disabled]="!specimenParametersForm.valid"
        (click)="stepper.next()"
      >
        {{ "342" | translate: "Next Step" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #chooseChannel>
  <div fxFlex fxLayout="column" fxLayoutGap="16px">
    <rt-tabs
      [options]="channelSelectionOptions"
      [defaultSelection]="selectedChannelOption"
      (changeToggle)="changeSelectedPage($event)"
      [ngClass]="!isEditMode() ? '' : 'has-not-template'"
      style="overflow-x: auto"
    ></rt-tabs>
    <div fxFlex [ngSwitch]="selectedChannelOption">
      <div
        *ngSwitchCase="channelSelectOptions.OpenChannels"
        fxLayout="column"
        fxLayoutGap="12px"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="header-desc" fxFlex="noshrink">
            {{ 20289 | translate: "Channel Filter" }}:
          </div>
          <input [formControl]="sensorFilterInput" />
        </div>
        <div
          class="mobile-flex-direction-column"
          style="display: flex; flex-direction: row; gap: 16px"
        >
          <div
            class="available-channels-wrapper mobile-full-width mobile-max-height-300"
            style="
              display: flex;
              flex-direction: column;
              height: 520px;
              max-height: 520px;
              width: 237px;
            "
          >
            <div class="available-channel-header">
              {{ "90" | translate: "Available Channels" }}
            </div>

            <div class="available-channels-content-wrapper" fxLayout="column">
              <mat-accordion multi="true">
                <mat-expansion-panel
                  *ngFor="
                    let sensorGroup of filteredGroupedSensorsForSelectedStation
                  "
                  class="grouped-sensors-expansion-panel"
                  expanded="true"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div fxFlex fxLayout="row" class="panel-title">
                        <div class="sensor-group-name">
                          {{ sensorGroup.name }}
                        </div>
                        <mat-icon
                          *ngIf="sensorGroup.sensors.length > 0"
                          class="add-button"
                          fxLayoutAlign="end center"
                          (click)="
                            addGroupToSelectedChannelList(sensorGroup, $event)
                          "
                        >
                          add
                        </mat-icon>
                      </div>
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                  </mat-expansion-panel-header>
                  <div
                    fxFlex
                    fxLayout="column"
                    fxLayoutGap="8px"
                    class="expansion-sub-item-container"
                  >
                    <div
                      *ngFor="let sensor of sensorGroup.sensors"
                      fxLayoutAlign="center center"
                    >
                      <span
                        fxFlex
                        class="sensor-description"
                        matTooltip="{{ sensor?.unChangeableChannelCodeText }}"
                      >
                        {{ sensor?.unChangeableChannelCodeText }}
                      </span>
                      <button
                        class="clickable select-sensor-button"
                        (click)="selectSensor({ sensor, isUserEvent: true })"
                      >
                        <mat-icon class="add-button" fxLayoutAlign="end center"
                          >add</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="available-channel-list">
                <div
                  *ngFor="
                    let sensor of filteredUngroupedSensorsForSelectedStation
                  "
                  class="sensor-list clickable"
                >
                  <span fxFlex>{{
                    sensor?.channelDescription?.description
                  }}</span>
                  <button
                    class="clickable select-sensor-button"
                    (click)="selectSensor({ sensor, isUserEvent: true })"
                  >
                    <mat-icon fxLayoutAlign="end center">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="selected-channels mobile-max-height-300"
            style="
              display: flex;
              flex-direction: column;
              flex: 1 1 0%;
              height: 520px;
              max-height: 520px;
            "
          >
            <div class="selected-channels-header" fxLayout="row">
              <div fxFlex>{{ "505" | translate: "Selected Channels" }}</div>
              <mat-icon
                class="action-icons-item clickable"
                mat-icon-button
                [matMenuTriggerFor]="moreMenu"
                *ngIf="mode !== testCreateEditMenuMode.Edit"
              >
                more_horiz
              </mat-icon>
              <mat-menu #moreMenu="matMenu" class="channel-operations-mat-menu">
                <div class="channel-operations-menu-wrapper">
                  <div fxLayoutGap="4px" fxLayout="column">
                    <button
                      mat-menu-item
                      (click)="openSaveAsTemplateDialog()"
                      [disabled]="getSensorsInSelectedStation().length === 0"
                    >
                      <mat-icon class="menu-action-icon">save</mat-icon>
                      <span
                        class="menu-action-text"
                        [ngClass]="{
                          'disabled-btn':
                            getSensorsInSelectedStation().length === 0
                        }"
                      >
                        {{ "484" | translate: "Save as Template" }}
                      </span>
                    </button>
                    <mat-divider></mat-divider>
                    <button
                      mat-menu-item
                      (click)="deleteSelectedChannels()"
                      class="menu-selection-item-with-warning"
                    >
                      <mat-icon>delete</mat-icon>
                      <span class="delete">{{
                        "161" | translate: "Delete All"
                      }}</span>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </div>
            <div
              class="table-wrapper selected-channels-table"
              *ngIf="hasSelectedChannel()"
            >
              <table
                mat-table
                [dataSource]="tableDataSource"
                class="channels-table"
              >
                <!-- Code Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "125" | translate: "Code" }}
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "333" | translate: "Name" }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    class="trace-name-container"
                  >
                    <div
                      class="trace-name-input-wrapper"
                      fxLayout="column"
                      [ngClass]="{
                        'form-field-not-valid': getTraceValidation(element)
                      }"
                    >
                      <input
                        (input)="traceNameChange($event, element)"
                        type="text"
                        [value]="element.name"
                        class="form-control trace-name-input"
                      />
                      <div class="error-text">
                        {{ "436" | translate: "Required Field" }}
                      </div>
                    </div>
                  </td>
                </ng-container>

                <!-- liveValue Column -->
                <ng-container matColumnDef="liveValue">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "311" | translate: "Live Value" }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.liveValue }}
                  </td>
                </ng-container>

                <!-- unit Column -->
                <ng-container matColumnDef="unit">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "610" | translate: "Unit" }}
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell class="schedule-button" *matCellDef="let row">
                    <mat-icon class="clickable" (click)="onDeleteSensor(row)"
                      >delete_outline</mat-icon
                    >
                  </mat-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  (click)="selection.toggle(row)"
                ></tr>
              </table>
            </div>
            <div *ngIf="!hasSelectedChannel()" class="no-selected-channel-text">
              {{
                "1905"
                  | translate
                    : "To open the register of channels, select a channel from the left table or choose a template."
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        *ngSwitchCase="channelSelectOptions.Templates"
        class="templates-wrapper"
      >
        <ng-container *ngTemplateOutlet="templatesView"></ng-container>
      </div>
      <div
        *ngSwitchCase="channelSelectOptions.AnalogScale"
        class="mobile-flex-direction-column"
        style="display: flex; flex-direction: row; gap: 16px"
      >
        <div
          class="selected-channels mobile-max-height-300"
          style="
            display: flex;
            flex-direction: column;
            flex: 1 1 0%;
            height: 520px;
            max-height: 520px;
          "
        >
          <div class="selected-channels-header" fxLayout="row">
            <div fxFlex>{{ "1906" | translate: "Analog Channels" }}</div>
          </div>
          <div
            class="table-wrapper selected-channels-table"
            *ngIf="hasAnalogSelectedChannel()"
          >
            <table
              mat-table
              [dataSource]="analogScaleTableDataSource"
              class="channels-table"
            >
              <!-- Code Column -->
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "125" | translate: "Code" }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.code }}</td>
              </ng-container>

              <!-- name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "333" | translate: "Name" }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <!-- analogScaleId Column -->
              <ng-container matColumnDef="analogScaleId">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "1907" | translate: "Analog Scale" }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select
                    id="element.id"
                    disableOptionCentering
                    [(value)]="element.analogScaleId"
                  >
                    <mat-option
                      *ngFor="let scale of analogScales"
                      [value]="scale.id"
                    >
                      name: {{ scale.name }} => {{ scale.calibration.a }} -
                      {{ scale.calibration.b }}
                    </mat-option>
                  </mat-select>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedAnalogColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedAnalogColumns"
                (click)="selection.toggle(row)"
              ></tr>
            </table>
          </div>
          <div
            *ngIf="!hasAnalogSelectedChannel()"
            class="no-selected-channel-text"
          >
            {{ "1908" | translate: "Please Select Analog Channel." }}
          </div>
        </div>
      </div>
      <div
        *ngSwitchCase="channelSelectOptions.OtherSources"
        fxLayout="column"
        fxLayoutGap="12px"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="header-desc" fxFlex="noshrink">
            {{ 20289 | translate: "Channel Filter" }}:
          </div>
          <input [formControl]="sensorFilterInput" />
        </div>
        <div
          class="mobile-flex-direction-column"
          style="display: flex; flex-direction: row; gap: 16px"
        >
          <div
            class="available-channels-wrapper mobile-full-width mobile-max-height-300"
            style="
              display: flex;
              flex-direction: column;
              height: 520px;
              max-height: 520px;
              width: 217px;
            "
          >
            <div class="available-channel-header">
              {{ "90" | translate: "Available Channels" }}
            </div>
            <div class="available-channels-content-wrapper" fxLayout="column">
              <mat-accordion multi="true">
                <mat-expansion-panel
                  *ngFor="
                    let sensorGroup of filteredGroupedSensorsForAllStationsExceptSelected
                  "
                  class="grouped-sensors-expansion-panel"
                  expanded="true"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div fxFlex fxLayout="row" class="panel-title">
                        <div class="sensor-group-name">
                          {{ sensorGroup.name }}
                        </div>
                        <mat-icon
                          *ngIf="sensorGroup.sensors.length > 0"
                          class="add-button"
                          fxLayoutAlign="end center"
                          (click)="
                            addGroupToSelectedChannelList(sensorGroup, $event)
                          "
                        >
                          add
                        </mat-icon>
                      </div>
                    </mat-panel-title>
                    <mat-panel-description></mat-panel-description>
                  </mat-expansion-panel-header>
                  <div
                    fxFlex
                    fxLayout="column"
                    fxLayoutGap="8px"
                    class="expansion-sub-item-container"
                  >
                    <div
                      *ngFor="let sensor of sensorGroup.sensors"
                      fxLayoutAlign="center center"
                    >
                      <span
                        fxFlex
                        class="sensor-description"
                        matTooltip="{{
                          sensor?.unChangeableChannelCodeText
                        }} / {{ sensor?.stationName }}"
                      >
                        {{ sensor?.unChangeableChannelCodeText }} /
                        {{ sensor?.stationName }}
                      </span>
                      <button
                        class="clickable select-sensor-button"
                        (click)="selectSensor({ sensor, isUserEvent: true })"
                      >
                        <mat-icon class="add-button" fxLayoutAlign="end center"
                          >add</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="available-channel-list">
                <div
                  *ngFor="
                    let sensor of filteredUngroupedSensorsForAllStationsExceptSelected
                  "
                  class="sensor-list clickable"
                >
                  <span fxFlex
                    >{{ sensor?.channelDescription?.description }} /
                    {{ sensor?.stationName }}</span
                  >
                  <button
                    class="clickable select-sensor-button"
                    (click)="selectSensor({ sensor, isUserEvent: true })"
                  >
                    <mat-icon fxLayoutAlign="end center">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="selected-channels mobile-max-height-300"
            style="
              display: flex;
              flex-direction: column;
              flex: 1 1 0%;
              height: 520px;
              max-height: 520px;
            "
          >
            <div class="selected-channels-header" fxLayout="row">
              <div fxFlex>{{ "505" | translate: "Selected Channels" }}</div>
              <mat-icon
                class="action-icons-item clickable"
                mat-icon-button
                [matMenuTriggerFor]="moreMenu"
                *ngIf="mode !== testCreateEditMenuMode.Edit"
              >
                more_horiz
              </mat-icon>
              <mat-menu #moreMenu="matMenu" class="channel-operations-mat-menu">
                <div class="channel-operations-menu-wrapper">
                  <div fxLayoutGap="4px" fxLayout="column">
                    <button mat-menu-item (click)="openSaveAsTemplateDialog()">
                      <mat-icon class="menu-action-icon">save</mat-icon>
                      <span class="menu-action-text">{{
                        "484" | translate: "Save as Template"
                      }}</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button
                      mat-menu-item
                      (click)="deleteSelectedChannels()"
                      class="menu-selection-item-with-warning"
                    >
                      <mat-icon>delete</mat-icon>
                      <span class="delete">{{
                        "161" | translate: "Delete All"
                      }}</span>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </div>
            <div
              class="table-wrapper selected-channels-table"
              *ngIf="hasSelectedChannel()"
            >
              <table
                mat-table
                [dataSource]="tableDataSource"
                class="channels-table"
              >
                <!-- Code Column -->
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "125" | translate: "Code" }}
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>

                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "333" | translate: "Name" }}
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    class="trace-name-container"
                  >
                    <div
                      class="trace-name-input-wrapper"
                      fxLayout="column"
                      [ngClass]="{
                        'form-field-not-valid': getTraceValidation(element)
                      }"
                    >
                      <input
                        (input)="traceNameChange($event, element)"
                        type="text"
                        [value]="element.name"
                        class="form-control trace-name-input"
                      />
                      <div class="error-text">
                        {{ "436" | translate: "Required Field" }}
                      </div>
                    </div>
                  </td>
                </ng-container>

                <!-- liveValue Column -->
                <ng-container matColumnDef="liveValue">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "311" | translate: "Live Value" }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.liveValue }}
                  </td>
                </ng-container>

                <!-- unit Column -->
                <ng-container matColumnDef="unit">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "610" | translate: "Unit" }}
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell class="schedule-button" *matCellDef="let row">
                    <mat-icon class="clickable" (click)="onDeleteSensor(row)"
                      >delete_outline</mat-icon
                    >
                  </mat-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  (click)="selection.toggle(row)"
                ></tr>
              </table>
            </div>
            <div *ngIf="!hasSelectedChannel()" class="no-selected-channel-text">
              {{
                "1905"
                  | translate
                    : "To open the register of channels, select a channel from the left table or choose a template."
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
      <div
        *ngIf="!this.isAllSelectedSensorHaveLiveValue()"
        fxLayout="column"
        fxLayoutGap="8px"
        style="overflow-x: auto"
      >
        <div class="has-no-live-value-warning">
          {{
            "20430"
              | translate
                : "Among the selected channels, there are channel(s) whose data cannot be read!"
          }}
        </div>
        <div style="display: flex; gap: 4px; overflow-x: auto">
          <div
            class="has-no-live-value-warning"
            *ngFor="let sensor of noLiveValueSensors"
          >
            {{ sensor.channelDescription?.description }}
          </div>
        </div>
      </div>

      <button
        class="rt-btn-primary"
        [ngClass]="
          this.hasSelectedSensor() ? 'rt-btn-primary' : 'rt-btn-disable'
        "
        [disabled]="!this.hasSelectedSensor()"
        style="flex-shrink: 0"
        (click)="stepper.next()"
      >
        {{ "342" | translate: "Next Step" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #templatesView>
  <div fxFlex>
    <div class="templates-wrapper" fxLayout="column">
      <div class="templates-header">{{ "561" | translate: "Templates" }}</div>
      <mat-stepper
        class="channel-selection-and-template-stepper mobile-max-height-300"
        #templateStepper
        fxFlex
        style="height: 520px; max-height: 520px"
      >
        <mat-step fxFlex fxLayoutGap="2px">
          <div
            class="template-filter-wrapper"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
          >
            <div class="header-desc" fxFlex="noshrink">
              {{ "20327" | translate: "Template Filter" }}:
            </div>
            <input [formControl]="templateFilterInput" />
          </div>
          <div class="template-list-wrapper" fxLayout="column">
            <div
              *ngFor="let channelDetail of channelsDetails"
              fxLayout="row"
              matStepperNext
              (click)="templateSelected(channelDetail); templateStepper.next()"
            >
              <ng-container
                *ngIf="
                  channelDetail?.name
                    .toLowerCase()
                    .includes(this.templateFilterText.toLowerCase())
                "
              >
                <div
                  class="available-channel-list-and-template clickable"
                  fxLayout="row"
                  fxFlex
                >
                  <div fxFlex fxLayout="row" fxFlex>
                    <div fxLayout="column" fxFlex="10" fxLayoutGap="3px">
                      <span class="channel-detail-user-name">{{
                        channelDetail?.id
                      }}</span>
                    </div>
                    <div fxLayout="column" fxFlex="55" fxLayoutGap="3px">
                      <span class="channel-detail-user-name">{{
                        channelDetail?.name
                      }}</span>
                    </div>
                    <span fxFlex="35" class="channel-count">
                      {{ channelDetail?.channels.length }}
                      {{ "114" | translate: "Channel" }}
                    </span>
                  </div>
                  <button
                    class="rt-btn-secondary delete-template-btn"
                    (click)="
                      deleteTemplateToSelectedChannelList(
                        channelDetail.id,
                        $event
                      )
                    "
                  >
                    {{ "160" | translate: "Delete" }}
                  </button>
                  <button
                    class="rt-btn-secondary delete-template-btn"
                    (click)="
                      addTemplateToSelectedChannelList(channelDetail, $event)
                    "
                  >
                    {{ "12" | translate: "Add" }}
                  </button>
                  <mat-icon fxLayoutAlign="end center">navigate_next</mat-icon>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-step>
        <mat-step fxLayout="column" fxFlex>
          <div
            class="selected-channel-name clickable"
            fxLayout="row"
            (click)="templateStepper.previous()"
          >
            <mat-icon class="back-icon">arrow_back</mat-icon>
            <div class="name">{{ selectedTemplate?.name }}</div>
          </div>
          <div class="channel-detail-list-wrapper" fxFlex>
            <div
              *ngFor="let channel of selectedTemplate?.channels"
              class="channel-detail-list clickable"
            >
              <div fxFlex fxLayout="row">
                <div fxLayout="column" fxFlex="60" fxLayoutGap="3px">
                  <span class="channel-detail-name">{{ channel?.code }}</span>
                </div>
                <span fxFlex="40" class="channel-count">{{
                  channel?.name
                }}</span>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</ng-template>

<ng-template #udaqRegistery>
  <div class="udaq-registery-state-wrapper" *ngIf="udaqSensorId !== -1">
    <mat-accordion>
      <mat-expansion-panel
        class="udaq-registery-expansion-panel"
        [expanded]="hasIniFiles"
        (opened)="openedExpansionPanel()"
        (closed)="closedExpansionPanel()"
      >
        <mat-expansion-panel-header
          class="udaq-reqistery-expansion-panel-header"
          #panelHeader
          (click)="panelHeader._toggle()"
        >
          <div fxLayout="row" class="udaq-reqistery-panel-header">
            <mat-panel-title fxLayout="column" fxLayoutGap="10px">
              <span class="udaq-reqistery-header">{{
                "283" | translate: "Ini Files"
              }}</span>
              <div fxLayout="row" fxLayoutGap="28px">
                <span class="header-desc">ini 1: {{ iniFileName1 }}</span>
                <span class="header-desc">ini 2: {{ iniFileName2 }}</span>
              </div>
            </mat-panel-title>
            <button
              fxLayoutAlign="end center"
              class="udaq-reqistery-header-detail"
              [ngClass]="
                isMakingIniCheckQuery ? 'rt-btn-disable' : 'rt-btn-fourth'
              "
              (click)="iniCheck($event)"
            >
              {{ "20401" | translate: "Check Udaq" }}
            </button>
          </div>
        </mat-expansion-panel-header>
        <div
          class="udaq-reqistery-content"
          fxLayout="column"
          fxLayoutGap="24px"
        >
          <div fxLayout="row" fxLayoutGap="5px" class="vertical-align-center">
            <mat-icon class="udaq-status-icon">check</mat-icon>
            <span class="udaq-registery-desc">
              {{ "284" | translate: "Ini files found udaq can be recorded." }}
            </span>
          </div>
          <mat-slide-toggle
            [checked]="isUdaqRecord"
            [disabled]="!hasIniFiles"
            (change)="changedIsUdaqRecord($event)"
            class="slide-udaq-registery"
          >
            {{ "422" | translate: "Register Udaq" }}
          </mat-slide-toggle>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <rt-loading-indicator
      [showLoadingIndicator]="isMakingIniCheckQuery"
      [path]="loaderPath"
      [isDarkMode]="isDarkMode"
    ></rt-loading-indicator>
  </div>
  <div class="udaq-disabled-text" *ngIf="udaqSensorId === -1">
    {{ "20434" | translate: "This station has no Udaq sensor!" }}
  </div>
</ng-template>

<ng-template style="display: flex; flex-direction: column" #dangerZone>
  <p style="color: red; font-weight: 600">
    {{ "20552" | translate: "This button deletes all collected test data!" }}
  </p>
  <button
    class="rt-btn-lg rt-btn-primary"
    style="background-color: red"
    (click)="deleteAllTestData()"
  >
    {{ "20549" | translate: "Delete All Test Data" }}
  </button>
</ng-template>
