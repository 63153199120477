export enum FilterTableName {
  ActiveTests = 'ActiveTests',
  FinishedTests = 'FinishedTests',
  ArchiveTests = 'ArchiveTests',
  PlannedTests = 'PlannedTests',
  PlannedTestsFromStationDetails = 'PlannedTestsFromStationDetails',
  ExtraTimeTests = 'ExtraTimeTests',
  LongRunningJobs = 'LongRunningJobs',
  ResourceList = 'ResourceList',
  RoleList = 'RoleList',
  RuleList = 'RuleList',
  SourceList = 'SourceList',
  RuleDetail = 'RuleDetail',
  SourceDetail = 'SourceDetail',
  Calibration = 'Calibration',
  CalibrationValues = 'CalibrationValues',
  Language = 'Language',
  OnBoardingReq = 'OnBoardingReq',
  ResourceDefList = 'ResourceDefList',
  SampleRate = 'SampleRate',
  TestType = 'TestType',
  TestSelect = 'TestSelect',
  DeviceGrid = 'DeviceGrid',
  SelectUser = 'SelectUser',
  ActionPolicyList = 'SourcePolicyList',
  ActionUserList = 'SourceUserList',
  Notification = 'Notification',
  SensorSetting = 'SensorSetting',
  NotificationCondition = 'NotificationCondition',
  GateTestSettings = 'GateTestSettings',
  GateControlTemplates = 'GateControlTemplates',
  GateControlCovers = 'GateControlCovers',
  AnalogScale = 'AnalogScale',
}
