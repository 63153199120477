import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-save-as-template-dialog',
  templateUrl: './save-as-template-dialog.component.html',
  styleUrls: ['./save-as-template-dialog.component.scss'],
})
export class SaveAsTemplateDialogComponent implements OnInit {
  @Output() closeDialogEvent = new EventEmitter();
  saveButtonDisable = true;

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {}

  onChange(text: any) {
    this.saveButtonDisable = !(text.value.trim().length > 0);
  }

  saveAsTemplate(name: string) {
    this.closeDialogEvent.emit(name);
  }

  closeDialog() {
    this.matDialog.closeAll();
  }
}
