import { Injectable } from '@angular/core';
import { NotificationType, ThirdPartyComponentTypes } from 'src/app/core/enums';
import { LanguageService } from 'src/app/core/services';
import { TranslatePipe } from '../pipes';

@Injectable()
export class UtilService {
  private yearText = new TranslatePipe(this.languageService).transform('640', 'year');
  private monthText = new TranslatePipe(this.languageService).transform('329', 'month');
  private dayText = new TranslatePipe(this.languageService).transform('156', 'day');
  private hourText = new TranslatePipe(this.languageService).transform('264', 'hour');
  private minuteText = new TranslatePipe(this.languageService).transform('326', 'minute');
  private secondText = new TranslatePipe(this.languageService).transform('494', 'second');
  private yearsText = new TranslatePipe(this.languageService).transform('20446', 'years');
  private monthsText = new TranslatePipe(this.languageService).transform('20447', 'months');
  private daysText = new TranslatePipe(this.languageService).transform('20448', 'days');
  private hoursText = new TranslatePipe(this.languageService).transform('20449', 'hours');
  private minutesText = new TranslatePipe(this.languageService).transform('20450', 'minutes');
  private secondsText = new TranslatePipe(this.languageService).transform('20451', 'seconds');

  constructor(private languageService: LanguageService) {}

  sortArrayIncreasingOrder(members: any[], sortParameter: string) {
    return members.sort((firstItem, secondItem) =>
      firstItem[sortParameter] === secondItem[sortParameter]
        ? 0
        : firstItem[sortParameter] < secondItem[sortParameter]
        ? -1
        : 1
    );
  }

  sortArrayDescendingOrder(members: any[], sortParameter: string) {
    return members.sort((firstItem, secondItem) =>
      firstItem[sortParameter] === secondItem[sortParameter]
        ? 0
        : firstItem[sortParameter] < secondItem[sortParameter]
        ? 1
        : -1
    );
  }

  getLastRowIndex(request: any, results: any) {
    if (!results || results.length === 0) {
      return request.startRow;
    }

    const currentLastRow = request.startRow + results.length;

    return currentLastRow + 1 <= request.endRow ? currentLastRow : -1;
  }

  isArraysEqual(firstArray: any[], secondArray: any[]): boolean {
    return (
      firstArray.length === secondArray.length &&
      firstArray.every((obj, idx) => this.isObjectsEqual(obj, secondArray[idx]))
    );
  }

  isObjectsEqual(firstObj: any, secondObj: any): boolean {
    return (
      Object.keys(firstObj).length === Object.keys(secondObj).length &&
      Object.keys(firstObj).every((key) => firstObj[key] === secondObj[key])
    );
  }

  getLanguageCodeByThirdPartyComponentType(componentType: number) {
    if (!!JSON.parse(localStorage.getItem('languageCodes') as string)[0]) {
      const componentLangCode = JSON.parse(localStorage.getItem('languageCodes') as string).filter(
        (code: { component_id: ThirdPartyComponentTypes }) => code.component_id == componentType
      );
      return componentLangCode[0].code;
    }
  }

  getDuration(date: any): string {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + (interval >= 2 ? this.yearsText : this.yearText);
    }

    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + (interval >= 2 ? this.monthsText : this.monthText);
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + (interval >= 2 ? this.daysText : this.dayText);
    }

    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + (interval >= 2 ? this.hoursText : this.hourText);
    }

    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + (interval >= 2 ? this.minutesText : this.minuteText);
    }

    return Math.floor(seconds) + ' ' + (seconds >= 2 ? this.secondsText : this.secondText);
  }

  getNotificationFieldsFromData(translatedText: string, data: any, type?: NotificationType): string {
    const matches = translatedText.match(/(?<=\[).+?(?=\])/g);
    if (matches) {
      matches.forEach((key: any) => {
        let value = key === 'date' ? new Date(data[key]).toLocaleString() : data[key];

        if (type === NotificationType.GenericNotification) {
          value = JSON.stringify(value);
        }
        if (value) {
          translatedText = translatedText.replace('[' + key + ']', value);
        }
      });
    }
    return translatedText;
  }
}
