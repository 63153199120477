import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { LanguageService } from 'src/app/core/services';
import { FilterSortType, SortDirectionType } from 'src/app/shared/enums';
import { TranslatePipe } from '../../pipes';

@Component({
  selector: 'app-test-room-list-filter-dialog',
  templateUrl: './test-room-list-filter-dialog.component.html',
  styleUrls: ['./test-room-list-filter-dialog.component.scss'],
})
export class TestRoomListFilterDialogComponent implements OnInit, OnDestroy {
  applyFilter = new Subject();
  resetFilter = new Subject();

  private alphabeticalText = new TranslatePipe(this.languageService).transform(1909, 'Alphabetical');
  private instantOccupancyText = new TranslatePipe(this.languageService).transform(1887, 'Instant Occupancy');
  private weeklyOccupancyText = new TranslatePipe(this.languageService).transform(1840, 'Weekly Occupancy');

  sortTypes = [
    { id: FilterSortType.alphabeticByRoomName, name: this.alphabeticalText },
    { id: FilterSortType.availabilityInstant, name: this.instantOccupancyText },
    { id: FilterSortType.availabilityWeekly, name: this.weeklyOccupancyText },
  ];

  roomsSortFilterForm: FormGroup;

  roomDynamicFieldsWithOptions: {
    title: string;
    values: (string | number)[];
  }[];

  filterForm: FormGroup;
  selectedSortTypeId: any;

  private unsubscribe = new Subject<void>();

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get sortDirectionType() {
    return SortDirectionType;
  }

  changeSortDirectionType() {
    this.roomsSortFilterForm.patchValue({
      sortDirection:
        this.roomsSortFilterForm.get('sortDirection')!.value === SortDirectionType.Ascending
          ? SortDirectionType.Descending
          : SortDirectionType.Ascending,
    });
  }
}
