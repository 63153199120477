<div class="lab-room-selector-wrapper menu-item-wrapper">
  <mat-menu class="lab-room-selector-mat-menu" #childMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let menuItem of menuItems">
      <!-- Handle branch node menu items -->
      <span *ngIf="hasChildren(menuItem)">
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu" class="sub-menu-btn">
          <span>{{ menuItem.name }}</span>
        </button>
        <app-menu-item #menu [itemType]="itemType" [menuItems]="menuItem.children"></app-menu-item>
      </span>

      <!-- Handle leaf node menu items -->
      <span *ngIf="isLeafNode(menuItem)" fxLayout="column">
        <mat-radio-button
          (click)="itemType === menuItemType.station ? stationChanged(menuItem) : roomChanged(menuItem)"
          [value]="menuItem.id"
          [id]="menuItem.id"
          matTooltip="{{ menuItem.name }}"
          [checked]="menuItem.isSelected">
          {{ menuItem.name }}
        </mat-radio-button>
      </span>
    </span>
  </mat-menu>
</div>
