<div
  class="delete-sensor-confirmation-dialog-wrapper"
  fxLayout="column"
  fxLayoutGap="34px"
  fxFlexFill
  fxLayoutAlign="space-between stretch">
  <div class="delete-sensor-dialog-content" fxFlex fxLayout="row" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="center center" class="delete-sensor-dialog-icon-wrapper">
      <mat-icon>report_gmailerrorred</mat-icon>
    </div>

    <div fxFlex fxLayout="column">
      <span class="delete-sensor-dialog-header-text">
        {{ 20503 | translate : 'Are you sure you want to delete this channel?' }}
      </span>
      <span class="delete-sensor-dialog-info-text">{{ 20504 | translate : 'This action cannot be undone.' }}</span>
    </div>
  </div>
  <div fxLayoutAlign="end end" fxLayout="row" fxLayoutGap="16px">
    <button class="rt-btn-fourth delete-sensor-dialog-cancel-btn" (click)="onCancel()">
      {{ '109' | translate : 'Cancel' }}
    </button>
    <button class="rt-btn-primary delete-sensor-dialog-delete-btn" (click)="onApprove()">
      {{ '20505' | translate : 'Yes Delete' }}
    </button>
  </div>
</div>
