<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="16px" class="lab-room-station-filter-wrapper">
  <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4px" class="selector-wrapper">
    <label>{{ 294 | translate: 'Lab' }}</label>
    <mat-select
      class="room-stations-dropdown"
      placeholder="{{ '54' | translate: 'All Laboratories' }}"
      disableOptionCentering
      [(ngModel)]="selectedLabId"
      (selectionChange)="filterTheRooms($event)"
      panelClass="custom-select-panel">
      <mat-option *ngFor="let lab of labRoomsStations" [value]="lab.id">{{ lab.name }}</mat-option>
    </mat-select>
  </div>
  <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4px" class="selector-wrapper">
    <label>{{ 465 | translate: 'Room' }}</label>
    <mat-select
      class="room-stations-dropdown"
      placeholder="{{ '55' | translate: 'All Rooms' }}"
      [(ngModel)]="selectedRoomId"
      disableOptionCentering
      (selectionChange)="filterTheStations($event)"
      panelClass="custom-select-panel">
      <mat-option *ngFor="let room of filteredRoomList" [value]="room.id">{{ room.name }}</mat-option>
    </mat-select>
  </div>
  <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4px" class="selector-wrapper">
    <label>{{ 532 | translate: 'Station' }}</label>
    <mat-select
      class="room-stations-dropdown"
      placeholder="{{ '56' | translate: 'All Stations' }}"
      [(ngModel)]="selectedStationId"
      disableOptionCentering
      (selectionChange)="setSelectTheStation($event)"
      panelClass="custom-select-panel">
      <mat-option *ngFor="let station of filteredStationList" [value]="station.id">
        {{ station.name }}
      </mat-option>
    </mat-select>
  </div>
</div>
