import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionControlService } from '../services';

@Directive({
  selector: '[appAccessControl]',
})
export class AccessControlDirective {
  @Input() set appAccessControl(accessControlParam: any) {
    this.hasAccess(accessControlParam) ? this.container.createEmbeddedView(this.templateRef) : this.container.clear();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private permissionControlService: PermissionControlService
  ) {}

  hasAccess(accessControlParams: any) {
    const { action, source, domain, domainType } = JSON.parse(accessControlParams as any);
    return this.permissionControlService.isActiveUserHasAuthorization(domainType, domain, source, action);
  }
}
