import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class RoomChangedService {
  private newRoom: Subject<any> = new Subject();

  setDataToSelectedRoom = this.newRoom.asObservable();

  selectedRoomChanged(data: any) {
    this.newRoom.next(data);
  }
}
