import { Component, Input, ViewChild } from '@angular/core';
import { MenuItemType } from 'src/app/shared/enums/menu-item-type.enum';
import { StationChangedService } from 'src/app/shared/services/station-changed.service';
import { RoomChangedService } from '../../../services/room-changed.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() menuItems: any;
  @Input() itemType: MenuItemType;
  @ViewChild('childMenu', { static: true }) public childMenu: any;

  constructor(private stationChangedService: StationChangedService, private roomChangedService: RoomChangedService) {}

  get menuItemType(): any {
    return MenuItemType;
  }

  hasChildren(child: any): boolean {
    return child.children && child.children.length > 0;
  }

  isLeafNode(child: any): boolean {
    return !child.children || child.children.length === 0;
  }

  stationChanged(station: any) {
    this.stationChangedService.selectedStationChanged(station);
  }

  roomChanged(room: any) {
    this.roomChangedService.selectedRoomChanged(room);
  }
}
