import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetTestTraceCalculationsResponse, VirtualTrace } from 'src/app/core/models';
import {
  DFCalculation,
  SS1Calculation,
  SS2Calculation,
} from 'src/app/modules/test/test-detail/info-and-notes/energy-regulation-calculation/energy-regulation-calculation.component';
import { RedisResponse } from 'src/app/shared/interfaces';
import { environment } from '../../../environments/environment';
import { TraceType, VirtualTraceCalculationType } from '../enums';

const baseUrl = environment.dataServicePath;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getRedisData(
    traces: (string | number)[],
    plotSize: number,
    sampleTime: number,
    range: { minRange: number | '-'; maxRange: number | '+' },
  ) {
    return this.http.post<RedisResponse[]>(
      `${baseUrl}/trace/getRedisData`,
      {
        traces,
        plotSize,
        sampleTime,
        range,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getDataLength(traces: number[], range: { minRange: number | '-'; maxRange: number | '+' } | undefined) {
    return this.http.post<number[]>(
      `${baseUrl}/trace/getDataLength`,
      { traces, range },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getAvailabilityData(labId: number, date: { min: number; max: number }) {
    return this.http.post(
      `${baseUrl}/lab/getAvailabilityData`,
      {
        labId,
        date,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  importTest(file: any): Observable<any> {
    const formData: any = new FormData();
    formData.append('file', file);

    return this.http.post(`${baseUrl}/archive/importTest`, formData, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auid: this.auid,
      },
    }) as Observable<any>;
  }

  checkImport(testId: number) {
    return this.http.post<{ success: boolean }>(
      `${baseUrl}/archive/checkImport`,
      { testId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  setArchiveTypeForImport(testId: number) {
    return this.http.post<{ success: boolean }>(
      `${baseUrl}/archive/setArchiveTypeForImport`,
      { testId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getMomentaryAvailabilityData(labId: number) {
    return this.http.post(
      `${baseUrl}/lab/getMomentaryAvailabilityData`,
      { labId },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  createCombinedTrace(
    name: string,
    testViewId: number,
    traceIds: number[],
    sampleTime: number,
    virtualTraceCalculationType: VirtualTraceCalculationType,
  ) {
    return this.http.post<VirtualTrace>(
      `${baseUrl}/trace/createCombinedTrace`,
      {
        name,
        testViewId,
        traceIds,
        sampleTime,
        calculationType: virtualTraceCalculationType,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  deleteVirtualTrace(virtualTraceId: number) {
    return this.http.post(
      `${baseUrl}/trace/deleteVirtualTrace`,
      {
        virtualTraceId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getVirtualTraces(
    traces: number[],
    plotSize: number,
    sampleTime: number,
    range: { minRange: number | '-'; maxRange: number | '+' },
    isTestRunning: boolean,
  ) {
    return this.http.post<RedisResponse[]>(
      `${baseUrl}/trace/getVirtualTraces`,
      {
        traces,
        plotSize,
        sampleTime,
        range,
        isTestRunning,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getUdaqTraces(
    udaqIds: number[],
    plotSize: number,
    sampleTime: number,
    range: { minRange: number | '-'; maxRange: number | '+' },
  ) {
    return this.http.post<RedisResponse[]>(
      `${baseUrl}/trace/getUdaqTraces`,
      {
        ids: udaqIds,
        plotSize,
        sampleTime,
        range,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getBoxTraceValues(id: number, traceType: TraceType, range: number) {
    return this.http.post(
      `${baseUrl}/trace/getBoxTraceValues`,
      {
        id,
        traceType,
        range,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getMultipleBoxTraceValues(traces: { id: number; traceType: TraceType; range: number }[]) {
    return this.http.post<any[]>(
      `${baseUrl}/trace/getMultipleBoxTraceValues`,
      {
        traces,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getLiveValuesWithSensorIds(sensorIds: number[]): Observable<any> {
    return this.http.post(
      `${baseUrl}/trace/getLiveValuesWithSensorIds`,
      {
        sensorIds,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getLiveValuesWithTraceIds({ traceIds, virtualTraceIds }: { traceIds: number[]; virtualTraceIds: number[] }) {
    return this.http.post<{
      traceValues: { id: number; value: number; hasNotValue: number; readDate?: number }[];
      vTraceValues: { id: number; value: number; hasNotValue: number; readDate?: number }[];
    }>(
      `${baseUrl}/trace/getLiveValuesWithTraceIds`,
      {
        traceIds,
        virtualTraceIds,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  resetTestTraces(traceArr: { key: number; isVirtualTrace: boolean }[], testViewId: number) {
    return this.http.post(
      `${baseUrl}/trace/resetTestTraces`,
      {
        traceArr,
        testViewId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  updateArchiveTraces(traceArr: { key: number; isVirtualTrace: boolean }[], testViewId: number) {
    return this.http.post(
      `${baseUrl}/trace/updateArchiveTraces`,
      {
        traceArr,
        testViewId,
      },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      },
    );
  }

  getDF1Values(
    nominalCenter: number,
    startOfD: number,
    endOfD: number,
    startOfF: number,
    endOfF: number,
    tempTrace: number,
    powTrace: number,
    isTempVirtual: boolean,
    powerThreshold: number,
  ): Observable<DFCalculation> {
    return this.http.post<DFCalculation>(
      `${baseUrl}/trace/getDF1Values`,
      {
        nominalCenter,
        startOfD,
        endOfD,
        startOfF,
        endOfF,
        tempTrace,
        powTrace,
        isTempVirtual,
        powerThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getDF2Values(
    dfPoints: { nominalCenter: number; startOfD1: number; endOfF1: number }[],
    tempTrace: number,
    powTrace: number,
    isTempVirtual: boolean,
    powerThreshold: number,
  ) {
    return this.http.post<DFCalculation[]>(
      `${baseUrl}/trace/getDF2Values`,
      {
        dfPoints,
        tempTrace,
        powTrace,
        isTempVirtual,
        powerThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getSS1Values(
    tempTrace: number,
    powTrace: number,
    range: { minRange: number; maxRange: number },
    isTempVirtual: boolean,
    powerThreshold: number,
  ) {
    return this.http.post<SS1Calculation>(
      `${baseUrl}/trace/getSS1Values`,
      {
        tempTrace,
        powTrace,
        range,
        isTempVirtual,
        powerThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getSS2Values(
    tempTrace: number,
    powTrace: number,
    ranges: { minRange: number; maxRange: number }[],
    isTempVirtual: boolean,
    powerThreshold: number,
  ) {
    return this.http.post<SS2Calculation>(
      `${baseUrl}/trace/getSS2Values`,
      {
        tempTrace,
        powTrace,
        ranges,
        isTempVirtual,
        powerThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getDailyEnergyConsumption(engTrace: number) {
    return this.http.post<{ dailyAvg: number }>(
      `${baseUrl}/trace/getDailyEnergyConsumption`,
      {
        engTrace,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getNextCycleTimestamp(
    powTrace: number,
    startingTimestamp: number,
    selectionMode: boolean,
    direction: boolean,
    powerThreshold: number,
  ) {
    return this.http.post<{ timestamp: number }>(
      `${baseUrl}/trace/getNextCycleTimestamp`,
      {
        powTrace,
        startingTimestamp,
        selectionMode,
        direction,
        powerThreshold,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getTestTraceCalculations(testId: number) {
    return this.http.post<GetTestTraceCalculationsResponse>(
      `${baseUrl}/trace/getTestTraceCalculations`,
      {
        testId,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  updateUdaqTraces(testId: number) {
    return this.http.post(
      `${baseUrl}/trace/updateUdaqTraces`,
      {
        testId,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }
}
