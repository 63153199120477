<div class="menu menu-container extra-time-menu" fxLayout="column" fxLayoutGap="33px">
  <div class="header" fxLayout="row">
    <div fxFlex class="header-text">
      {{ '20063' | translate: 'Extra Time Request' }}
    </div>
    <div class="icons-wrapper" fxLayout="row" fxLayoutAlign="end start">
      <div class="close-icon-wrapper" (click)="closeMenu.emit()" matTooltip="{{ 20154 | translate: 'Close' }}">
        <mat-icon>keyboard_tab</mat-icon>
      </div>
    </div>
  </div>
  <div fxFlex class="menu-content extra-time-menu-content" fxLayout="column" fxLayoutGap="24px">
    <div>
      <div class="rt-form" fxLayout="row">
        <div fxLayout="column" fxFlex="50">
          <div class="form-row" fxLayout="column">
            <label class="disabled-label">{{ '570' | translate: 'Test Name' }}</label>
            <span class="extra-time-info">{{ testName }}</span>
          </div>
          <div class="form-row" fxLayout="column">
            <label class="disabled-label">{{ '116' | translate: 'Channel Description' }}</label>
            <span class="extra-time-info">{{ description }}</span>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50">
          <div class="form-row" fxLayout="column">
            <label class="disabled-label">{{ '433' | translate: 'Requested Day' }}</label>
            <span class="extra-time-info">{{ requestedTime }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="planning-wrapper" fxLayout="column" fxLayoutAlign="space-between center">
      <div
        *ngIf="!isInDrawer"
        fxLayout="row"
        class="vertical-align-center planning-container"
        fxLayoutAlign="space-between center"
        fxFlex>
        <app-lab-room-selector
          [setRoomId]="selectedRoomId"
          (selectedRoomChanged)="selectedRoomChanged($event)"></app-lab-room-selector>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
          <div fxLayout="row" class="sub-header">
            <div class="filter-text-inbox-wrapper" fxLayout="row">
              <div fxLayout="row" class="temp-indicator">
                <mat-icon class="temp-icon">device_thermostat</mat-icon>
                <span class="temp-text vertical-align-center">{{ tempText }}</span>
              </div>
              <div fxLayout="row">
                <mat-icon class="humidity-icon">water_drop</mat-icon>
                <span class="humidity-text vertical-align-center">{{ humidityText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="planning-container">
        <rt-planning
          [resources]="resources"
          [events]="events"
          [focusedTestId]="testId"
          (dateChanged)="onDateChanged($event)"
          (resourceDialogClicked)="onResourceDialogClicked($event)"
          (eventDialogClicked)="onEventDialogClicked($event)"
          [options]="planningOptions"></rt-planning>
      </div>
    </div>
  </div>
  <div class="menu-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button
      class="rt-btn-secondary rt-btn-lg"
      [disabled]="testExtensionRequestStatus !== extensionApproveStatus.Pending"
      [ngClass]="{ 'rt-btn-disable': testExtensionRequestStatus !== extensionApproveStatus.Pending }"
      (click)="rejectExtraTimeRequest()">
      {{ '423' | translate: 'Reject' }}
    </button>
    <button
      class="rt-btn-secondary rt-btn-lg"
      [disabled]="testExtensionRequestStatus !== extensionApproveStatus.Pending"
      [ngClass]="{ 'rt-btn-disable': testExtensionRequestStatus !== extensionApproveStatus.Pending }"
      (click)="approveExtraTimeRequest()">
      {{ '67' | translate: 'Approve' }}
    </button>
  </div>
</div>
