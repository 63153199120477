<div class="launcher-wrapper">
  <div class="launcher-search-bar">
    <div class="launcher-search-wrapper">
      <mat-icon class="launcher-header-icon">{{selectedTest ? 'arrow_forward' :'search'}}</mat-icon>
      <input type="text" class="launcher-search-input" placeholder="{{ '492' | translate: 'Search' }}..." [(ngModel)]="filterText" (ngModelChange)="filterTests()"/>
    </div>
    <div class="launcher-dialog-close-icon-wrapper" (click)="closeDialog()">
      <mat-icon class="launcher-dialog-close-icon">close</mat-icon>
    </div>
  </div>
  <div class="launcher-body">
    <div class="launcher-body-header">
      <div class="launcher-body-header-wrapper">
        <span class="launcher-body-header-text">{{ '570' | translate: 'Test Name' }}</span>
        <span *ngIf="isExtraColumnsWillShowByEnvironment" class="launcher-body-header-text">{{'208' | translate: 'Engineer'}}</span>
        <span *ngIf="isExtraColumnsWillShowByEnvironment" class="launcher-body-header-text">{{'50020' | translate: 'Technician'}}</span>
        <span *ngIf="isExtraColumnsWillShowByEnvironment" class="launcher-body-header-text">{{ '50016' | translate: 'Index No' }}</span>
        <span class="launcher-body-header-text">{{ '465' | translate: 'Room' }} / {{ '532' | translate: 'Station' }}</span>
      </div>
    </div>
    <div class="launcher-test-list-wrapper">
      <div class="launcher-test-wrapper" [ngClass]="selectedTest && selectedTest.id === test.id ? 'launcher-selected-test-wrapper' : ''" *ngFor="let test of filteredTests" (click)="changeSelectedTest(test)" (dblclick)="goToTest()">
        <div class="launcher-test-name-wrapper">
          <mat-icon class="launcher-test-name-icon">ssid_chart</mat-icon>
          <span class="launcher-test-name-text" matTooltip="{{ test.name }}">{{ test.name }}</span>
        </div>
        <span *ngIf="isExtraColumnsWillShowByEnvironment" class="launcher-test-pale-text" matTooltip="{{ test.inventory_engineer }}">{{ test.inventory_engineer }}</span>
        <span *ngIf="isExtraColumnsWillShowByEnvironment" class="launcher-test-pale-text" matTooltip="{{ test.inventory_technician}}"> {{ test.inventory_technician }}</span>
        <span *ngIf="isExtraColumnsWillShowByEnvironment" class="launcher-test-pale-text" matTooltip="{{ test.inventory_index_no  }}">{{ test.inventory_index_no }}</span>
        <span class="launcher-test-shiny-text" matTooltip="{{ test.test_space_parent_name + ' / ' +  test.test_space_name }}">{{ test.test_space_parent_name }} / {{ test.test_space_name}}</span>
      </div>
    </div>
  </div>
  <rt-loading-indicator
  [showLoadingIndicator]="isLoading"
  [path]="loaderPath"
  [isDarkMode]="isDarkMode"></rt-loading-indicator>
</div>

