import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DFCalculation,
  SS1Calculation,
} from 'src/app/modules/test/test-detail/info-and-notes/energy-regulation-calculation/energy-regulation-calculation.component';
import { environment } from 'src/environments/environment';
import { TraceType } from '../enums';

const baseUrl = environment.csvServicePath;

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  createTestViewCSV(
    testViewId: number,
    hasUdaq: boolean,
    floatString: ',' | '.',
    truncateAmount: number,
    deviceTag: string,
    sortedTraces: { id: number; sortIndex: number; type: TraceType }[],
  ) {
    return this.http.post(
      `${baseUrl}/createTestViewCSV`,
      { testViewId, hasUdaq, floatString, truncateAmount, deviceTag, userId: this.auid, sortedTraces },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  createRangeSelectionExcel(
    testViewId: number,
    rangeId: number,
    truncateAmount: number,
    range: { minRange: number; maxRange: number },
    plotImage: string,
    deviceTag: string,
    rangeValues: {
      id: number;
      name: string;
      avg: number;
      min: number;
      max: number;
      start: number;
      end: number;
      isVirtualTrace: boolean;
    }[],
    hasUdaq: boolean,
    sortedTraces: { id: number; sortIndex: number; type: TraceType }[],
  ) {
    return this.http.post(
      `${baseUrl}/createRangeSelectionExcel`,
      {
        testViewId,
        rangeId,
        truncateAmount,
        range,
        plotImage,
        deviceTag,
        rangeValues,
        hasUdaq,
        userId: this.auid,
        sortedTraces,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  createTestReport(
    testId: number,
    calculations: {
      df1Calculations: DFCalculation;
      df2Calculations: DFCalculation[];
      ss1Calculations: SS1Calculation;
      ss2Calculations: DFCalculation;
    },
  ) {
    return this.http.post(
      `${baseUrl}/createTestReport`,
      { testId, calculations },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  createExport(testId: number) {
    return this.http.post(
      `${baseUrl}/createExport`,
      { testId },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  getAvailableTestExports(): Observable<string[]> {
    return this.http.get<string[]>(`${baseUrl}/getAvailableTestExports`, {
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  getExport(testId: number) {
    const options = { responseType: 'blob' as 'json' };

    return this.http.get(`${baseUrl}/getExport?testId=${testId}`, {
      ...options,
      headers: {
        authorization: `Bearer ${this.token}`,
        auId: this.auid,
      },
    });
  }

  createPLCReport(variableIds: number[], plcIds: number[], startDate: number, fileName: string) {
    return this.http.post(
      `${baseUrl}/createPLCReport`,
      {
        variableIds,
        plcIds,
        startDate,
        fileName,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  hasTestViewCSV(testViewId: number) {
    return this.http.post(
      `${baseUrl}/hasTestViewCSV`,
      { testViewId },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }
}
