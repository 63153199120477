<div fxLayout="column" fxLayoutGap="5px" class="device-menu-content">
  <div class="device-dialog-header">{{ '20160' | translate: 'Specimens' }}</div>
  <div fxLayout="row" class="filter-text-input-container">
    <div class="filter-text-inbox-device-wrapper">
      <input [formControl]="searchValueControl" type="text" placeholder="{{ 492 | translate: 'Search' }}..." />
      <mat-icon>search</mat-icon>
    </div>
  </div>
  <div fxFlex class="device-grid-menu-wrapper custom-table-menu-wrapper">
    <rt-table-ssr
      #table
      [setServerSideDataSource]="serverSideDatasource"
      [cacheBlockSize]="cacheBlockSize"
      [maxBlocksInCache]="maxBlocksInCache"
      (requestParamsChanged)="requestParamsChanged($event)"
      [columnNameToSort]="columnNameToSort"
      [defaultSortColumnInfo]="defaultSortColumnInfo"
      class="device-table"
      [gridOptions]="gridOptions"
      [defaultColDef]="defaultColDef"
      [setColumnDefs]="columnDefs"
      [tableName]="tableName"
      [filterNameText]="20129 | translate: 'Filter Name'"
      [saveText]="483 | translate: 'Save'"
      [columnsText]="20010 | translate: 'Columns'"
      [savedFiltersText]="20009 | translate: 'Saved Filters'"
      [savedCurrentFilterText]="20011 | translate: 'Save Current Filter'"
      [columnText]="20015 | translate: 'Column'"
      [filterText]="246 | translate: 'Filter'"
      [savedFilters]="savedFilters"
      [frameworkComponents]="frameworkComponents"
      [noRowsTemplate]="1918 | translate: 'No Data To Show'"
      [saveFilterChangesText]="20207 | translate: 'Save Filter Changes'"
      (savedFilterDeleted)="onSavedFilterDeleted($event)"
      (activeSavedFilterSwitched)="onActiveSavedFilterSwitched()"
      (userSavedFilterCreate)="onUserSavedFilterCreate($event)"></rt-table-ssr>
    <rt-loading-indicator
      [showLoadingIndicator]="isLoadingDeviceData"
      [path]="loaderPath"
      [isDarkMode]="isDarkMode"></rt-loading-indicator>
  </div>
</div>
