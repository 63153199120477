import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.statisticServicePath;

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  public token: string | null = localStorage.getItem('token');
  private auid = localStorage.getItem('userId') as string;

  constructor(private http: HttpClient) {}

  setToken(token: string) {
    this.token = token;
  }

  setAuid(auid: string) {
    this.auid = auid;
  }

  getUsageStatistics(startDate: string, endDate: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/usageStatistics/getUsageStatistics`,
      { startDate, endDate },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      }
    ) as Observable<any>;
  }

  getMostPreferredTestTypes(startDate: string, endDate: string): Observable<any> {
    return this.http.post(
      `${baseUrl}/usageStatistics/getMostPreferredTestTypes`,
      { startDate, endDate },
      {
        headers: {
          authorization: `Bearer ${this.token}`,
          auid: this.auid,
        },
      }
    ) as Observable<any>;
  }
}
